import React from "react";
import "./user.scss";
import { getValue } from "@utils/lodash";
import { getFirstLetterOfEachString } from "@common/text-helpers";
import EditSvgComponent from "@assets/svg-components/edit";
import ResendSvgComponent from "@assets/svg-components/resend";
import { getColorStyle } from "@common/color";
import ProfileCircle from "@components/ProfileCircle";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
function UsersTable(props: any) {
  return (
    <table className="datatable_table">
      <thead className="datatable_table_thead">
        <tr className="datatable_table_tr">
          <th className="datatable_table_th">
            <label className="m-0 position-relative" htmlFor="header-checkbox">
              <input
                type="checkbox"
                className="default-checkbox"
                id="header-checkbox"
                onChange={() =>
                  props.handleCheckAll(
                    getValue(props, `usersList.length`, 0) ===
                      getValue(props, `selectedIDs.length`, 0)
                      ? "All"
                      : "None"
                  )
                }
                checked={
                  getValue(props, `usersList.length`, 0) ===
                  getValue(props, `selectedIDs.length`, 0)
                }
              />
              <div className="custom-checkbox">
                <img
                  src="/images/uploaded-details/tick.svg"
                  className="img-fluid uploaded-tick"
                />
              </div>
            </label>
          </th>
          <th className="datatable_table_th">
            <h6 className="">Name</h6>
            <p className="">Employee ID</p>
          </th>
          <th className="datatable_table_th">
            <h6 className="">Email Address</h6>
            <p className="">Department</p>
          </th>
          <th className="datatable_table_th">
            <h6 className="">Role</h6>
            <p className="">Policy</p>
          </th>
          <th className="datatable_table_th">
            <h6 className="">Submit to</h6>
          </th>
          <th className="datatable_table_th">
            <h6 className="">Status</h6>
          </th>
          <th className="datatable_table_th">
            <h6 className="">Action</h6>
          </th>
        </tr>
      </thead>
      <tbody className="datatable_table_tbody">
        {getValue(props, `usersList`, []).map((item: any, index: number) => {
          console.log(item);
          return (
            <tr key={index} className="datatable_table_tr">
              <td className="datatable_table_td">
                <label
                  className="m-0 position-relative"
                  htmlFor={`header-checkbox-${index}`}
                >
                  <input
                    type="checkbox"
                    className="default-checkbox"
                    id={`header-checkbox-${index}`}
                    onChange={() =>
                      props.handleCheckUsers(getValue(item, `id`, ""))
                    }
                    checked={getValue(props, `selectedIDs`, []).includes(
                      getValue(item, `id`, "")
                    )}
                  />
                  <div className="custom-checkbox">
                    <img
                      src="/images/uploaded-details/tick.svg"
                      className="img-fluid uploaded-tick"
                    />
                  </div>
                </label>
              </td>
              <td
                className="datatable_table_td"
                onClick={() => props.handleNavigate(getValue(item, `id`, ""))}
              >
                <div className="d-flex align-items-center">
                  <ProfileCircle
                    item={item}
                    name="submits_to"
                    value={getValue(item, `email`, "")}
                  />
                  <div>
                    <h6 className="color_primary">
                      {getValue(item, `first_name`, "")}{" "}
                      {getValue(item, `middle_name`, "")}{" "}
                      {getValue(item, `last_name`, "")}
                    </h6>
                    <p className="">{getValue(item, `employee_id`, "")}</p>
                  </div>
                </div>
              </td>
              <td
                className="datatable_table_td"
                onClick={() => props.handleNavigate(getValue(item, `id`, ""))}
              >
                <div className="d-flex align-items-center">
                  <div>
                    <h6 className="">{getValue(item, `email`, "")}</h6>
                    <p className="">
                      {getValue(item, `department.department_name`, "")}
                    </p>
                  </div>
                </div>
              </td>
              <td
                className="datatable_table_td"
                onClick={() => props.handleNavigate(getValue(item, `id`, ""))}
              >
                <div className="d-flex flex-wrap__wrap mb-1">
                  {getValue(item, `roles`, []).map(
                    (item: object, index: number) => {
                      if (index < 3)
                        return (
                          <p className="small_text__16 border border-radius__10 p-1 px-2">
                            {getValue(item, `title`, "")}
                          </p>
                        );
                    }
                  )}
                  {getValue(item, `roles.length`, 0) > 3 && (
                    <div className="d-flex align-items-center">
                      <p className="ms-2 small_text__14 color_primary">
                        {getValue(item, `roles.length`, 0) - 3}{" "}
                      </p>
                      <p className="ms-1 small_text__14 color_primary">more</p>
                    </div>
                  )}
                </div>
                <p className="">{getValue(item, `policy.policy_name`, "")}</p>
              </td>
              <td
                className="datatable_table_td"
                onClick={() => props.handleNavigate(getValue(item, `id`, ""))}
              >
                <div className="d-flex align-items-center">
                  <ProfileCircle item={item} name="submits_to" />
                  <div>
                    <h6 className="header_text__14">
                      {getValue(item, `submits_to.first_name`, "")}{" "}
                      {getValue(item, `submits_to.last_name`, "")}
                    </h6>
                    <p className="">{getValue(item, `submits_to.email`, "")}</p>
                  </div>
                </div>
              </td>
              <td
                className="datatable_table_td"
                onClick={() => props.handleNavigate(getValue(item, `id`, ""))}
              >
                <h6
                  className=""
                  style={getColorStyle(getValue(item, `status`, ""))}
                >
                  {getValue(item, `status`, "")}
                </h6>
              </td>
              <td className="datatable_table_td action-icon-td">
                {/* <div className="d-flex align-items-center ">
                  {!getValue(item, `is_verified`, false) && (
                    <div
                      onClick={() =>
                        props.sendInviteUser(getValue(item, `id`, ""))
                      }
                    >
                      <ResendSvgComponent />
                    </div>
                  )}
                  <div
                    onClick={() =>
                      props.handleNavigate(getValue(item, `id`, ""))
                    }
                  >
                    <EditSvgComponent />
                  </div> */}
                {/* <div
                      className="action_container mx-2"
                      onClick={() =>
                        props.handleNavigate(getValue(item, `id`, ""))
                      }
                    >
                      <img
                        src="/images/pen.svg"
                        className="action_container_image img-fluid"
                      />
                    </div> */}
                {/* <div className="action_container mx-2">
                      <img
                        src="/images/delete-gray.svg"
                        className="action_container_image img-fluid"
                        onClick={() =>
                          props.handleDeleteFun(
                            getValue(item, `id`, ""),
                            getValue(item, `title`, "")
                          )
                        }
                      />
                    </div> */}
                {/* </div> */}

                {/* <div> */}
                {getValue(props, `permissions`, []).includes("update") && (
                  <UncontrolledDropdown
                    className="table-action-icon-wrap"
                    direction="end"
                    container={"body"}
                  >
                    <DropdownToggle>
                      <div className="switch_header__dropdown m-0 align-items-center">
                        <div className="close-image_container action-icon-wrap">
                          <img
                            src={"/images/more.svg"}
                            className=" close_image"
                          />
                        </div>
                      </div>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>
                        <div
                          className={`more-dropdown-main-item `}
                          onClick={() =>
                            props.handleNavigate(getValue(item, `id`, ""))
                          }
                        >
                          <p>Edit</p>
                        </div>
                      </DropdownItem>
                      {!getValue(item, `is_verified`, false) && (
                        <DropdownItem>
                          <div
                            className={`more-dropdown-main-item `}
                            onClick={() =>
                              props.sendInviteUser(getValue(item, `id`, ""))
                            }
                          >
                            <p>Resend Invite</p>
                          </div>
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
                {/* </div> */}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default UsersTable;
