import InputField from "@common/InputRuleFeild";
import SingleSelectSearchDropdown from "@components/common/CustomSearchableDropdown/SingleSelectSearchDropdown";
import { getValue } from "@utils/lodash";
import React from "react";

function CustomerForm(props: any) {
  const {
    request,
    setRequest,
    simpleValidator,
    handleChangeText,
    types,
  } = props;
  return (
    <div className="checkpoint-active-details-wrapper__form-wrapper">
      <div className="checkpoint-active-details-wrapper__form-content">
        <div className="user-details-wrapper__form  user-details-wrapper__form--org-profile">
          <div className="user-details-wrapper__form-group form-group mb-3">
            <label className="checkpoint-active-details__label">
              Customer Type <span>*</span>
            </label>
            <SingleSelectSearchDropdown
              label={"label"}
              name="Customer Type"
              value={getValue(request, `customer_type`, "")}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  customer_type: getValue(e, `value`, ""),
                })
              }
              data={types}
              placeholder={"Choose Customer Type"}
              width={"100%"}
              validator={simpleValidator}
              selectKey={"value"}
            />
          </div>
          <div className="user-details-wrapper__form-group form-group">
            <label className="checkpoint-active-details__label">
              Salutation <span>*</span>
            </label>
            <InputField
              inputType="TEXT"
              placeholder="Enter Organization Name"
              className="form-control w-100"
              name="salutation"
              id="salutation"
              value={getValue(request, `salutation`, "")}
              onChange={(e: any) => handleChangeText(e)}
              label="Salutation"
              validator={simpleValidator}
            />
          </div>
          <div className="user-details-wrapper__form-group form-group">
            <label className="checkpoint-active-details__label">
              First Name <span>*</span>
            </label>
            <InputField
              inputType="TEXT"
              placeholder="Enter First Name"
              className="form-control w-100"
              name="first_name"
              id="first_name"
              value={getValue(request, `first_name`, "")}
              onChange={(e: any) => handleChangeText(e)}
              label="First Name"
              validator={simpleValidator}
            />
          </div>
          <div className="user-details-wrapper__form-group form-group">
            <label className="checkpoint-active-details__label">
              Last Name <span>*</span>
            </label>
            <InputField
              inputType="TEXT"
              placeholder="Enter Last Name"
              className="form-control w-100"
              name="last_name"
              id="last_name"
              value={getValue(request, `last_name`, "")}
              onChange={(e: any) => handleChangeText(e)}
              label="Last Name"
              validator={simpleValidator}
            />
          </div>
          <div className="user-details-wrapper__form-group form-group">
            <label className="checkpoint-active-details__label">
              Company Name <span>*</span>
            </label>
            <InputField
              inputType="TEXT"
              placeholder="Enter Company Name"
              className="form-control w-100"
              name="company_name"
              id="company_name"
              value={getValue(request, `company_name`, "")}
              onChange={(e: any) => handleChangeText(e)}
              label="Company Name"
              validator={simpleValidator}
            />
          </div>
          <div className="user-details-wrapper__form-group form-group">
            <label className="checkpoint-active-details__label">
              Contact Display Name <span>*</span>
            </label>
            <InputField
              inputType="TEXT"
              placeholder="Enter Contact Display Name"
              className="form-control w-100"
              name="contact_display_name"
              id="contact_display_name"
              value={getValue(request, `contact_display_name`, "")}
              onChange={(e: any) => handleChangeText(e)}
              label="Contact Display Name "
              validator={simpleValidator}
            />
          </div>

          <div className="user-details-wrapper__form-group form-group">
            <label className="checkpoint-active-details__label">
              Email <span>*</span>
            </label>
            <InputField
              inputType="TEXT"
              placeholder="Enter Email"
              className="form-control w-100"
              name="email"
              id="email"
              value={getValue(request, `email`, "")}
              onChange={(e: any) => handleChangeText(e)}
              label="Email"
              validator={simpleValidator}
            />
          </div>
          <div className="user-details-wrapper__form-group form-group">
            <label className="checkpoint-active-details__label">
              Website <span>*</span>
            </label>
            <InputField
              inputType="TEXT"
              placeholder="Enter Website"
              className="form-control w-100"
              name="website"
              id="website"
              value={getValue(request, `website`, "")}
              onChange={(e: any) => handleChangeText(e)}
              label="Website"
              validator={simpleValidator}
            />
          </div>

          <div className="user-details-wrapper__form-group form-group">
            <label className="checkpoint-active-details__label">
              Phone Number <span>*</span>
            </label>
            <InputField
              inputType="TEXT"
              placeholder="Enter Phone Number"
              className="form-control w-100"
              name="phone_no"
              id="phone_no"
              value={getValue(request, `phone_no`, "")}
              onChange={(e: any) => handleChangeText(e)}
              label="Phone Number"
              validator={simpleValidator}
            />
          </div>
          <div className="user-details-wrapper__form-group form-group">
            <label className="checkpoint-active-details__label">
              Mobile Number <span>*</span>
            </label>
            <InputField
              inputType="TEXT"
              placeholder="Enter Mobile Number"
              className="form-control w-100"
              name="mobile_no"
              id="mobile_no"
              value={getValue(request, `mobile_no`, "")}
              onChange={(e: any) => handleChangeText(e)}
              label="Mobile Number"
              validator={simpleValidator}
            />
          </div>
          <div className="user-details-wrapper__form-group form-group">
            <label className="checkpoint-active-details__label">
              Currency <span>*</span>
            </label>
            <SingleSelectSearchDropdown
              label={"label"}
              name="Currency"
              value={getValue(request, `currency`, "")}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  currency: getValue(e, `id`, ""),
                })
              }
              data={types}
              placeholder={"Choose Currency"}
              width={"100%"}
              validator={simpleValidator}
              lookup_api={"base_currency"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerForm;
