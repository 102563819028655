import { getValue } from "@utils/lodash";
import React from "react";

function ExpenseSvgComponent(props: any) {
  return (
    <>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        // width="18"
        // height="18"
        viewBox="0 0 18 18"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"
        }
      >
        <g id="sticky_note_2_black_24dp" transform="translate(-0.262 -0.262)">
          <rect
            id="Rectangle_13818"
            data-name="Rectangle 13818"
            width="18"
            height="18"
            transform="translate(0.262 0.262)"
            fill="none"
          />
          <path
            id="Path_39131"
            data-name="Path 39131"
            d="M15.349,4.544V11.49H11.49v3.859H4.544V4.544H15.349m0-1.544H4.544A1.548,1.548,0,0,0,3,4.544V15.349a1.548,1.548,0,0,0,1.544,1.544h7.718l4.631-4.631V4.544A1.548,1.548,0,0,0,15.349,3Zm-5.4,8.49H6.087V9.946H9.946ZM13.805,8.4H6.087V6.859h7.718Z"
            transform="translate(-0.685 -0.685)"
            // fill="#3c4043"
            fill={
              getValue(props, `color`, "")
                ? getValue(props, `color`, "")
                : "#3c4043"
            }
          />
        </g>
      </svg> */}
      <svg xmlns="http://www.w3.org/2000/svg" 
      width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"}
      height={
        getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"
      }
      viewBox="0 0 18 18">
        <g id="sticky_note_2_black_24dp" transform="translate(-0.262 -0.262)">
          <rect id="Rectangle_13818" data-name="Rectangle 13818" width="18" height="18" transform="translate(0.262 0.262)" fill="none"/>
          <path id="Path_39131" data-name="Path 39131" d="M15.349,4.544V11.49H11.49v3.859H4.544V4.544H15.349m0-1.544H4.544A1.548,1.548,0,0,0,3,4.544V15.349a1.548,1.548,0,0,0,1.544,1.544h7.718l4.631-4.631V4.544A1.548,1.548,0,0,0,15.349,3Zm-5.4,8.49H6.087V9.946H9.946ZM13.805,8.4H6.087V6.859h7.718Z" transform="translate(-0.685 -0.685)" fill={
              getValue(props, `color`, "")
                ? getValue(props, `color`, "")
                : "#3c4043"
            } />
        </g>
      </svg>
    </>
  );
}

export default ExpenseSvgComponent;
