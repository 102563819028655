import { QueryRequestHelper } from "@common/query-request-helper";
import { formatText } from "@common/text-helpers";
import { getAllCurrency } from "@services/currency.service";
import { getAllCustomers } from "@services/customers.service";
import { getAllDepartments } from "@services/departments.service";
import { getAllExpenseCategories } from "@services/expense-category.service";
import { getAllExpenseReports } from "@services/expense-reports.service";
import { getAllExpenseTypes } from "@services/expense-type.service";
import {
  listManagementByGroupcode,
  locationManagementSearchCities,
  locationManagementSearchCountries,
} from "@services/list-management.service";
import { getAllPaidThroughAccount } from "@services/paid-through-accounts.service";
import { getAllPolicies } from "@services/policy.service";
import { getAllProjects } from "@services/projects.service";
import { getAllRoles } from "@services/roles.service";
import { getAllAirports, getAllTrips } from "@services/trips.service";
import { getAllCommonUsers } from "@services/users.service";
import { getValue } from "@utils/lodash";

export const baseCurrencySearchableDropdown = async (search_text: string) => {
  let paylod = {
    search_text: search_text,
    page_no: 1,
    page_size: 10,
    status: "active",
  };
  let queryRequest = QueryRequestHelper(paylod);
  try {
    let resp = await getAllCurrency(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.data.length`, 0) > 0
          ? getValue(resp, `data.data`, []).map((item: object) => ({
              ...item,
              value:
                getValue(item, `currency_symbol`, "") +
                " " +
                getValue(item, `currency_code`, ""),
              label:
                getValue(item, `currency_symbol`, "") +
                " " +
                getValue(item, `currency_code`, ""),
            }))
          : [];
      return data;
    }
  } catch (error) {}
};

export const usersSearchableDropdown = async (
  id: string,
  search_text: string
) => {
  let paylod = {
    id: id,
    search_text: search_text,
    page_no: 1,
    page_size: 10,
    is_active: true,
  };
  let queryRequest = QueryRequestHelper(paylod);
  try {
    let resp = await getAllCommonUsers(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.users.length`, 0) > 0
          ? getValue(resp, `data.users`, []).map((item: object) => ({
              ...item,
              value: `${getValue(item, `first_name`, "")}${" "}${getValue(
                item,
                `last_name`,
                ""
              )}${" "}${" "}(${getValue(item, `email`, "")})`,
              label: `${getValue(item, `first_name`, "")}${" "}${getValue(
                item,
                `last_name`,
                ""
              )}${" "}${" "}(${getValue(item, `email`, "")})`,
              workflow_name: `${getValue(
                item,
                `first_name`,
                ""
              )}${" "}${getValue(item, `last_name`, "")}`,
            }))
          : [];
      return data;
    }
  } catch (error) {}
};

export const usersByIDSearchableDropdown = async (
  id: string,
  search_text: string
) => {
  let paylod = {
    search_text: search_text,
    page_no: 1,
    page_size: 10,
    is_active: true,
    id: id,
  };
  let queryRequest = QueryRequestHelper(paylod);
  try {
    let resp = await getAllCommonUsers(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.users.length`, 0) > 0
          ? getValue(resp, `data.users`, []).map((item: object) => ({
              ...item,
              value: `${getValue(item, `id`, "")}`,
              label: `${getValue(item, `first_name`, "")}${" "}${getValue(
                item,
                `last_name`,
                ""
              )}${" "}${" "}(${getValue(item, `email`, "")})`,
              id: `${getValue(item, `id`, "")}`,
              workflow_name: `${getValue(
                item,
                `first_name`,
                ""
              )}${" "}${getValue(item, `last_name`, "")}${" "}${" "}(${getValue(
                item,
                `email`,
                ""
              )})`,
            }))
          : [];
      return data;
    }
  } catch (error) {}
};

export const customersSearchableDropdown = async (search_text: string) => {
  let paylod = {
    search_text: search_text,
    page_no: 1,
    page_size: 10,
    // is_active:true
  };
  let queryRequest = QueryRequestHelper(paylod);
  try {
    let resp = await getAllCustomers(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.data.length`, 0) > 0
          ? getValue(resp, `data.data`, []).map((item: object) => ({
              ...item,
              value: getValue(item, `contact_display_name`, ""),
              label: getValue(item, `contact_display_name`, ""),
              workflow_name: getValue(item, `contact_display_name`, ""),
            }))
          : [];
      return data;
    }
  } catch (error) {}
};

export const rolesSearchableDropdown = async (
  id: string,
  search_text: string
) => {
  let paylod = {
    search_text: search_text,
    page_no: 1,
    page_size: 100,
    is_active: true,
    ids: id,
  };
  let queryRequest = QueryRequestHelper(paylod);
  try {
    let resp = await getAllRoles(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.roles.length`, 0) > 0
          ? getValue(resp, `data.roles`, []).map((item: object) => ({
              ...item,
              value: getValue(item, `title`, ""),
              label: getValue(item, `title`, ""),
            }))
          : [];
      return data;
    }
  } catch (error) {}
};

export const departmentsSearchableDropdown = async (
  id: string,
  search_text: string
) => {
  let paylod = {
    search_text: search_text,
    page_no: 1,
    page_size: 10,
    status: "active",
    id: id,
  };
  let queryRequest = QueryRequestHelper(paylod);
  try {
    let resp = await getAllDepartments(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.departments.length`, 0) > 0
          ? getValue(resp, `data.departments`, []).map((item: object) => ({
              ...item,
              value: getValue(item, `department_name`, ""),
              label: getValue(item, `department_name`, ""),
              workflow_name: getValue(item, `department_name`, ""),
            }))
          : [];
      return data;
    }
  } catch (error) {}
};
export const departmentsHeadSearchableDropdown = async (
  search_text: string
) => {
  let paylod = {
    search_text: search_text,
    page_no: 1,
    page_size: 10,
    is_active: true,
  };
  let queryRequest = QueryRequestHelper(paylod);
  try {
    let resp = await getAllDepartments(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.departments.length`, 0) > 0
          ? getValue(resp, `data.departments`, []).map((item: object) => ({
              ...item,
              label: `${getValue(
                item,
                `department_name`,
                ""
              )}${" - "}${getValue(
                item,
                `department_head.name`,
                ""
              )}${" "}${" "}(${getValue(item, `department_head.email`, "")})`,
              value: getValue(item, `id`, ""),
              id: getValue(item, `id`, ""),
            }))
          : [];
      return data;
    }
  } catch (error) {}
};

export const expenseTypeSearchableDropdown = async (
  id: string,
  search_text: string
) => {
  let paylod = {
    search_text: search_text,
    page_no: 1,
    page_size: 50,
    enable_policy: true,
    id: search_text ? "" : id,
    status: "active",
  };
  let queryRequest = QueryRequestHelper(paylod);
  try {
    let resp = await getAllExpenseTypes(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.data.length`, 0) > 0
          ? getValue(resp, `data.data`, []).map((item: object) => ({
              ...item,
              value: formatText(getValue(item, `expense_type_name`, "")),
              label: formatText(getValue(item, `expense_type_name`, "")),
              workflow_name: getValue(item, `expense_type_name`, ""),
            }))
          : [];
      return data;
    }
  } catch (error) {}
};

export const expenseReportsSearchableDropdown = async (search_text: string) => {
  let paylod = {
    search_text: search_text,
    page_no: 1,
    page_size: 10,
    filter_type: "list",
    approval_status: "unsubmitted",
    is_active: true,
  };
  let queryRequest = QueryRequestHelper(paylod);
  try {
    let resp = await getAllExpenseReports(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.expense_reports.length`, 0) > 0
          ? getValue(resp, `data.expense_reports`, []).map((item: object) => ({
              ...item,
              value:
                formatText(getValue(item, `report_name`, "")) +
                " - " +
                getValue(item, `report_id`, ""),
              label:
                formatText(getValue(item, `report_name`, "")) +
                " - " +
                getValue(item, `report_id`, ""),
              workflow_name: getValue(item, `report_name`, ""),
            }))
          : [];
      return data;
    }
  } catch (error) {}
};

export const expenseTripSearchableDropdown = async (search_text: string) => {
  let paylod = {
    search_text: search_text,
    page_no: 1,
    page_size: 10,
    filter_type: "expense_report",
    is_active: true,
  };
  let queryRequest = QueryRequestHelper(paylod);
  try {
    let resp = await getAllTrips(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.length`, 0) > 0
          ? getValue(resp, `data`, []).map((item: object) => ({
              ...item,
              value:
                formatText(getValue(item, `trip_name`, "")) +
                " - " +
                getValue(item, `trip_id`, ""),
              label:
                formatText(getValue(item, `trip_name`, "")) +
                " - " +
                getValue(item, `trip_id`, ""),
              workflow_name: getValue(item, `trip_name`, ""),
            }))
          : [];
      return data;
    }
  } catch (error) {}
};
export const expenseReportsSubmittedSearchableDropdown = async (
  search_text: string
) => {
  let paylod = {
    search_text: search_text,
    page_no: 1,
    page_size: 10,
    filter_type: "list",
    // approval_status: "unsubmitted",
    is_unsubmitted: true,
    is_active: true,
  };
  let queryRequest = QueryRequestHelper(paylod);
  try {
    let resp = await getAllExpenseReports(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.expense_reports.length`, 0) > 0
          ? getValue(resp, `data.expense_reports`, []).map((item: object) => ({
              ...item,
              value:
                formatText(getValue(item, `report_name`, "")) +
                " - " +
                getValue(item, `report_id`, ""),
              label:
                (getValue(item, `report_name`, "")),
                //  +" - " +
                // getValue(item, `report_id`, "")
              //   (getValue(item, `report_name`, "") + "\n" + getValue(item, `report_id`, "")), // For plain text rendering
              workflow_name: getValue(item, `report_name`, ""),
            }))
          : [];
      return data;
    }
  } catch (error) {}
};

export const expenseCategorySearchableDropdown = async (
  search_text: string
) => {
  let paylod = {
    search_text: search_text,
    page_no: 1,
    page_size: 10,
    is_active: true,
  };
  let queryRequest = QueryRequestHelper(paylod);
  try {
    let resp = await getAllExpenseCategories(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.data.length`, 0) > 0
          ? getValue(resp, `data.data`, []).map((item: object) => ({
              ...item,
              value: formatText(getValue(item, `category_name`, "")),
              label: formatText(getValue(item, `category_name`, "")),
              workflow_name: getValue(item, `category_name`, ""),
            }))
          : [];
      return data;
    }
  } catch (error) {}
};

export const paidThroughSearchableDropdown = async (
  id: string,
  search_text: string
) => {
  let paylod = {
    search_text: search_text,
    page_no: 1,
    page_size: 10,
    status: "active",
    id: id,
  };
  let queryRequest = QueryRequestHelper(paylod);
  try {
    let resp = await getAllPaidThroughAccount(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.data.length`, 0) > 0
          ? getValue(resp, `data.data`, []).map((item: object) => ({
              ...item,
              value: formatText(getValue(item, `account_name`, "")),
              label: formatText(getValue(item, `account_name`, "")),
              workflow_name: getValue(item, `account_name`, ""),
            }))
          : [];
      return data;
    }
  } catch (error) {}
};

export const policieshSearchableDropdown = async (
  id: string,
  search_text: string
) => {
  let paylod = {
    search_text: search_text,
    page_no: 1,
    page_size: 10,
    is_active: true,
    id: id,
  };
  let queryRequest = QueryRequestHelper(paylod);
  try {
    let resp = await getAllPolicies(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.data.length`, 0) > 0
          ? getValue(resp, `data.data`, []).map((item: object) => ({
              ...item,
              value: formatText(getValue(item, `policy_name`, "")),
              label: formatText(getValue(item, `policy_name`, "")),
              workflow_name: getValue(item, `policy_name`, ""),
            }))
          : [];
      return data;
    }
  } catch (error) {}
};

export const projectsSearchableDropdown = async (
  id: string,
  search_text: string
) => {
  let paylod = {
    search_text: search_text,
    page_no: 1,
    page_size: 10,
    // is_active:true,
    status: "active",
    id: id,
  };
  let queryRequest = QueryRequestHelper(paylod);
  try {
    let resp = await getAllProjects(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.data.length`, 0) > 0
          ? getValue(resp, `data.data`, []).map((item: object) => ({
              ...item,
              value: formatText(getValue(item, `project_name`, "")),
              label: formatText(getValue(item, `project_name`, "")),
              workflow_name: getValue(item, `project_name`, ""),
            }))
          : [];
      return data;
    }
  } catch (error) {}
};

export const airportsSearchableDropdown = async (search_text: string) => {
  let paylod = {
    search_text: search_text,
    page_no: 1,
    page_size: 10,
    // is_active:true
  };
  let queryRequest = QueryRequestHelper(paylod);
  try {
    let resp = await getAllAirports(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.airport.length`, 0) > 0
          ? getValue(resp, `data.airport`, []).map((item: object) => ({
              ...item,
              value: getValue(item, `id`, ""),
              label:
                " " +
                getValue(item, `id`, "") +
                " - " +
                formatText(getValue(item, `name`, "")),
            }))
          : [];
      return data;
    }
  } catch (error) {}
};

export const citiesSearchableDropdown = async (search_text: string) => {
  let paylod = {
    search_text: search_text,
    page_no: 1,
    page_size: 10,
    // is_active:true
  };
  let queryRequest = QueryRequestHelper(paylod);
  try {
    let resp = await locationManagementSearchCities(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.cities.length`, 0) > 0
          ? getValue(resp, `data.cities`, []).map((item: object) => ({
              ...item,
              value: getValue(item, `id`, ""),
              label: getValue(item, `name`, ""),
            }))
          : [];
      return data;
    }
  } catch (error) {}
};

export const designationsSearchableDropdown = async (search_text: string) => {
  let paylod = {
    search_text: search_text,
    page_no: 1,
    page_size: 10,
    // is_active:true
  };
  let queryRequest = QueryRequestHelper(paylod);
  try {
    let resp = await listManagementByGroupcode("designation");
    if (resp) {
      let data =
        getValue(resp, `data.listItems.length`, 0) > 0
          ? getValue(resp, `data.listItems`, []).map((item: object) => ({
              ...item,
              id: getValue(item, `id`, ""),
              value: getValue(item, `id`, ""),
              label: getValue(item, `text`, ""),
              workflow_name: getValue(item, `text`, ""),
            }))
          : [];
      return data;
    }
  } catch (error) {}
};

export const destinationSearchableDropdown = async (search_text: string) => {
  let paylod = {
    search_text: search_text,
    page_no: 1,
    page_size: 10,
    // is_active:true
  };
  let queryRequest = QueryRequestHelper(paylod);
  try {
    let resp = await locationManagementSearchCountries(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.countries.length`, 0) > 0
          ? getValue(resp, `data.countries`, []).map((item: object) => ({
              ...item,
              value: getValue(item, `id`, ""),
              label: getValue(item, `name`, ""),
            }))
          : [];
      return data;
    }
  } catch (error) {}
};
