import React, { useEffect, useState } from "react";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import { useNavigate } from "react-router-dom";
import { QueryRequestHelper } from "@common/query-request-helper";
import { deleteSpecificExpenseEntry } from "@services/expense-entry.service";
import { getValue } from "@utils/lodash";
import { toast } from "react-toastify";
import DeleteModal from "@common/popup/DeleteModal";
import PaginationPage from "@components/pagination/pagination";
import NavigationBar from "@components/NotificationBar";
import SearchHeader from "@components/common/SearchHeader";
import Notfound from "@components/Not-Found";
import TripsTable from "./table";
import {
  formatText,
  removeNullOrUndefinedProperties,
} from "@common/text-helpers";
import { getAllTripsAdmin } from "@services/trips.service";
import NoAccessPage from "@components/common/NoAccess";
import { adminTripsDownload } from "@services/upload.service";
import SkeletonTable from "@components/common/Skeleton/skeleton-table";

function AdminTrips(props: any) {
  let navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  let mainQueryRequest = QueryRequestHelper(urlParams);

  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const [isLoading, setIsLoading] = React.useState(true);
  const [list, setList] = React.useState<[]>([]);
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (Object.keys(urlParams).length === 0 && props.permissionAPITriggered) {
      getData();
    }
  }, [props.permissionAPITriggered]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlParams = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(urlParams).length > 0 && props.permissionAPITriggered) {
      getData();
      if (urlParams.page_no) {
        setPage_no(parseInt(urlParams.page_no));
      }
      if (urlParams.search_text) {
        setSearchInput(urlParams.search_text);
        setShowSearchTextbox(true);
      }
      if (urlParams.limit) {
        setLimit(parseInt(urlParams.limit));
      }
    }
  }, [window.location.href, props.permissionAPITriggered]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const getData = async () => {
    let payload: any = {
      page_no: urlParams.page_no ? parseInt(urlParams.page_no) : 1,
      page_size: urlParams.limit ? parseInt(urlParams.limit) : limit,
      search_text: urlParams.search_text,
      approval_status: urlParams.approval_status
        ? urlParams.approval_status
        : "approved",
      sort_by: urlParams.sort_by,
      travel_mode: "flight",
      filter_type: "list",
    };
    let queryRequest = QueryRequestHelper(payload);
    try {
      setIsLoading(true);
      let resp = await getAllTripsAdmin(queryRequest);
      if (resp) {
        setList(getValue(resp, `data.trip`, []));
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Search section                               */
  /* -------------------------------------------------------------------------- */
  const [showSearchTextbox, setShowSearchTextbox] = useState(false);
  const [search_text, setSearchInput] = useState<any>("");

  const toggleSearch = () => {
    setShowSearchTextbox((prevState) => !prevState);
  };
  const toggleSearchClose = () => {
    setSearchInput("");
    toggleSearch();
    if (urlParams.search_text) {
      delete urlParams.search_text;
      let payload: any = { ...urlParams };
      let queryRequest = QueryRequestHelper(payload);

      navigate(`${window.location.pathname}?${queryRequest}`);
      getData();
    }
  };

  const handleChangeSearch = async (e: any) => {
    setSearchInput(e.target.value);

    let payload: any = {
      ...urlParams,
      page_no: 1,
      search_text: e.target.value,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    if (!e.target.value) {
      getData();
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const [selectedIDs, setSelectedIDs] = useState<any>([]);

  const handleCheckUsers = (id: string) => {
    let userIDs =
      getValue(selectedIDs, `length`, 0) > 0
        ? selectedIDs.map((item: string) => item)
        : [];
    if (userIDs.includes(id)) {
      let filterIDs = selectedIDs.filter((item: string) => item !== id);
      setSelectedIDs(filterIDs);
    } else {
      selectedIDs.push(id);
      setSelectedIDs([...selectedIDs]);
    }
  };
  const handleCheckAll = (status: string) => {
    if (status === "All") {
      setSelectedIDs([]);
    } else {
      let userIDs =
        getValue(list, `length`, 0) > 0
          ? list.map((item: object) => getValue(item, `id`, ""))
          : [];
      setSelectedIDs(userIDs);
    }
  };

  const handleNavigateURL = (key: string, value: string) => {
    if (value) {
      let payload = {
        ...urlParams,
        [key]: value,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
    } else {
      delete urlParams[key];
      let payload = {
        ...urlParams,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
      getData();
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Delete section                             */
  /* -------------------------------------------------------------------------- */

  const [deleteValue, setDeleteValue] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const handleDeleteFun = (id: string, value: string) => {
    setDeleteValue(value);
    setDeleteId(id);
    handleDelete();
  };
  const [isDelete, setIsDelete] = useState(false);
  const handleDelete = () => {
    setIsDelete(!isDelete);
  };

  const handleDeleteFunction = async () => {
    try {
      let resp = await deleteSpecificExpenseEntry(deleteId);
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        handleDelete();
        getData();
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlParams = Object.fromEntries(urlSearchParams.entries());
    let payload = {
      ...urlParams,
      page_no: page_no,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  const handleChangeLimit = (limit: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlParams = Object.fromEntries(urlSearchParams.entries());
    let payload: any = {
      ...urlParams,
      limit: limit,
      page_no: "1",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  const handleChangeActiveStatus = async (value: string) => {
    // try {
    //   let resp = await adminBulkUpdateStatus({
    //     ids: selectedIDs,
    //     is_active: value === "active" ? true : false,
    //   });
    //   if (resp) {
    //     toast.success(getValue(resp, `message`, ""));
    //     setSelectedIDs([]);
    //     getData();
    //   } else {
    //     getData();
    //   }
    // } catch (error) {}
  };
  const [check, setCheck] = useState(false);
  const handleRemoveNavItems = () => {
    setCheck(!check);
    setSelectedIDs([]);
  };
  const handleNavigateUserEntity = () => {
    if (getValue(selectedIDs, `[${0}]`, "")) {
      navigate(
        `/admin/user/entity-mapping/${getValue(selectedIDs, `[${0}]`, "")}`
      );
    } else {
      toast.error("Please select User");
    }
  };
  const handleNavigate = (id: string) => {
    navigate(`/admin/trips/detail/${id}?${mainQueryRequest}`);
  };

  const [filterOptions] = useState([
    { value: "unsubmitted", label: formatText("unsubmitted") },
    { value: "awaiting_approval", label: formatText("awaiting_approval") },
    { value: "approved", label: formatText("approved") },
    { value: "rejected", label: formatText("rejected") },
    { value: "reimbursed", label: formatText("reimbursed") },
    { value: "expired", label: formatText("expired") },
    { value: "archived", label: formatText("archived") },
    { value: "completed", label: formatText("completed") },
  ]);

  const handleDownload = async () => {
    try {
      let payload = {
        report_name: `trip-report`,
        report_description: "trip-report",
        approval_status: getValue(urlParams, `approval_status`, ""),
      };
      let resp = await adminTripsDownload(
        removeNullOrUndefinedProperties(payload)
      );
      if (resp) {
        navigate(`/report-file-logs/ADMIN_TRIP_REPORTS`);
      }
    } catch (error) {}
  };

  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      {!isLoading &&
      !getValue(props, `subTabPermissionList`, []).includes("admin_trips") ? (
        <NoAccessPage />
      ) : (
        <>
          <div className="dashboard-wrapper__header">
            <h4 className="dashbaord-wrapper__header-title">Trips</h4>
            <div className="d-flex justify-content-end align-items-center gap-2">
              {getValue(selectedIDs, `length`, 0) > 0 && (
                <NavigationBar
                  selectedIDs={selectedIDs}
                  handleChangeActiveStatus={handleChangeActiveStatus}
                  handleRemoveNavItems={handleRemoveNavItems}
                  multi={
                    // getValue(props, `permissions`, []).includes("update")
                    //   ? true
                    //   : false
                    true
                  }
                  permissions={getValue(props, `permissions`, [])}
                  entityMapping={
                    getValue(props, `products`, []).includes("SELFCOMP")
                      ? true
                      : false
                  }
                  handleNavigateUserEntity={handleNavigateUserEntity}
                />
              )}
              <div className="admin-wrapper position-relative">
                <SearchHeader
                  route={"/admin/trips/add"}
                  add={"false"}
                  searchInput={search_text}
                  showSearchTextbox={showSearchTextbox}
                  toggleSearch={toggleSearch}
                  toggleSearchClose={toggleSearchClose}
                  handleChangeSearch={handleChangeSearch}
                  reportFilter
                  filterOptions={filterOptions}
                  handleNavigateURL={handleNavigateURL}
                  // add={
                  //   getValue(props, `permissions`, []).includes("create")
                  //     ? "true"
                  //     : "false"
                  // }
                  filter_key="approval_status"
                  download_with_logs={
                    getValue(props, `permissions`, []).includes("update")
                      ? true
                      : false
                  }
                  handleDownload={handleDownload}
                  download_type={"ADMIN_TRIP_REPORTS"}
                  log_name={"ADMIN_TRIP_REPORTS"}
                  report_logs
                  // dropdownOptions={dropdownOptions}
                />
              </div>
            </div>
          </div>

          {isLoading || !props.permissionAPITriggered ? (
            // <GearLoader />
            <>
              <SkeletonTable />
            </>
          ) : (
            <>
              <div className="datatable-main-wrapper">
                <div
                  className={
                    getValue(selectedIDs, `length`, 0) > 0
                      ? "datatable_main_container_notifiction"
                      : "datatable_main_container"
                  }
                  style={{ overflowY: isLoading ? "hidden" : "auto" }}
                >
                  <>
                    {getValue(list, `length`, 0) > 0 ? (
                      <TripsTable
                        list={list}
                        selectedIDs={selectedIDs}
                        handleCheckUsers={handleCheckUsers}
                        handleCheckAll={handleCheckAll}
                        isLoading={isLoading}
                        handleDeleteFun={handleDeleteFun}
                        handleNavigate={handleNavigate}
                      />
                    ) : (
                      <>
                        <Notfound />
                      </>
                    )}
                  </>
                </div>
                <div className="fixed_footer_wrapper d-flex justify-content-end">
                  {totalCount > 10 ? (
                    <PaginationPage
                      totalCount={totalCount}
                      limit={limit}
                      pageNumber={page_no}
                      handleChangePagination={handleChangePagination}
                      handleChangeLimit={handleChangeLimit}
                    />
                  ) : (
                    <p className="show-rows-count d-flex">
                      Total Count:&nbsp;&nbsp;{" "}
                      <p style={{ color: "rgb(13, 110, 253)" }}>{totalCount}</p>
                    </p>
                  )}
                </div>
              </div>
            </>
          )}
          <DeleteModal
            isOpen={isDelete}
            handleModal={handleDelete}
            handleSubmit={handleDeleteFunction}
            deleteValue={deleteValue}
          />
        </>
      )}
    </DashboardLayout>
  );
}

export default AdminTrips;
