import React from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import AddSvgComponent from "@assets/svg-components/add";

export default function ReportNotfound(props:any) {
  return (
    <>
      <div className="not-found-wrapper d-flex w-100 align-items-center justify-content-center new-not-found-wrapper">
        <div className="not-found-wrapper__details text-center">
          <img
            src="/images/notfound/report-not-found.svg"
            className="not-found-image img-fluid trips-image"
          />
          <p className="header_text__19 text-center not-found-text">
            No Records Found. <br /> Easily consolidate all expenses into a single, comprehensive report!
          </p>

          <button className="btn btn-primary btn-add-on_not_found" onClick={() => props.toggleReportNotFound()}>
            <span className="d-flex align-items-center">
              <AddSvgComponent color={"#fff"} size={20} />
              Create Report
            </span>
          </button>
        </div>
      </div>
    </>
  );
}
