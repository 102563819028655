import React, { useState } from "react";

const ContactDetails = ({ hotelDetails }) => {
  const [activeTab, setActiveTab] = useState(
    hotelDetails ? "Add Guests" : "Add Point of contact"
  );

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  return (
    <div className=" d-flex flex-column align-items-center gap-4 mb-5 pt-4 pb-5">
      <div className="w-75 mt-1">
        <p className="mb-2 header_text__18">
          {hotelDetails ? "Guest Details" : "Point of contact details"}
        </p>
        <div className=" border border-radius__8 p-4 d-flex flex-column gap-1">
          <div className="d-flex  gap-5  w-100 mb-3">
            {[
              hotelDetails ? "Add Guests" : "Add Point of contact",
              hotelDetails && "Saved Guests",
            ].map((tab) => (
              <div
                key={tab}
                className={`cursor-pointer position-relative ${
                  activeTab === tab ? " color_primary" : ""
                }`}
                onClick={() => handleTabClick(tab)}
              >
                <h6
                  className={`cursor-pointer header_text__16 ${
                    activeTab === tab
                      ? " color_primary tab-active"
                      : "tab-notactive"
                  }`}
                >
                  {tab}
                </h6>
              </div>
            ))}
          </div>
          <div>
            <div className="d-flex gap-4 align-items-start">
              <label
                className="m-0 position-relative"
                htmlFor="header-checkbox"
              >
                <input
                  type="checkbox"
                  className="default-checkbox"
                  id="header-checkbox"
                  checked
                  placeholder={hotelDetails ? "Adult" : "Cab"}
                />
                <div className="custom-checkbox">
                  <img
                    src="/images/uploaded-details/tick.svg"
                    className="img-fluid uploaded-tick"
                  />
                </div>
              </label>
              <div className="w-100">
                <div className="d-flex gap-3 align-items-center">
                  <span>{hotelDetails ? "Adult" : "Cab 1"}</span>
                  <img
                    src="/images/dashboard/trips/online/arrow_drop_down.svg"
                    className="cursor-pointer"
                  />
                  <p className="small_text__14">
                    {hotelDetails ? "Guest 1" : "Toyota Etios"}
                  </p>
                </div>
                <p className="small_text__14 mt-3">
                  IMPORTANT: Enter your name as it is mentioned on your passport
                  or any government approved ID.
                </p>
                <div className="mt-3 d-flex gap-3">
                  <div>
                    <h6 className="small_text__12">Title</h6>
                    <div className="location-trigger  d-flex justify-content-between">
                      <p
                        className="small_text__14 "
                        style={{ color: "#DADCE0" }}
                      >
                        Select title
                      </p>
                      <img
                        src="/images/dashboard/trips/online/arrow_drop_down.svg"
                        className="cursor-pointer"
                      />
                    </div>
                  </div>
                  <div>
                    <h6 className="small_text__12">First & Middle Name</h6>
                    <div className="location-trigger  ">
                      <p
                        className="small_text__14 "
                        style={{ color: "#DADCE0" }}
                      >
                        Enter First & Middle Name
                      </p>
                    </div>
                  </div>
                  <div>
                    <h6 className="small_text__12">Last Name</h6>
                    <div className="location-trigger  ">
                      <p
                        className="small_text__14 "
                        style={{ color: "#DADCE0" }}
                      >
                        Last Name
                      </p>
                    </div>
                  </div>
                </div>
                {!hotelDetails && (
                  <>
                    <div className="mt-3">
                      <h6 className="small_text__12">Pickup Location</h6>
                      <div className="location-trigger  w-100">
                        <img
                          src="/images/dashboard/trips/online/search.png"
                          className="search"
                        />
                        <p
                          className="small_text__14 "
                          style={{ color: "#DADCE0" }}
                        >
                          Enter Pickup Location
                        </p>
                      </div>
                    </div>
                    <div className="my-3 d-flex gap-3">
                      <div>
                        <h6 className="small_text__12">Country Code</h6>
                        <div className="location-trigger w-auto d-flex justify-content-between">
                          <img
                            src="/images/dashboard/trips/cab-booking/india.png"
                            className="cursor-pointer"
                          />
                          <p className="small_text__14 ">(91)</p>
                          <img
                            src="/images/dashboard/trips/online/arrow_drop_down.svg"
                            className="cursor-pointer"
                          />
                        </div>
                      </div>
                      <div>
                        <h6 className="small_text__12">Mobile Number</h6>
                        <div className="location-trigger  ">
                          <p
                            className="small_text__14 "
                            style={{ color: "#DADCE0" }}
                          >
                            Enter Mobile Number
                          </p>
                        </div>
                      </div>
                      <div>
                        <h6 className="small_text__12">Email Address</h6>
                        <div className="location-trigger  ">
                          <p
                            className="small_text__14 "
                            style={{ color: "#DADCE0" }}
                          >
                            Enter Email Id
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="d-flex gap-4 align-items-start mt-4 pt-4 pb-2 travel-offers">
              <label
                className="m-0 position-relative"
                htmlFor="header-checkbox"
              >
                <input
                  type="checkbox"
                  className="default-checkbox"
                  id="header-checkbox"
                  checked
                  placeholder={hotelDetails ? "Adult" : "Cab2"}
                />
                <div className="custom-checkbox">
                  <img
                    src="/images/uploaded-details/tick.svg"
                    className="img-fluid uploaded-tick"
                  />
                </div>
              </label>
              <div className="w-100">
                <div className="d-flex gap-3 align-items-center">
                  <span>{hotelDetails ? "Adult" : "Cab 2"}</span>
                  <img
                    src="/images/dashboard/trips/online/arrow_drop_down.svg"
                    className="cursor-pointer"
                  />
                  <p className="small_text__14">
                    {hotelDetails ? "Guest 2" : "Maruti Ertiga"}
                  </p>
                </div>
                <p className="small_text__14 mt-3">
                  IMPORTANT: Enter your name as it is mentioned on your passport
                  or any government approved ID.
                </p>
                <div className="mt-3 d-flex gap-3">
                  <div>
                    <h6 className="small_text__12">Title</h6>
                    <div className="location-trigger  d-flex justify-content-between">
                      <p
                        className="small_text__14 "
                        style={{ color: "#DADCE0" }}
                      >
                        Select title
                      </p>
                      <img
                        src="/images/dashboard/trips/online/arrow_drop_down.svg"
                        className="cursor-pointer"
                      />
                    </div>
                  </div>
                  <div>
                    <h6 className="small_text__12">First & Middle Name</h6>
                    <div className="location-trigger  ">
                      <p
                        className="small_text__14 "
                        style={{ color: "#DADCE0" }}
                      >
                        Enter First & Middle Name
                      </p>
                    </div>
                  </div>
                  <div>
                    <h6 className="small_text__12">Last Name</h6>
                    <div className="location-trigger  ">
                      <p
                        className="small_text__14 "
                        style={{ color: "#DADCE0" }}
                      >
                        Last Name
                      </p>
                    </div>
                  </div>
                </div>
                {!hotelDetails && (
                  <>
                    <div className="mt-3">
                      <h6 className="small_text__12">Pickup Location</h6>
                      <div className="location-trigger  w-100">
                        <img
                          src="/images/dashboard/trips/online/search.png"
                          className="search"
                        />
                        <p
                          className="small_text__14 "
                          style={{ color: "#DADCE0" }}
                        >
                          Enter Pickup Location
                        </p>
                      </div>
                    </div>
                    <div className="my-3 d-flex gap-3">
                      <div>
                        <h6 className="small_text__12">Country Code</h6>
                        <div className="location-trigger w-auto d-flex justify-content-between">
                          <img
                            src="/images/dashboard/trips/cab-booking/india.png"
                            className="cursor-pointer"
                          />
                          <p className="small_text__14 ">(91)</p>
                          <img
                            src="/images/dashboard/trips/online/arrow_drop_down.svg"
                            className="cursor-pointer"
                          />
                        </div>
                      </div>
                      <div>
                        <h6 className="small_text__12">Mobile Number</h6>
                        <div className="location-trigger  ">
                          <p
                            className="small_text__14 "
                            style={{ color: "#DADCE0" }}
                          >
                            Enter Mobile Number
                          </p>
                        </div>
                      </div>
                      <div>
                        <h6 className="small_text__12">Email Address</h6>
                        <div className="location-trigger  ">
                          <p
                            className="small_text__14 "
                            style={{ color: "#DADCE0" }}
                          >
                            Enter Email Id
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {hotelDetails && (
        <div className="w-75 mt-1">
          <p className="mb-2 header_text__18">Contact Information</p>
          <div className=" border border-radius__8 p-4 ">
            <p className="mb-2 header_text__18">
              Your Room Booking information will be sent here
            </p>
            <div className="mt-3 d-flex gap-3 ps-5">
              <div>
                <h6 className="small_text__12">Country Code</h6>
                <div className="location-trigger w-auto d-flex justify-content-between">
                  <img
                    src="/images/dashboard/trips/cab-booking/india.png"
                    className="cursor-pointer"
                  />
                  <p className="small_text__14 ">(91)</p>
                  <img
                    src="/images/dashboard/trips/online/arrow_drop_down.svg"
                    className="cursor-pointer"
                  />
                </div>
              </div>
              <div>
                <h6 className="small_text__12">Mobile Number</h6>
                <div className="location-trigger  ">
                  <p className="small_text__14 " style={{ color: "#DADCE0" }}>
                    Enter Mobile Number
                  </p>
                </div>
              </div>
              <div>
                <h6 className="small_text__12">Email Address</h6>
                <div className="location-trigger  ">
                  <p className="small_text__14 " style={{ color: "#DADCE0" }}>
                    Enter Email Id
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="w-75 mt-1">
        <p className="mb-2 header_text__18">Special Request, If any.</p>
        <p className="mb-2 small_text__14">
          Special requests cannot be guaranteed – but the property will do its
          best to meet your needs..
        </p>
        <div className=" border border-radius__8 p-4 ">
          <p
            className="mb-2 small_text__14"
            style={{ color: "#DADCE0", height: "100px" }}
          >
            Enter Special Request (Max 90 words)
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactDetails;
