import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import PromptModal from "@prompt/modal";
import _ from "lodash";
import { useCallbackPrompt } from "@prompt/useCallbackPrompt";
import StickyHeader from "./StickyHeader";
import ProjectForm from "./projectForm";
import {
  createProject,
  getSpecificProject,
  updateProject,
} from "@services/projects.service";
import Loader from "@components/common/Loader";
import NoAccessPage from "@components/common/NoAccess";
import GearLoader from "@components/common/GearLoader";

function ProjectsDetailsPage(props: any) {
  const navigate = useNavigate();
  const params = useParams();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());

  /* -------------------------------------------------------------------------- */
  /*                               UseState section                             */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [info, setInfo] = useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [request, setRequest] = useState({
    project_name: "",
    project_code: "",
    description: "",
    project_head: "",
    is_associate_all_user: false,
    associate_users: [],
  });

  // const [dirtyFields, setDirtyFields] = useState({
  //   project_name: "",
  //   project_code: "",
  //   description: "",
  //   project_head: "",
  //   is_associate_all_user: false,
  //   associate_users: [],
  // });
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (props.permissionAPITriggered) {
      if (getValue(params, `id`, "")) {
        getData();
      } else {
        setIsLoading(false);
      }
    }
  }, [props.permissionAPITriggered]);

  /* -------------------------------------------------------------------------- */
  /*                                 API section                                */
  /* -------------------------------------------------------------------------- */
  const getData = async () => {
    try {
      setIsLoading(true);
      let resp = await getSpecificProject(getValue(params, `id`, ""));
      if (resp) {
        setInfo(getValue(resp, `data`, {}));
        let users =
          getValue(resp, `data.project_users.length`, 0) > 0
            ? getValue(resp, `data.project_users`, []).map((item: object) => ({
                ...item,
                user: getValue(item, `user.id`, ""),
                action: "UPDATE",
              }))
            : [];
        let obj = {
          project_name: getValue(resp, `data.project_name`, ""),
          project_code: getValue(resp, `data.project_code`, ""),
          description: getValue(resp, `data.description`, ""),
          project_head: getValue(resp, `data.project_head.id`, ""),
          is_associate_all_user: getValue(
            resp,
            `data.is_associate_all_user`,
            false
          ),
          associate_users: users,
        };
        setRequest({ ...request, ...obj });
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        let payload = request;
        setSubmitLoading(true);
        if (getValue(params, `id`, "")) {
          let resp = await updateProject(getValue(params, `id`, ""), payload);
          if (resp) {
            toast.success(getValue(resp, `message`, ""));
            setSubmitLoading(false);
            // getData();
            navigate(`/admin/modules/projects`);
          } else {
            setSubmitLoading(false);
          }
        } else {
          let resp = await createProject(payload);
          if (resp) {
            toast.success(getValue(resp, `message`, ""));
            setSubmitLoading(false);
            // getData();
            navigate(`/admin/modules/projects`);
          } else {
            setSubmitLoading(false);
          }
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                              OnChange section                              */
  /* -------------------------------------------------------------------------- */
  const handleChangeText = (e: any) => {
    const { name, value } = e.target;
    setRequest({
      ...request,
      [name]: value,
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                                Prompt Section                              */
  /* -------------------------------------------------------------------------- */
  // const [isChanged, setIsChanged] = useState(false);
  // useEffect(() => {
  //   setIsChanged(
  //     _.isEqualWith(request, dirtyFields, (a: any, b: any) => {
  //       // if both are null or equal to an empty string then they are equal
  //       if ((_.isNull(a) || a === "") && (_.isNull(b) || b === "")) return true;
  //     })
  //   );
  // });
  // const [
  //   showPrompt,
  //   confirmNavigation,
  //   cancelNavigation,
  // ]: any = useCallbackPrompt(!isChanged);
  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      {/* <PromptModal
        showDialog={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      /> */}
      <StickyHeader
        isChanged={false}
        handleSubmit={handleSubmit}
        submitLoading={submitLoading}
        id={params.id}
        route={`/admin/modules/projects`}
        title={`${getValue(params, `id`, "") ? "Update" : "New"}${" "}Project`}
        permissions={getValue(props, `permissions`, [])}
      />
      {!isLoading &&
      !getValue(props, `subTabPermissionList`, []).includes(
        "admin_modules_projects"
      ) ? (
        <NoAccessPage />
      ) :  (
        <>
          <ProjectForm
            request={request}
            id={getValue(params, `id`, "")}
            setRequest={setRequest}
            simpleValidator={simpleValidator}
            handleChangeText={handleChangeText}
          />
        </>
      )}
    </DashboardLayout>
  );
}

export default ProjectsDetailsPage;
