import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { getValue } from "@utils/lodash";
import "./pagination.scss";

export default function PaginationPage(props: any) {
	const [options, setOptions] = useState<any>([]);
	useEffect(() => {
		let option = [];
		for (let i = 0; i < 11; i++) {
			if (i) option.push(i * 10);
		}
		setOptions(option);
	}, []);

	return (
		<section className="pagination-wrapper d-flex align-items-center ">
			<div className="show-rows d-flex align-items-center">
				<p className="show-rows-count" >Total Count: <span style={{ color: "rgb(13, 110, 253)" }}>{props.totalCount}</span></p>
				{getValue(props, `showLimit`, "") !== "false" && (
					<>
						<p className="show-rows-count">Show rows:</p>
						<select
							className="show-select"
							onChange={(e) => props.handleChangeLimit(e.target.value)}
						>
							{options.map((item: number) => {
								return (
									<option selected={item == props.limit} value={item}>
										{item}
									</option>
								);
							})}
						</select>
					</>
				)}
			</div>
			{getValue(props, `showLimit`, "") !== "false" && (
				<div className="show-count mx-4">
					{getValue(props, `pageNumber`, 0)} -{" "}
					{Math.ceil(
						getValue(props, `totalCount`, 0) / getValue(props, `limit`, 1)
					)}{" "}
					of{" "}
					{Math.ceil(
						getValue(props, `totalCount`, 0) / getValue(props, `limit`, 1)
					)}
				</div>
			)}

			<div>
				<Pagination
					activePage={props.pageNumber}
					itemsCountPerPage={props.limit}
					totalItemsCount={props.totalCount}
					pageRangeDisplayed={5}
					onChange={props.handleChangePagination}
				/>
			</div>
		</section>
	);
}
