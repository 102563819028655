import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllUsers = (queryRequest: string) =>
  get(`${config.API_URL}/users?${queryRequest}`);

export const getAllCommonUsers = (queryRequest: string) =>
  get(`${config.API_URL}/users/common/list?${queryRequest}`);

export const getSpecificUser = (id: string) =>
  get(`${config.API_URL}/users/${id}`);

export const getMyProfile = (id: string) =>
  // get(`${config.API_URL}/users/${id}`);
  get(`${config.API_URL}/users/me`);

export const createUser = (payload: object) =>
  post(`${config.API_URL}/users`, payload);

export const updateUser = (id: string, payload: object) =>
  patch(`${config.API_URL}/users/${id}`, payload);

export const deleteUser = (id: string) =>
  Delete(`${config.API_URL}/users/${id}`);

export const inviteUser = (id: string) =>
  get(`${config.API_URL}/users/${id}/send-invite`);

export const updateUserStatusBulk = (payload: object) =>
  patch(`${config.API_URL}/users/bulk/active-inactive`, payload);

export const getUserForm = (queryRequest: string) =>
  get(`${config.API_URL}/users/form`);

export const getUserFormEdit = (id: string) =>
  get(`${config.API_URL}/users/${id}/form-edit`);
