import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../online/trip-online.scss";
import HeaderLayout from "@layouts/withoutNaviagtion/withoutNavigationLayout";
import { Ribbon } from "lucide-react";

const bookingType = [
  {
    value: "div1",
    label: "Offline Request",
    image: "/images/dashboard/trips/offline.png",
    ribbon: false,
  },
  {
    value: "div2",
    label: "Online Booking ",
    image: "/images/dashboard/trips/online.png",
    ribbon: true,
  },
];

const RequestType = () => {
  const [selectedOption, setSelectedOption] = useState("div1");
  const navigate = useNavigate();

  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  const handleButtonClick = () => {
    if (selectedOption === "div2") {
      navigate("/client/trips/online-booking");
    } else if (selectedOption === "div1") {
      navigate("/client/trips/add");
    }
  };

  return (
    <HeaderLayout>
      <div className="dashbaord-wrapper_section_container mt-5">
        <div className="ms-5 ps-lg-5">
          <h2 className="header_text___24">Trips - Lets get started</h2>
          <p>
            Craft your dream getaway however you choose! We offer the
            flexibility of both online booking and personalized offline
            assistance.
          </p>
        </div>
        <div className="d-flex justify-content-center">
          <div>
            <div className="d-flex justify-content-center gap-4 my-5 flex-wrap">
              {bookingType.map((option) => (
                <div key={option.value} className="radio-container">
                  <input
                    type="radio"
                    id={option.value}
                    name="divOption"
                    value={option.value}
                    checked={selectedOption === option.value}
                    onChange={handleOptionChange}
                    className="custom-radio"
                  />
                  <label htmlFor={option.value}>
                    <div>
                      <img src={option.image} alt={option.label} />
                      <div className="my-4">
                        <h4 className="text-center dashbaord-wrapper__header-title">
                          {option.label}
                        </h4>
                      </div>
                    </div>
                  </label>
                  {option.ribbon === true && (
                    <div className="ribbon">Coming soon</div>
                  )}
                </div>
              ))}
            </div>
            <div className="d-flex justify-content-end gap-3">
              <button
                onClick={() => navigate("/client/trips/")}
                className="deeptravel-button deeptravel-button--header-buttons deeptravel-button--secondary "
              >
                Cancel
              </button>
              <button
                onClick={handleButtonClick}
                className="deeptravel-button deeptravel-button--primary deeptravel-button--header-buttons"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </HeaderLayout>
  );
};

export default RequestType;
