import { ClientApprovalReportRoutes } from "./approval-reports.routes";
import { ClientDashboardRoutes } from "./dashboard.routes";
import { ClientExpenseRoutes } from "./expense.routes";
import { ClientTripsRoutes } from "./trips.routes";
import { ClientReportRoutes } from "./reports.routes";
import { ClientSettingsRoutes } from "./settings.routes";

export const ClientRoutes = [
  ...ClientDashboardRoutes,
  ...ClientExpenseRoutes,
  ...ClientReportRoutes,
  ...ClientSettingsRoutes,
  ...ClientApprovalReportRoutes,
  ...ClientTripsRoutes
];
