import ClientReports from "@pages/Client/Reports";
import ClientReportCreate from "@pages/Client/Reports/Create";
import ClientReportDetail from "@pages/Client/Reports/Detail";

export const ClientReportRoutes = [
  //expenses
  {
    path: "/client/reports",
    name: "reports",
    component: ClientReports,
  },

  {
    path: "/client/reports/add",
    name: "reports",
    component: ClientReportCreate,
  },
  {
    path: "/client/reports/edit/:id",
    name: "reports",
    component: ClientReportCreate,
  },
  {
    path: "/client/reports/detail/:id",
    name: "reports",
    component: ClientReportDetail,
  },
];
