import React from "react";
import TripTravelInfo from "./components/flight/travelinfo";
import TripReportUploadSection from "./trip-report-upload";
import { getValue } from "@utils/lodash";

function TripsRightSection(props: any) {
  const {
    formInfo,
    setShowApprovalPopup,
    handleUploadDocuments,
    uploadLoading,
    handleDeleteFun,
    documents,
    showTravelDocument,
    toggleDocument,
    toggleTravelProfile,
    getData,
    toggleDocumentUser,
  } = props;
  return (
    <div>
      <div className="trips_right_container_content_holder">
        <TripTravelInfo
          formInfo={formInfo}
          setShowApprovalPopup={setShowApprovalPopup}
          showTravelDocument={showTravelDocument}
          toggleDocument={toggleDocument}
          toggleTravelProfile={toggleTravelProfile}
          client={props.client}
          toggleDocumentUser={toggleDocumentUser}
        />
        <div className="">
          <TripReportUploadSection
            handleUploadDocuments={handleUploadDocuments}
            uploadLoading={uploadLoading}
            formInfo={formInfo}
            handleDeleteFun={handleDeleteFun}
            documents={documents}
            permissions={getValue(props, `permissions`, [])}
            getData={getData}
          />
        </div>
      </div>
    </div>
  );
}

export default TripsRightSection;
