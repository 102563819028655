import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import ModulesNavigation from "../common/sub-header";
import React, { useEffect, useRef, useState } from "react";
import SearchHeader from "@components/common/SearchHeader";
import { useNavigate } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getValue } from "@utils/lodash";
import { toast } from "react-toastify";
import {
  createTravelDocument,
  deleteTravelDocument,
  downloadTravelDocumentExcel,
  getAllTravelDocuments,
  updateTravelDocument,
} from "@services/settings.service";
import TravelDocumentPopup from "./add-popup";
import { getlocationManagementCountries } from "@services/list-management.service";
import { mediaUpload } from "@services/upload.service";
import {
  json2array,
  removeNullOrUndefinedProperties,
  removeNullOrUndefinedPropertiesKeepArray,
} from "@common/text-helpers";
import Loader from "@components/common/Loader";
import TravelDocumentTable from "./table";
import Notfound from "@components/Not-Found";
import PaginationPage from "@components/pagination/pagination";
import DeleteModal from "@common/popup/DeleteModal";
import { getSpecificTravelDocument } from "@services/settings.service";
import moment from "moment";
import { NewDate, convertDate, currentDate } from "@common/date-helpers";
import NavigationBar from "@components/NotificationBar";
import { changeTravelDocumentToMandatory } from "@services/settings.service";
import { handleDownloadOnClick } from "@common/downloadfile";
import { convertElementaryArrayToJSONArray } from "@common/text-helpers";
import NoAccessPage from "@components/common/NoAccess";
import SettingTravelDocumentNotfound from "@components/Not-Found/settings-travel-not-found";
import SkeletonTable from "@components/common/Skeleton/skeleton-table";

function SettingsTravelDocuments(props: any) {
  let navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const [isLoading, setIsLoading] = React.useState(true);
  const [list, setList] = React.useState<[]>([]);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const handleOpenPopup = () => {
    setShowUpdatePopup(!showUpdatePopup);
  };
  const [countryList, setCountryList] = useState([]);
  const [request, setRequest] = useState<any>({
    document_type: "",
    document_country: "",
    document_issued_on: "",
    document_number: "",
    document_valid_from: "",
    document_expires_on: "",
    attachment_ids: [],
    // user:''
  });

  const reset = () => {
    setRequest({
      ...request,
      document_type: "",
      document_country: "",
      document_issued_on: "",
      document_number: "",
      document_valid_from: "",
      document_expires_on: "",
      attachment_ids: [],
      // user:''
    });
    setDocuments([]);
    setEditId("");
  };
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length === 0 && props.permissionAPITriggered) {
      getData();
    }
    getCountriesList();
  }, [props.permissionAPITriggered]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length > 0 && props.permissionAPITriggered) {
      getData();
      if (params.page_no) {
        setPage_no(parseInt(params.page_no));
      }
      if (params.search_text) {
        setSearchInput(params.search_text);
        setShowSearchTextbox(true);
      }
      if (params.limit) {
        setLimit(parseInt(params.limit));
      }
    }
  }, [window.location.href, props.permissionAPITriggered]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const getCountriesList = async () => {
    try {
      let resp = await getlocationManagementCountries("");
      if (resp) {
        setCountryList(
          getValue(resp, `data.countries`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `id`, ""),
            label: getValue(item, `name`, ""),
          }))
        );
      } else {
      }
    } catch (error) {}
  };
  const getData = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload: any = {
      page_no: params.page_no ? parseInt(params.page_no) : 1,
      page_size: params.limit ? parseInt(params.limit) : limit,
      search_text: params.search_text,
      // is_admin: false,
      sort_by: params.sort_by,
      filter_type: getValue(params, `filter_type`, ""),
    };
    let queryRequest = QueryRequestHelper(payload);
    try {
      setIsLoading(true);
      let resp = await getAllTravelDocuments(queryRequest);
      if (resp) {
        setList(getValue(resp, `data.travel_documents`, []));
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Search section                               */
  /* -------------------------------------------------------------------------- */
  const [showSearchTextbox, setShowSearchTextbox] = useState(false);
  const [search_text, setSearchInput] = useState<any>("");

  const toggleSearch = () => {
    setShowSearchTextbox((prevState) => !prevState);
  };
  const toggleSearchClose = () => {
    setSearchInput("");
    toggleSearch();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.search_text) {
      delete params.search_text;
      let payload: any = { ...params };
      let queryRequest = QueryRequestHelper(payload);

      navigate(`${window.location.pathname}?${queryRequest}`);
      getData();
    }
  };
  const handleChangeSearch = async (e: any) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    setSearchInput(e.target.value);

    let payload: any = {
      ...params,
      page_no: 1,
      search_text: e.target.value,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    if (!e.target.value) {
      getData();
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const [selectedIDs, setSelectedIDs] = useState<any>([]);
  const handleCheckUsers = (id: string) => {
    let userIDs =
      getValue(selectedIDs, `length`, 0) > 0
        ? selectedIDs.map((item: string) => item)
        : [];
    if (userIDs.includes(id)) {
      let filterIDs = selectedIDs.filter((item: string) => item !== id);
      setSelectedIDs(filterIDs);
    } else {
      selectedIDs.push(id);
      setSelectedIDs([...selectedIDs]);
    }
  };
  const handleCheckAll = (status: string) => {
    if (status === "All") {
      setSelectedIDs([]);
    } else {
      let userIDs =
        getValue(list, `length`, 0) > 0
          ? list.map((item: object) => getValue(item, `id`, ""))
          : [];
      setSelectedIDs(userIDs);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Delete section                             */
  /* -------------------------------------------------------------------------- */

  const [deleteValue, setDeleteValue] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const handleDeleteFun = (id: string, value: string) => {
    setDeleteValue(value);
    setDeleteId(id);
    handleDelete();
  };
  const [isDelete, setIsDelete] = useState(false);
  const handleDelete = () => {
    setIsDelete(!isDelete);
  };
  const handleDeleteFunction = async () => {
    try {
      let resp = await deleteTravelDocument(deleteId);
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        handleDelete();
        getData();
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload = {
      ...params,
      page_no: page_no,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  const handleChangeLimit = (limit: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload: any = {
      ...params,
      limit: limit,
      page_no: "1",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  const handleChangeActiveStatus = async (value: string) => {
    // try {
    //   let resp = await adminBulkUpdateStatus({
    //     ids: selectedIDs,
    //     is_active: value === "active" ? true : false,
    //   });
    //   if (resp) {
    //     toast.success(getValue(resp, `message`, ""));
    //     setSelectedIDs([]);
    //     getData();
    //   } else {
    //     getData();
    //   }
    // } catch (error) {}
  };

  const [check, setCheck] = useState(false);
  const handleRemoveNavItems = () => {
    setCheck(!check);
    setSelectedIDs([]);
  };
  const handleNavigateUserEntity = () => {
    if (getValue(selectedIDs, `[${0}]`, "")) {
      navigate(
        `/admin/user/entity-mapping/${getValue(selectedIDs, `[${0}]`, "")}`
      );
    } else {
      toast.error("Please select User");
    }
  };
  const handleNavigate = (id: string) => {
    // navigate(`/client/reports/detail/${id}`);
    // setShowUpdatePopup(true)
    getTravelDocument(id);
  };

  const [editId, setEditId] = useState("");
  const getTravelDocument = async (id: string) => {
    setEditId(id);
    try {
      let resp = await getSpecificTravelDocument(id);
      if (resp) {
        setRequest({
          ...request,
          document_type: getValue(resp, `data.document_type`, ""),
          document_country: getValue(resp, `data.document_country.id`, ""),
          document_issued_on: NewDate(
            getValue(resp, `data.document_issued_on`, "")
          ),
          document_number: getValue(resp, `data.document_number`, ""),
          document_valid_from: NewDate(
            getValue(resp, `data.document_valid_from`, "")
          ),
          document_expires_on: NewDate(
            getValue(resp, `data.document_expires_on`, "")
          ),
          attachment_ids: getValue(resp, `data.attachments`, []),
          // user: getValue(resp, `data.user.id`, []),
        });
        setDocuments(getValue(resp, `data.attachments`, []));
        setShowUpdatePopup(true);
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                                 Submit section                             */
  /* -------------------------------------------------------------------------- */

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setSubmitLoading(true);
        request["attachment_ids"] = documents.map((item: any) =>
          getValue(item, `id`, "")
        );
        // request["is_admin"] = false;
        let resp;
        if (editId) {
          resp = await updateTravelDocument(
            editId,
            removeNullOrUndefinedPropertiesKeepArray(request)
          );
        } else {
          resp = await createTravelDocument(
            removeNullOrUndefinedPropertiesKeepArray(request)
          );
        }
        if (resp) {
          toast.success(getValue(resp, `message`, ""));
          setSubmitLoading(false);
          setShowUpdatePopup(false);
          simpleValidator.current.hideMessages();
          forceUpdate(0);
          reset();
          getData();
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };

  const handlePrimary = async () => {
    try {
      let resp = await changeTravelDocumentToMandatory(selectedIDs[0]);
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        getData();
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                               Upload Section                               */
  /* -------------------------------------------------------------------------- */
  const [documents, setDocuments] = useState<any>([]);
  const fileTypes = ["PDF", "JPEG", "XLSX", "CSV", "XLSM", "XLX", "JPG", "PNG"];
  const handleUploadDocuments = async (e: any) => {
    Array.from(e).forEach(async (item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      try {
        let resp = await mediaUpload(formData);
        if (resp) {
          setDocuments((prevDocuments: any) => [
            ...prevDocuments,
            getValue(resp, `data`, {}),
          ]);
          e = null;
        } else {
          e = null;
        }
      } catch (error) {
        e = null;
      }
    });
  };
  const handleRemoveDocuments = (index: number) => {
    let list = documents.filter((item: object, i: number) => index !== i);
    setDocuments(list);
  };
  /* -------------------------------------------------------------------------- */
  /*                                 Filter section                             */
  /* -------------------------------------------------------------------------- */
  const handleNavigateURL = (key: string, value: string) => {
    if (value) {
      let payload = {
        ...urlParams,
        [key]: value,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
    } else {
      delete urlParams[key];
      let payload = {
        ...urlParams,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
      getData();
    }
  };
  const filterOptions = convertElementaryArrayToJSONArray([
    "all",
    "visa",
    "passport",
    "esta",
    "others",
  ]);
  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      {!isLoading &&
      !getValue(props, `subTabPermissionList`, []).includes(
        "settings_travel_documents"
      ) ? (
        <NoAccessPage />
      ) : (
        <>
          {/* <ModulesNavigation
        headerTitle={"Travel Documents"}
        name={`expenses`}
        // handleSubmit={handleUpdate}
        // isLoading={submitLoading}
        hideSubmit
      /> */}
          <div className="dashboard-wrapper__header d-flex align-items-center justify-content-between">
            <h4 className="dashbaord-wrapper__header-title">
              Travel Documents
            </h4>
            <div className="d-flex justify-content-end align-items-center gap-2">
              {getValue(selectedIDs, `length`, 0) > 0 && (
                <NavigationBar
                  selectedIDs={selectedIDs}
                  handleChangeActiveStatus={handleChangeActiveStatus}
                  handleRemoveNavItems={handleRemoveNavItems}
                  multi={false}
                  permissions={getValue(props, `permissions`, [])}
                  entityMapping={
                    getValue(props, `products`, []).includes("SELFCOMP")
                      ? true
                      : false
                  }
                  handleNavigateUserEntity={handleNavigateUserEntity}
                  document_primary
                  handlePrimary={handlePrimary}
                />
              )}
              <div className="admin-wrapper position-relative">
                <SearchHeader
                  route={"/client/reports/add"}
                  searchInput={search_text}
                  showSearchTextbox={showSearchTextbox}
                  toggleSearch={toggleSearch}
                  toggleSearchClose={toggleSearchClose}
                  handleChangeSearch={handleChangeSearch}
                  add={
                    getValue(props, `permissions`, []).includes("create")
                      ? "true"
                      : "false"
                  }
                  // download
                  isAdd={
                    getValue(props, `permissions`, []).includes("create")
                      ? true
                      : false
                  }
                  handleAddFun={handleOpenPopup}
                  reportFilter
                  filter_key={"filter_type"}
                  filterOptions={filterOptions}
                  handleNavigateURL={handleNavigateURL}
                />
              </div>
            </div>
          </div>
          <div className="datatable-main-wrapper">
            <div
              className={
                getValue(selectedIDs, `length`, 0) > 0
                  ? "datatable_main_container_notifiction"
                  : "datatable_main_container"
              }
              style={{ overflowY: isLoading ? "hidden" : "auto" }}
            >
              {isLoading || !props.permissionAPITriggered ? (
                // <Loader />
                <SkeletonTable />
              ) : getValue(list, `length`, 0) > 0 ? (
                <TravelDocumentTable
                  list={list}
                  selectedIDs={selectedIDs}
                  handleCheckUsers={handleCheckUsers}
                  handleCheckAll={handleCheckAll}
                  isLoading={isLoading}
                  handleDelete={handleDeleteFun}
                  handleNavigate={handleNavigate}
                  permissions={getValue(props, `permissions`, [])}
                />
              ) : (
                <>
                  <SettingTravelDocumentNotfound
                    toggleTravel={handleOpenPopup}
                  />
                </>
              )}
            </div>
          </div>
          <div className="fixed_footer_wrapper d-flex justify-content-end">
            {/* {list.length > 0 && (
              <img
                src="/images/building-illustration.svg"
                className="img-fluid"
              />
            )} */}
            {totalCount > 10 ? (
              <PaginationPage
                totalCount={totalCount}
                limit={limit}
                pageNumber={page_no}
                handleChangePagination={handleChangePagination}
                handleChangeLimit={handleChangeLimit}
              />
            ) : (
              <p className="show-rows-count d-flex">
                Total Count:&nbsp;&nbsp;{" "}
                <p style={{ color: "rgb(13, 110, 253)" }}>{totalCount}</p>
              </p>
            )}
          </div>
          <DeleteModal
            isOpen={isDelete}
            handleModal={handleDelete}
            handleSubmit={handleDeleteFunction}
            deleteValue={deleteValue}
          />
          <TravelDocumentPopup
            showUpdatePopup={showUpdatePopup}
            setShowUpdatePopup={setShowUpdatePopup}
            request={request}
            setRequest={setRequest}
            countryList={countryList}
            handleSubmit={handleSubmit}
            submitLoading={submitLoading}
            handleUploadDocuments={handleUploadDocuments}
            fileTypes={fileTypes}
            documents={documents}
            editId={editId}
            reset={reset}
            simpleValidator={simpleValidator}
            handleRemoveDocuments={handleRemoveDocuments}
            permissions={getValue(props, `permissions`, [])}
          />
        </>
      )}
    </DashboardLayout>
  );
}

export default SettingsTravelDocuments;
