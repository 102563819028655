import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import './dropdown.scss';

export default function Dropdown() {
  return (
    <div className="mx-2 cursor-pointer d-flex align-items-center">
      {/* <h6 className="dropdown__title">Take Action</h6>
                <img src="/images/arrow-drop-white.svg" className="img-fluid" /> */}
      <UncontrolledDropdown>
        <DropdownToggle caret>Take Action</DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="dropdown-header-title">Update Status</h6>
              <img src="/images/close-black.svg" className="img-fluid" />
            </div>
          </DropdownItem>
          <DropdownItem>MARK COMPLIED</DropdownItem>
          <DropdownItem>MARK PARTIALLY COMPLIED</DropdownItem>
          <DropdownItem>MARK COMPLIED</DropdownItem>
          <DropdownItem>MARK COMPLIED</DropdownItem>
          <DropdownItem>MARK COMPLIED</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
}
