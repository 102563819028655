import AdminTrips from "@pages/Admin/Trips";
import AdminTripsDetail from "@pages/Admin/Trips/detail";

export const AdminTripsRoutes = [
  //expenses
  {
    path: "/admin/trips",
    name: "admin_trips",
    component: AdminTrips,
  },
  {
    path: "/admin/trips/detail/:id",
    name: "admin_trips",
    component: AdminTripsDetail,
  },
];
