import React, { useEffect, useState, useRef } from "react";
import "./currency-detail.scss";
//import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import InputField from "@common/InputRuleFeild";
import { getValue, setValue } from "@utils/lodash";
import SimpleReactValidator from "simple-react-validator";
import { useParams, useNavigate } from "react-router-dom";
import countries from "@common/json/contry.json";
import Layout from "@layouts/withoutNaviagtion/withoutNavigationLayout";
import _ from "lodash";
import { useCallbackPrompt } from "@prompt/useCallbackPrompt";
import PromptModal from "@prompt/modal";
import {
  createCurrency,
  getSpecificCurrency,
  updateCurrency,
} from "@services/currency.service";
import GearLoader from "@components/common/GearLoader";
import SingleSelectSearchDropdown from "@components/common/CustomSearchableDropdown/SingleSelectSearchDropdown";
import StickyHeader from "./StickyHeader";
import Currencies from "@common/json/currency.json";
import NoAccessPage from "@components/common/NoAccess";
interface IAdminOrgCurrencyDetailInfoProps {}

const AdminOrgCurrencyDetailInfo: React.FunctionComponent<
  IAdminOrgCurrencyDetailInfoProps
> = (props: any) => {
  let orgParams = useParams();
  let params = useParams();
  let navigate = useNavigate();

  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [request, setRequest] = useState({
    currency_code: "",
    currency_symbol: "",
    decimal_places: 0,
  });

  const [dirtyFields, setDirtyFields] = useState<any>({
    currency_code: "",
    currency_symbol: "",
    decimal_places: 0,
  });

  const [currencyCodes, setCurrencyCodes] = useState([]);
  const [decimalPlaceList, setDecimalPlaceList] = useState([
    { value: "0", label: "0" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
  ]);
  const [formatList, setFormatList] = useState([
    { value: "1,234,567.89", label: "1,234,567.89" },
    { value: "1.234.567,89", label: "1.234.567,89" },
    { value: "1 234 567,89", label: "1 234 567,89" },
  ]);

  const reset = () => {
    setRequest({
      ...request,
      currency_code: "",
      currency_symbol: "",
      decimal_places: 0,
    });
    setDirtyFields({
      ...dirtyFields,
      currency_code: "",
      currency_symbol: "",
      decimal_places: 0,
    });
  };

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  const [currencyList, setCurrencyList] = useState([]);
  useEffect(() => {
    if (props.permissionAPITriggered) {
      //currencies
      let codes: any = Currencies.map((item: object) => ({
        ...item,
        label: `${getValue(item, `cc`, "")} - ${getValue(item, `name`, "")}`,
        value: `${getValue(item, `cc`, "")}`,
      }));
      let name: any = Currencies.map((item: object) => ({
        ...item,
        label: `${getValue(item, `symbol`, "")} - ${getValue(
          item,
          `name`,
          ""
        )}`,
        value: `${getValue(item, `symbol`, "")}`,
      }));
      setCurrencyCodes(codes);
      setCurrencyList(name);

      if (getValue(params, `id`, "")) {
        getCurrency(getValue(params, `id`, ""));
      } else {
        setLoading(false);
      }
    }
  }, [props.permissionAPITriggered]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const getCurrency = async (id: string) => {
    try {
      setLoading(true);
      let resp = await getSpecificCurrency(id);
      if (resp) {
        let countryCode = countries.filter(
          (item: object) =>
            getValue(item, `dial_code`, "") ===
            getValue(resp, `data.mobile_area_code`, "")
        );
        // mobile_area_code
        let code: any =
          countryCode.length > 0 &&
          countryCode.map((item) => ({
            id: getValue(item, `id`, ""),
            country: item.code,
            label: <>{item.dial_code + " " + item.name}</>,
            value: item.dial_code + " " + item.name,
          }));
        setRequest({
          ...request,
          currency_code: getValue(resp, `data.currency_code`, ""),
          currency_symbol: getValue(resp, `data.currency_symbol`, ""),
          decimal_places: getValue(resp, `data.decimal_places`, ""),
        });
        setDirtyFields({
          ...dirtyFields,
          currency_code: getValue(resp, `data.currency_code`, ""),
          currency_symbol: getValue(resp, `data.currency_symbol`, ""),
          decimal_places: getValue(resp, `data.decimal_places`, ""),
        });
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();

    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setIsLoading(true);
        let payload = request;
        if (getValue(params, `id`, "")) {
          let resp = await updateCurrency(getValue(params, `id`, ""), payload);
          if (resp) {
            setIsLoading(false);
            confirmNavigation();
            toast.success(getValue(resp, `message`, ""));
            navigate(`/admin/organisation/currencies`);
          } else {
            setIsLoading(false);
          }
        } else {
          let resp = await createCurrency(payload);
          if (resp) {
            setIsLoading(false);
            reset();
            confirmNavigation();
            toast.success(getValue(resp, `message`, ""));
            navigate(`/admin/organisation/currencies`);
          } else {
            setIsLoading(false);
          }
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const handleChangeText = (e: any) => {
    const { name, value } = e.target;
    setRequest({
      ...request,
      [name]: value,
    });
  };

  const handleTimeZone = (value: any) => {
    setValue(request, "user_timezone", value);
    setRequest({ ...request });
  };

  const handleChangeCountryCode = (e: any) => {
    setValue(request, "mobile_area_code", e);
    setRequest({ ...request });
  };

  const handleChangeRole = (e: any) => {
    setValue(request, "roles", e);
    setRequest({ ...request });
  };

  /* -------------------------------------------------------------------------- */
  /*                                Prompt Section                              */
  /* -------------------------------------------------------------------------- */
  const [isChanged, setIsChanged] = useState(false);
  useEffect(() => {
    setIsChanged(
      _.isEqualWith(request, dirtyFields, (a: any, b: any) => {
        // if both are null or equal to an empty string then they are equal
        if ((_.isNull(a) || a === "") && (_.isNull(b) || b === "")) return true;
      })
    );
  });
  const [showPrompt, confirmNavigation, cancelNavigation]: any =
    useCallbackPrompt(!isChanged);

  const handleChangeSelect = (e: any, type: string) => {
    if (type === "currency_code") {
      setRequest({
        ...request,
        currency_code: e.cc,
      });
    }
    if (type === "decimal_places") {
      setRequest({
        ...request,
        decimal_places: e.value,
      });
    }
    // if (type === "format") {
    //   setRequest({
    //     ...request,
    //     format: e.id,
    //   });
    // }
  };

  return (
    <>
      {/* <Header /> */}
      <Layout>
        <>
          <PromptModal
            showDialog={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
          />
          <StickyHeader
            title={`${
              getValue(orgParams, `id`, "") ? "Update" : "New"
            } Currency`}
            params={orgParams}
            route={"/admin/organisation/currencies"}
            handleSubmit={handleSubmit}
            submitLoading={isLoading}
            isChanged={isChanged}
            permissions={getValue(props, `permissions`, [])}
          />

          <div className="currency_container">
            {!loading &&
            !getValue(props, `subTabPermissionList`, []).includes(
              "admin_organisations_currencies"
            ) ? (
              <NoAccessPage />
            ) : isLoading || !props.permissionAPITriggered ? (
              <GearLoader />
            ) : (
              <>
                <div className="checkpoint-active-details-wrapper__form-wrapper">
                  <div className="checkpoint-active-details-wrapper__form-content">
                    <div className="user-details-wrapper__form  user-details-wrapper__form--org-profile currency-details-wrapper__form">
                      <div className="user-details-wrapper__form-group form-group">
                        <label className="checkpoint-active-details__label font-error-label">
                          Currency Code <span>*</span>
                        </label>
                        {/* <InputField
                          inputType="INPUT_REACT_SELECT"
                          placeholder="Choose Currency Code"
                          className="form-control w-100"
                          name="currency_code"
                          id="currency_code"
                          label="Currency Code"
                          options={currencyCodes}
                          value={
                            getValue(currencyCodes, `length`, 0) === 0
                              ? null
                              : currencyCodes.filter(
                                  (item: object) =>
                                    getValue(item, `value`, "") ==
                                    getValue(request, `currency_code`, "")
                                )[0]
                          }
                          onChange={(e: any) =>
                            handleChangeSelect(e, "currency_code")
                          }
                          validator={simpleValidator}
                        /> */}

                        <SingleSelectSearchDropdown
                          label={"label"}
                          selectKey={"value"}
                          name="Currency Code"
                          value={getValue(request, `currency_code`, "")}
                          onChange={(e: any) =>
                            setRequest({
                              ...request,
                              currency_code: getValue(e, `value`, ""),
                              currency_symbol: getValue(e, `symbol`, ""),
                            })
                          }
                          placeholder={"Choose Currency Code"}
                          data={currencyCodes}
                          width={"358px"}
                          validator={simpleValidator}
                        />
                      </div>
                      <div className="user-details-wrapper__form-group form-group">
                        <label className="checkpoint-active-details__label font-error-label">
                          Currency Symbol <span>*</span>{" "}
                        </label>

                        <SingleSelectSearchDropdown
                          label={"label"}
                          selectKey={"value"}
                          name="Currency Symbol"
                          value={getValue(request, `currency_symbol`, "")}
                          onChange={(e: any) =>
                            setRequest({
                              ...request,
                              currency_symbol: getValue(e, `value`, ""),
                            })
                          }
                          placeholder={"Choose Currency Ssymbol"}
                          data={currencyList}
                          width={"358px"}
                          validator={simpleValidator}
                          disabled
                        />
                        {/* <InputField
                        inputType="TEXT"
                        placeholder="Enter Currency Symbol"
                        className="form-control w-100"
                        name="currency_symbol"
                        id="currency_symbol"
                        label="Currency Symbol"
                        value={getValue(request, `currency_symbol`, "")}
                        onChange={(e: any) => handleChangeText(e)}
                        validator={simpleValidator}
                      /> */}
                      </div>
                      <div className="user-details-wrapper__form-group form-group ">
                        <label className="font-error-label">
                          Decimal Places <span>*</span>
                        </label>
                        {/* <InputField
                          inputType="INPUT_REACT_SELECT"
                          placeholder="Choose Deciaml Places"
                          className="form-control w-100"
                          name="decimal_places"
                          id="decimal_places"
                          label="Deciaml Places"
                          options={decimalPlaceList}
                          value={
                            decimalPlaceList.length === 0
                              ? null
                              : decimalPlaceList.filter(
                                  (item: object) =>
                                    getValue(item, `value`, "") ==
                                    getValue(request, `decimal_places`, "")
                                )[0]
                          }
                          onChange={(e: any) =>
                            handleChangeSelect(e, "decimal_places")
                          }
                          validator={simpleValidator}
                        /> */}

                        <SingleSelectSearchDropdown
                          label={"label"}
                          selectKey={"value"}
                          name="Deciaml Places"
                          value={getValue(request, `decimal_places`, "")}
                          onChange={(e: any) =>
                            setRequest({
                              ...request,
                              decimal_places: getValue(e, `value`, ""),
                            })
                          }
                          placeholder={"Choose Deciaml Places"}
                          data={decimalPlaceList}
                          width={"358px"}
                          validator={simpleValidator}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      </Layout>
    </>
  );
};

export default AdminOrgCurrencyDetailInfo;
