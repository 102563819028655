import React from 'react';
import './bulk-upload.scss';

export default function BulkUpload() {
  return (
    <div className="add-filter-wrapper__filter-container">
      <div className="d-flex">
        <img
          src="/images/file-upload.svg"
          className="img-fluid add-filter-wrapper__upload-image"
        />
      </div>
      <h6 className="add-filter-wrapper__filter-title">BULK UPLOAD</h6>
    </div>
  );
}
