import AddSvgComponent from "@assets/svg-components/add";
import SingleSelectSearchDropdown from "@components/common/CustomSearchableDropdown/SingleSelectSearchDropdown";
import { getValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
import VehicleAddPopup from "../popup/vehicle-popup";
import {
  createVehicle,
  deleteVehicle,
  getAllVehicles,
  updateVehicle,
} from "@services/vehicle.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import VehicleListPopup from "../popup/vehicle-list-popup";
import { toast } from "react-toastify";
import {
  createMileageRates,
  deleteMileageRates,
  getAllMileageRates,
  updateMileageRates,
} from "@services/mileage-rate.service";
import {
  createMileage,
  getAllMileages,
  getMileageByPolicy,
  updateMileage,
} from "@services/mileage.service";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import MileageRateAddPopup from "../popup/mileage-rate-popup";
import EditSvgComponent from "@assets/svg-components/edit";
import DeleteSvgComponent from "@assets/svg-components/delete";
import { convertCurrentDate } from "@common/date-helpers";

import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

function MileagePage(props: any) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  /* -------------------------------------------------------------------------- */
  /*                             UseEffect section                              */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getMileageList();
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                               Mileage section                              */
  /* -------------------------------------------------------------------------- */
  const mainParams = useParams();
  const [mileageReq, setMileageReq] = useState({
    policy: parseInt(getValue(mainParams, `id`, "")),
    mileage_expense_type: null,
    mileage_unit: "km",
  });
  const [mileageID, setMileageID] = useState("");
  const [mileageInfo, setMileageInfo] = useState({});
  const getMileageList = async () => {
    try {
      let payload = {
        page_no: 1,
        page_size: 100,
      };
      setIsLoading(true);
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getMileageByPolicy(
        getValue(urlParams, `policy_id`, "")
          ? parseInt(getValue(urlParams, `policy_id`, ""))
          : parseInt(getValue(mainParams, `id`, ""))
      );
      if (resp) {
        if (getValue(resp, `data.id`, "")) {
          setMileageReq({
            ...mileageReq,
            mileage_expense_type: getValue(
              resp,
              `data.mileage_expense_type.id`,
              ""
            ),
            mileage_unit: getValue(resp, `data.mileage_unit`, ""),
          });
          getVehicleList();
          setMileageInfo(getValue(resp, `data`, {}));
          setMileageID(getValue(resp, `data.id`, ""));
          getMileageRateList(getValue(resp, `data.id`, ""));
          setIsLoading(false);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const [submitLoading1, setSubmitLoading1] = useState(false);
  const handleSubmitMileage = async () => {
    setSubmitLoading1(true);
    try {
      let resp = await createMileage({
        ...mileageReq,
        policy: getValue(urlParams, `policy_id`, "")
          ? parseInt(getValue(urlParams, `policy_id`, ""))
          : parseInt(getValue(mainParams, `id`, "")),
      });
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        getMileageList();
      }
    } catch (error) {}
  };
  const handleUpdateMileage = async (obj: object) => {
    setSubmitLoading1(true);
    try {
      let resp = await updateMileage(mileageID, obj);
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        getMileageList();
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                               Vehicle section                              */
  /* -------------------------------------------------------------------------- */
  const types = [
    { value: "km", label: "Km" },
    { value: "mile", label: "Mile" },
  ];

  const [isVehicleOpen, setVehicleOpen] = useState(false);
  const handleVehicleModal = () => {
    setVehicleOpen(!isVehicleOpen);
  };

  const [vehicleList, setVehicleList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const getVehicleList = async () => {
    try {
      let payload = {
        page_no: 1,
        page_size: 100,
      };
      setIsLoading(true);
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllVehicles(queryRequest);
      if (resp) {
        setVehicleList(
          getValue(resp, `data.vehicles`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `id`, ""),
            name: getValue(item, `vehicle_name`, ""),
            label: getValue(item, `vehicle_name`, ""),
          }))
        );
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const [isVehicleListOpen, setVehicleListOpen] = useState(false);
  const handleVehicleListModal = () => {
    setVehicleListOpen(!isVehicleListOpen);
  };

  const [request, setRequest] = useState({
    vehicle_name: "",
    mileage_type: "manual",
    description: "",
  });
  /* ----------------------------   Submit Section   -------------------------------- */

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      let resp;
      if (editId) {
        resp = await updateVehicle(editId, request);
      } else {
        resp = await createVehicle(request);
      }
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        setSubmitLoading(false);
        setRequest({
          ...request,
          vehicle_name: "",
          mileage_type: "manual",
          description: "",
        });
        handleVehicleModal();
        getVehicleList();
        setEditId("");
      }
      setSubmitLoading(false);
    } catch (error) {
      setSubmitLoading(false);
    }
  };
  /* ----------------------------   Edit Section   -------------------------------- */
  const [editId, setEditId] = useState("");
  const handleEditFun = (obj: object) => {
    setEditId(getValue(obj, `id`, ""));
    setRequest({
      ...request,
      vehicle_name: getValue(obj, `vehicle_name`, ""),
      mileage_type: getValue(obj, `mileage_type`, ""),
      description: getValue(obj, `description`, ""),
    });
    setVehicleListOpen(false);
    setVehicleOpen(true);
  };
  /* ----------------------------   Delete Section   -------------------------------- */
  const handleDeleteFun = async (id: string) => {
    try {
      let resp = await deleteVehicle(id);
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        getVehicleList();
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                          Mileage Rate Section                              */
  /* -------------------------------------------------------------------------- */
  const [editId1, setEditId1] = useState("");
  const [isMileageRateListOpen, setMileageRateListOpen] = useState(false);
  const handleMileageRateListModal = () => {
    setMileageRateListOpen(!isMileageRateListOpen);
  };
  const [mileageRateReq, setMileageRateReq] = useState<any>({
    effective_date: "",
    mileage_rate: 0,
    threshold_mileage_rate: 0,
    vehicle: "",
  });
  const [isLoading1, setIsLoading1] = useState(false);
  const [mileageRateList, setMileageRateList] = useState([]);
  const getMileageRateList = async (id: string) => {
    try {
      let payload = {
        page_no: 1,
        page_size: 100,
      };
      setIsLoading(true);
      let queryRequest = QueryRequestHelper(payload);
      setIsLoading1(true);
      let resp = await getAllMileageRates(id, queryRequest);
      if (resp) {
        setMileageRateList(getValue(resp, `data.mileage_rates`, []));
        setIsLoading1(false);
      } else {
        setIsLoading1(false);
      }
    } catch (error) {
      setIsLoading1(false);
    }
  };
  /* ----------------------------   Submit Section   -------------------------------- */

  const [submitLoading2, setSubmitLoading2] = useState(false);
  const handleSubmit2 = async () => {
    try {
      setSubmitLoading2(true);
      let resp;
      if (editId1) {
        resp = await updateMileageRates(editId1, mileageRateReq);
      } else {
        resp = await createMileageRates(mileageID, mileageRateReq);
      }
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        setSubmitLoading2(false);
        setMileageRateReq({
          ...mileageRateReq,
          effective_date: "",
          mileage_rate: 0,
          threshold_mileage_rate: 0,
          vehicle: "",
        });
        handleMileageRateListModal();
        getMileageRateList(mileageID);
        setEditId1("");
      }
      setSubmitLoading2(false);
    } catch (error) {
      setSubmitLoading2(false);
    }
  };
  /* ----------------------------   Edit Section   -------------------------------- */
  const handleEditMileageRate = (obj: object) => {
    setEditId1(getValue(obj, `id`, ""));
    setMileageRateReq({
      ...mileageRateReq,
      effective_date: new Date(getValue(obj, `effective_date`, "")),
      mileage_rate: getValue(obj, `mileage_rate`, ""),
      threshold_mileage_rate: getValue(obj, `threshold_mileage_rate`, ""),
      vehicle: getValue(obj, `vehicle.id`, ""),
    });
    setMileageRateListOpen(true);
  };
  /* ----------------------------   Delete Section   -------------------------------- */
  const handleDeleteMileageRate = async (id: string) => {
    try {
      let resp = await deleteMileageRates(id);
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        getMileageList();
      }
    } catch (error) {}
  };

  return (
    <div className="mileage-limits-tabs-content-wrap ">
      <div>
        {/* className="m-4" */}
        {getValue(mileageInfo, `id`, "") ? (
          <div>
            <h6 className="label-holder">Default Unit</h6>
            <div className="mt-2 d-flex align-items-center flex-wrap form-group">
              {types.map((item: object) => {
                return (
                  <div
                    className="mileage_type_container d-flex align-items-center justify-content-center p-1 me-2 cursor-pointer"
                    onClick={() => {
                      setMileageReq({
                        ...mileageReq,
                        mileage_unit: getValue(item, `value`, ""),
                      });
                      handleUpdateMileage({
                        policy: parseInt(getValue(mainParams, `id`, "")),
                        mileage_expense_type: getValue(
                          mileageReq,
                          `mileage_expense_type`,
                          ""
                        ),
                        mileage_unit: getValue(item, `value`, ""),
                      });
                    }}
                  >
                    <input
                      type="radio"
                      checked={
                        getValue(mileageReq, `mileage_unit`, "") ===
                        getValue(item, `value`, "")
                      }
                    />
                    <h6 className="header_text__14 ms-2">
                      {getValue(item, `label`, "")}
                    </h6>
                  </div>
                );
              })}
            </div>
            <div className="w-40 mileage-expense-label-wrap form-group">
              <SingleSelectSearchDropdown
                placeholder={`Choose`}
                onChange={(e: any) => {
                  setMileageReq({
                    ...mileageReq,
                    mileage_expense_type: getValue(e, `value`, ""),
                  });
                  handleUpdateMileage({
                    policy: parseInt(getValue(mainParams, `id`, "")),
                    mileage_expense_type: getValue(e, `value`, ""),
                    mileage_unit: getValue(mileageReq, `mileage_unit`, ""),
                  });
                }}
                label={`label`}
                //  validator={
                //    getValue(item, `is_mandatory`, false) && simpleValidator
                //  }
                width={"100%"}
                data={getValue(props, `list`, [])}
                value={getValue(mileageReq, `mileage_expense_type`, "")}
                selectKey={"id"}
                name={"Expense Type"}
                //   mandatory
                showLabel
              />
            </div>
            <div className="vehicle_container form-group">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  {getValue(vehicleList, `length`, 0) > 0 ? (
                    <h6
                      className="header_text__14 color_primary cursor-pointer"
                      onClick={handleVehicleListModal}
                    >
                      You have added {getValue(vehicleList, `length`, 0)}{" "}
                      vehicle
                    </h6>
                  ) : (
                    <h6 className="header_text__14">Add company vehicles</h6>
                  )}
                  <p className="small_text__14 mt-1">
                    Add the vehicles used in your organization and configure
                    appropriate mileage rates for them
                  </p>
                </div>
                {getValue(props, `permissions`, []).includes("update") && (
                  <div
                    className="d-flex align-items-center vehicle_add_container cursor-pointer"
                    onClick={handleVehicleModal}
                  >
                    <AddSvgComponent color="#1966A1" />
                    <h6 className="header_text__14 ms-2 color_primary">
                      New Vehicle
                    </h6>
                  </div>
                )}
              </div>
            </div>
            <div>
              {getValue(mileageRateList, `length`, 0) > 0 ? (
                <div className="mileage_rate_container">
                  {" "}
                  <table className="datatable_table">
                    <thead className="datatable_table_thead">
                      <th className="datatable_table_th text-start">
                        <h6>Expense Type</h6>
                      </th>
                      <th className="datatable_table_th">
                        <h6>Vehicle</h6>
                        <p>Start Date</p>
                      </th>
                      <th className="datatable_table_th">
                        <h6>Mileage Rate</h6>
                        <p>Mileage Unit</p>
                      </th>
                      <th className="datatable_table_th">
                        <h6>Action</h6>
                      </th>
                    </thead>
                    <tbody className="datatable_table_tbody">
                      {mileageRateList.map((item: object, index: number) => {
                        return (
                          <tr key={index} className="datatable_table_tr">
                            <td className="datatable_table_td_common justify-content-start">
                              <h6>
                                {getValue(
                                  item,
                                  `mileage.mileage_expense_type.expense_type_name`,
                                  ""
                                )}
                              </h6>
                            </td>
                            <td className="datatable_table_td">
                              <h6>
                                {" "}
                                {getValue(item, `vehicle.vehicle_name`, "")}
                              </h6>
                              <p>
                                {convertCurrentDate(
                                  getValue(item, `effective_date`, "")
                                )}
                              </p>
                            </td>

                            <td className="datatable_table_td">
                              <h6>{getValue(item, `mileage_rate`, "")}</h6>
                              <p>
                                {getValue(item, `mileage.mileage_unit`, "")}
                              </p>
                            </td>

                            <td className="datatable_table_td action-icon-td">
                              {/* <div className="d-flex align-items-center ">
                                <div
                                  onClick={() => handleEditMileageRate(item)}
                                >
                                  <EditSvgComponent />
                                </div>
                                <div
                                  className="action_container mx-2"
                                  onClick={() =>
                                    handleDeleteMileageRate(
                                      getValue(item, `id`, "")
                                    )
                                  }
                                >
                                  <DeleteSvgComponent />
                                </div>
                              </div> */}
                              {getValue(props, `permissions`, []).includes(
                                "update"
                              ) && (
                                <UncontrolledDropdown
                                  className="table-action-icon-wrap"
                                  direction="start"
                                  container={"body"}
                                >
                                  <DropdownToggle>
                                    <div className="switch_header__dropdown m-0 align-items-center">
                                      <div className="close-image_container action-icon-wrap">
                                        <img
                                          src={"/images/more.svg"}
                                          className=" close_image"
                                        />
                                      </div>
                                    </div>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem>
                                      <div
                                        className={`more-dropdown-main-item `}
                                        onClick={() =>
                                          handleEditMileageRate(item)
                                        }
                                      >
                                        <p>Edit</p>
                                      </div>
                                    </DropdownItem>
                                    <DropdownItem>
                                      <div
                                        className={`more-dropdown-main-item `}
                                        onClick={() =>
                                          handleDeleteMileageRate(
                                            getValue(item, `id`, "")
                                          )
                                        }
                                      >
                                        <p>Delete</p>
                                      </div>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {getValue(props, `permissions`, []).includes("update") && (
                    <div
                      className="d-flex align-items-center justify-content-center cursor-pointer mt-3"
                      onClick={handleMileageRateListModal}
                    >
                      <AddSvgComponent color={"#1966A1"} />
                      <h6 className="header_text__14 ms-2 color_primary">
                        New Rate
                      </h6>
                    </div>
                  )}
                </div>
              ) : (
                <div className="mileage_rate_container mt-4 d-flex justify-content-center">
                  <div className="w-50">
                    <h6 className="header_text__16 text-center">
                      Configure mileage rates
                    </h6>
                    <p className="small_text__14 text-center mt-2">
                      Mileage rates are used to calculate fuel reimbursement for
                      employees associated with this policy. Based on expense
                      created date, corresponding mileage rate is applied.
                      Default rate can be set by creating a mileage rate without
                      choosing the date.
                    </p>
                    {getValue(props, `permissions`, []).includes("update") && (
                      <div
                        className="d-flex align-items-center justify-content-center rate_add_container cursor-pointer mt-3"
                        onClick={handleMileageRateListModal}
                      >
                        <AddSvgComponent color={"#fff"} />
                        <h6 className="header_text__14 ms-2 text-white">
                          New Rate
                        </h6>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div>
            <h6 className="header_text__14">Default Unit</h6>
            <div className="m-2 d-flex align-items-center flex-wrap">
              {types.map((item: object) => {
                return (
                  <div
                    className="mileage_type_container d-flex align-items-center justify-content-center p-1 mx-2 cursor-pointer"
                    onClick={() =>
                      setMileageReq({
                        ...mileageReq,
                        mileage_unit: getValue(item, `value`, ""),
                      })
                    }
                  >
                    <input
                      type="radio"
                      checked={
                        getValue(mileageReq, `mileage_unit`, "") ===
                        getValue(item, `value`, "")
                      }
                    />
                    <h6 className="header_text__14 ms-2">
                      {getValue(item, `label`, "")}
                    </h6>
                  </div>
                );
              })}
            </div>
            <div className="w-40 mt-4">
              <SingleSelectSearchDropdown
                placeholder={`Choose`}
                onChange={(e: any) =>
                  setMileageReq({
                    ...mileageReq,
                    mileage_expense_type: getValue(e, `value`, ""),
                  })
                }
                label={`label`}
                //  validator={
                //    getValue(item, `is_mandatory`, false) && simpleValidator
                //  }
                width={"100%"}
                data={getValue(props, `list`, [])}
                value={getValue(mileageReq, `mileage_expense_type`, "")}
                selectKey={"id"}
                name={"Expense Type"}
                mandatory
                showLabel
              />
            </div>

            <div className="mt-4">
              <NavLink to={`${`/admin/user-controls/policies`}`}>
                <button className="deeptravel-button deeptravel-button--header-buttons deeptravel-button--primary">
                  Cancel
                </button>
              </NavLink>

              <button
                className={`deeptravel-button deeptravel-button--header-buttons deeptravel-button--secondary`}
                onClick={handleSubmitMileage}
                disabled={submitLoading1}
              >
                {submitLoading ? "Please wait..." : "Save"}
              </button>
            </div>
          </div>
        )}
      </div>
      <VehicleAddPopup
        isOpen={isVehicleOpen}
        handleModal={handleVehicleModal}
        request={request}
        setRequest={setRequest}
        handleSubmit={handleSubmit}
        submitLoading={submitLoading}
        editId={editId}
        setEditId={setEditId}
      />
      <VehicleListPopup
        isOpen={isVehicleListOpen}
        handleModal={handleVehicleListModal}
        list={vehicleList}
        handleEditFun={handleEditFun}
        handleDeleteFun={handleDeleteFun}
      />
      <MileageRateAddPopup
        vehicleList={vehicleList}
        mileageRateReq={mileageRateReq}
        setMileageRateReq={setMileageRateReq}
        handleModal={handleMileageRateListModal}
        isOpen={isMileageRateListOpen}
        request={mileageRateReq}
        setRequest={setMileageRateReq}
        editId={editId1}
        setEditId={setEditId1}
        handleSubmit={handleSubmit2}
        submitLoading={submitLoading2}
      />
    </div>
  );
}

export default MileagePage;
