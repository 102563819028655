import BackSvgComponent from "@assets/svg-components/back";
import { getColorStyle, getStatus } from "@common/color";
import { getFirstLetterOfEachString } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import React from "react";
import { NavLink } from "react-router-dom";

function StickyHeader(props: any) {
  const { route } = props;
  return (
    <div>
      <div className="common-sticky-header">
        <section className="inner-page-navigation-with-back-cta-buttons position-relative">
          <div className="container-fluid  d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-between align-items-center p-2">
              <NavLink to={route ? `${route}` : "#"}>
                <BackSvgComponent />
              </NavLink>
              {/* <p className="header_text"> Reports</p> */}
              <div
                className="profile_circle"
                style={{
                  background: "#FF8F1A",
                  border: "1px solid #FF8F1A",
                }}
              >
                <h5 className="title" style={{ color: "white" }}>
                  {getFirstLetterOfEachString(
                    getValue(props, `info.created_by.email`, "")
                  )}
                </h5>
              </div>
              <div>
                <h6 className="header_text__18">
                  {getValue(props, `info.created_by.first_name`, "")}{" "}
                  {getValue(props, `info.created_by.last_name`, "")}
                </h6>
                <p className="small_text__14">
                  {getValue(props, `info.created_by.email`, "")}
                </p>
              </div>
              <div className="vr-line mx-3"></div>

              <div>
                <h6 className="header_text__16 ms-1">
                  {getValue(props, `info.report_id`, "")}
                </h6>
                <h6
                  className=""
                  style={getColorStyle(
                    getValue(props, `info.approval_status`, "") == "unsubmitted"
                      ? "draft"
                      : getValue(props, `info.approval_status`, "")
                  )}
                >
                  {getStatus(getValue(props, `info.approval_status`, ""))}
                </h6>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default StickyHeader;
