import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllPaidThroughAccount = (queryRequest: string) =>
  get(`${config.API_URL}/paid-through-account?${queryRequest}`);

export const getSpecificPaidThroughAccount = (id: string) =>
  get(`${config.API_URL}/paid-through-account/${id}`);

export const createPaidThroughAccount = (payload: object) =>
  post(`${config.API_URL}/paid-through-account`, payload);

export const updatePaidThroughAccount = (id: string, payload: object) =>
  patch(`${config.API_URL}/paid-through-account/${id}`, payload);

export const deletePaidThroughAccount = (id: string) =>
  Delete(`${config.API_URL}/paid-through-account/${id}`);

export const updateaidThroughAccountBulk = (payload: object) =>
  patch(`${config.API_URL}/paid-through-account/bulk/active-inactive`, payload);
