import React from "react";
import "./styles.scss";
import { getValue } from "@utils/lodash";

function MinusSvgComponent(props:any) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        // height="48"
        viewBox="0 0 448 512"
        // width="48"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"
        }
      >
        <path
          d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"
          fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "#3c4043"
          }
        />
      </svg>
    </>
  );
}

export default MinusSvgComponent;