import * as React from "react";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import { getValue } from "@utils/lodash";
import Lottie from "lottie-react";
import bin from "@lottie/done.json";

interface IOrgTokenConfirmProps {
  isOpen: boolean;
  handleModal: () => void;
  handleSubmit: () => void;
}
const OrgTokenConfirm: React.FunctionComponent<IOrgTokenConfirmProps> = (
  props
) => {
  return (
    <Modal isOpen={getValue(props, `isOpen`, "")} toggle={props.handleModal}>
      {/* <ModalHeader>
        <h5 className="header_text">Delete</h5>
      </ModalHeader> */}
      <section className="delete-popup-wrapper">
        <div className="delete-popup-wrapper__popup">
          <div style={{ justifyContent: "center", display: "flex" }}>
            <div style={{ width: "25%", height: "25%", alignSelf: "center" }}>
              <Lottie animationData={bin} loop={true} />
            </div>
          </div>
          <div className="delete-popup-wrapper__form-group form-group">
            <p className="mt-3 delete-popup-wrapper__desc_text small_text__16">
              Would you like to generate a new client token to replace the old
              token used for SSO ?
            </p>
          </div>
        </div>
      </section>
      <ModalFooter>
        <div className="delete-popup-wrapper__cta-wrapper">
          <button
            onClick={props.handleModal}
            className="delete-popup-wrapper__cta-btn delete-popup-wrapper__cta-btn--secondary"
          >
            No
          </button>
          <button
            className="delete-popup-wrapper__cta-btn delete-popup-wrapper__cta-btn--primary"
            onClick={props.handleSubmit}
          >
            Yes
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default OrgTokenConfirm;
