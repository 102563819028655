import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllMileageRates = (id: string, queryRequest: string) =>
  get(`${config.API_URL}/mileage/${id}/mileage-rates?${queryRequest}`);

export const getSpecificMileageRates = (id: string, rate_id: string) =>
  get(`${config.API_URL}/mileage/${id}/mileage-rates/${rate_id}`);

export const createMileageRates = (id: string, payload: object) =>
  post(`${config.API_URL}/mileage/${id}/mileage-rates`, payload);

export const updateMileageRates = (rate_id: string, payload: object) =>
  patch(`${config.API_URL}/mileage/mileage-rates/${rate_id}`, payload);

export const deleteMileageRates = (rate_id: string) =>
  Delete(`${config.API_URL}/mileage/mileage-rates/${rate_id}`);
