import { config } from "../env";
import {
  DeleteWithPayload,
  post,
  get,
  getWithHeader,
  patch,
} from "./helpers/http-handler";

export const createHistory = (payload: object) =>
  post(`${config.API_URL}/approval-history`, payload);

export const getHistory = (queryRequest: string) =>
  get(`${config.API_URL}/approval-history?${queryRequest}`);

export const approveExpenseReportLogin = (id: string, token: string) =>
  getWithHeader(
    `${config.API_URL}/email-approval/expense-report/${id}/approve`,
    token
  );

export const rejectExpenseReportLogin = (id: string, token: string) =>
  getWithHeader(
    `${config.API_URL}/email-approval/expense-report/${id}/reject`,
    token
  );

export const approveTriptLogin = (id: string, token: string) =>
  getWithHeader(`${config.API_URL}/email-approval/trip/${id}/approve`, token);

export const rejectTriptLogin = (id: string, token: string) =>
  getWithHeader(`${config.API_URL}/email-approval/trip/${id}/reject`, token);

export const downloadURL = (encoded_key: string) =>
  get(
    `${config.API_URL}/media-uploads/get-download-url?encoded_key=${encoded_key}`
  );
