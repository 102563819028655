import AddSvgComponent from "@assets/svg-components/add";
import DocumentSvgComponent from "@assets/svg-components/document";
import { getValue } from "@utils/lodash";
import React from "react";

const DocumentFormatSelector = ({
  item,
  handleDownloadOnClick,
  formInfo,
  handleEditExpense,
}: any) => {
  return (
    <div className="d-  ">
      {getValue(item, `receipts`, []).map((doc: any, index: any) => {
        return (
          <div
            key={index}
            onClick={() => {
              const receiptsLength = getValue(item, "receipts.length", 0);
              const approvalStatus = getValue(formInfo, "approval_status", "");
              if (receiptsLength > 0) {
                // handleClickGallery(item);
                // setTimeout(() => {
                //   openGallery(index);
                // }, 500);
                // handleDownloadOnClick(
                //   getValue(item, `url`, "")
                // );
              } else if (receiptsLength === 0) {
                if (
                  approvalStatus === "unsubmitted" ||
                  approvalStatus === "recalled"
                ) {
                  handleEditExpense(getValue(item, "id", ""));
                }
              }
            }}
          >
            <div
              className="doc_view mx-1"
              onClick={() =>
                handleDownloadOnClick(
                  getValue(doc, `url`, ""),
                  getValue(doc, `name`, "")
                )
              }
            >
              {!getValue(doc, `url`, "") ? (
                <AddSvgComponent color={"gray"} size={26} />
              ) : getValue(doc, `extension`, "") === ".png" ||
                getValue(doc, `extension`, "") === ".jpg" ||
                getValue(doc, `extension`, "") === ".jpeg" ? (
                <img
                  src="/images/image_file.png"
                  className="img-fluid cursor-pointer static_image"
                  alt=""
                  width={36}
                  height={36}
                />
              ) : // <img
              //   // src={getValue(doc, `url`, "")}
              //   src="/images/image.svg"
              //   className="img-fluid cursor-pointer static_image "
              //   width={20}
              // />
              getValue(doc, `extension`, "") === ".pdf" ? (
                <img
                  src="/images/pdf_file.png"
                  className="img-fluid cursor-pointer static_image"
                  alt=""
                  width={36}
                  height={36}
                />
              ) : getValue(doc, `extension`, "") === ".xls" ||
                getValue(doc, `extension`, "") === ".xlsx" ? (
                <img
                  src="/images/excel.png"
                  className="img-fluid cursor-pointer static_image"
                  alt=""
                  width={36}
                  height={36}
                />
              ) : (
                <img
                  src="/images/file.png"
                  className="img-fluid cursor-pointer static_image"
                  alt=""
                  width={36}
                  height={36}
                />
              )}
              {/* {isHovered && (
  <div
    className={
      getValue(item, `receipts.length`, 0) === 0
        ? ""
        : "overlay"
    }
  >
    <p>
      {getValue(item, `receipts.length`, 0) > 0
        ? `+ ${
            getValue(item, `receipts.length`, 0) - 1
          }`
        : ""}
    </p>
  </div>
)} */}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DocumentFormatSelector;
