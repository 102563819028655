import HeaderLayout from "@layouts/withoutNaviagtion/withoutNavigationLayout";
import { useState } from "react";
import ReviewSelection from "../components/SelectionSummary/ReviewSelection";
import ContactDetails from "../components/SelectionSummary/ContactDetails";
import { useNavigate } from "react-router-dom";

function BookingSummary({ hotelSummary }) {
  const [activeStep, setActiveStep] = useState(1);
  const navigate = useNavigate();

  const handleContinue = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 1) {
      window.history.back();
    } else {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 1:
        return <ReviewSelection hotelDetails={hotelSummary} />;
      case 2:
        return <ContactDetails hotelDetails={hotelSummary} />;
      case 3:
        return (
          <>
            <ReviewSelection hotelDetails={hotelSummary} />
            <ContactDetails hotelDetails={hotelSummary} />
          </>
        );
      default:
        return null;
    }
  };

  const steps = [
    {
      icon: hotelSummary
        ? "/images/dashboard/trips/cab-booking/bed.svg"
        : "/images/dashboard/trips/cab-booking/taxi.svg",
      activeIcon: hotelSummary
        ? "/images/dashboard/trips/cab-booking/bed-active.svg"
        : "/images/dashboard/trips/cab-booking/taxi-active.svg",
      text: hotelSummary ? "Rooms Selected" : "Cars Selected",
    },
    {
      icon: "/images/dashboard/trips/cab-booking/awesome.svg",
      activeIcon: "/images/dashboard/trips/cab-booking/awesome-active.svg",
      text: "Review Selection",
    },
    {
      icon: "/images/dashboard/trips/cab-booking/users.svg",
      activeIcon: "/images/dashboard/trips/cab-booking/users-active.svg",
      text: "Contact Details",
    },
    {
      icon: "/images/dashboard/trips/cab-booking/approval.svg",
      activeIcon: "/images/dashboard/trips/cab-booking/approval-active.svg",
      text: "Summary",
    },
  ];

  return (
    <HeaderLayout>
      <div className="d-flex justify-content-between px-3 align-items-center border-bottom summary-nav">
        <div className="d-flex gap-3 align-items-center">
          <img
            src="/images/back-link.svg"
            onClick={() => window.history.back()}
            className="cursor-pointer"
          />
          <span>Booking Summary</span>
        </div>
        <div>
          <button
            onClick={handleBack}
            className="deeptravel-button deeptravel-button--header-buttons deeptravel-button--primary "
          >
            {activeStep === 1 ? "Cancel" : "Back"}
          </button>
          {activeStep !== 3 ? (
            <button
              onClick={handleContinue}
              className="deeptravel-button deeptravel-button--secondary deeptravel-button--header-buttons"
            >
              Continue
            </button>
          ) : (
            <button
              onClick={() =>
                navigate(
                  `/client/trips/online-booking/${
                    hotelSummary ? "properties" : "transport"
                  }/booking/thank-you`
                )
              }
              className="deeptravel-button deeptravel-button--secondary deeptravel-button--header-buttons"
            >
              Submit
            </button>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-center px-3 align-items-center border-bottom py-3 gap-5 selected-summary bg-white">
        {steps.map((step, index) => (
          <div
            key={index}
            className="d-flex flex-column justify-content-center align-items-center gap-2 mx-4"
          >
            <img
              src={index === activeStep ? step.activeIcon : step.icon}
              width={22}
            />
            <p
              className={`text-center small_text__14 ${
                index === activeStep ? "color_primary" : ""
              }`}
            >
              {step.text}
            </p>
          </div>
        ))}
      </div>
      <div className="summary-details">{renderStepContent(activeStep)}</div>
    </HeaderLayout>
  );
}

export default BookingSummary;
