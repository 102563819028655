import { getValue } from "@utils/lodash";
import React from "react";

function CashAdvanceSvgComponent(props: any) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        className="icon align-text-top mr-1"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"
        }
        fill={
          getValue(props, `color`, "")
            ? getValue(props, `color`, "")
            : "#3c4043"
        }
      >
        <path d="m461.6 136-40.2-88.4C404.6 10.7 361-5.6 324.1 11.1L59.5 131.4l-2.7 1.2-1.2.6c-.7.3-1.4.7-2.1 1.1-29 15.2-48.8 45.6-48.8 80.6v202c0 50.2 40.8 91 91 91h320.5c50.2 0 91-40.8 91-91v-202c.1-33.7-18.3-63.1-45.6-78.9zM341.5 49.4c15.8-7.2 34.5-.2 41.7 15.6l26.8 58.9H177.6l163.9-74.5zm74.8 416.5H95.7c-27 0-49-22-49-49v-202c0-19.4 11.3-36.1 27.6-44.1l2.4-1.1c5.8-2.5 12.3-3.8 19-3.8h320.5c27 0 49 22 49 49v81.5h-57.5c-11.6 0-21 9.4-21 21s9.4 21 21 21h57.5v78.5c.1 27-21.9 49-48.9 49z"></path>
      </svg>
    </div>
  );
}

export default CashAdvanceSvgComponent;
