import { getValue } from "@utils/lodash";
import "./right-popup.scss";
import Loader from "@components/common/Loader";
import Notfound from "@components/Not-Found";
import { formatAmount, formatText } from "@common/text-helpers";
import { convertCurrentDate } from "@common/date-helpers";
function UnAssociatedExpensePopup(props: any) {
  const {
    showUpdatePopup,
    setShowUpdatePopup,
    request,
    handleChangeText,
    submitLoading,
    handleSubmit,
    editId,
    setRequest,
  } = props;
  return (
    <div
      className={`compelte-task-wrapper ${
        showUpdatePopup ? "compelte-task-wrapper--active" : ""
      }`}
      style={{ width: "50%" }}
    >
      <div className="compelte-task-wrapper__header d-flex align-items-center justify-content-between">
        <h6 className="compelte-task-wrapper__title">Add Expenses to Report</h6>
        <img
          src="/images/close-white.svg"
          className="img-fluid navigation-bar-wrapper__close-btn"
          onClick={() => {
            setShowUpdatePopup(false);
          }}
        />
      </div>
      <div className="unassociated_main_container">
        {getValue(props, `isLoading`, false) ? (
          <Loader />
        ) : getValue(props, `list.length`, 0) > 0 ? (
          <div className="unassociate_list_container">
            {getValue(props, `list`, []).map((item: object, index: number) => {
              return (
                <div
                  className="image_container mb-3 position-relative"
                  onClick={() => props.handleCheck(getValue(item, `id`, ""))}
                >
                  <div>
                    <input
                      type="checkbox"
                      className="input_checkbox"
                      checked={getValue(props, `expenseEntryIds`, []).includes(
                        getValue(item, `id`, "")
                      )}
                      // onChange={() =>
                      //   props.handleCheck(getValue(item, `id`, ""))
                      // }
                    />
                  </div>
                  <div
                    className="image_container_body d-flex justify-content-center align-items-center"
                    style={props.containerStayle}
                  >
                    {/* {getValue(item, `extension`, "") === ".png" ||
                    getValue(item, `extension`, "") === ".jpg" ||
                    getValue(item, `extension`, "") === ".jpeg" ? (
                      <img
                        src={getValue(item, `url`, "")}
                        className="img-fluid cursor-pointer close_image"
                      />
                    ) : getValue(item, `extension`, "") === ".pdf" ? (
                      <img
                        src="/images/pdf.svg"
                        className="img-fluid cursor-pointer close_image"
                        width="65px"
                      />
                    ) : getValue(item, `extension`, "") === ".xls" ||
                      getValue(item, `extension`, "") === ".xlsx" ? (
                      <img
                        src="/images/excel.svg"
                        className="img-fluid cursor-pointer close_image"
                        width="65px"
                      />
                    ) : (
                      <img
                        src="/images/pdf.svg"
                        className="img-fluid cursor-pointer close_image"
                        width="65px"
                      />
                    )} */}
                    <img
                      src="/images/expense.svg"
                      className="img-fluid cursor-pointer close_image"
                      width="65px"
                    />
                  </div>
                  <div
                    className="image_containertext_body align-items-center px-3"
                    style={props.textContainerStayle}
                  >
                    <div className="d-flex">
                      <p className="small_text" style={props.textStyle}>
                        {convertCurrentDate(getValue(item, `created_at`, ""))} -{" "}
                      </p>
                      <p
                        className={`small_text ms-1 ${
                          getValue(item, `status`, "") === "associated"
                            ? "text-success"
                            : "text-danger"
                        }`}
                      >
                        {formatText(getValue(item, `status`, ""))}
                      </p>
                    </div>
                    <p className="small_text__14 color_primary mt-1">
                      {getValue(item, `expense_type.expense_type_name`, "")}
                    </p>
                    <p className="header_text mt-1">
                      {getValue(item, `claimed_currency_symbol`, "")}{" "}
                      {formatAmount(getValue(item, `amount`, ""))}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <Notfound />
        )}
      </div>

      <div className="fixed_moda_footer_wrapper me-3">
        <button
          className="deeptravel-button deeptravel-button--header-buttons deeptravel-button--secondary "
          onClick={() => setShowUpdatePopup(false)}
        >
          Cancel
        </button>
        <button
          className="deeptravel-button deeptravel-button--header-buttons deeptravel-button--primary  ms-3"
          onClick={() => handleSubmit()}
          disabled={
            submitLoading || getValue(props, `expenseEntryIds.length`, 0) === 0
          }
        >
          {submitLoading ? "Please wait..." : "Submit"}
        </button>
      </div>
    </div>
  );
}

export default UnAssociatedExpensePopup;
