import MyViewsTrips from "@pages/Client/Trips";
import ClientTripsCreate from "@pages/Client/Trips/create";
import ClientTripsDetail from "@pages/Client/Trips/detail";
import RequestType from "@pages/Client/Trips/components/BookingType";
import OnlineBooking from "@pages/Client/Trips/online";
import FilteredProperties from "@pages/Client/Trips/propertiesList";
import FilteredTransport from "@pages/Client/Trips/transport";
import PropertyDetails from "@pages/Client/Trips/property";
import CabBooking from "@pages/Client/Trips/BookingSummary";
import PropertyBooking from "@pages/Client/Trips/PropertyBooking";
import TripThankYou from "@pages/Client/Trips/components/SelectionSummary/ThankYou";

export const ClientTripsRoutes = [
  //expenses
  {
    path: "/client/trips",
    name: "trips",
    component: MyViewsTrips,
  },
  {
    path: "/client/trips/booking",
    name: "trips",
    component: RequestType,
  },
  {
    path: "/client/trips/add",
    name: "trips",
    component: ClientTripsCreate,
  },
  {
    path: "/client/trips/edit/:id",
    name: "trips",
    component: ClientTripsCreate,
  },
  {
    path: "/client/trips/detail/:id",
    name: "trips",
    component: ClientTripsDetail,
  },
  {
    path: "/client/trips/online-booking",
    name: "trips",
    component: OnlineBooking,
  },
  {
    path: "/client/trips/online-booking/properties",
    name: "trips",
    component: FilteredProperties,
  },
  {
    path: "/client/trips/online-booking/properties/:property_name",
    name: "trips",
    component: PropertyDetails,
  },
  {
    path: "/client/trips/online-booking/properties/booking",
    name: "trips",
    component: PropertyBooking,
  },
  {
    path: "/client/trips/online-booking/transport",
    name: "trips",
    component: FilteredTransport,
  },
  {
    path: "/client/trips/online-booking/transport/booking",
    name: "trips",
    component: CabBooking,
  },
  {
    path: "/client/trips/online-booking/properties/booking/thank-you",
    name: "trips",
    component: TripThankYou,
  },
  {
    path: "/client/trips/online-booking/transport/booking/thank-you",
    name: "trips",
    component: TripThankYou,
  },
];
