import MyViewsExpense from "@pages/Client/Expense";
import ClientExpenseCreate from "@pages/Client/Expense/Create";
import ClientExpenseDetail from "@pages/Client/Expense/Detail";

export const ClientExpenseRoutes = [
  //expenses
  {
    path: "/client/expenses",
    name: "expenses",
    component: MyViewsExpense,
  },
  {
    path: "/client/expenses/add",
    name: "expenses",
    component: ClientExpenseCreate,
  },
  {
    path: "/client/expenses/edit/:id",
    name: "expenses",
    component: ClientExpenseCreate,
  },
  {
    path: "/client/expenses/detail/:id",
    name: "expenses",
    component: ClientExpenseDetail,
  },
];
