import RemoveCircleSvgComponent from "@assets/svg-components/remove-circle";
import {
  handleSelectArrayChange,
  handleSelectArrayChangeStaticValue,
  handleTextArrayChange,
  handleTextArraySubmit,
} from "@common/handleChange";
import SingleSelectSearchDropdown from "@components/common/CustomSearchableDropdown/SingleSelectSearchDropdown";
import { getValue } from "@utils/lodash";
import React from "react";
import NotePopup from "./note-popup";
import EditSvgComponent from "@assets/svg-components/edit";
import PenSvgComponent from "@assets/svg-components/pen";
import { formatText } from "@common/text-helpers";

function Step3(props: any) {
  const { request, setRequest, approval_config_flow } = props;

  // AUTO_REJECT
  const approvalTypes = ["CONFIGURE_APPROVAL_FLOW", "AUTO_APPROVE"];

  const types = [
    { value: "SUBMITS_TO", label: "Submit To" },
    { value: "MANUAL_APPROVER", label: "Manual Approver" },
    { value: "PROJECT_HEAD", label: "Project Head" },
    // { value: "DEPARTMENT_HEAD", label: "Department Head" },
    {
      value: "DEPARTMENT_HEAD_LOGGED_IN_USER",
      label: "Department Head Logged in User",
    },
  ];

  const submitToTypes = [
    { value: "SINGLE_LEVEL", label: "Single Level" },
    { value: "TWO_LEVELS", label: "Two Levels" },
    { value: "THREE_LEVELS", label: "Three Levels" },
    { value: "FOUR_LEVELS", label: "Four Levels" },
    { value: "FIVE_LEVELS", label: "Five Levels" },
  ];

  /* -------------------------------------------------------------------------- */
  /*                             Add/Remove section                             */
  /* -------------------------------------------------------------------------- */
  const addCriteria = () => {
    if (getValue(request, `approval_config_flow.length`, 0) < 10) {
      let obj = {
        action: "ADD",
        approval_flow: "",
        approver: "",
        approval_level: "",
        note: "",
      };
      request.approval_config_flow.push(obj);
      setRequest({ ...request });
    }
  };

  const removeCriteria = (i: number) => {
    let length = getValue(request, `approval_config_flow.length`, 0);
    let filtered =
      getValue(request, `approval_config_flow.length`, 0) > 0
        ? getValue(request, `approval_config_flow`, []).filter(
            (_item: object, index: number) =>
              index !== (i == 0 ? (length > 1 ? length - 1 : i) : i)
          )
        : [];
    setRequest({
      ...request,
      approval_config_flow: filtered,
    });
  };

  const handleRemoveCondition = (index: number) => {
    let array = getValue(request, `approval_config_flow`, []);
    let filtered = array.filter((item: object, ind: number) => ind !== index);
    setRequest({
      ...request,
      approval_config_flow: filtered,
    });
  };

  return (
    <div className="custom-approval-step3">
      <div className="custom-approval-header-wrap">
        <h6 className="header_text__20_0_weigth">
        Approvals
        </h6>
        <p className="small_text">
        You can either configure approver levels or allow the system to auto
          approve / reject the reports
        </p>
      </div>
      <div className="">
        <div className="d-flex align-items-center mb-16">
          {approvalTypes.map((item: string) => {
            return (
              <div
                className="d-flex cursor-pointer custom-approval-step3-input-radio-wrap"
                onClick={() =>
                  setRequest({
                    ...request,
                    approval_type: item,
                  })
                }
              >
                <input
                  type="radio"
                  className="approval__container__radio cursor-pointer"
                  checked={
                    getValue(request, `approval_type`, "") === item
                      ? true
                      : false
                  }
                />
                <p className=" ">{formatText(item)}</p>
              </div>
            );
          })}
        </div>

        {getValue(request, `approval_type`, "") === "AUTO_APPROVE" ? (
          <></>
        ) : (
          <>
            <div className="mt-4 mb-3">
              {getValue(request, `approval_config_flow.length`, 0) > 0
                ? getValue(request, `approval_config_flow`, []).map(
                    (item: object, index: number) => {
                      if (getValue(item, `action`, "") !== "REMOVE")
                        return (
                          <div className="d-flex align-items-center mb-16  custom-approval-approvals-wrrapper ">
                            <h6 className="header_text">{index + 1}</h6>
                            <div
                              className="d-flex align-items-center ms-3"
                              style={{ flexWrap: "wrap" }}
                            >
                              <div className="mb-1">
                                <SingleSelectSearchDropdown
                                  data={types}
                                  label="label"
                                  width="300px"
                                  placeholder={"Select"}
                                  value={getValue(item, `approval_flow`, "")}
                                  selectKey={"value"}
                                  onChange={(e: object) =>
                                    handleSelectArrayChange(
                                      index,
                                      e,
                                      "approval_flow",
                                      "approval_config_flow",
                                      setRequest
                                    )
                                  }
                                />
                              </div>
                              {getValue(item, `approval_flow`, "") ===
                                "SUBMITS_TO" && (
                                <>
                                  <div className="ms-3"></div>
                                  <div className="mb-1">
                                    <SingleSelectSearchDropdown
                                      data={submitToTypes}
                                      label="label"
                                      width="300px"
                                      placeholder={"Select"}
                                      value={getValue(
                                        item,
                                        `approval_level`,
                                        ""
                                      )}
                                      selectKey={"value"}
                                      onChange={(e: object) => {
                                        handleSelectArrayChange(
                                          index,
                                          e,
                                          "approval_level",
                                          "approval_config_flow",
                                          setRequest
                                        );
                                        handleTextArraySubmit(
                                          props.index,
                                          "approver",
                                          "",
                                          "approval_config_flow",
                                          props.setRequest
                                        );
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                              {getValue(item, `approval_flow`, "") ===
                                "MANUAL_APPROVER" && (
                                <>
                                  <div className="ms-3"></div>
                                  <div className="mb-1">
                                    <SingleSelectSearchDropdown
                                      label="label"
                                      width="300px"
                                      placeholder={"Select"}
                                      value={getValue(item, `approver`, "")}
                                      lookup_id={getValue(item, `approver`, "")}
                                      selectKey={"id"}
                                      onChange={(e: object) => {
                                        handleSelectArrayChange(
                                          index,
                                          e,
                                          "approver",
                                          "approval_config_flow",
                                          setRequest
                                        );
                                        handleTextArraySubmit(
                                          props.index,
                                          "approval_level",
                                          "",
                                          "approval_config_flow",
                                          props.setRequest
                                        );
                                      }}
                                      lookup_api={"users_id"}
                                    />
                                  </div>
                                </>
                              )}
                              {/* {getValue(item, `approval_flow`, "") ===
                                "DEPARTMENT_HEAD" && (
                                <>
                                  <div className="ms-3"></div>
                                  <div className="mb-1">
                                    <SingleSelectSearchDropdown
                                      label="label"
                                      width="300px"
                                      placeholder={"Select"}
                                      value={getValue(item, `approver`, "")}
                                      selectKey={"id"}
                                      lookup_id={getValue(item, `approver`, "")}
                                      onChange={(e: object) => {
                                        handleSelectArrayChange(
                                          index,
                                          e,
                                          "approver",
                                          "approval_config_flow",
                                          setRequest
                                        );
                                        handleTextArraySubmit(
                                          props.index,
                                          "approval_level",
                                          "",
                                          "approval_config_flow",
                                          props.setRequest
                                        );
                                      }}
                                      lookup_api={"department_head"}
                                    />
                                  </div>
                                </>
                              )} */}
                              <div className="ms-3">
                                <p className="desc_text">
                                  Note to the Approver
                                </p>
                                {getValue(item, `note`, "") ? (
                                  <div className="d-flex align-items-center">
                                    <p className="desc_text">
                                      {getValue(item, `note`, "")}
                                    </p>
                                    <div className="mt-2 ms-3 cursor-pointer">
                                      <NotePopup
                                        index={index}
                                        setRequest={setRequest}
                                        value={getValue(item, `note`, "")}
                                        header={
                                          <div className="d-flex align-items-center cursor-pointer">
                                            <PenSvgComponent
                                              color={"#5f6368"}
                                              size={18}
                                            />
                                          </div>
                                        }
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    <NotePopup
                                      index={index}
                                      setRequest={setRequest}
                                      value={getValue(item, `note`, "")}
                                      header={
                                        <div className="d-flex align-items-center cursor-pointer">
                                          <img
                                            className="custom_field_button_container_image mt-2"
                                            src={`/images/add_circle.svg`}
                                            height={"18px"}
                                          />
                                          <p className="small_text__blue_14 ms-2 mt-2">
                                            Add Note
                                          </p>
                                        </div>
                                      }
                                    />
                                    {/* <div className="d-flex align-items-center cursor-pointer">
                                <img
                                  className="custom_field_button_container_image mt-2"
                                  src={`/images/add_circle.svg`}
                                  height={"18px"}
                                />
                                <p className="small_text__blue_14 ms-2 mt-2">
                                  Add Note
                                </p>
                              </div> */}
                                  </>
                                )}
                              </div>
                              <div className="ms-3"></div>
                              <div
                                className="cursor-pointer"
                                // onClick={() => removeCriteria(index)}
                                onClick={() =>
                                  getValue(item, `id`, "")
                                    ? handleSelectArrayChangeStaticValue(
                                        index,
                                        "approval_config_flow",
                                        "action",
                                        "REMOVE",
                                        setRequest
                                      )
                                    : handleRemoveCondition(index)
                                }
                              >
                                <RemoveCircleSvgComponent color={"#fe7070"} />
                              </div>
                            </div>
                          </div>
                        );
                    }
                  )
                : ""}
            </div>
            {getValue(request, `approval_config_flow.length`, 0) < 10 && (
              <div className=" d-flex justify-content-between align-items-center">
                <div
                  className="custom_field_button_container me-2 mt-3"
                  onClick={() => addCriteria()}
                >
                  <img
                    className="custom_field_button_container_image"
                    src={`/images/add_blue.svg`}
                  />
                  <h6 className="custom_field_button_container_text">
                    Add More
                  </h6>
                </div>
              </div>
            )}
            {/* <div className="border-bottom mt-3 mb-4"></div> */}
          </>
        )}
      </div>
    </div>
  );
}

export default Step3;
