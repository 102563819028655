import BackSvgComponent from "@assets/svg-components/back";
import { getValue } from "@utils/lodash";
import React from "react";
import { NavLink } from "react-router-dom";

function StickyHeader(props: any) {
  const { isChanged, handleSubmit, submitLoading, id, route } = props;
  return (
    <div className="common-sticky-header">
      <section className="inner-page-navigation-with-back-cta-buttons position-relative">
        <div
          className="container-fluid  d-flex justify-content-between align-items-center"
          style={{ height: "50px" }}
        >
          <div className="d-flex align-items-center">
            <NavLink to={`${route}`}>
              <BackSvgComponent />
            </NavLink>
            <h6 className="header_text">{getValue(props, `title`, "")}</h6>
          </div>
          {getValue(props, `permissions`, []).includes("update") &&
            !props.hideSubmit && (
              <div className="d-flex align-items-center gap-2">
                <NavLink to={`${route}`}>
                  <button className="deeptravel-button deeptravel-button--header-buttons deeptravel-button--secondary">
                    Cancel
                  </button>
                </NavLink>

                <button
                  className={`deeptravel-button deeptravel-button--header-buttons deeptravel-button--primary ${
                    !isChanged ? "secondary" : "primary"
                  }`}
                  onClick={handleSubmit}
                  disabled={isChanged || submitLoading}
                >
                  {submitLoading ? "Please wait..." : id ? "Update" : "Save"}
                </button>
              </div>
            )}
        </div>
      </section>
    </div>
  );
}

export default StickyHeader;
