import ReportsSvgComponent from "@assets/svg-components/sidebar/reports";
import { getColorStyle, getSingleColor } from "@common/color";
import {
  convertCurrentDate,
  convertCurrentDateWithTime,
} from "@common/date-helpers";
import {
  concatString,
  formatAmount,
  formatText,
  getFirstLetterOfEachString,
} from "@common/text-helpers";
import ProfileCircle from "@components/ProfileCircle";
import { getValue } from "@utils/lodash";
import React from "react";

function ReportsTable(props: any) {
  return (
    <div>
      <div>
        <table className="datatable_table">
          <thead className="datatable_table_thead">
            <th className="datatable_table_th" style={{ width: "70px" }}>
              {/* <label
                className="m-0 position-relative"
                htmlFor="header-checkbox"
              >
                <input
                  type="checkbox"
                  className="default-checkbox"
                  id="header-checkbox"
                  onChange={() =>
                    props.handleCheckAll(
                      getValue(props, `list.length`, 0) ===
                        getValue(props, `selectedIDs.length`, 0)
                        ? "All"
                        : "None"
                    )
                  }
                  checked={
                    getValue(props, `list.length`, 0) > 0 &&
                    getValue(props, `list.length`, 0) ===
                      getValue(props, `selectedIDs.length`, 0)
                  }
                />
                <div className="custom-checkbox">
                  <img
                    src="/images/uploaded-details/tick.svg"
                    className="img-fluid uploaded-tick"
                  />
                </div>
              </label> */}
            </th>
            <th className="datatable_table_th">
              <h6 className="">Report ID</h6>
            </th>
            <th className="datatable_table_th">
              <h6 className="">Employee ID</h6>
            </th>
            <th className="datatable_table_th">
              <h6 className="">Report Name</h6>
            </th>
            <th className="datatable_table_th">
              <h6 className="">Duration From</h6>
            </th>
            <th className="datatable_table_th">
              <h6 className="">Duration To</h6>
            </th>
            <th className="datatable_table_th">
              <h6 className="">Submitted by</h6>
            </th>
            <th className="datatable_table_th">
              <h6 className="">Total</h6>
            </th>
            <th className="datatable_table_th">
              <h6 className="">Approval Status</h6>
            </th>
          </thead>
          <tbody className="datatable_table_tbody">
            {getValue(props, `list`, []).map((item: any, index: number) => {
              return (
                <tr key={index} className="datatable_table_tr">
                  <td className="datatable_table_td" style={{ width: "70px" }}>
                    {/* <label
                      className="m-0 position-relative"
                      htmlFor={`header-checkbox-${index}`}
                    >
                      <input
                        type="checkbox"
                        className="default-checkbox"
                        id={`header-checkbox-${index}`}
                        onChange={() =>
                          props.handleCheckUsers(getValue(item, `id`, ""))
                        }
                        checked={getValue(props, `selectedIDs`, []).includes(
                          getValue(item, `id`, "")
                        )}
                      />
                      <div className="custom-checkbox">
                        <img
                          src="/images/uploaded-details/tick.svg"
                          className="img-fluid uploaded-tick"
                        />
                      </div>
                    </label> */}
                    <ReportsSvgComponent
                      color={getSingleColor(
                        getValue(item, `approval_status`, "")
                      )}
                      size={24}
                    />
                  </td>
                  <td
                    className="datatable_table_td align-items-center"
                    onClick={() =>
                      props.handleNavigate(getValue(item, `id`, ""))
                    }
                  >
                    <div className="d-flex">
                      <div>
                        <h6 className="header_text color_primary">
                          {getValue(item, `report_id`, "")}
                        </h6>
                      </div>
                    </div>
                  </td>
                  <td
                    onClick={() =>
                      props.handleNavigate(getValue(item, `id`, ""))
                    }
                    className={`datatable_table_td`}
                  >
                    <h6 className="">
                      {getValue(item, `created_by.employee_id`, "")}
                    </h6>
                  </td>
                  <td
                    className="datatable_table_td"
                    onClick={() =>
                      props.handleNavigate(getValue(item, `id`, ""))
                    }
                  >
                    <h6 className="">
                      {concatString(getValue(item, `report_name`, ""), 30)}
                    </h6>
                  </td>
                  <td
                    className="datatable_table_td"
                    onClick={() =>
                      props.handleNavigate(getValue(item, `id`, ""))
                    }
                  >
                    <h6 className="">
                      {convertCurrentDate(getValue(item, `duration_from`, ""))}
                    </h6>
                  </td>
                  <td
                    className="datatable_table_td"
                    onClick={() =>
                      props.handleNavigate(getValue(item, `id`, ""))
                    }
                  >
                    <h6 className="">
                      {convertCurrentDate(getValue(item, `duration_to`, ""))}
                    </h6>
                  </td>
                  <td
                    className="datatable_table_td"
                    onClick={() =>
                      props.handleNavigate(getValue(item, `id`, ""))
                    }
                  >
                    {getValue(item, `created_by.email`, "") ? (
                      <div className="d-flex align-items-center">
                        <ProfileCircle
                          name="created_by"
                          item={item}
                          value={getValue(item, `created_by.first_name`, "")}
                        />
                        <div>
                          <h6>
                            {" "}
                            {getValue(item, `created_by.first_name`, "")}{" "}
                            {getValue(item, `created_by.middle_name`, "")}{" "}
                            {getValue(item, `created_by.last_name`, "")}
                          </h6>
                          <p>{getValue(item, `created_by.email`, "")}</p>
                        </div>
                      </div>
                    ) : (
                      <p className="text-center ms-5">-</p>
                    )}
                  </td>
                  <td
                    className="datatable_table_td"
                    onClick={() =>
                      props.handleNavigate(getValue(item, `id`, ""))
                    }
                  >
                    <h6 className="">
                      {getValue(item, `currency_symbol`, "")}{" "}
                      {formatAmount(getValue(item, `total`, ""))}
                    </h6>
                  </td>
                  <td
                    className="datatable_table_td"
                    onClick={() =>
                      props.handleNavigate(getValue(item, `id`, ""))
                    }
                  >
                    <h6
                      className=""
                      style={getColorStyle(
                        getValue(item, `approval_status`, "")
                      )}
                    >
                      {formatText(getValue(item, `approval_status`, ""))}
                    </h6>
                  </td>
                  {/* <td className="datatable_table_td">
                  <div className="more-dropdown">
                    <UncontrolledDropdown>
                      <DropdownToggle>
                        <div className="switch_header__dropdown m-0">
                          <div className="dropdown-content d-flex cursor-pointer">
                            <img
                              src="/images/more.svg"
                              width="24px"
                              height="24px"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() =>
                            props.handleNavigate(getValue(item, `id`, ""))
                          }
                        >
                          <div className={`more-dropdown-item `}>
                            <p>Edit</p>
                          </div>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            props.sendInviteUser(getValue(item, `id`, ""))
                          }
                        >
                          <div className={`more-dropdown-item `}>
                            <p>View</p>
                          </div>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            props.handleDeleteFun(
                              getValue(item, `id`, ""),
                              getValue(item, `title`, "")
                            )
                          }
                        >
                          <div className={`more-dropdown-item `}>
                            <p>Delete</p>
                          </div>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ReportsTable;
