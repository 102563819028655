import { getValue } from "@utils/lodash";
import "./trip-tab-nav.scss";
import FlightSvgComponent from "@assets/svg-components/trips/flight";
import HotelSvgComponent from "@assets/svg-components/trips/hotel";
import CarSvgComponent from "@assets/svg-components/trips/car";
import TrainSvgComponent from "@assets/svg-components/trips/train";
import BusSvgComponent from "@assets/svg-components/trips/bus";
import TripsDetailSvgComponent from "@assets/svg-components/trips/trip-details";
import {
  Bed,
  BusFront,
  CarTaxiFront,
  ListCollapse,
  Plane,
  TrainFront,
} from "lucide-react";

export default function TripsVerticalTabNavigation(props: any) {
  const { travelModes } = props;
  return (
    <section className="w-25 inner-page-divider overflow-y-auto ">
      <ul
        className="inner-page-navigation-with-back-cta-buttons__navigation_left_temp d-inline-flex flex-column gap-3  cursor-pointer line-seperator w-100 "
        style={{ padding: "32px" }}
      >
        <li
          className={`inner-page-navigation-with-back-cta-buttons__nav-list-item_trip  position-relative ${
            getValue(props, `activeTab`, "") === "info" ? "active" : ""
          } 
              ${
                getValue(props, `checkMandatoryModules.info.length`, 0) > 0
                  ? "error-tabs"
                  : ""
              }`}
          onClick={() => props.setActiveTab("info")}
        >
          <div className="d-flex align-items-center gap-3">
            <ListCollapse
              color={
                getValue(props, `checkMandatoryModules.info.length`, 0) > 0
                  ? "red"
                  : "#5A2D5C "
              }
            />
            <p className="text-wrap">Trip Request Details</p>
          </div>
        </li>
        {/* <div className="vr-line"></div> */}
        {getValue(travelModes, `flight_reservation`, false) && (
          <li
            className={`inner-page-navigation-with-back-cta-buttons__nav-list-item_trip   ${
              getValue(props, `activeTab`, "") === "flight" ? "active" : ""
            } ${
              getValue(props, `checkMandatoryModules.flight.length`, 0) > 0
                ? "error-tabs"
                : ""
            }`}
            onClick={() => props.setActiveTab("flight")}
          >
            {getValue(props, `flightList.length`, 0) > 0 && (
              <div className="trip-count-section">
                {getValue(props, `flightList.length`, 0)}
              </div>
            )}
            <div className="d-flex align-items-center gap-3">
              <Plane
                color={
                  getValue(props, `checkMandatoryModules.flight.length`, 0) > 0
                    ? "red"
                    : "#5A2D5C "
                }
              />
              <p className="">Flight</p>
            </div>
          </li>
        )}
        {getValue(travelModes, `hotel_reservation`, false) && (
          <li
            className={`inner-page-navigation-with-back-cta-buttons__nav-list-item_trip ${
              getValue(props, `activeTab`, "") === "hotel" ? "active" : ""
            }
                ${
                  getValue(props, `checkMandatoryModules.hotel.length`, 0) > 0
                    ? "error-tabs"
                    : ""
                }`}
            onClick={() => props.setActiveTab("hotel")}
          >
            {getValue(props, `hotelList.length`, 0) > 0 && (
              <div className="trip-count-section">
                {getValue(props, `hotelList.length`, 0)}
              </div>
            )}
            <div className="d-flex align-items-center gap-3">
              <Bed
                color={
                  getValue(props, `checkMandatoryModules.hotel.length`, 0) > 0
                    ? "red"
                    : "#5A2D5C "
                }
              />
              <p className="">Hotel</p>
            </div>
          </li>
        )}

        {getValue(travelModes, `car_rental`, false) && (
          <li
            className={`inner-page-navigation-with-back-cta-buttons__nav-list-item_trip   ${
              getValue(props, `activeTab`, "") === "car" ? "active" : ""
            }
                ${
                  getValue(props, `checkMandatoryModules.car.length`, 0) > 0
                    ? "error-tabs"
                    : ""
                }`}
            onClick={() => props.setActiveTab("car")}
          >
            {getValue(props, `carList.length`, 0) > 0 && (
              <div className="trip-count-section">
                {getValue(props, `carList.length`, 0)}
              </div>
            )}
            <div className="d-flex align-items-center gap-3">
              <CarTaxiFront
                color={
                  getValue(props, `checkMandatoryModules.car.length`, 0) > 0
                    ? "red"
                    : "#5A2D5C "
                }
              />
              <p className="">Car Rental</p>
            </div>
          </li>
        )}
        {getValue(travelModes, `train`, false) && (
          <li
            className={`inner-page-navigation-with-back-cta-buttons__nav-list-item_trip  ${
              getValue(props, `activeTab`, "") === "train" ? "active" : ""
            }
                ${
                  getValue(props, `checkMandatoryModules.train.length`, 0) > 0
                    ? "error-tabs"
                    : ""
                }`}
            onClick={() => props.setActiveTab("train")}
          >
            {getValue(props, `trainList.length`, 0) > 0 && (
              <div className="trip-count-section">
                {getValue(props, `trainList.length`, 0)}
              </div>
            )}
            <div className="d-flex align-items-center gap-3">
              <TrainFront
                color={
                  getValue(props, `checkMandatoryModules.train.length`, 0) > 0
                    ? "red"
                    : "#5A2D5C "
                }
              />
              <p className="">Train</p>
            </div>
          </li>
        )}

        {getValue(travelModes, `bus`, false) && (
          <li
            className={`inner-page-navigation-with-back-cta-buttons__nav-list-item_trip ${
              getValue(props, `activeTab`, "") === "bus" ? "active" : ""
            }
                ${
                  getValue(props, `checkMandatoryModules.bus.length`, 0) > 0
                    ? "error-tabs"
                    : ""
                }`}
            onClick={() => props.setActiveTab("bus")}
          >
            {getValue(props, `busList.length`, 0) > 0 && (
              <div className="trip-count-section">
                {getValue(props, `busList.length`, 0)}
              </div>
            )}
            <div className="d-flex align-items-center gap-3">
              <BusFront
                color={
                  getValue(props, `checkMandatoryModules.bus.length`, 0) > 0
                    ? "red"
                    : "#5A2D5C "
                }
              />
              <p className="">Bus</p>
            </div>
          </li>
        )}
      </ul>

      {/* <button
            className={`deeptravel-button deeptravel-button--header-buttons deeptravel-button-secondary`}
          ></button> */}
    </section>
  );
}
