import { getValue } from "@utils/lodash";
import React from "react";

function HotelSolidSvgComponent(props: any) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="hotel_black_24dp-2"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
        viewBox="0 0 24 24"
      >
        <path
          id="Path_46320"
          data-name="Path 46320"
          d="M0,0H24V24H0Z"
          fill="none"
        />
        <path
          id="Path_46321"
          data-name="Path 46321"
          d="M7,13a3,3,0,1,0-3-3A3,3,0,0,0,7,13ZM19,7H11v7H3V5H1V20H3V17H21v3h2V11A4,4,0,0,0,19,7Z"
          fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "#fd9933"
          }
        />
      </svg>
    </div>
  );
}

export default HotelSolidSvgComponent;
