import { getValue } from "@utils/lodash";
import "./index.scss";
import { handleDownloadOnClick } from "@common/downloadfile";
import DragAndDropFileComponent from "@common/FileUpload/file-drag-drop";
import { formatSmallString } from "@common/text-helpers";
import CloseSmallSvgComponent from "@assets/svg-components/close-small";

export default function CommonImportData(props: any) {
  const fileTypes = ["PDF", "JPEG", "XLSX", "CSV", "XLSM", "XLX", "JPG", "PNG"];
  return (
    <div className="popup-wrapper__details">
      <div className="popup-wrapper__download">
        <button
          className="popup-wrapper__download-btn"
          onClick={
            () =>
              handleDownloadOnClick(
                getValue(props, `url`, "")
                  ? getValue(props, `url`, "")
                  : "",
                "user"
              )
            // props.handleDownload(getValue(props, `type`, ""))
          }
        >
          Download Template
        </button>
      </div>
      <div className="popup-wrapper__import">
        <h6 className="popup-wrapper__import-title">Import Data</h6>
        <p className="popup-wrapper__import-text">
          <span className="d-block">Instructions </span>
          Use the template provided and import data using excel. Ensure no
          duplicates, incorrect format is added. Please wait after you have
          uploaded.
        </p>
      </div>
      <div className="form-group">
        <DragAndDropFileComponent
          uploadFile={props.handleChangeUpload}
          name="file"
          types={fileTypes}
          multiple
          // className="d-none"
          classes="bulk-upload_drag-n-drop-wrapper position-absolute"
          fileName={getValue(props, `importedFile.name`, "")}
          setImportTedFile={props.setImportTedFile}
          file={"false"}
        />
        {getValue(props, `importedFile.name`, "") && (
          <div className="attach-files-wrapper__files cursor-pointer d-flex justify-content-between mt-2 p-1 bg-body align-items-center px-3">
            <p
              style={{
                fontSize: "12px",
                marginTop: "0px",
                textAlign: "center",
              }}
            >
              {formatSmallString(getValue(props, `importedFile.name`, ""))}
            </p>
            <div onClick={() => props.setImportTedFile("")}>
              <CloseSmallSvgComponent />
            </div>
          </div>
        )}
      </div>
      <div className="popup-wrapper__cta-btns">
        <button
          className="popup-wrapper__cta-button popup-wrapper__cta-button--secondary mx-3"
          onClick={() => {
            props.closePopup();
          }}
        >
          Cancel
        </button>

        <button
          className="popup-wrapper__cta-button popup-wrapper__cta-button--primary"
          onClick={() => {
            // props.importedSuccessfully();
            props.handleUpload();
          }}
          disabled={getValue(props, `submitLoading`, false)}
        >
          {getValue(props, `submitLoading`, false)
            ? "Please wait..."
            : "Import"}
        </button>
      </div>
    </div>
  );
}
