import { getValue } from "@utils/lodash";
import React, { ChangeEvent } from "react";
import "./form.scss";
type Props = {
  disabled: boolean;
  name: string;
  type: string;
  value: any;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  maxLength: number;
  placeholder: string;
  validator: any;
  label: string;
  mandatory: boolean;
  hideLabel: boolean;
  validatoryType: string;
  checked: boolean;
};

export default function Checkbox({
  disabled,
  name,
  type,
  value,
  onChange,
  maxLength,
  placeholder,
  validator,
  label,
  mandatory,
  hideLabel,
  validatoryType,
  checked,
}: Props) {

  return (
    <div
      // className={`${"signup-form-wrapper__form-group"} ${
      //   validator && validator.current.message(label, value, "required")
      //     ? "signup-form-wrapper__form-group--error"
      //     : ""
      // } form-group mb-3`}
    >
      {!hideLabel && (
        <label className={mandatory ? "form-label-error" : "form-label"}>
          {label} {mandatory && "*"}
        </label>
      )}

      <input
        className={`form-control-radio`}
        disabled={false || disabled}
        name={name}
        type={type}
        value={value}
        checked={checked}
        onChange={(e) => onChange(e)}
        placeholder={placeholder}
      />
      {validator && (
        <p className={`${"error-text"}`}>
          {validator.current.message(
            label,
            value,
            validatoryType ? validatoryType : "required"
          )}
        </p>
      )}
    </div>
  );
}
