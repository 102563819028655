import React from "react";
import { getValue } from "@utils/lodash";

function DocumentSvgComponent(props: any) {
  return (
    <div>
      <svg
        id="receipt_black_24dp"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        // width="48"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
      >
        <path
          id="Path_45639"
          data-name="Path 45639"
          d="M0,0H24V24H0Z"
          fill="none"
        />
        <path
          id="Path_45640"
          data-name="Path 45640"
          d="M19.5,3.5,18,2,16.5,3.5,15,2,13.5,3.5,12,2,10.5,3.5,9,2,7.5,3.5,6,2,4.5,3.5,3,2V22l1.5-1.5L6,22l1.5-1.5L9,22l1.5-1.5L12,22l1.5-1.5L15,22l1.5-1.5L18,22l1.5-1.5L21,22V2ZM19,19.09H5V4.91H19ZM6,15H18v2H6Zm0-4H18v2H6ZM6,7H18V9H6Z"
          fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "lightgray"
          }
        />
      </svg>
    </div>
  );
}

export default DocumentSvgComponent;
