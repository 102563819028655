import { getValue } from "@utils/lodash";
import React from "react";

function LineArrowDownSvgComponent(props: any) {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" 
      width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
      height={
        getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
      }
      >
        <path d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z" fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "#3c4043"
          } />
      </svg>
    </>
  );
}

export default LineArrowDownSvgComponent;
