import React from 'react'
import './index.scss';
export default function NotfoundSmall() {
  return (
    <>
      <div className="not-found-wrapper-small d-flex w-100">
        <div className="not-found-wrapper__details-small">
          <img
            src="/images/notfound/no-results-found.svg"
            className="not-found-image-small img-fluid"
          />
          <p className="no-data-found">No Data Found</p>
        </div>
      </div>
    </>
  );
}
