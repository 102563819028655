import React, { useEffect, useState } from "react";
import HeaderLayout from "@layouts/withoutNaviagtion/withoutNavigationLayout";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { approveExpenseReport } from "@services/expense-reports.service";
import { getValue } from "@utils/lodash";
import { approveExpenseReportLogin, approveTriptLogin } from "@services/common.service";
import { toast } from "react-toastify";
import GearLoader from "@components/common/GearLoader";
import { useNavigate } from "react-router-dom";

function TripApprove(props: any) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  const [message,setMessage] = useState<any>('')

  const getData = async () => {
    try {
      let resp = await approveTriptLogin(
        getValue(params, `id`, ""),
        getValue(urlParams, `token`, "")
      );
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        setMessage(getValue(resp, `message`, ""))

        // navigate("/login");
      }
    } catch (error) {
      setMessage(error)
    }
  };
  return (
    <HeaderLayout>
      {/* <div className=" d-flex align-items-center justify-content-center mt-5">
        <h6 className="header_text__18">Trip Approved Successfully</h6>
      </div> */}

        <div className="d-flex h-100 w-100 align-items-center justify-content-center">
          <div className="not-found-wrapper d-flex w-100 align-items-center justify-content-center new-not-found-wrapper">
            <div className="not-found-wrapper__details text-center">
              <img
                src="/images/notfound/trips-not-found.svg"
                className="not-found-image img-fluid trips-image"
              />
              <p className="header_text__19 text-center not-found-text">
                {message}<br /> 
                <span></span>
              </p>
            </div>
          </div>
        </div>
    </HeaderLayout>
  );
}

export default TripApprove;
