// https://stackblitz.com/edit/lightgallery-react-dynamic-mode?file=index.tsx
import LightGallery from "lightgallery/react";
// import plugins if you need
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
// If you want you can use SCSS instead of css
import "lightgallery/scss/lightgallery.scss";
import "lightgallery/scss/lg-zoom.scss";

function LightGalleryPreview(props) {
    const { imageList, onInit } = props;
    return (
        <div>
            <LightGallery
                elementClassNames="custom-classname"
                dynamic
                dynamicEl={imageList}
                onInit={onInit}
                plugins={[lgZoom]}
            ></LightGallery>
        </div>
    );
}

export default LightGalleryPreview;