import ArrowDownSvgComponent from "@assets/svg-components/arrow-down";
import CloseSmallSvgComponent from "@assets/svg-components/close-small";
import ClockSvgComponent from "@assets/svg-components/trips/clock";
import DateSvgComponent from "@assets/svg-components/trips/date";
import HotelSvgComponent from "@assets/svg-components/trips/hotel";
import {
  formHandleBooleanArrayChangeByKey,
  formHandleDateArrayChangeByKey,
  formHandleSelectArrayChangeByKey,
  formHandleSelectArrayChangeByKeyValue,
  formHandleTextArrayChangeByKey,
  formHandleTimeArrayChangeByKey,
} from "@common/handleChange";
import { getValue } from "@utils/lodash";
import React from "react";
import DatePicker from "react-datepicker";
import HotelCityPopup from "../../Dropdown/hotel-city-popup";
import InputField from "@common/InputRuleFeild";
import { convertElementaryArrayToJSONArray } from "@common/text-helpers";
import SingleSelectSearchDropdown from "@components/common/CustomSearchableDropdown/SingleSelectSearchDropdown";
import { todayDate } from "@common/date-helpers";
import LocationSvgComponent from "@assets/svg-components/trips/location";
import TripDateComponent from "../common/trip_date";
import TripTimeComponent from "../common/trip_time";
import TripCityComponent from "../common/trip_city";
import AddSvgComponent from "@assets/svg-components/add";
import { CirclePlus } from "lucide-react";

function TripCreateCarSection(props: any) {
  /* -------------------------------------------------------------------------- */
  /*                               Car CRUD Section                             */
  /* -------------------------------------------------------------------------- */
  const addSection = () => {
    let obj = {
      pick_up_city_name: "",
      pick_up_location: "",
      pick_up_date: "",
      pick_up_time: "",
      drop_off_location: "",
      drop_off_date: "",
      drop_off_time: "",
      drop_off_city_name: "",
      car_type: "",
      is_driver_needed: true,
    };
    props.itenaries.push(obj);
    props.setItenaries([...props.itenaries]);
  };
  const removeSection = (index: number) => {
    let filtered =
      getValue(props, `itenaries.length`, 0) > 0
        ? getValue(props, `itenaries`, []).filter(
            (item: object, i: number) => index !== i
          )
        : [];
    props.setItenaries(filtered);
    props.hideValidation();
  };
  const carTypes = convertElementaryArrayToJSONArray([
    "small",
    "medium",
    "large",
    "estate",
    "premium",
    "people_carrier",
    "suv",
  ]);
  /* -------------------------------------------------------------------------- */
  /*                            Date Picker Section                             */
  /* -------------------------------------------------------------------------- */

  return (
    <div className="trips-car-tab-main-container">
      {/* <div className="trips_header_bg d-flex align-items-center p-2 px-4 mt-4">
        <h6 className="header_text__18 ">Car Rentals</h6>
      </div> */}
      <div className="mt-4">
        {getValue(props, `itenaries.length`, 0) > 0 ? (
          getValue(props, `itenaries`, []).map(
            (item: object, index: number) => {
              return (
                <div className="multiple-dyno-wrap">
                  <div className="d-flex justify-content-sm-between align-items-center w-100">
                    <div className="w-100">
                      <div className="d-flex flex-wrap_wrap w-100">
                        <div className="trip_car_container mr-8">
                          <div className="d-flex">
                            <div className="mr-8 trips-car-datepicker">
                              <TripDateComponent
                                validator={props.validator}
                                item={item}
                                index={index}
                                date_key={"pick_up_date"}
                                setItenaries={props.setItenaries}
                                label={"Pick Up Date"}
                                null_date={"drop_off_date"}
                              />
                            </div>
                            <div className="">
                              <TripTimeComponent
                                label={"Pick Up time"}
                                validator={props.validator}
                                item={item}
                                index={index}
                                time_key={"pick_up_time"}
                                setItenaries={props.setItenaries}
                              />
                            </div>
                          </div>
                          <div className="tripcity-with-no-margin-right position-relative">
                            <TripCityComponent
                              item={item}
                              index={index}
                              validator={props.validator}
                              setItenaries={props.setItenaries}
                              label={"Pickup Location"}
                              city_key={"pick_up_location"}
                              city_name_key={"pick_up_city_name"}
                              width={"434px"}
                              locationIcon
                            />
                          </div>
                        </div>
                        <div className="trip_car_container mr-8">
                          <div className="d-flex">
                            <div className="trips-car-datepicker mr-8">
                              <TripDateComponent
                                validator={props.validator}
                                item={item}
                                index={index}
                                date_key={"drop_off_date"}
                                setItenaries={props.setItenaries}
                                label={"Drop Off Date"}
                                minDate={
                                  getValue(item, `pick_up_date`, "")
                                    ? new Date(
                                        getValue(item, `pick_up_date`, "")
                                      )
                                    : null
                                }
                              />
                            </div>
                            <div>
                              <TripTimeComponent
                                label={"Drop Off Time"}
                                validator={props.validator}
                                item={item}
                                index={index}
                                time_key={"drop_off_time"}
                                setItenaries={props.setItenaries}
                              />
                            </div>
                          </div>
                          <div className="tripcity-with-no-margin-right position-relative">
                            <TripCityComponent
                              item={item}
                              index={index}
                              validator={props.validator}
                              setItenaries={props.setItenaries}
                              label={"Drop Location"}
                              city_key={"drop_off_location"}
                              city_name_key={"drop_off_city_name"}
                              width={"434px"}
                              locationIcon
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center mb-16">
                        <div className="choose-car-type-wrapper">
                          <div className="d-flex">
                            <label className="trip-label ms-0">
                              {"Car Type"}
                            </label>
                            <span className="form-label-error_red">*</span>
                          </div>
                          <SingleSelectSearchDropdown
                            label={"label"}
                            selectKey={"value"}
                            name="car_type"
                            value={getValue(item, `car_type`, "")}
                            onChange={(e: any) =>
                              formHandleSelectArrayChangeByKey(
                                index,
                                e,
                                "car_type",
                                props.setItenaries
                              )
                            }
                            placeholder={"Choose Car Type"}
                            data={carTypes}
                            width={"284px"}
                          />
                        </div>

                        <div className="driver-required-wrap">
                          <div className="d-flex">
                            <label className="trip-label ms-2">&nbsp;</label>
                          </div>

                          <label
                            className="m-0 position-relative"
                            htmlFor={`header-checkbox-cardriver_${index}`}
                          >
                            <input
                              type="checkbox"
                              className="default-checkbox"
                              id={`header-checkbox-cardriver_${index}`}
                              onChange={() =>
                                formHandleBooleanArrayChangeByKey(
                                  index,
                                  "is_driver_needed",
                                  !getValue(item, `is_driver_needed`, false),
                                  props.setItenaries
                                )
                              }
                              checked={getValue(
                                item,
                                `is_driver_needed`,
                                false
                              )}
                            />
                            <div className="custom-checkbox">
                              <img
                                src="/images/uploaded-details/tick.svg"
                                className="img-fluid uploaded-tick"
                              />
                            </div>
                          </label>
                          <label
                            className="ms-3 small_text__16 cursor-pointer"
                            htmlFor={`header-checkbox-cardriver_${index}`}
                          >
                            Driver Required
                          </label>
                        </div>
                      </div>
                      <div className="flight_description_wrap mb-16">
                        <InputField
                          inputType="TEXTAREA"
                          placeholder="Add Description (Max 200 Characters)"
                          rows={2}
                          name="description"
                          value={getValue(item, `description`, "")}
                          onChange={(e: any) =>
                            formHandleTextArrayChangeByKey(
                              index,
                              e,
                              props.setItenaries
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className=" mt-2" onClick={() => removeSection(index)}>
                      <CloseSmallSvgComponent />
                    </div>
                  </div>
                </div>
              );
            }
          )
        ) : (
          <div className="mt-5 pt-5">
            <div className="d-flex justify-content-center mb-4">
              <img src="/images/notfound/trips-not-found.svg" />
            </div>
            <p className="header_text__19 text-center not-found-text">
              No Cars have been included.
              <br /> Kindly select the option below to add them.
            </p>
            <div
              className="d-flex justify-content-center align-items-center cursor-pointer  mt-4"
              onClick={addSection}
            >
              <div className="border border-radius__8 d-flex justify-content-center p-2 px-3">
                <AddSvgComponent size={28} color="#1966A1" />
                <h6 className="header_text__18 ms-2 color_primary">Add Cars</h6>
              </div>
            </div>
          </div>
        )}
      </div>

      {getValue(props, `itenaries.length`, 0) > 0 && (
        <div
          className="custom_field_button_container mt-3"
          onClick={addSection}
        >
          <CirclePlus color="#1966A1" size={18} />
          <h6 className="header_text__16 color_primary ms-2">
            {getValue(props, `itenaries.length`, 0) > 0 && "Add another car"}
          </h6>
        </div>
      )}
    </div>
  );
}

export default TripCreateCarSection;
