import ClientDashboard from "@pages/Client/Dashboard";
import ClientHome from "@pages/Client/Home";

export const ClientDashboardRoutes = [
  { path: "/", name: "dashboard", component: ClientHome },
  {
    path: "/client/dashboard",
    name: "dashboard",
    component: ClientDashboard,
  },
];
