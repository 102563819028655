import React from "react";
import "../../online/trip-online.scss";

const CabFilter = () => {
  return (
    <div className="property-filter-wrapper mt-5">
      <div className="d-flex justify-content-between online-booking-wrapper pb-2">
        <div className="d-flex gap-1 align-items-center cursor-pointer color_primary">
          <img src="/images/dashboard/trips/online/filter_list.png" />
          Filters
        </div>
        <div
          className="d-flex gap-1 align-items-center"
          style={{ color: "#DADCE0" }}
        >
          <img src="/images/dashboard/trips/online/refresh.png" />
          <img src="/images/dashboard/trips/online/Check.svg" />
          Apply
        </div>
      </div>
      <div className="online-booking-wrapper py-3">
        <div className="d-flex justify-content-between align-items-center header_text__16 cursor-pointer">
          Capacity
          <img src="/images/dashboard/trips/online/expand_more.png" />
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width  cursor-pointer"
          />
          2 - 5 Passengers (345)
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width cursor-pointer"
          />
          6 or More Passengers
        </div>
      </div>
      <div className="online-booking-wrapper py-3">
        <div className="d-flex justify-content-between align-items-center header_text__16 cursor-pointer">
          Deals
          <img src="/images/dashboard/trips/online/expand_more.png" />
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box.png"
            className="checkbox-width cursor-pointer"
          />
          Just deals
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width cursor-pointer"
          />
          Free cancellation
        </div>
      </div>
      <div className="online-booking-wrapper py-3">
        <div className="d-flex justify-content-between align-items-center header_text__16 cursor-pointer">
          Your budget per night (in Rs.)
          <img src="/images/dashboard/trips/online/expand_more.png" />
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width cursor-pointer"
          />
          Less than 10,000
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width cursor-pointer"
          />
          10,000 to 20,000
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box.png"
            className="checkbox-width cursor-pointer"
          />
          40,000 to 60,000
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width cursor-pointer"
          />
          60,000 to 80,000
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width cursor-pointer"
          />
          Above 80,000
        </div>
      </div>
      <div className="online-booking-wrapper py-3">
        <div className="d-flex justify-content-between align-items-center header_text__16 cursor-pointer">
          Car Type
          <img src="/images/dashboard/trips/online/expand_more.png" />
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width cursor-pointer"
          />
          Compact (30)
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width cursor-pointer"
          />
          Midsize (40)
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box.png"
            className="checkbox-width cursor-pointer"
          />
          SUV (40)
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width cursor-pointer"
          />
          Sedan (12)
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width cursor-pointer"
          />
          Premium (30)
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width cursor-pointer"
          />
          Luxury (40)
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width cursor-pointer"
          />
          Free Parking
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box.png"
            className="checkbox-width cursor-pointer"
          />
          Minivan (40)
        </div>
      </div>
    </div>
  );
};

export default CabFilter;
