import { getValue } from "@utils/lodash";
import { convertDate, convertDateTime } from "@common/date-helpers";
import { removeEmptyPropertiesWithZero } from "@common/text-helpers";

export const FormRequestHelper = (fields: any) => {
  let obj: any = {
    custom_fields: [],
  };
  let length = getValue(fields, `length`, 0);
  for (let i = 0; i < length; i++) {
    if (!getValue(fields, `[${i}].is_custom_field`, "")) {
      if (getValue(fields, `[${i}].field_name`, "") === "currency") {
        obj["currency_id"] = findTypeValidation(getValue(fields, `[${i}]`, {}));
      } else {
        obj[getValue(fields, `[${i}].field_name`, "")] = findTypeValidation(
          getValue(fields, `[${i}]`, {})
        );
      }
    } else {
      if (findTypeValidation(getValue(fields, `[${i}]`, "")))
        obj["custom_fields"].push({
          field_name: getValue(fields, `[${i}].field_name`, ""),
          field_value: findTypeValidation(getValue(fields, `[${i}]`, {})),
        });
    }
  }
  // Object.keys(obj).forEach((key) => {
  //   if (!obj[key] || obj[key] === undefined) {
  //     delete obj[key];
  //   }
  // });
  // removeNullOrUndefinedProperties(obj)
  return removeEmptyPropertiesWithZero(obj);
};

const findTypeValidation = (val: object) => {
  if (getValue(val, `sub_data_type`, "") === "date_time") {
    switch (
      `${getValue(val, `data_type`, "")}` +
      "-" +
      `${getValue(val, `sub_data_type`, "")}`
    ) {
      case "date-datetime":
        return getValue(val, `field_value`, "")
          ? convertDateTime(getValue(val, `field_value`, ""))
          : null;

      default:
        return getValue(val, `field_value`, "");
    }
  } else {
    switch (getValue(val, `data_type`, "")) {
      case "text":
        return getValue(val, `field_value`, "");
      case "numerical":
        return getValue(val, `field_value`, "")
          ? parseFloat(getValue(val, `field_value`, 0))
          : null;
      case "date":
        return getValue(val, `field_value`, "")
          ? convertDate(getValue(val, `field_value`, ""))
          : null;
      case "datetime":
        return getValue(val, `field_value`, "")
          ? convertDateTime(getValue(val, `field_value`, ""))
          : null;
      case "radio":
        return typeof getValue(val, `field_value`, "") === "string"
          ? getValue(val, `field_value`, "") == "true"
            ? true
            : false
          : getValue(val, `field_value`, "");
      default:
        return getValue(val, `field_value`, "");
    }
  }
};

export const checkBoolean = (value: string) => {
  switch (value) {
    case "true":
      return true;
    case "false":
      return false;
    default:
      return value;
  }
};

export const FormAppendDataRequestHelper = (field: any, info: any) => {
  let name = getValue(field, `field_name`, "");
  let type = getValue(field, `data_type`, "");
  let display_name = getValue(field, `display_name`, "");

  if (!getValue(field, `is_custom_field`, false)) {
    if (type === "picklist" || type === "lookup") {
      if (name === "currency_id") {
        field["field_value"] = getValue(info, `currency.id`, "");
      } else if (name === "travel_type") {
        field["field_value"] = getValue(info, `travel_type`, "");
      } else if (name === "gender") {
        field["field_value"] = getValue(info, `gender`, "");
      } else if (name === "roles") {
        field["field_value"] = getValue(info, `roles`, []).map((item: object) =>
          getValue(item, `id`, "")
        );
      } else {
        field["field_value"] = getValue(info, `[${name}].id`, "");
      }
    } else {
      field["field_value"] = getValue(info, `[${name}]`, "");
    }
  } else {
    // console.log(field)
    field["field_value"] =
      info["expense_entry_cf"] ||
      info["trip_cf"] ||
      info["user_cf"] ||
      info["expense_report_cf"]
        ? info["expense_entry_cf"]
          ? info["expense_entry_cf"][display_name]
          : info["trip_cf"]
          ? info["trip_cf"][display_name]
          : info["user_cf"]
          ? info["user_cf"][display_name]
          : info["expense_report_cf"][display_name]
        : "";
  }
  return field;
};

export const getLookUpIdByName = (item: object) => {
  switch (getValue(item, `field_name`, "")) {
    case "expense_type":
      return getValue(item, `field_value`, "");
    default:
      return null;
  }
};
