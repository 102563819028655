import { getColorStyle } from "@common/color";
import { convertCurrentDate } from "@common/date-helpers";
import { sortJSONObjectArray } from "@common/text-helpers";
import { formatText } from "@common/text-helpers";
import { getValue } from "@utils/lodash";

function CommonApproverFlowPopup(props: any) {
  const { showUpdatePopup, setShowUpdatePopup } = props;
  const getStepColors = (value: number) => {
    switch (value) {
      case 1:
        return "#FEF5E7";
      case 2:
        return "#FFEBD0";
      case 3:
        return "#FFDFB6";
      case 4:
        return "#FFD4A0";
      case 5:
        return "#FFC987";
      case 6:
        return "#FFBD71";
      case 7:
        return "#FFB25B";
      case 8:
        return "#FFA746";
      case 9:
        return "#FF9B30";
      case 10:
        return "#FF8F1A";

      default:
        return "#FF8F1A";
    }
  };
  return (
    <div
      className={`compelte-task-wrapper ${
        showUpdatePopup ? "compelte-task-wrapper--active" : ""
      }`}
      style={{ width: "30%" }}
    >
      <div className="compelte-task-wrapper__header d-flex align-items-center justify-content-between">
        <h6 className="compelte-task-wrapper__title">Approval Details</h6>
        <img
          src="/images/close-white.svg"
          className="img-fluid navigation-bar-wrapper__close-btn"
          onClick={() => {
            setShowUpdatePopup(false);
          }}
        />
      </div>
      <div className="approver_flow_main_container">
        {getValue(props, `approvers.length`, 0) > 0 &&
          sortJSONObjectArray(
            getValue(props, `approvers`, []),
            "approval_level"
          ).map((item: object) => {
            return (
              <div className="approver_flow_container m-3 p-3 position-relative ">
                <div className="d-flex ms-3 align-items-center">
                  <div
                    className="profile_circle"
                    style={{
                      background: getStepColors(
                        getValue(item, `approval_level`, "")
                      ),
                      border: "1px solid #FF8F1A",
                    }}
                  >
                    <h5 className="title" style={{ color: "#D35400" }}>
                      {getValue(item, `approval_level`, "")}
                    </h5>
                  </div>
                  <div className="ms-2">
                    <div>
                      <div>
                        <h6 className="header_text__17">
                          {getValue(item, `approver.first_name`, "")}{" "}
                          {getValue(item, `approver.middle_name`, "")}{" "}
                          {getValue(item, `approver.last_name`, "")}
                        </h6>
                        <p className="small_text">
                          {getValue(item, `approver.email`, "")}
                        </p>
                      </div>
                    </div>
                    {(getValue(item, `submitted_at`, "") ||
                      getValue(item, `approved_at`, "")) && (
                      <div className="border-radius__8 mt-3">
                        {getValue(item, `submitted_at`, "") && (
                          <div className="d-flex align-items-center">
                            <p className=" small_text__12">Submitted On:</p>
                            <p className=" header_text__12 ms-1">
                              {convertCurrentDate(
                                getValue(item, `submitted_at`, "")
                              )}
                            </p>
                          </div>
                        )}
                        {getValue(item, `approved_at`, "") && (
                          <div className="d-flex align-items-center mt-1">
                            <p className=" small_text__12">Approved On:</p>
                            <p className=" header_text__12 ms-1">
                              {convertCurrentDate(
                                getValue(item, `approved_at`, "")
                              )}
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <h6
                  className="header_text status-poistion-absolute"
                  style={getColorStyle(getValue(item, `approval_status`, ""))}
                >
                  {formatText(getValue(item, `approval_status`, ""))}
                </h6>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default CommonApproverFlowPopup;
