import BackSvgComponent from "@assets/svg-components/back";
import EyeSvgComponent from "@assets/svg-components/eye";
import InfoSvgComponent from "@assets/svg-components/info";
import { getColorStyle } from "@common/color";
import RejectCommentPopup from "@components/common/reject-popup";
import { getValue } from "@utils/lodash";
import { X } from "lucide-react";
import { NavLink } from "react-router-dom";
import { Tooltip } from "react-tooltip";

function StickyHeader(props: any) {
  const { handleSubmit, submitLoading, recallLoading, route } = props;
  const getStatus = (status: string) => {
    switch (status) {
      case "unsubmitted":
        return "Unsubmitted";
      case "awaiting_approval":
        return "Submitted";
      case "approved":
        return "Approved";
      case "rejected":
        return "Rejected";
      case "reimbursed":
        return "Reimbursed";
      case "expired":
        return "Expired";
      case "archived":
        return "Archived";
      case "recalled":
        return "Recalled";
      case "sent_back":
        return "Sent Back";
      default:
        return "";
    }
  };
  return (
    <div className="common-sticky-header">
      <section className="inner-page-navigation-with-back-cta-buttons position-relative">
        <div className="container-fluid  d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-between align-items-center">
            <NavLink to={route ? `${route}` : "#"}>
              <X className="me-3" size={20} />
            </NavLink>
            <p className="header_text"> {getValue(props, `title`, "")}</p>
            <div className="d-flex align-items-center">
              <h6
                className="ms-4"
                style={getColorStyle(
                  getValue(props, `formInfo.approval_status`, "") ==
                    "unsubmitted"
                    ? "draft"
                    : getValue(props, `formInfo.approval_status`, "")
                )}
              >
                {getStatus(getValue(props, `formInfo.approval_status`, ""))}
              </h6>
              {getValue(props, `formInfo.approval_status`, "") ===
                "rejected" && (
                <RejectCommentPopup
                  header={
                    <div className="ms-2 cursor-pointer">
                      <EyeSvgComponent color={"#EC6861"} />
                    </div>
                  }
                  comment={getValue(props, `rejectComment.comments`, "")}
                />
              )}

              {getValue(props, `formInfo.approval_status`, "") ===
                "sent_back" && (
                <RejectCommentPopup
                  header={
                    <div className="ms-2 cursor-pointer">
                      <EyeSvgComponent color={"#EC6861"} />
                    </div>
                  }
                  type="sent_back"
                  rejectComment={getValue(props, `sent_backComment`, {})}
                  comment={getValue(props, `sent_backComment.comments`, "")}
                />
              )}
            </div>
            {/* {getValue(props, `formInfo.trip`, {}) && (
              <div className="ms-4">
                <div className="report-with-trips" style={getColorStyle("active")} onClick={props.toggleTripDetail}>
                  {getValue(props, `formInfo.trip.trip_id`, "")}
                </div>
              </div>
            )} */}
          </div>

          <div className="d-flex align-items-center">
            {getValue(props, `histoyList.length`, 0) > 0 && (
              <div
                className="mx-2 border p-2 border-radius__8 d-flex align-items-center"
                onClick={props.toggleHistory}
              >
                <InfoSvgComponent color={"gray"} />
                <h6 className="header_text__16 cursor-pointer ms-1">History</h6>
              </div>
            )}
            {getValue(props, `permissions`, []).includes("update") && (
              <>
                {(getValue(props, `formInfo.approval_status`, "") ===
                  "recalled" ||
                  getValue(props, `formInfo.approval_status`, "") ===
                    "unsubmitted") && (
                  <div
                    className="header_square"
                    onClick={props.handleOpenPopup}
                  >
                    <img
                      src="/images/edit-gray.svg"
                      className="header_square_image img-fluid"
                    />
                  </div>
                )}
                {getValue(props, `formInfo.approval_status`, "") &&
                getValue(props, `formInfo.approval_status`, "") ===
                  "awaiting_approval" ? (
                  <button
                    className={`ms-2 deeptravel-button deeptravel-button--header-buttons deeptravel-button--orange`}
                    onClick={() => handleSubmit("recall")}
                    disabled={recallLoading}
                  >
                    {recallLoading ? "Please wait..." : "Recall"}
                  </button>
                ) : (
                  <div style={{ height: "48px" }}></div>
                )}
                {getValue(props, `formInfo.approval_status`, "") &&
                (getValue(props, `formInfo.approval_status`, "") ===
                  "unsubmitted" ||
                  getValue(props, `formInfo.approval_status`, "") ===
                    "recalled" ||
                  getValue(props, `formInfo.approval_status`, "") ===
                    "sent_back") ? (
                  <>
                    <button
                      className={`ms-2 deeptravel-button deeptravel-button--header-buttons deeptravel-button--primary`}
                      onClick={() => handleSubmit("submit")}
                      disabled={submitLoading}
                      data-tooltip-id={
                        getValue(props, `formInfo.is_policy_violated`, false)
                          ? "my-tooltip"
                          : undefined
                      }
                      data-tooltip-content={
                        getValue(props, `formInfo.is_policy_violated`, false)
                          ? "This expense violated certain policies"
                          : ""
                      }
                    >
                      {submitLoading ? "Please wait..." : "Submit for Approval"}
                    </button>
                    {getValue(props, `formInfo.is_policy_violated`, false) && (
                      <Tooltip id="my-tooltip" />
                    )}
                  </>
                ) : (
                  <div style={{ height: "48px" }}></div>
                )}
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default StickyHeader;
