import { getValue } from "@utils/lodash";
import React from "react";

function HotelSwapSvgComponent(props: any) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        // width="58"
        // height="58"
        viewBox="0 0 58 58"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "58"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "58"
        }
      >
        <defs>
          <filter
            id="Ellipse_3626"
            x="0"
            y="0"
            width="58"
            height="58"
            filterUnits="userSpaceOnUse"
          >
            <feOffset dy="3" />
            <feGaussianBlur stdDeviation="3" result="blur" />
            <feFlood flood-opacity="0.161" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
          </filter>
        </defs>
        <g
          id="Group_9797"
          data-name="Group 9797"
          transform="translate(-541.25 -944)"
        >
          <g
            transform="matrix(1, 0, 0, 1, 541.25, 944)"
            filter="url(#Ellipse_3626)"
          >
            <circle
              id="Ellipse_3626-2"
              data-name="Ellipse 3626"
              cx="20"
              cy="20"
              r="20"
              transform="translate(9 6)"
              fill="#fff"
            />
          </g>
          <g id="single_bed_black_24dp" transform="translate(558 958)">
            <g id="Group_9795" data-name="Group 9795">
              <rect
                id="Rectangle_17109"
                data-name="Rectangle 17109"
                width="24"
                height="24"
                fill="none"
              />
            </g>
            <g id="Group_9796" data-name="Group 9796">
              <path
                id="Path_45638"
                data-name="Path 45638"
                d="M20,12a2.006,2.006,0,0,0-2-2V7a2.006,2.006,0,0,0-2-2H8A2.006,2.006,0,0,0,6,7v3a2.006,2.006,0,0,0-2,2v5H5.33L6,19H7l.67-2h8.67L17,19h1l.67-2H20Zm-4-2H13V7h3ZM8,7h3v3H8ZM6,12H18v3H6Z"
                fill={
                  getValue(props, `color`, "")
                    ? getValue(props, `color`, "")
                    : "#5f6368"
                }
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

export default HotelSwapSvgComponent;
