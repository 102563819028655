import React, { useEffect, useState } from "react";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import "./index.scss";

function SkeletonTable(props: any)  {
  return (
    <>
        <div className="shimmer-effect-wrapper" style={{ padding: "0 24px" }}>
            <div className="w-100">
                <table className="datatable_table w-100">
                <thead className="datatable_table_thead"> 
                    <tr>
                    <th className="datatable_table_th" colSpan={5} >
                        <Skeleton style={{height: "30px"}} />
                    </th>
                    </tr>
                </thead>
                <tbody className="datatable_table_tbody">
                    <tr className="datatable_table_tr">
                    <td className="datatable_table_td"> <Skeleton /> </td>
                    <td className="datatable_table_td"> <Skeleton /> </td>
                    <td className="datatable_table_td"> <Skeleton /> </td>
                    <td className="datatable_table_td"> <Skeleton /> </td>
                    <td className="datatable_table_td"> <Skeleton /> </td>
                    </tr>

                    <tr className="datatable_table_tr">
                    <td className="datatable_table_td"> <Skeleton /> </td>
                    <td className="datatable_table_td"> <Skeleton /> </td>
                    <td className="datatable_table_td"> <Skeleton /> </td>
                    <td className="datatable_table_td"> <Skeleton /> </td>
                    <td className="datatable_table_td"> <Skeleton /> </td>
                    </tr>

                    <tr className="datatable_table_tr">
                    <td className="datatable_table_td"> <Skeleton /> </td>
                    <td className="datatable_table_td"> <Skeleton /> </td>
                    <td className="datatable_table_td"> <Skeleton /> </td>
                    <td className="datatable_table_td"> <Skeleton /> </td>
                    <td className="datatable_table_td"> <Skeleton /> </td>
                    </tr>

                    <tr className="datatable_table_tr">
                    <td className="datatable_table_td"> <Skeleton /> </td>
                    <td className="datatable_table_td"> <Skeleton /> </td>
                    <td className="datatable_table_td"> <Skeleton /> </td>
                    <td className="datatable_table_td"> <Skeleton /> </td>
                    <td className="datatable_table_td"> <Skeleton /> </td>
                    </tr>

                    <tr className="datatable_table_tr">
                    <td className="datatable_table_td"> <Skeleton /> </td>
                    <td className="datatable_table_td"> <Skeleton /> </td>
                    <td className="datatable_table_td"> <Skeleton /> </td>
                    <td className="datatable_table_td"> <Skeleton /> </td>
                    <td className="datatable_table_td"> <Skeleton /> </td>
                    </tr>

                    <tr className="datatable_table_tr">
                    <td className="datatable_table_td"> <Skeleton /> </td>
                    <td className="datatable_table_td"> <Skeleton /> </td>
                    <td className="datatable_table_td"> <Skeleton /> </td>
                    <td className="datatable_table_td"> <Skeleton /> </td>
                    <td className="datatable_table_td"> <Skeleton /> </td>
                    </tr>
                </tbody>
                </table>
            </div>
        </div>
    </>
  );
};

export default SkeletonTable;
