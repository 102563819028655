import AdminDashboard from "@pages/Admin/Dashboard";
import FileLogs from "@pages/common/FileLogs";
import ReportFileLogs from "@pages/common/ReportFileLogs";

export const AdminDashboardRoutes = [
  {
    path: "/admin/dashboard",
    name: "admin_dashboard",
    component: AdminDashboard,
  },
  { path: "/file-logs/:type", name: "admin_dashboard", component: FileLogs },
  { path: "/report-file-logs/:type", name: "admin_dashboard", component: ReportFileLogs },

];
