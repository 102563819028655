import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import {
  getAllApprovalConfigList,
  getApprovalConfigConditionFields,
} from "@services/settings.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getValue } from "@utils/lodash";
import { useParams } from "react-router-dom";
import { formatText, sortJSONObjectArray } from "@common/text-helpers";
import SimpleReactValidator from "simple-react-validator";
import { createApprovalConfig } from "@services/settings.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import BackSvgComponent from "@assets/svg-components/back";
import { getSpecificApprovalConfig } from "@services/settings.service";
import { removeEmptyProperties } from "@common/text-helpers";
import GearLoader from "@components/common/GearLoader";
import NoAccessPage from "@components/common/NoAccess";

function PrferenceApprovalDetailPage(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [request, setRequest] = useState({
    action: "ADD",
    approval_config_type: getValue(params, `name`, "").toUpperCase(),
    approval_name: "",
    description: "",
    priority_order: 0,
    criteria_pattern: "",
    approval_type: "CONFIGURE_APPROVAL_FLOW",
    approval_config_conditions: [],
    approval_config_flow: [],
  });

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (props.permissionAPITriggered) {
      if (getValue(params, `id`, "")) {
        getApprovalList();
        getConditionFields();
      } else {
        getApprovalList();
        getConditionFields();
      }
    }
  }, [props.permissionAPITriggered]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [condtionalFields, setConditionalFields] = useState([]);
  const getConditionFields = async () => {
    if (getValue(params, `name`, "")) {
      try {
        let payload = {
          approval_config_type: getValue(params, `name`, "").toUpperCase(),
        };
        let queryRequest = QueryRequestHelper(payload);
        let resp = await getApprovalConfigConditionFields(queryRequest);
        if (resp) {
          let fields =
            getValue(resp, `data.length`, 0) > 0
              ? getValue(resp, `data`, []).map((item: object) => ({
                  ...item,
                  value: getValue(item, `field_name`, ""),
                  label: formatText(getValue(item, `field_name`, "")),
                }))
              : [];
          setConditionalFields(fields);
          getData();
        }
      } catch (error) {}
    }
  };

  const [isLoading, setIsLoading] = useState(true);
  const getData = async () => {
    if (getValue(params, `id`, ""))
      try {
        setIsLoading(true);
        let resp = await getSpecificApprovalConfig(getValue(params, `id`, ""));
        if (resp) {
          let approval_config_conditions =
            getValue(resp, `data.approval_config_conditions.length`, 0) > 0
              ? sortJSONObjectArray(
                  getValue(resp, `data.approval_config_conditions`, []),
                  "order"
                ).map((item: any, index: number) => ({
                  ...item,
                  field_name: getValue(item, `field_name`, ""),
                  field_value: getValue(item, `field_value`, ""),
                  operation: getValue(item, `operation`, ""),
                  action: "UPDATE",
                  criteria_pattern: findOperations(
                    getValue(resp, `data.criteria_pattern`, "")
                  )[index],
                  // order: index + 1,
                }))
              : [];

          let approval_config_flow =
            getValue(resp, `data.approval_config_flow.length`, 0) > 0
              ? sortJSONObjectArray(
                  getValue(resp, `data.approval_config_flow`, []),
                  "order"
                ).map((item: any, index: number) => ({
                  ...item,
                  action: "UPDATE",
                  approver: getValue(item, `approver.id`, ""),

                  // order: index + 1,
                }))
              : [];
          setRequest({
            ...request,
            action: "UPDATE",
            approval_config_type: getValue(
              resp,
              `data.approval_config_type`,
              ""
            ),
            approval_name: getValue(resp, `data.approval_name`, ""),
            description: getValue(resp, `data.description`, ""),
            priority_order: getValue(resp, `data.priority_order`, ""),
            criteria_pattern: getValue(resp, `data.criteria_pattern`, ""),
            approval_type: getValue(resp, `data.approval_type`, ""),
            approval_config_conditions: approval_config_conditions,
            approval_config_flow: approval_config_flow,
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
  };

  const findOperations = (expression: any) => {
    // Regular expression to find all occurrences of 'AND' and 'OR'
    if (expression) {
      const operators = expression.match(/\bAND\b|\bOR\b/g);
      operators.unshift("WHEN");
      return operators;
    } else {
      return ["WHEN"];
    }
  };
  const [approvalLength, setApprovalLength] = useState(0);
  const getApprovalList = async () => {
    try {
      let payload = {
        page_size: "100",
        approval_config_type: getValue(params, `name`, "").toUpperCase(),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllApprovalConfigList(queryRequest);
      if (resp) {
        setApprovalLength(getValue(resp, `data.data.length`, 0));
      } else {
      }
    } catch (error) {}
  };
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    let req = JSON.parse(JSON.stringify(request));
    //------------------- Updating Request --------------//
    let approval_config_conditions =
      getValue(req, `approval_config_conditions.length`, 0) > 0
        ? getValue(req, `approval_config_conditions`, []).map(
            (item: any, index: number) => ({
              field_name: getValue(item, `field_name`, ""),
              field_value: getValue(item, `field_value`, ""),
              field_value_id: getValue(item, `field_value_id`, ""),
              operation: getValue(item, `operation`, ""),
              action: getValue(item, `action`, "")
                ? getValue(item, `action`, "")
                : "ADD",
              id:
                getValue(item, `action`, "") === "UPDATE" ||
                getValue(item, `action`, "") === "REMOVE"
                  ? getValue(item, `id`, "")
                  : "",
              order: getValue(item, `order`, "")
                ? getValue(item, `order`, "")
                : index + 1,
            })
          )
        : [];

    let approval_config_flow =
      getValue(req, `approval_config_flow.length`, 0) > 0
        ? getValue(req, `approval_config_flow`, []).map(
            (item: any, index: number) => ({
              ...item,
              action: getValue(item, `action`, "")
                ? getValue(item, `action`, "")
                : "ADD",
              order: getValue(item, `order`, "")
                ? getValue(item, `order`, "")
                : index + 1,
              id:
                getValue(item, `action`, "") === "UPDATE" ||
                getValue(item, `action`, "") === "REMOVE"
                  ? getValue(item, `id`, "")
                  : "",
            })
          )
        : [];
    req["id"] = getValue(params, `id`, "") ? getValue(params, `id`, "") : "";
    // req["approval_config_conditions"] = removeNullValuesFromObjectArray(
    //   approval_config_conditions
    // );
    req["approval_config_conditions"] = removeEmptyProperties(
      approval_config_conditions
    );
    req["approval_config_flow"] =
      getValue(req, `approval_type`, "") === "CONFIGURE_APPROVAL_FLOW"
        ? removeEmptyProperties(approval_config_flow)
        : [];
    req["criteria_pattern"] =
      getValue(req, `criteria_pattern`, "") &&
      getValue(req, `criteria_pattern`, "") === "1"
        ? ""
        : getValue(req, `criteria_pattern`, "");
    if (!getValue(params, `id`, "")) {
      req["priority_order"] = approvalLength + 1;
    }

    //------------------- Removing Null/empty values --------------//
    Object.keys(req).forEach((key) => {
      if (
        (!req[key] ||
          req[key] === undefined ||
          (Array.isArray(req[key]) && req[key].length === 0)) &&
        typeof req[key] !== "boolean"
      ) {
        delete req[key];
      }
    });
    try {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
      } else {
        setSubmitLoading(true);
        let payload = req;
        let resp = await createApprovalConfig(payload);
        if (resp) {
          toast.success(getValue(resp, `message`, ""));
          setSubmitLoading(false);
          simpleValidator.current.hideMessages();
          forceUpdate(0);
          navigate(
            `/admin/settings/approval/preferences/${getValue(
              params,
              `name`,
              ""
            )}`
          );
        } else {
          setSubmitLoading(false);
        }
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      <div
        className="dashboard-wrapper__header d-flex align-items-center justify-content-between"
        style={{ height: "54px" }}
      >
        <div className="d-flex align-items-center">
          <NavLink
            to={`/admin/settings/approval/preferences/${getValue(
              params,
              `name`,
              ""
            )}`}
            className="user-details-wrapper__header-back-link"
          >
            <BackSvgComponent />
          </NavLink>

          <h4 className="dashbaord-wrapper__header-title">
            {getValue(params, `id`, "") ? "Update" : "New"}{" "}
            {formatText(getValue(params, `name`, ""))} Custom Approval
          </h4>
        </div>

        <div className="mt-1">
          {getValue(props, `permissions`, []).includes("update") && (
            <button
              onClick={handleSubmit}
              disabled={submitLoading}
              className="deeptravel-button deeptravel-button--header-buttons deeptravel-button--secondary"
            >
              {submitLoading
                ? "Please wait..."
                : getValue(params, `id`, "")
                ? "Update"
                : "Submit"}
            </button>
          )}
        </div>
      </div>
      {!isLoading &&
      !getValue(props, `subTabPermissionList`, []).includes(
        "admin_settings_approval_flows"
      ) ? (
        <NoAccessPage />
      ) : (
        <>
          <div className="custom_approval__container">
            <Step1
              request={request}
              setRequest={setRequest}
              simpleValidator={simpleValidator}
            />
            <Step2
              request={request}
              setRequest={setRequest}
              condtionalFields={condtionalFields}
            />
            <Step3 request={request} setRequest={setRequest} />
          </div>
        </>
      )}
    </DashboardLayout>
  );
}

export default PrferenceApprovalDetailPage;
