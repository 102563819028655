import { getValue } from "@utils/lodash";
import React from "react";

function AmountSvgComponent(props: any) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"
        }
        fill={
          getValue(props, `color`, "")
            ? getValue(props, `color`, "")
            : "#3c4043"
        }
      >
        <path d="M419.5 57.4h-327c-50.2 0-91 40.8-91 91v215.2c0 50.2 40.8 91 91 91h327c50.2 0 91-40.8 91-91V148.4c0-50.2-40.8-91-91-91zm49 91v6.1c-26.7-7.4-47.8-28.4-55.1-55.1h6.1c27 0 49 22 49 49zm-376-49h6.1c-7.4 26.7-28.4 47.8-55.1 55.1v-6.1c0-27 22-49 49-49zm-49 264.2v-6.1c26.7 7.4 47.8 28.4 55.1 55.1h-6.1c-27 0-49-22-49-49zm98.2 49c-8.8-49.9-48.2-89.4-98.2-98.2V197.6c49.9-8.8 89.4-48.2 98.2-98.2h228.6c8.8 49.9 48.2 89.4 98.2 98.2v116.8c-49.9 8.8-89.4 48.2-98.2 98.2H141.7zm277.8 0h-6.1c7.4-26.7 28.4-47.8 55.1-55.1v6.1c0 27-22 49-49 49z"></path>
        <path d="M256 192.5c-35 0-63.5 28.5-63.5 63.5s28.5 63.5 63.5 63.5 63.5-28.5 63.5-63.5-28.5-63.5-63.5-63.5zm0 85c-11.8 0-21.5-9.6-21.5-21.5 0-11.8 9.6-21.5 21.5-21.5 11.8 0 21.5 9.6 21.5 21.5 0 11.8-9.7 21.5-21.5 21.5z"></path>
      </svg>
    </div>
  );
}

export default AmountSvgComponent;
