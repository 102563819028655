import InputField from "@common/InputRuleFeild";
import { QueryRequestHelper } from "@common/query-request-helper";
import { formatText } from "@common/text-helpers";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import PromptModal from "@prompt/modal";
import { getValue } from "@utils/lodash";
import React, { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import _ from "lodash";
import { useCallbackPrompt } from "@prompt/useCallbackPrompt";
import { NavLink } from "react-router-dom";
import {
  createCustomField,
  getCustomDatatypes,
  getSpecificCustomFieldwithMileage,
  getSpecificField,
  updateSpecificField,
} from "@services/modules.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import SingleSelectSearchDropdown from "@components/common/CustomSearchableDropdown/SingleSelectSearchDropdown";
import NavViews from "../components/NavViews";
import {
  attachExpenseType,
  getAllExpenseTypes,
  removeAttachedExpenseType,
} from "@services/expense-type.service";
import GearLoader from "@components/common/GearLoader";
import NoAccessPage from "@components/common/NoAccess";
function FieldDetails(props: any) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  const navigate = useNavigate();
  const params = useParams();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (Object.keys(urlParams).length === 0 && props.permissionAPITriggered) {
      if (getValue(params, `id`, "")) {
        getAllExpenseTypeList();
        getDataTypes();
      } else {
        getDataTypes();
      }
      getInfo(getValue(params, `id`, ""));
    }
  }, [props.permissionAPITriggered]);

  useEffect(() => {
    if (Object.keys(urlParams).length > 0 && props.permissionAPITriggered) {
      if (getValue(urlParams, `activeTab`, "") == 2) {
        getAllExpenseTypeList();
      }
    }
  }, [window.location.href, props.permissionAPITriggered]);
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [dataTypeList, setDataTypeList] = useState([]);
  const [request, setRequest] = useState({
    entity_type: "expense_entry",
    sub_data_type: "",
    display_name: "",
    field_value: "",
    is_mandatory: false,
    is_enabled: true,
  });
  const [dirtyFields, setDirtyFields] = useState({
    entity_type: "expense_entry",
    sub_data_type: "",
    display_name: "",
    field_value: "",
    is_mandatory: false,
    is_enabled: true,
  });

  /* -------------------------------------------------------------------------- */
  /*                                   API Section                              */
  /* -------------------------------------------------------------------------- */

  const getInfo = async (id: string) => {
    if (id)
      try {
        // let resp = await getSpecificField(id);
        let resp = await getSpecificCustomFieldwithMileage(id);
        if (resp) {
          let obj: any = {
            entity_type: "expense_entry",
            sub_data_type: getValue(resp, `data.sub_data_type`, ""),
            display_name: getValue(resp, `data.display_name`, ""),
            field_value: getValue(resp, `data.field_value`, ""),
            is_mandatory: getValue(resp, `data.is_mandatory`, ""),
            is_enabled: getValue(resp, `data.is_enabled`, ""),
          };
          // setRequest((prevRequest: any) => {
          //   return { ...prevRequest, ...obj };
          // });
          // setDirtyFields((prevRequest: any) => {
          //   return { ...prevRequest, ...obj };
          // });
          setRequest({
            ...request,
            ...obj,
          });
          setDirtyFields({
            ...dirtyFields,
            ...obj,
          });
          if (getValue(resp, `data.expense_types.length`, 0) > 0) {
            setExpense_type_ids(
              getValue(resp, `data.expense_types`, []).map((item: object) => ({
                ...item,
                value: getValue(item, `id`, ""),
                label: getValue(item, `expense_type_name`, ""),
                name: getValue(item, `expense_type_name`, ""),
              }))
            );
          } else {
            setExpense_type_ids([]);
          }
        }
      } catch (error) {}
  };
  const getDataTypes = async () => {
    let payload = {
      entity_type: "expense_entry",
    };
    let queryRequest = QueryRequestHelper(payload);
    try {
      let resp = await getCustomDatatypes(queryRequest);
      if (resp) {
        setDataTypeList(
          getValue(resp, `data`, []).map((item: string) => ({
            value: item,
            label: formatText(item),
          }))
        );
      }
    } catch (error) {}
  };

  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        let resp;
        if (getValue(params, `id`, "")) {
          resp = await updateSpecificField(getValue(params, `id`, ""), request);
        } else {
          resp = await createCustomField(request);
        }
        if (resp) {
          toast.success(getValue(resp, `message`, ""));
          setIsLoading(false);
          navigate(
            `/admin/modules/expenses/fields/detail/${
              getValue(params, `id`, "")
                ? getValue(params, `id`, "")
                : getValue(resp, `data.id`, "")
            }?activeTab=2`
          );
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                Prompt Section                              */
  /* -------------------------------------------------------------------------- */
  const [isChanged, setIsChanged] = useState(false);
  useEffect(() => {
    setIsChanged(
      _.isEqualWith(request, dirtyFields, (a: any, b: any) => {
        // if both are null or equal to an empty string then they are equal
        if ((_.isNull(a) || a === "") && (_.isNull(b) || b === "")) return true;
      })
    );
  });
  const [showPrompt, confirmNavigation, cancelNavigation]: any =
    useCallbackPrompt(!isChanged);
  /* -------------------------------------------------------------------------- */
  /*                                   Tab Section                              */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (getValue(urlParams, `activeTab`, 1)) {
      setActiveTab(parseInt(getValue(urlParams, `activeTab`, 1)));
    }
  }, [window.location.href]);
  const [views] = useState([
    {
      name: "Custom Field",
    },
    {
      name: "Expense Claim Form",
    },
  ]);
  const [activeTab, setActiveTab] = useState(0);
  const handleChangeActiveTab = (tab: any) => {
    let payload = {
      ...urlParams,
      activeTab: tab,
    };
    let queryRequest = QueryRequestHelper(payload);
    if (getValue(params, `id`, "")) {
      navigate(
        `/admin/modules/expenses/fields/detail/${getValue(
          params,
          `id`,
          ""
        )}?${queryRequest}`
      );
    } else {
      navigate(`/admin/modules/expenses/fields/add?${queryRequest}`);
    }
  };

  const [expense_type_ids, setExpense_type_ids] = useState([]);
  const [list, setList] = useState([]);
  const getAllExpenseTypeList = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    try {
      let payload = {
        // page_no: params.page_no ? parseInt(params.page_no) : 1,
        // page_size: params.limit ? parseInt(params.limit) : limit,
        page_no: 1,
        page_size: 100,
        search_text: params.search_text,
      };
      let queryRequest = QueryRequestHelper(payload);
      setIsLoading(true);
      let resp = await getAllExpenseTypes(queryRequest);
      if (resp) {
        setList(
          getValue(resp, `data.data`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `id`, ""),
            label: getValue(item, `expense_type_name`, ""),
            name: getValue(item, `expense_type_name`, ""),
          }))
        );
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const attachExpenseTypes = async (ids: any) => {
    if (getValue(ids, `length`, 0) > getValue(expense_type_ids, `length`, 0)) {
      try {
        let resp = await attachExpenseType(getValue(params, `id`, ""), {
          expense_type_ids: ids.map((item: object) => getValue(item, `id`, "")),
        });
        if (resp) {
          getInfo(getValue(params, `id`, ""));
          toast.success(getValue(resp, `message`, ""));
        }
      } catch (error) {}
    } else {
      const nonMatching = _.differenceWith(expense_type_ids, ids, _.isEqual);
      try {
        let payload = {
          expense_type_id: getValue(nonMatching, `[${0}].id`, ""),
        };
        let queryRequest = QueryRequestHelper(payload);
        let resp = await removeAttachedExpenseType(
          getValue(params, `id`, ""),
          queryRequest
        );
        if (resp) {
          toast.success(getValue(resp, `message`, ""));
          getInfo(getValue(params, `id`, ""));
        }
      } catch (error) {}
    }
  };

  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      {!isLoading &&
      !getValue(props, `subTabPermissionList`, []).includes(
        "admin_modules_expenses"
      ) ? (
        <NoAccessPage />
      ) : (
        <>
          <PromptModal
            showDialog={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
          />
          <div className="modulefields_detail_container">
            <div className="modulefields_detail_header_container px-5 bg-white">
              <p className="modulefields_detail_header_text">
                {getValue(params, `id`, "") ? "Update" : "New"} Custom Field
              </p>
              {/* <NavLink to={`/admin/modules/expenses/fields`}>
                <img
                  src="/images/close-black.svg"
                  className="modulefields_detail_close"
                />
              </NavLink> */}
            </div>
            <div className="px-5">
              <NavViews
                views={views}
                activeTab={activeTab}
                handleChangeActiveTab={handleChangeActiveTab}
                params={params}
              />
            </div>
            <div className="modulefields_form_container">
              {activeTab === 1 && (
                <div className="user-details-wrapper m-0">
                  <div className="checkpoint-active-details-wrapper__form-wrapper">
                    <div className="checkpoint-active-details-wrapper__form-content_new">
                      <div className="user-details-wrapper__form-group form-group w-50">
                        <label className="checkpoint-active-details__label form-label">
                          Display Name{" "}
                          <span className="form-label-error">*</span>
                        </label>
                        <InputField
                          inputType="TEXT"
                          placeholder="Enter Display Name"
                          className="form-control w-100"
                          name="display_name"
                          id="display_name"
                          value={getValue(request, `display_name`, "")}
                          onChange={(e: any) =>
                            setRequest({
                              ...request,
                              display_name: e.target.value,
                            })
                          }
                          label="Dispaly Name"
                          validator={simpleValidator}
                        />
                      </div>
                      <div className="user-details-wrapper__form-group form-group w-50">
                        <label className="checkpoint-active-details__label form-label">
                          Data Type <span className="form-label-error">*</span>
                        </label>
                        <SingleSelectSearchDropdown
                          label={"label"}
                          selectKey={"value"}
                          name="Date Separator"
                          value={getValue(request, `sub_data_type`, "")}
                          onChange={(e: any) =>
                            setRequest({
                              ...request,
                              sub_data_type: getValue(e, `value`, ""),
                            })
                          }
                          placeholder={"Choose Date Separator"}
                          data={dataTypeList}
                          // width={"408px"}
                          validator={simpleValidator}
                          disabled={getValue(params, `id`, "") ? true : false}
                        />
                      </div>

                      <div className="user-details-wrapper__form-group form-group mt-3">
                        <label className="checkpoint-active-details__label form-label">
                          Mandatory <span className="form-label-error">*</span>
                        </label>
                        <div className="d-flex align-items-center">
                          <div className="d-flex align-items-center mt-1">
                            <input
                              type="radio"
                              className="form-radio"
                              checked={getValue(request, `is_mandatory`, false)}
                              onChange={() =>
                                setRequest({ ...request, is_mandatory: true })
                              }
                            />
                            <label className="ms-2">Yes</label>
                          </div>
                          <div className="d-flex align-items-center mt-1 ms-3">
                            <input
                              type="radio"
                              className="form-radio"
                              checked={
                                !getValue(request, `is_mandatory`, false)
                              }
                              onChange={() =>
                                setRequest({ ...request, is_mandatory: false })
                              }
                            />
                            <label className="ms-2">No</label>
                          </div>
                        </div>
                      </div>

                      <div className="user-details-wrapper__form-group form-group mt-3">
                        <label className="checkpoint-active-details__label form-label">
                          Enabled <span className="form-label-error">*</span>
                        </label>
                        <div className="d-flex align-items-center">
                          <div className="d-flex align-items-center mt-1">
                            <input
                              type="radio"
                              className="form-radio"
                              checked={getValue(request, `is_enabled`, false)}
                              onChange={() =>
                                setRequest({ ...request, is_enabled: true })
                              }
                            />
                            <label className="ms-2">Yes</label>
                          </div>
                          <div className="d-flex align-items-center mt-1 ms-3">
                            <input
                              type="radio"
                              className="form-radio"
                              checked={!getValue(request, `is_enabled`, false)}
                              onChange={() =>
                                setRequest({ ...request, is_enabled: false })
                              }
                            />
                            <label className="ms-2">No</label>
                          </div>
                        </div>
                      </div>

                      <div className="user-details-wrapper__cta-btns mt-5 d-flex align-items-center gap-2">
                        <NavLink to={`/admin/modules/expenses/fields`}>
                          <button className="deeptravel-button deeptravel-button--header-buttons deeptravel-button--secondary ">
                            Cancel
                          </button>
                        </NavLink>
                        <button
                          className={`deeptravel-button deeptravel-button--header-buttons deeptravel-button--${
                            isChanged ? "secondary" : "primary"
                          }`}
                          onClick={handleSubmit}
                          disabled={isChanged}
                        >
                          {isLoading || !props.permissionAPITriggered
                            ? "Please wait..."
                            : getValue(params, `id`, "")
                            ? "Update"
                            : "Save"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 2 && (
                <div>
                  <div className="user-details-wrapper m-0">
                    <div className="checkpoint-active-details-wrapper__form-wrapper">
                      <div className="checkpoint-active-details-wrapper__form-content_new  w-50">
                        <div className="enable-expense-level-fields-label-wrap">
                          <label className="checkpoint-active-details__label mb-2 form-label-error">
                            Enable Expense Level <span>*</span>
                          </label>
                          <InputField
                            inputType="INPUT_REACT_SELECT"
                            isMulti
                            placeholder="Expense Type"
                            options={list}
                            onChange={(e: any) => attachExpenseTypes(e)}
                            value={expense_type_ids}
                          />
                        </div>

                        <div className="user-details-wrapper__cta-btns mt-5">
                          <button
                            className={`deeptravel-button deeptravel-button--header-buttons deeptravel-button--${
                              isChanged ? "primary" : "secondary"
                            }`}
                            onClick={attachExpenseTypes}
                            disabled={isChanged}
                          >
                            {isLoading || !props.permissionAPITriggered
                              ? "Please wait..."
                              : getValue(params, `id`, "")
                              ? "Update"
                              : "Save"}
                          </button>{" "}
                          &nbsp;&nbsp;
                          <NavLink to={`/admin/modules/expenses/fields`}>
                            <button className="deeptravel-button deeptravel-button--header-buttons deeptravel-button--primary">
                              Cancel
                            </button>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
}

export default FieldDetails;
