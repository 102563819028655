import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllDepartments = (queryRequest: string) =>
  get(`${config.API_URL}/departments?${queryRequest}`);

export const getSpecificDepartment = (id: string) =>
  get(`${config.API_URL}/departments/${id}`);

export const createDepartment = (payload: object) =>
  post(`${config.API_URL}/departments`, payload);

export const updateDepartment = (id: string, payload: object) =>
  patch(`${config.API_URL}/departments/${id}`, payload);

export const deleteDepartment = (id: string) =>
  Delete(`${config.API_URL}/departments/${id}`);

export const updateDepartmentsStatusBulk = (payload: object) =>
  patch(`${config.API_URL}/departments/bulk/active-inactive`, payload);
