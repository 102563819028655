import { formatText } from "@common/text-helpers";
import GearLoader from "@components/common/GearLoader";
import { getValue } from "@utils/lodash";

function RolesSection(props: any) {
  const {
    allList,
    handleSelectAll,
    selectedPermissions,
    isLoading,
    mainPermissions,
    sameMembers,
    handleChangeAllCheckList,
    handleChangePermission,
    name,
  } = props;

  return (
    <div>
      <div className="rolls-wrapper__permission mt-4 roles_selectall_permission_wrapper">
        {/* <h4 className="rolls-wrapper__permission-title mb-4">Client Permissions</h4> */}
        <label
          className="position-relative form-group d-flex align-items-center cursor-pointer ml-0"
          htmlFor={`select-1-z2${name}`}
          key={`select-1-z2${name}`}
        >
          <div className="custom-input-checkbox-wrap">
            <input
              type="checkbox"
              className="default-checkbox"
              id={`select-1-z2${name}`}
              onChange={() =>
                handleSelectAll(
                  getValue(allList, `length`, 0) ===
                    getValue(selectedPermissions, `length`, 0)
                    ? "false"
                    : "true"
                )
              }
              checked={
                getValue(selectedPermissions, `length`, 0) > 0 &&
                getValue(allList, `length`, 0) ===
                  getValue(selectedPermissions, `length`, 0)
              }
            />
            <div className="custom-checkbox--bg-blue">
              <img
                src="/images/uploaded-details/tick.svg"
                className="img-fluid uploaded-tick"
              />
            </div>
          </div>
          <p className="permission-checkbox-module-name"> {"Select All Permissions"}</p>
        </label>
        <p className="rolls-wrapper__permission-text">
          Applications - Define all allowed actions for the applicable tab
        </p>
      </div>
      {isLoading ? (
        <GearLoader />
      ) : (
        <div className="user-details-wrapper__form-group form-group">
          {getValue(mainPermissions, `length`, 0) > 0 &&
            mainPermissions.map((item: any) => {
              return (
                <>
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="roles-module-name-header">
                    {/* style={{ color: "#266ef1" }} */}
                      {formatText(getValue(item, `component`, ""))}
                    </h4>
                  </div>
                  <div className="roles-checkbox-module-permission-main-wrap">
                    {getValue(item, `items`, []).map(
                      (items: any, index: number) => {
                        let arr1 = getValue(items, `permissions`, []).map(
                          (item: any) => item.value
                        );
                        let arr2 = selectedPermissions;
                        let confirmChecked = sameMembers(arr1, arr2);
                        let checker = (arr: any, target: any) =>
                          target.every((v: any) => arr.includes(v));
                        return (
                          <div className="d-flex justify-content-between roles-checkbox-module-permission-wrap">
                            <label
                              className="position-relative form-group d-flex align-items-center cursor-pointer mb-0 ms-0"
                              htmlFor={`select-${getValue(
                                items,
                                `component`,
                                ""
                              )}-${getValue(
                                item,
                                `component`,
                                ""
                              )}-${index}-z2${name}`}
                              key={index}
                            >
                              <div className="custom-input-checkbox-wrap">
                                <input
                                  type="checkbox"
                                  className="default-checkbox"
                                  id={`select-${getValue(
                                    items,
                                    `component`,
                                    ""
                                  )}-${getValue(
                                    item,
                                    `component`,
                                    ""
                                  )}-${index}-z2${name}`}
                                  checked={
                                    arr2.length > 0 && checker(arr2, arr1)
                                  }
                                  onChange={() =>
                                    handleChangeAllCheckList(
                                      items,
                                      item.component,
                                      confirmChecked ? "false" : "true"
                                    )
                                  }
                                />
                                <div className="custom-checkbox--bg-blue">
                                  <img
                                    src="/images/uploaded-details/tick.svg"
                                    className="img-fluid uploaded-tick"
                                  />
                                </div>
                              </div>
                              <p className="permission-checkbox-module-name">
                                {formatText(getValue(items, `component`, ""))}
                              </p>
                            </label>
                            <div className="d-flex">
                              {getValue(items, `permissions`, []).map(
                                (name: object, i: number) => {
                                  return (
                                    <div className="rolls-details-list__form-group form-group my-0 d-flex align-items-center justify-content-space-between w-100 mw-90">
                                      <label
                                        className="position-relative form-group d-flex align-items-center cursor-pointer mb-0 ms-0"
                                        htmlFor={`select-${getValue(
                                          name,
                                          `value`,
                                          ""
                                        )}-${i}-z1${name}`}
                                        key={`select-${getValue(
                                          name,
                                          `value`,
                                          ""
                                        )}-${i}-z1${name}`}
                                      >
                                        <div className="custom-input-checkbox-wrap">
                                          <input
                                            type="checkbox"
                                            className="default-checkbox"
                                            id={`select-${getValue(
                                              name,
                                              `value`,
                                              ""
                                            )}-${i}-z1${name}`}
                                            checked={selectedPermissions.includes(
                                              getValue(name, `value`, "")
                                            )}
                                            onChange={() =>
                                              handleChangePermission(
                                                getValue(name, `value`, "")
                                              )
                                            }
                                          />
                                          <div className="custom-checkbox--bg-blue">
                                            <img
                                              src="/images/uploaded-details/tick.svg"
                                              className="img-fluid uploaded-tick"
                                            />
                                          </div>
                                        </div>
                                        <p className="permission-checkbox-permission-name">
                                          {" "}
                                          {getValue(name, `text`, "")}
                                        </p>
                                      </label>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </>
              );
            })}
        </div>
      )}
    </div>
  );
}

export default RolesSection;
