import AddSvgComponent from "@assets/svg-components/add";
import ViolationSvgComponent from "@assets/svg-components/violation";
import { convertCurrentDate } from "@common/date-helpers";
import { formatAmount, formatText } from "@common/text-helpers";
import NotfoundSmall from "@components/Not-Found/not-found-small";
import { getValue } from "@utils/lodash";
import { Tooltip } from "react-tooltip";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledAccordion,
  UncontrolledDropdown,
  Dropdown,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Accordion,
} from "reactstrap";
import { useCallback, useEffect, useRef, useState } from "react";
import LightGalleryPreview from "@common/lightgallery-preview";
import EyeSvgComponent from "@assets/svg-components/eye";
import _ from "lodash";
import ArrowDownSvgComponent from "@assets/svg-components/arrow-down";
import LineArrowDownSvgComponent from "@assets/svg-components/line-arrow-down";
import moment from "moment-timezone";
import { handleDownloadOnClick } from "@common/downloadfile";
import DocumentSvgComponent from "@assets/svg-components/document";
import InputField from "@common/InputRuleFeild";
import {
  updateExpenseAmount,
  updateExpenseCurrencyAmount,
} from "@services/expense-entry.service";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import { useLocation } from "react-router-dom";
import {
  Banknote,
  CalendarDays,
  CarFront,
  ChevronDown,
  ChevronUp,
  Circle,
  CircleChevronDown,
  CircleChevronUp,
  Navigation,
  Pencil,
  Trash,
  Trash2,
  TriangleAlert,
  UserRoundPlus,
} from "lucide-react";
import { Collapse } from "antd";
import DocumentFormatSelector from "./components/document-format-selector";

function ReportExpenseListSection(props) {
  const { list, handleDeleteFun } = props;
  const [isHovered, setIsHovered] = useState(false);
  const handleHover = () => {
    setIsHovered(true);
  };
  const handleHoverExit = () => {
    setIsHovered(false);
  };

  const [userInfo, setUserInfo] = useState({});
  useEffect(() => {
    if (getValue(localStorage, `user`, {}))
      setUserInfo(JSON.parse(getValue(localStorage, `user`, {})));
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                            Image Preview section                           */
  /* -------------------------------------------------------------------------- */
  const lightGallery = useRef(null);
  const handleClickGallery = (item) => {
    let list = getValue(item, `receipts`, []).map((item) => ({
      id: getValue(item, `id`, ""),
      size: "1400-933",
      src: getValue(item, `url`, ""),
      thumb: getValue(item, `url`, ""),
      subHtml: `<div class="lightGallery-captions">
                  <h4><a href="">${getValue(item, `name`, "")}</a></h4>
              </div>`,
    }));
    setImageList(list);
  };
  const [imageList, setImageList] = useState([]);
  const openGallery = useCallback((index) => {
    lightGallery.current.openGallery(index);
  }, []);
  const onInit = useCallback((detail) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  const [modalReadMore, setModalReadMore] = useState(false);
  const toggleReadMore = () => setModalReadMore(!modalReadMore);
  const [modalEditAmount, setModalEditAmount] = useState(false);
  const toggleEditAmount = () => setModalEditAmount(!modalEditAmount);

  const [editRequest, setEditRequest] = useState({
    amount: 0,
    remark: "",
  });
  const editAmount = (amount) => {
    setEditRequest({
      ...editRequest,
      amount: amount,
    });
    toggleEditAmount();
  };

  /* Currency */
  const [modalEditCurrencyValue, setModalEditCurrencyValue] = useState(false);
  const toggleEditCurrencyValue = () =>
    setModalEditCurrencyValue(!modalEditCurrencyValue);
  // const [expenseDataCurrency, setExpenseDataCurrency] = useState();

  const [editCurrencyRequest, setEditCurrencyRequest] = useState({
    claimed_currency: 0,
    conversion_rate: 0,
    remark: "",
  });

  const editCurrencyAmount = (claimed_amount, base_amount) => {
    setEditCurrencyRequest({
      ...editCurrencyRequest,
      claimed_currency: claimed_amount,
      conversion_rate: base_amount,
    });
    toggleEditCurrencyValue();
  };

  console.log(editCurrencyRequest, "---editCurrencyRequest---");

  const [modalInfo, setModalInfo] = useState([]);
  const getModalData = (obj) => {
    setModalInfo(obj);
    toggleReadMore();
    // toggleEditAmount();
  };

  const formatDate = (inputDate) => {
    // const parsedDate = moment(inputDate);
    const parsedDate = moment(inputDate, ["DD-MM-YYYY", "YYYY-MM-DD"], true);
    // Check if the parsed date is valid
    if (parsedDate.isValid()) {
      // Format the date to "DD MMM YYYY" format
      const formattedDate = parsedDate.format("DD MMM YYYY");
      // console.log(formattedDate); // Output: 29 Feb 2024
      return formattedDate;
    } else {
      return inputDate;
    }
  };

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const params = useParams();
  const [amountEditId, setAmountEditId] = useState("");
  const [amountCurrency, setAmountCurrency] = useState("");
  const [amountLoading, setAmountLoading] = useState(false);
  const handleUpdateAmountFun = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setAmountLoading(true);
        let resp = await updateExpenseAmount(amountEditId, {
          amount: parseFloat(editRequest.amount),
          remark: editRequest.remark,
        });
        // console.log(resp, "sxsaxsaxsaxsa")
        if (resp) {
          setEditRequest({
            ...editRequest,
            amount: 0,
            remark: "",
          });
          toast.success(getValue(resp, `message`, ""));
          setAmountEditId("");
          setAmountCurrency("");
          toggleReadMore();
          simpleValidator.current.hideMessages();
          forceUpdate(0);
          props.getList();
          props.getAssociatedExpenseList();
        }
      } catch (error) {
        setAmountLoading(false);
      }
    }
  };

  const handleChangeAmount = (e) => {
    const userInput = e.target.value;
    const regex = /^[0-9.]*$/; // Regular expression to allow only digits and a single decimal point
    let input = userInput.slice(0, 6);

    if (regex.test(input)) {
      return input;
    } else {
      return input ? input.replace(/[^0-9.]/g, "") : "";
    }
  };

  const [currencyAmountEditId, setCurrencyAmountEditId] = useState("");
  const [baseCurrency, setBaseCurrency] = useState("");
  const [claimedCurrency, setClaimedCurrency] = useState("");

  const [currencyLoading, setCurrencyLoading] = useState(false);
  const handleUpdateCurrencyFun = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setCurrencyLoading(true);
        let resp = await updateExpenseCurrencyAmount(currencyAmountEditId, {
          claimed_currency: parseFloat(editCurrencyRequest.claimed_currency),
          conversion_rate: parseFloat(editCurrencyRequest.conversion_rate),
          remark: editCurrencyRequest.remark,
        });

        if (resp) {
          console.log(resp, "---resp---");

          setEditCurrencyRequest({
            ...editCurrencyRequest,
            claimed_currency: 0,
            conversion_rate: 0,
            remark: "",
          });

          toast.success(getValue(resp, `message`, ""));
          setCurrencyAmountEditId("");
          setBaseCurrency("");
          setClaimedCurrency("");
          toggleEditCurrencyValue();
          simpleValidator.current.hideMessages();
          forceUpdate(0);
          props.getList();
          props.getAssociatedExpenseList();
        }
      } catch (error) {
        setCurrencyLoading(false);
      }
    }
  };

  const location = useLocation();

  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  console.log("list", list);

  return (
    <div
      className={`${
        props.hideExpenseHeader
          ? getValue(props, `formInfo.approval_status`, "") === "reimbursed"
            ? "report-detail-wrapper_left_container_approval_list_section_reimbursement"
            : "report-detail-wrapper_left_container_approval_list_section"
          : "report-detail-wrapper_left_container_list_section"
      } position-relative expense-details-left-card-list-item report-detail-wrapper_left_container_list_section mt-16`}
    >
      <Accordion open={open} toggle={toggle} className="reports-accordion">
        {getValue(list, `length`, 0) > 0 ? (
          list.map((item, index) => {
            const expenseEntryData = getValue(item, "expense_entry_cf", {});
            // Extract non-null key-value pairs into an array of objects
            const data = _.chain(expenseEntryData)
              .omitBy(_.isNull)
              .map((value, label) => ({ value, label }))
              .value();

            return (
              <AccordionItem>
                <AccordionHeader targetId={index}>
                  <div>
                    <h5 className="header_text__32">
                      {getValue(item, `expense_type.expense_type_name`, "")}
                    </h5>
                    <p className="small_text__14">
                      Expense No: {getValue(item, `expense_no`, "")}
                    </p>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <div
                      data-tooltip-id={
                        getValue(item, `policy_violations.length`, "") === 0
                          ? ""
                          : `my-tooltip${index}`
                      }
                      data-tooltip-content="This expense violated certain policies"
                      className={`report-detail-list-action-icons-holder`}
                    >
                      <TriangleAlert
                        color={
                          getValue(item, `policy_violations.length`, "") > 0
                            ? "#ff8a00"
                            : "#fff"
                        }
                        size={24}
                      />
                      <Tooltip id={`my-tooltip${index}`} />
                    </div>
                    {open === index &&
                      getValue(userInfo, `id`, "") ===
                        getValue(props, `formInfo.created_by.id`, "") &&
                      (getValue(props, `formInfo.approval_status`, "") ===
                        "awaiting_approval" ||
                        getValue(props, `formInfo.approval_status`, "") ===
                          "unsubmitted" ||
                        getValue(props, `formInfo.approval_status`, "") ===
                          "recalled") && (
                        <div className="d-flex align-items-center gap-2 mx-4">
                          <div className="report-accordion-icon">
                            {(getValue(
                              props,
                              `formInfo.approval_status`,
                              ""
                            ) === "unsubmitted" ||
                              getValue(
                                props,
                                `formInfo.approval_status`,
                                ""
                              ) === "recalled") && (
                              <Pencil
                                size={18}
                                onClick={() =>
                                  getValue(
                                    props,
                                    `formInfo.approval_status`,
                                    ""
                                  ) === "unsubmitted" ||
                                  getValue(
                                    props,
                                    `formInfo.approval_status`,
                                    ""
                                  ) === "recalled"
                                    ? props.handleEditExpense(
                                        getValue(item, `id`, "")
                                      )
                                    : null
                                }
                              />
                            )}
                          </div>
                          <div className="report-accordion-icon">
                            <Trash2
                              size={18}
                              onClick={() =>
                                handleDeleteFun(getValue(item, `id`, ""), "")
                              }
                            />
                          </div>
                        </div>
                      )}
                    <p className="header_text__20 text-right">
                      {getValue(item, `claimed_currency_symbol`, "")}{" "}
                      {getValue(item, `amount`, "")}
                    </p>
                    {open === index ? (
                      <img
                        src="/images/accordion-active.svg"
                        width={36}
                        height={36}
                        alt=""
                      />
                    ) : (
                      <img
                        src="/images/accordion.svg"
                        width={36}
                        height={36}
                        alt=""
                      />
                    )}
                  </div>
                </AccordionHeader>
                <AccordionBody accordionId={index}>
                  <UncontrolledAccordion defaultOpen="1">
                    <div className={``} key={index}>
                      <div className="">
                        <div className="expense-accordion-items-detail">
                          <div className=" d-flex align-items-center gap-2">
                            <CalendarDays size={18} />
                            <div className="">
                              <p className="desc_text">Expense Date</p>
                              <h5 className="header_text">
                                {convertCurrentDate(
                                  getValue(item, `expense_date`, "")
                                )}
                              </h5>
                            </div>
                          </div>

                          {getValue(
                            item,
                            `expense_entry_mileage.distance`,
                            ""
                          ) && (
                            <>
                              <div className=" d-flex align-items-center gap-2">
                                <Navigation size={18} />
                                <div className="">
                                  <p className="desc_text">Distance</p>
                                  <h5 className="header_text">
                                    {getValue(
                                      item,
                                      `expense_entry_mileage.distance`,
                                      ""
                                    )}{" "}
                                    {getValue(
                                      item,
                                      `expense_entry_mileage.mileage_unit`,
                                      ""
                                    )}
                                  </h5>
                                </div>
                                {/* <div className="vertical_line"></div> */}
                              </div>
                              <div className=" d-flex align-items-center gap-2">
                                <CarFront size={18} />
                                <div>
                                  <p className="desc_text">Vehicle Type</p>
                                  <h5 className="header_text">
                                    {getValue(
                                      item,
                                      `expense_entry_mileage.vehicle.vehicle_name`,
                                      ""
                                    )}
                                  </h5>
                                </div>
                                {/* <div className="vertical_line"></div> */}
                              </div>
                            </>
                          )}

                          <div className=" d-flex align-items-center gap-2">
                            <Banknote size={18} />
                            <div className="">
                              <p className="desc_text">Paid Through</p>
                              <h5 className="header_text">
                                {" "}
                                {formatText(
                                  getValue(
                                    item,
                                    `paid_through.account_name`,
                                    ""
                                  )
                                )}
                              </h5>
                            </div>
                          </div>
                          <div className=" d-flex align-items-center gap-2">
                            <UserRoundPlus size={18} />
                            <div className="">
                              <p className="desc_text">Reference</p>
                              <h5 className="header_text">
                                {getValue(item, `referance_no`, "")
                                  ? getValue(item, `referance_no`, "")
                                  : "-"}
                              </h5>
                            </div>
                          </div>

                          <div className=" d-flex align-items-center gap-2">
                            <Circle size={18} />
                            <div className="">
                              <p className="desc_text">Status</p>

                              <h5 className="header_text">
                                {formatText(getValue(item, `status`, ""))}
                              </h5>
                            </div>
                            {/* <div className="vertical_line"></div> */}
                          </div>

                          {/* <div className="expense-detail-wrapper_report d-flex align-items-center view-more-dd-holder">
                      {getValue(data, `length`, 0) > 0 && (
                        <AccordionItem
                          className={`report-detail-list-action-icons-holder`}
                        >
                          <AccordionHeader targetId={`moreInfo`}>
                            <div
                              data-tooltip-id={`my-info${index}`}
                              data-tooltip-content="More Info"
                            >
                              <EyeSvgComponent color={"#dcdcdc"} size={24} />
                              View more info
                              <Tooltip id={`my-info${index}`} />
                            </div>
                          </AccordionHeader>
                        </AccordionItem>
                      )}
                    </div> */}

                          {/* <div className="expense-detail-wrapper_report d-flex align-items-center view-more-dd-holder">
                      <h5
                        className="header_text"
                        onClick={() => getModalData(data)}
                      >
                        View more info
                      </h5>
                    </div> */}

                          {getValue(data, `length`, 0) > 0 && (
                            <div className="expense-detail-wrapper_report d-flex align-items-center view-more-dd-holder">
                              <h5
                                className="header_text"
                                onClick={() => getModalData(data)}
                              >
                                View more info
                              </h5>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="d-flex justify-content-between gap-4 align-items-center mt-3">
                        <div className="expense-details-card-desc-wrap ">
                          <div className="">
                            <p className="desc_text">Description</p>
                            <h5 className="header_text">
                              {getValue(item, `description`, "")
                                ? getValue(item, `description`, "")
                                : "No description added"}
                            </h5>
                          </div>
                        </div>
                        <div>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center report-detail-list-action-icons-wrapper">
                              {/* {getValue(data, `length`, 0) > 0 && (
                          <AccordionItem className={`report-detail-list-action-icons-holder`}>
                            <AccordionHeader targetId={`moreInfo`}>
                              <div
                                data-tooltip-id={`my-info${index}`}
                                data-tooltip-content="More Info"
                                
                              >
                                <EyeSvgComponent color={"#dcdcdc"} size={24} />
                                <Tooltip id={`my-info${index}`} />
                              </div>
                            </AccordionHeader>
                          </AccordionItem>
                        )} */}
                              {/* <AccordionItem>
                          <AccordionHeader targetId={`violation`}> */}

                              {/* {getValue(userInfo, `id`, "") ===
                                getValue(props, `formInfo.created_by.id`, "") &&
                                (getValue(
                                  props,
                                  `formInfo.approval_status`,
                                  ""
                                ) === "awaiting_approval" ||
                                  getValue(
                                    props,
                                    `formInfo.approval_status`,
                                    ""
                                  ) === "unsubmitted" ||
                                  getValue(
                                    props,
                                    `formInfo.approval_status`,
                                    ""
                                  ) === "recalled") && (
                                  <UncontrolledDropdown className="action-icons-main-holder">
                                    <DropdownToggle>
                                      <div className="switch_header__dropdown m-0 align-items-center">
                                        <div className="close-image_container action-icon-wrap">
                                          <img
                                            src={"/images/more.svg"}
                                            className=" close_image"
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </DropdownToggle>
                                    <DropdownMenu className="mt-4">
                                      {(getValue(
                                        props,
                                        `formInfo.approval_status`,
                                        ""
                                      ) === "unsubmitted" ||
                                        getValue(
                                          props,
                                          `formInfo.approval_status`,
                                          ""
                                        ) === "recalled") && (
                                        <DropdownItem
                                          onClick={() =>
                                            getValue(
                                              props,
                                              `formInfo.approval_status`,
                                              ""
                                            ) === "unsubmitted" ||
                                            getValue(
                                              props,
                                              `formInfo.approval_status`,
                                              ""
                                            ) === "recalled"
                                              ? props.handleEditExpense(
                                                  getValue(item, `id`, "")
                                                )
                                              : null
                                          }
                                        >
                                          <div
                                            className={`more-dropdown-main-item `}
                                          >
                                            <p>Edit</p>
                                          </div>
                                        </DropdownItem>
                                      )}
                                      <DropdownItem
                                        onClick={() =>
                                          handleDeleteFun(
                                            getValue(item, `id`, ""),
                                            ""
                                          )
                                        }
                                      >
                                        <div
                                          className={`more-dropdown-main-item `}
                                        >
                                          <p>Delete</p>
                                        </div>
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                )} */}
                              {/* </AccordionHeader>
                        </AccordionItem> */}
                              {/* <div className="close-image_container ms-2"></div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-3">
                        {getValue(item, `bc_currency`, "") !==
                          getValue(item, `claimed_currency`, "") && (
                          <p className="small_text mb-3">
                            Amount in {getValue(item, `bc_currency`, "")}
                            {": "}
                            {getValue(item, `bc_amount`, "")} {"( "}1{" "}
                            {getValue(item, `claimed_currency_symbol`, "")} ={" "}
                            {getValue(item, `conversion_rate`, "")}{" "}
                            {getValue(item, `bc_currency`, "")}
                            {" )"}
                          </p>
                        )}
                      </div>

                      {/* )} */}
                      {getValue(item, `receipts.length`, 0) > 0 ? (
                        <div>
                          {/* <div className="border-dotted mb-3"></div> */}
                          <div className="d-flex justify-content-between align-items-center my-3 ">
                            <DocumentFormatSelector
                              item={item}
                              handleDownloadOnClick={handleDownloadOnClick}
                              formInfo={props.formInfo}
                              handleEditExpense={props.handleEditExpense}
                            />
                            {location.pathname !==
                              `/client/reports/detail/${getValue(
                                params,
                                `id`,
                                ""
                              )}` &&
                              getValue(userInfo, `id`, "") !=
                                getValue(item, `created_by.id`, "") && (
                                // <div
                                //   onClick={(e) => {
                                //     e.stopPropagation();
                                //     setAmountEditId(getValue(item, `id`, ""));
                                //     editAmount(
                                //       parseFloat(getValue(item, `total`, ""))
                                //     );
                                //     setAmountCurrency(
                                //       getValue(item, `bc_currency`, "")
                                //     );
                                //   }}
                                // >
                                //   <img
                                //     src="/images/edit-gray.svg"
                                //     class="header_square_image img-fluid"
                                //   />
                                // </div>

                                <UncontrolledDropdown className="action-icons-main-holder">
                                  <DropdownToggle>
                                    <div className="switch_header__dropdown m-0 align-items-center">
                                      <div
                                        className="close-image_container action-icon-wrap"
                                        style={{
                                          height: "24px",
                                          width: "24px",
                                          transform: "rotate(90deg)",
                                        }}
                                      >
                                        <img
                                          src={"/images/more.svg"}
                                          className=" close_image"
                                        />
                                      </div>
                                    </div>
                                  </DropdownToggle>
                                  <DropdownMenu className="mt-35px">
                                    <DropdownItem
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setAmountEditId(
                                          getValue(item, `id`, "")
                                        );
                                        editAmount(
                                          parseFloat(
                                            getValue(item, `total`, "")
                                          )
                                        );
                                        setAmountCurrency(
                                          getValue(item, `bc_currency`, "")
                                        );
                                      }}
                                    >
                                      <div
                                        className={`more-dropdown-main-item `}
                                      >
                                        <p>Edit Expense Amount</p>
                                      </div>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              )}
                          </div>
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            const receiptsLength = getValue(
                              item,
                              "receipts.length",
                              0
                            );
                            const approvalStatus = getValue(
                              props,
                              "formInfo.approval_status",
                              ""
                            );
                            if (receiptsLength > 0) {
                              // handleClickGallery(item);
                              // setTimeout(() => {
                              //   openGallery(index);
                              // }, 500);
                            } else if (receiptsLength === 0) {
                              if (
                                approvalStatus === "unsubmitted" ||
                                approvalStatus === "recalled"
                              ) {
                                props.handleEditExpense(
                                  getValue(item, "id", "")
                                );
                              }
                            }
                          }}
                        >
                          {/* <div className="border-dotted mb-3"></div> */}
                          <div className="d-flex justify-content-between align-items-center ">
                            <div className="d-flex justify-content-center align-items-center">
                              <div key={index} className="">
                                <div className="doc_view me-1 d-flex justify-content-center align-items-center">
                                  <AddSvgComponent color={"gray"} size={26} />
                                  {isHovered && (
                                    <div
                                      className={
                                        getValue(item, `receipts.length`, 0) ===
                                        0
                                          ? ""
                                          : "overlay"
                                      }
                                    >
                                      <p>
                                        {getValue(item, `receipts.length`, 0) >
                                        0
                                          ? `+ ${
                                              getValue(
                                                item,
                                                `receipts.length`,
                                                0
                                              ) - 1
                                            }`
                                          : ""}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            {location.pathname !==
                              `/client/reports/detail/${getValue(
                                params,
                                `id`,
                                ""
                              )}` && (
                              // <div
                              //   onClick={(e) => {
                              //     e.stopPropagation();
                              //     setAmountEditId(getValue(item, `id`, ""));
                              //     editAmount(
                              //       parseFloat(getValue(item, `total`, ""))
                              //     );
                              //     setAmountCurrency(
                              //       getValue(item, `bc_currency`, "")
                              //     );
                              //   }}
                              // >
                              //   <img
                              //     src="/images/edit-gray.svg"
                              //     class="header_square_image img-fluid"
                              //   />
                              // </div>

                              <UncontrolledDropdown className="action-icons-main-holder">
                                <DropdownToggle>
                                  <div className="switch_header__dropdown m-0 align-items-center">
                                    <div
                                      className="close-image_container action-icon-wrap"
                                      style={{
                                        height: "24px",
                                        width: "24px",
                                        transform: "rotate(90deg)",
                                      }}
                                    >
                                      <img
                                        src={"/images/more.svg"}
                                        className=" close_image"
                                      />
                                    </div>
                                  </div>
                                </DropdownToggle>
                                <DropdownMenu className="mt-35px">
                                  <DropdownItem
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setAmountEditId(getValue(item, `id`, ""));
                                      editAmount(
                                        parseFloat(getValue(item, `total`, ""))
                                      );
                                      setAmountCurrency(
                                        getValue(item, `claimed_currency`, "")
                                      );
                                    }}
                                  >
                                    <div className={`more-dropdown-main-item `}>
                                      <p>Edit Expense Amount</p>
                                    </div>
                                  </DropdownItem>

                                  <DropdownItem
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      // setExpenseDataCurrency(item)
                                      // toggleEditCurrencyValue();
                                      setCurrencyAmountEditId(
                                        getValue(item, `id`, "")
                                      );
                                      editCurrencyAmount(
                                        parseFloat(getValue(item, `total`, "")),
                                        parseFloat(
                                          getValue(item, `conversion_rate`, "")
                                        )
                                      );
                                      setBaseCurrency(
                                        getValue(item, `bc_currency`, "")
                                      );
                                      setClaimedCurrency(
                                        getValue(item, `claimed_currency`, "")
                                      );
                                    }}
                                  >
                                    <div className={`more-dropdown-main-item `}>
                                      <p>Edit Currency Value</p>
                                    </div>
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            )}
                          </div>
                        </div>
                      )}

                      {/* ----------------------- Policy violation  -----------------------  */}
                      {getValue(item, `policy_violations.length`, 0) === 1 && (
                        <div className="violationAccordian mt-3">
                          <div className="d-flex align-items-center justify-content-center">
                            <div className="d-flex align-items-center justify-content-center">
                              <ViolationSvgComponent
                                size={18}
                                color={"#b15620"}
                              />
                              <p className="desc_text color_violation_desc mt-0">
                                {getValue(
                                  item,
                                  `policy_violations[${0}].display_msg`,
                                  ""
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                      <div>
                        {getValue(item, `policy_violations.length`, 0) > 1 && (
                          <div className="violationAccordian">
                            <AccordionItem>
                              <AccordionHeader targetId={`violation`}>
                                <div className="d-flex align-items-center justify-content-center">
                                  <div className="d-flex align-items-center justify-content-center">
                                    <ViolationSvgComponent
                                      size={18}
                                      color={"#b15620"}
                                    />
                                    <p className="desc_text color_violation_desc mt-0">
                                      {getValue(
                                        item,
                                        `policy_violations[${0}].display_msg`,
                                        ""
                                      )}
                                    </p>
                                  </div>
                                  <div className="violation-arrow-icon-holder">
                                    <LineArrowDownSvgComponent
                                      color={"#b15620"}
                                      size={18}
                                    />
                                  </div>
                                </div>
                              </AccordionHeader>
                            </AccordionItem>
                          </div>
                        )}
                        <AccordionBody accordionId={"violation"}>
                          <div className="violationAccordian pt-0">
                            {getValue(item, `policy_violations.length`, 0) >
                              1 && (
                              <div className="d-flex justify-content-center align-items-center">
                                {getValue(item, `policy_violations`, []).map(
                                  (item, index) => {
                                    if (index > 0)
                                      return (
                                        <div className="d-flex justify-content-center align-items-center">
                                          <ViolationSvgComponent
                                            size={18}
                                            color={"#b15620"}
                                          />
                                          <p className="desc_text color_violation_desc mt-0">
                                            {getValue(item, `display_msg`, "")}
                                          </p>
                                        </div>
                                      );
                                  }
                                )}
                              </div>
                            )}
                          </div>
                        </AccordionBody>
                      </div>

                      {/* ----------------------- More Info  -----------------------  */}

                      <AccordionBody accordionId={"moreInfo"}>
                        {getValue(item, `policy_violations.length`, 0) > 0 && (
                          <div className="mt-3 mb-2">
                            {data.map((item) => {
                              return (
                                <div>
                                  <div className="d-flex align-items-center">
                                    <p className="small_text__14 ms-2">
                                      {getValue(item, `label`, "")}
                                    </p>
                                    <p className="mx-2">:</p>
                                    <p className="small_text__14 ms-2 color_primary">
                                      {getValue(item, `value`, "")}
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </AccordionBody>

                      <div></div>
                    </div>
                  </UncontrolledAccordion>
                </AccordionBody>
              </AccordionItem>
            );
          })
        ) : (
          <NotfoundSmall />
        )}
      </Accordion>
      {/* <ExpenseAmountSection formInfo={getValue(props,`formInfo`,{})}/> */}
      {getValue(list, `length`, 0) > 0 ? (
        <div className="expense_amount_calculation_section">
          <div className="d-flex align-items-center justify-content-end">
            <h6 className="desc_text__16">Total Expense Amount</h6>
            {/* <div className="ms-3">:</div> */}
            <h6 className="header_text expense_amount_currency">
              {getValue(props, `formInfo.currency_symbol`, "")}{" "}
              {formatAmount(getValue(props, `formInfo.total`, ""))}
            </h6>
          </div>
          <div className="d-flex align-items-center justify-content-end bar_section">
            <h6 className="header_tex__14">Amount to be Reimbursed</h6>
            {/* <div className="ms-3">:</div> */}
            <h6 className="header_text expense_amount_currency">
              {getValue(props, `formInfo.currency_symbol`, "")}{" "}
              {formatAmount(getValue(props, `formInfo.total`, ""))}
            </h6>
          </div>
        </div>
      ) : null}
      {getValue(imageList, `length`, 0) > 0 && (
        <LightGalleryPreview onInit={onInit} imageList={imageList} />
      )}
      <Modal
        isOpen={modalReadMore}
        toggle={toggleReadMore}
        backdropClassName={"readMoreBackDrop"}
        modalClassName={"readMoreModalReports"}
        centered
      >
        <ModalHeader toggle={toggleReadMore}>More Details</ModalHeader>
        <ModalBody>
          <div className="more-details-body-wrap">
            {modalInfo.map((item) => {
              return (
                <div>
                  <div className="more-details-label-value">
                    <p className="mb-value-holder">
                      {formatDate(getValue(item, `value`, ""))}
                    </p>
                    <p className="md-label-holder">
                      {getValue(item, `label`, "")}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalEditAmount}
        toggle={toggleEditAmount}
        backdropClassName={"readMoreBackDrop"}
        modalClassName={"readMoreModalReports"}
        centered
      >
        <ModalHeader toggle={toggleEditAmount}>Edit Expense Amount</ModalHeader>
        <ModalBody>
          <div className="p-3">
            <div>
              <label>Amount</label>
              <div className="input-group edit-expense-amount-edit-group">
                <InputField
                  inputType="TEXT"
                  placeholder="Enter Amount"
                  className="form-control w-100"
                  name="edit_amount"
                  id="edit_amount"
                  // label="Edit Amount"
                  value={getValue(editRequest, `amount`, 0)}
                  onChange={(e) =>
                    setEditRequest({
                      ...editRequest,
                      amount: handleChangeAmount(e),
                    })
                  }
                />
                <span class="input-group-text">{amountCurrency}</span>
              </div>
            </div>
            <div className="mt-2">
              <label>Remarks *</label>
              <InputField
                inputType="TEXTAREA"
                placeholder="Enter Remarks"
                className="form-control w-100"
                name="remark"
                id="remark"
                label="Remarks"
                value={getValue(editRequest, `remark`, "")}
                onChange={(e) =>
                  setEditRequest({
                    ...editRequest,
                    remark: e.target.value,
                  })
                }
                validator={simpleValidator}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn p-2 btn-primary"
            onClick={handleUpdateAmountFun}
            disabled={amountLoading}
          >
            {amountLoading ? "Please wait..." : "Submit"}
          </button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalEditCurrencyValue}
        toggle={toggleEditCurrencyValue}
        backdropClassName={"readMoreBackDrop"}
        modalClassName={"readMoreModalReports"}
        centered
      >
        <ModalHeader toggle={toggleEditCurrencyValue}>
          Edit Currency Value
        </ModalHeader>
        <ModalBody>
          <div className="p-3">
            <div className="mb-3">
              <label>Claimed Currency</label>
              <div className="input-group edit-expense-amount-edit-group">
                <InputField
                  inputType="TEXT"
                  placeholder="Enter Amount"
                  className="form-control w-100"
                  name="edit_amount"
                  id="edit_amount"
                  // label="Edit Amount"
                  disabled
                  value={getValue(editCurrencyRequest, `claimed_currency`, 0)}
                  onChange={(e) =>
                    setEditCurrencyRequest({
                      ...editCurrencyRequest,
                      claimed_currency: handleChangeAmount(e),
                    })
                  }
                />
                <span class="input-group-text">{claimedCurrency}</span>
              </div>
            </div>
            <div className="mb-3">
              <label>Base Currency</label>
              <div className="input-group edit-expense-amount-edit-group">
                <InputField
                  inputType="TEXT"
                  placeholder="Enter Amount"
                  className="form-control w-100"
                  name="edit_amount"
                  id="edit_amount"
                  // label="Edit Amount"
                  value={getValue(editCurrencyRequest, `conversion_rate`, 0)}
                  onChange={(e) =>
                    setEditCurrencyRequest({
                      ...editCurrencyRequest,
                      conversion_rate: handleChangeAmount(e),
                    })
                  }
                />
                <span class="input-group-text">{baseCurrency}</span>
              </div>
            </div>
            <div>
              <label>Remarks *</label>
              <InputField
                inputType="TEXTAREA"
                placeholder="Enter Remarks"
                className="form-control w-100"
                name="remark"
                id="remark"
                label="Remarks"
                value={getValue(editCurrencyRequest, `remark`, "")}
                onChange={(e) =>
                  setEditCurrencyRequest({
                    ...editCurrencyRequest,
                    remark: e.target.value,
                  })
                }
                validator={simpleValidator}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn p-2 btn-primary"
            onClick={handleUpdateCurrencyFun}
            disabled={currencyLoading}
          >
            {currencyLoading ? "Please wait..." : "Submit"}
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ReportExpenseListSection;
