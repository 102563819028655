// https://stackoverflow.com/questions/70585815/react-this-props-match-is-undefined
// https://reactrouter.com/docs/en/v6/getting-started/tutorial#reading-url-params
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import NotFoundPage from "@pages/404";
import { PrivateRoutes } from "./private.routes";
import { PublicRoutes } from "./public.routes";
import { getValue } from "@utils/lodash";
import { useEffect, useState } from "react";
import _ from "lodash";
import { getAllPermissions } from "@services/auth.service";
import rolesMock from "@common/json/roles.json";
import SSOLoginPage from "@pages/SSO";
import TripApprove from "@pages/Auth/TripsReport/TripApprove";
import TripReject from "@pages/Auth/TripsReport/TripReject";
import ExpenseReportApprove from "@pages/Auth/TripsReport/ExpenseApprove";
import ExpenseReportReject from "@pages/Auth/TripsReport/ExpenseReject";
const IndexRoutes = () => {
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [tabPermissionList, setTabPermissionList] = useState([]);
  const [subTabPermissionList, setSubTabPermissionList] = useState([]);
  const [permissionAPITriggered, setPermissionAPITriggered] = useState(false);
  const [mainPermissions, setMainPermissions] = useState([]);
  const [products, setProducts] = useState([]);
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (getValue(localStorage, `user`, "")) {
      let user = JSON.parse(getValue(localStorage, `user`, ""));
    }
    let token = localStorage.getItem("accessToken");
    if (token) {
      getPermissions();
    }
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const covertSecondString = (s: string) => {
    var nth = 0;
    s = s.replace(/:/g, function (match, i, original) {
      nth++;
      return nth === 2 ? "-" : match;
    });
    return s;
  };

  const getPermissions = async () => {
    try {
      let resp = await getAllPermissions();
      if (resp) {
        formatPermissions(getValue(resp, `data.permissions`, []));
        // formatPermissions(rolesMock)
      } else {
        setPermissionAPITriggered(true);
      }
    } catch (error) {
      setPermissionAPITriggered(true);
    }
  };
  const formatPermissions = (rolesMock: any) => {
    const filteredRoles = rolesMock.filter(Boolean);

    const list = filteredRoles.map((item: any) => {
      const itemToSplit = covertSecondString(item);
      return itemToSplit.includes("-")
        ? itemToSplit.split("-")
        : itemToSplit.split(":");
    });

    const arr = list.map(([tab, permission]: string[]) => ({
      tab,
      permission,
    }));

    const groupedPermission = arr.reduce(
      (acc: any, { tab, permission }: any) => {
        acc[tab] = [...(acc[tab] || []), { tab, permission }];
        return acc;
      },
      {}
    );

    const arr1: any = Object.entries(groupedPermission).map(
      ([key, value]: any) => {
        const [tab, tabKey] = key.split(":");
        const permissions = value.map(({ permission }: any) => permission);
        return { tab, tabKey: tabKey ? `${tab}_${tabKey}` : tab, permissions };
      }
    );

    const tabList = _.uniq(arr1.map(({ tab }: any) => tab));
    const subTabList = _.uniq(arr1.map(({ tabKey }: any) => tabKey));

    setMainPermissions(arr1);
    setTabPermissionList(tabList);
    setSubTabPermissionList(subTabList);
    setPermissionAPITriggered(true);
    // setProducts(getValue(resp, `data.ps`, []));
  };

  return (
    <BrowserRouter>
      <Routes>
        {PublicRoutes.map((item: any, key) => {
          if (item) {
            let Element: any = item.component;
            return (
              <Route
                key={key}
                path={item.path}
                element={
                  !localStorage.getItem("accessToken") ? (
                    <Element />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
            );
          }
        })}
        {PrivateRoutes.map((item: any, key) => {
          let Element: any = item.component;
          let permissions = mainPermissions.filter(
            (items: object) => getValue(items, `tabKey`, "") == item.name
          );
          return (
            <Route
              key={key}
              path={item.path}
              element={
                localStorage.getItem("accessToken") ? (
                  <Element
                    permissions={
                      getValue(permissions, `length`, 0) > 0
                        ? getValue(permissions, `[${0}].permissions`, [])
                        : []
                    }
                    // products={products}
                    name={getValue(item, `name`, "")}
                    tabPermissionList={tabPermissionList}
                    subTabPermissionList={subTabPermissionList}
                    permissionAPITriggered={permissionAPITriggered}
                  />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
          );
        })}
        <Route
          path={"/email-approval/trip/:id/approve"}
          element={<TripApprove />}
        />
        <Route
          path={"/email-approval/trip/:id/reject"}
          element={<TripReject />}
        />
        <Route
          path={"/email-approval/expense-report/:id/approve"}
          element={<ExpenseReportApprove />}
        />
        <Route
          path={"/email-approval/expense-report/:id/reject"}
          element={<ExpenseReportReject />}
        />
        <Route path={"*"} element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default IndexRoutes;
