import { convertCurrentDate } from "@common/date-helpers";
import { formatAmount } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import React from "react";
import ReportExpenseHeaderSection from "./report-expense-header";

function ReportTitleHeader(props: any) {
  const {
    formInfo,
    hideExpenseHeader,
    params,
    setShowUpdatePopup,
    showUpdatePopup,
  } = props;
  return (
    <div className="report-detail-wrapper_left_container_header_section flex-column">
      <div className=" w-100 pt-3 px-3">
        <div>
          <h6 className=" report-detail_headertitle pb-4">
            {getValue(formInfo, `report_name`, "")}
            {/* ({getValue(formInfo, `report_id`, "")}) */}
          </h6>
          <span className="small_text__14">Total</span>

          <h6 className="report-detail_sub-headertitle mb-3 mt-2">
            {getValue(formInfo, `currency_symbol`, "")}{" "}
            {formatAmount(getValue(formInfo, `total`, ""))}
          </h6>
        </div>
        <div className="d-flex mt-4">
          <div className="w-50 ">
            <p className="small_text__14 pb-1">Duration </p>
            <div className="header_text__16">
              {convertCurrentDate(getValue(formInfo, `duration_from`, ""))} -{" "}
              {convertCurrentDate(getValue(formInfo, `duration_to`, ""))}
            </div>
          </div>

          {getValue(formInfo, `approval_config.id`, "") && (
            <div className="w-50 ">
              <p className="small_text__14 pb-1">Approval ID</p>
              <div className="header_text__16">
                {getValue(formInfo, `approval_config.id`, "")}
              </div>
            </div>
          )}
        </div>

        <div className="d-flex justify-content-between">
          {/* <div className="ms-5">
              <h6 className="header_text">{"INR 0.00"}</h6>
              <p className="desc_text">Amount to be reimbursed</p>
            </div> */}
        </div>
      </div>
      {/*  */}
    </div>
  );
}

export default ReportTitleHeader;
