import InputField from "@common/InputRuleFeild";
import SingleSelectSearchDropdown from "@components/common/CustomSearchableDropdown/SingleSelectSearchDropdown";
import { getValue } from "@utils/lodash";

function ProjectForm(props: any) {
  const { request, setRequest, simpleValidator, handleChangeText } = props;
  return (
    <div className="checkpoint-active-details-wrapper__form-wrapper project-form-wrapper">
      <div className="checkpoint-active-details-wrapper__form-content">
        <div className="user-details-wrapper__form  user-details-wrapper__form--org-profile">
          <div className="user-details-wrapper__form-group">
            <label className="checkpoint-active-details__label form-label-error">
              Project Name <span>*</span>
            </label>
            <InputField
              inputType="TEXT"
              placeholder="Enter Project Name"
              className="form-control w-100"
              name="project_name"
              id="project_name"
              value={getValue(request, `project_name`, "")}
              onChange={(e: any) => handleChangeText(e)}
              label="Project Name"
              validator={simpleValidator}
            />
          </div>
          <div className="user-details-wrapper__form-group">
            <label className="checkpoint-active-details__label form-label-error">
              Project Code <span>*</span>
            </label>
            <InputField
              inputType="TEXT"
              placeholder="Enter Project Code"
              className="form-control w-100"
              name="project_code"
              id="project_code"
              value={getValue(request, `project_code`, "")}
              onChange={(e: any) => handleChangeText(e)}
              label="Project Code"
              validator={simpleValidator}
            />
          </div>
          <div className="user-details-wrapper__form-group">
            <label className="checkpoint-active-details__label form-label-error">
              Description <span>*</span>
            </label>
            <InputField
              inputType="TEXTAREA"
              placeholder="Enter Description"
              className="form-control w-100"
              name="description"
              id="description"
              value={getValue(request, `description`, "")}
              onChange={(e: any) => handleChangeText(e)}
              label="Description"
              validator={simpleValidator}
            />
          </div>
          <div className="user-details-wrapper__form-group">
            <label className="checkpoint-active-details__label form-label-error">
              Project Head <span>*</span>
            </label>
            <SingleSelectSearchDropdown
              label={"label"}
              name="Project Head"
              value={getValue(request, `project_head`, "")}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  project_head: getValue(e, `id`, ""),
                })
              }
              placeholder={"Choose Project Head"}
              width={"100%"}
              validator={simpleValidator}
              lookup_api={"users"}
              lookup_id={getValue(request, `project_head`, "")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectForm;
