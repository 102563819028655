import * as React from "react";
import "./index.scss";
import Lottie from "lottie-react";
import Loader from "./gear-loader.json";

interface INotFoundPageProps {}

const GearLoader: React.FunctionComponent<INotFoundPageProps> = (props) => {
  return (
    <div className="loading-wrapper  d-flex w-100 my-5">
      <div className="loading-wrapper__details">
        {/* <div style={{ width: "100%", height: "100%" }}> */}
        <Lottie animationData={Loader} loop={true} />
        {/* </div> */}
      </div>
    </div>
  );
};

export default GearLoader;
