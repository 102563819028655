import { travelType } from "@common/constants";
import { formatText } from "@common/text-helpers";
import { getValue } from "@utils/lodash";

export const generateFieldOptions = (
  name: string,
  options: any,
  tripLimit: any
) => {
  switch (name) {
    case "customer":
      return getValue(options, `length`, 0) > 0
        ? options.map((item: object) => ({
            ...item,
            value: getValue(item, `text`, ""),
            label: getValue(item, `text`, ""),
          }))
        : [];

    case "location":
      return getValue(options, `length`, 0) > 0
        ? options.map((item: object) => ({
            ...item,
            value: getValue(item, `text`, ""),
            label: getValue(item, `text`, ""),
          }))
        : [];
    case "designation":
      return getValue(options, `length`, 0) > 0
        ? options.map((item: object) => ({
            ...item,
            value: getValue(item, `text`, ""),
            label: getValue(item, `text`, ""),
          }))
        : [];
    case "country_code":
      return getValue(options, `length`, 0) > 0
        ? options.map((item: object) => ({
            ...item,
            value: getValue(item, `text`, ""),
            label: getValue(item, `text`, ""),
          }))
        : [];
    case "user_timezone":
      return getValue(options, `length`, 0) > 0
        ? options.map((item: object) => ({
            ...item,
            value: getValue(item, `text`, ""),
            label: getValue(item, `text`, ""),
          }))
        : [];
    case "project":
      return getValue(options, `length`, 0) > 0
        ? options.map((item: object) => ({
            ...item,
            value: getValue(item, `text`, ""),
            label: getValue(item, `text`, ""),
          }))
        : [];

    case "gender":
      return getValue(options, `length`, 0) > 0
        ? options.map((item: string) => ({
            value: item,
            label: formatText(item),
          }))
        : [];
    case "travel_type":
      if (getValue(tripLimit, `is_active`, false)) {
        const selectedTravelTypes = [];
        if (getValue(tripLimit, `is_domestic`, false)) {
          selectedTravelTypes.push(
            travelType.find((type) => type.value === "domestic")
          );
        }
        if (getValue(tripLimit, `is_international`, false)) {
          selectedTravelTypes.push(
            travelType.find((type) => type.value === "international")
          );
        }
        return selectedTravelTypes;  
      } else {
        return travelType;
      }

    default:
      return options;
  }
};
