import BackSvgComponent from "@assets/svg-components/back";
import { getValue } from "@utils/lodash";
import { NavLink } from "react-router-dom";

function StickyHeader(props: any) {
  const { isChanged, handleSubmit, submitLoading, id, route } = props;
  return (
    <div className="common-sticky-header">
      <section className="inner-page-navigation-with-back-cta-buttons position-relative">
        <div className="container-fluid  d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-between align-items-center">
            <NavLink
              to={route}
              className="user-details-wrapper__header-back-link"
            >
              <BackSvgComponent />
            </NavLink>
            <p className="header_text"> {getValue(props, `title`, "")}</p>
          </div>
          {getValue(props, `permissions`, []).includes("update") && (
            <div className="d-flex align-items-center">
              {/* <button className=" deeptravel-button deeptravel-button--header-buttons deeptravel-button--primary mx-3">
              Cancel
            </button> */}
              {/* <button
              onClick={handleSubmit}
              disabled={isChanged || submitLoading}
              className="ms-1 deeptravel-button deeptravel-button--header-buttons deeptravel-button--secondary mx-3"
            >
              {submitLoading ? "Please wait..." : "Update"}
            </button> */}
              <button
                onClick={handleSubmit}
                disabled={isChanged || submitLoading}
                className="deeptravel-button deeptravel-button--header-buttons deeptravel-button--primary"
              >
                {submitLoading ? "Please wait..." : "Update"}
              </button>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}

export default StickyHeader;
