import DeleteSvgComponent from "@assets/svg-components/delete";
import EditSvgComponent from "@assets/svg-components/edit";
import InputField from "@common/InputRuleFeild";
import {
  handleNumberChange,
  handleTextBoolean,
  handleTextChange,
} from "@common/handleChange";
import SingleSelectSearchDropdown from "@components/common/CustomSearchableDropdown/SingleSelectSearchDropdown";
import { createVehicle } from "@services/vehicle.service";
import { getValue } from "@utils/lodash";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Modal } from "reactstrap";

function VehicleListPopup(props: any) {
  const { isOpen, handleModal, list } = props;
  const [request, setRequest] = useState({
    vehicle_name: "",
    mileage_type: "manual",
    description: "",
  });
  return (
    <Modal isOpen={isOpen} toggle={handleModal} size="lg">
      <div className="m-2">
        <div className="mt-2 px-4 d-flex justify-content-between align-items-center">
          <h6 className="header_text">Vehicle List</h6>
          <div className="close-image_container ms-3" onClick={handleModal}>
            <img className="close_image" src={`/images/close-black.svg`} />
          </div>
        </div>
        <hr />
      </div>

      <div className="px-4 mb-4 mt-3">
        <table className="datatable_table">
          <thead className="datatable_table_thead">
            <th className="datatable_table_th">Vehicle Name</th>
            <th className="datatable_table_th">Mileage Type</th>
            <th className="datatable_table_th">Action</th>
          </thead>
          <tbody className="datatable_table_tbody">
            {getValue(props, `list`, []).map((item: object, index: number) => {
              return (
                <tr key={index} className="datatable_table_tr">
                  <td className="datatable_table_td_common">
                    {getValue(item, `vehicle_name`, "")}
                  </td>
                  <td className="datatable_table_td">
                    {getValue(item, `mileage_type`, "")}
                  </td>
                  <td className="datatable_table_td">
                    <div className="d-flex align-items-center ">
                      <div
                        onClick={() =>
                          props.handleEditFun(item)
                        }
                      >
                        <EditSvgComponent />
                      </div>
                      <div
                        className="action_container mx-2"
                        onClick={() =>
                          props.handleDeleteFun(
                            getValue(item, `id`, ""),
                            getValue(item, `title`, "")
                          )
                        }
                      >
                        <DeleteSvgComponent />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="add-popup-wrapper__cta-wrapper">
          <hr />

          <button
            className="add-popup-wrapper__cta-btn add-popup-wrapper__cta-btn--secondary"
            onClick={() => {
              setRequest({
                ...request,
                vehicle_name: "",
                mileage_type: "manual",
                description: "",
              });
              handleModal();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default VehicleListPopup;
