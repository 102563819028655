import React from "react";
import "./index.scss";
export default function Notfound() {
  return (
    <>
      <div className="not-found-wrapper d-flex w-100 align-items-center justify-content-center">
        <div className="not-found-wrapper__details">
          <img
            src="/images/notfound/no-results-found.svg"
            className="not-found-image img-fluid"
          />
          <p className="header_text__19 text-center mt-3">No Data Found</p>
        </div>
      </div>
    </>
  );
}
