import React from "react";
import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/scss/lightgallery.scss";
import "lightgallery/scss/lg-zoom.scss";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

const PropertyPhotoes = ({ images, hideHeading }) => {
  const onInit = () => {
    console.log("lightGallery has been initialized");
  };

  return (
    <div
      className={`w-100 d-flex flex-column align-items-center gap-4 ${
        hideHeading ? "" : "mb-5 pt-4"
      }`}
    >
      {!hideHeading && (
        <div className="w-75">
          <h4 className="">All Photos</h4>
          <p className="small_text__14 mt-1">
            Hotel Grand Godwin, Delhi, India
          </p>
        </div>
      )}
      <div className={`${hideHeading ? "w-100" : "w-75"}`}>
        <LightGallery
          onInit={onInit}
          speed={500}
          plugins={[lgThumbnail, lgZoom]}
        >
          {images.map((image, index) => (
            <a href={image.image} key={index} className="p-1">
              <img
                alt="img1"
                src={image.image}
                className="my-1"
                style={{
                  borderRadius: "12px",
                  width: hideHeading ? "100px" : "",
                }}
              />
            </a>
          ))}
        </LightGallery>
      </div>
    </div>
  );
};

export default PropertyPhotoes;
