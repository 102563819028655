export const DateFormatList = [
  { label: "MM/dd/yy", value: "MM/dd/yy" },
  { label: "dd/MM/yy", value: "dd/MM/yy" },
  { label: "yy/MM/dd", value: "yy/MM/dd" },

  { label: "MM/dd/yyyy", value: "MM/dd/yyyy" },
  { label: "dd/MM/yyyy", value: "dd/MM/yyyy" },
  { label: "yyyy/MM/dd", value: "yyyy/MM/dd" },

  { label: "dd mmm yyyy", value: "dd mmm yyyy" },
  { label: "mmm dd yyyy", value: "mmm dd yyyy" },
  { label: "yyyy mmm dd", value: "yyyy mmm dd" },
];

export const DateSeperator = [
  { label: ".", value: "." },
  { label: "/", value: "/" },
  { label: "-", value: "-" },
];

export const months = [
	{ label: "January", value: "1" },
	{ label: "February", value: "2" },
	{ label: "March", value: "3" },
	{ label: "April", value: "4" },
	{ label: "May", value: "5" },
	{ label: "June", value: "6" },
	{ label: "July", value: "7" },
	{ label: "August", value: "8" },
	{ label: "September", value: "9" },
	{ label: "October", value: "10" },
	{ label: "November", value: "11" },
	{ label: "December", value: "12" },
];

export const monthsString = [
	"_",
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec",
];

export const FiscalYearList = [
    { label: "January - December", value: "January-December" },
    { label: "February - January", value: "February-January" },
    { label: "March - February", value: "March-February" },
    { label: "April - March", value: "April-March" },
    { label: "May - April", value: "May-April" },
    { label: "June - May", value: "June-May" },
    { label: "July - June", value: "July-June" },
    { label: "August - July", value: "August-July" },
    { label: "September - August", value: "September-August" },
    { label: "October - September", value: "October-September" },
    { label: "November - October", value: "November-October" },
    { label: "December - November", value: "December-November" }
  ];
