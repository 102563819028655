import AddSvgComponent from "@assets/svg-components/add";
import ExpenseSvgComponent from "@assets/svg-components/sidebar/expense";
import { getValue } from "@utils/lodash";
import React from "react";
import { useNavigate } from "react-router-dom";

function ReportExpenseHeaderSection(props: any) {
  const { params, setShowUpdatePopup, showUpdatePopup, formInfo } = props;
  const navigate = useNavigate();
  return (
    <>
      {getValue(formInfo, `approval_status`, "") !== "reimbursed" && (
        <div className="d-flex justify-content-end gap-2">
          <button
            className={`deeptravel-button deeptravel-button--header-buttons deeptravel-button--secondary ${
              props.disabled ? "bg-disabled" : ""
            }`}
            onClick={() =>
              !props.disabled &&
              navigate(`/client/expenses/add?expense_report_id=${params.id}`)
            }
          >
            <AddSvgComponent color={!props.disabled ? "#893297" : "#d5d5d5"} />
            New Expense
          </button>
          <button
            className={`deeptravel-button deeptravel-button--header-buttons deeptravel-button--secondary  ${
              props.disabled ? "bg-disabled" : ""
            }`}
            onClick={() => {
              !props.disabled && setShowUpdatePopup(!showUpdatePopup);
            }}
          >
            <ExpenseSvgComponent
              color={!props.disabled ? "#893297" : "#d5d5d5"}
            />
            Add Unreported Expense
          </button>
        </div>
      )}
    </>
  );
}

export default ReportExpenseHeaderSection;
