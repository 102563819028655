import InputField from "@common/InputRuleFeild";
import SingleSelectSearchDropdown from "@components/common/CustomSearchableDropdown/SingleSelectSearchDropdown";
import { getValue } from "@utils/lodash";
import { Modal } from "reactstrap";
import DatePicker from "react-datepicker";

function PerDiemRateTravelHoursPopup(props: any) {
  const {
    isOpen,
    handleModal,
    request,
    setRequest,
    handleSubmit,
    submitLoading,
    editId,
    setEditId,
  } = props;

  // Utility function to format time as HH:mm from Date object
    const formatTime = (date:any) => {
      return date ? `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}` : "";
    };

    // Utility function to parse HH:mm string into Date object
    const parseTime = (timeString:any) => {
      if (!timeString) return null; // If the time string is empty, return null
      const [hours, minutes] = timeString.split(":");
      const date = new Date();
      date.setHours(parseInt(hours, 10));
      date.setMinutes(parseInt(minutes, 10));
      return date;
    };

    // Function to handle time selection and update the request object
    const handleTimeChange = (field:any, date:any) => {
      const formattedTime = formatTime(date); // Convert Date to HH:mm string
      setRequest((prev:any) => ({
        ...prev,
        [field]: formattedTime, // Update the specific field (start_time or end_time)
      }));
    };
  console.log(request, "---PerDiemRateTravelHoursPopup Request---");

  return (
    <Modal isOpen={isOpen} toggle={handleModal}>
      <div className="m-2">
        <div className="mt-2 px-4 d-flex justify-content-between align-items-center">
          <h6 className="header_text">{editId ? "Edit" : "Create"}  Travel Duration</h6>
          <div className="close-image_container ms-3" onClick={handleModal}>
            <img className="close_image" src={`/images/close-black.svg`} />
          </div>
        </div>
        <hr />
      </div>

      <div className="px-4 mb-4 per-diem-rate-hours-holder per-diem-popup-wrap">

      <div className="d-flex gap-2">
        <div className="user-details-wrapper__form-group form-group">
          <label className="checkpoint-active-details__label form-error-label">
            Start Time <span>*</span>
          </label>
          <DatePicker
            selected={parseTime(request.start_time)} // Parse HH:mm string back into Date object
            onChange={(date:any) => handleTimeChange("start_time", date)} // Handle time changes
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={1}
            timeCaption="Time"
            dateFormat="HH:mm"
            placeholderText="Select Start Time"
            className="custom-form-control form-control"
          />
        </div>

        <div className="user-details-wrapper__form-group form-group">
          <label className="checkpoint-active-details__label form-error-label">
            End Time <span>*</span>
          </label>
          <DatePicker
            selected={parseTime(request.end_time)} // Parse HH:mm string back into Date object
            onChange={(date:any) => handleTimeChange("end_time", date)} // Handle time changes
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={1}
            timeCaption="Time"
            dateFormat="HH:mm"
            placeholderText="Select End Time"
            className="custom-form-control form-control"
          />
        </div>
      </div>

        <div>
          <h6 className=" desc_text">% for Per Diem Rate </h6>
          <div className="">
            <InputField
              inputType="NUMBER"
              name="percentage"
              placeholder="Enter Percentage"
              value={getValue(request, `percentage`, "") == 0 ? "" : getValue(request, `percentage`, "")}
              onChange={(e: any) =>
                setRequest({ ...request, percentage: Number(e.target.value) })
              }
            />
          </div>
        </div>

        <div className="add-popup-wrapper__cta-wrapper">
          <hr />
          <button
            className="add-popup-wrapper__cta-btn add-popup-wrapper__cta-btn--secondary"
            onClick={() => {
              setRequest({
                ...request,
                start_time: "",
                end_time: "",
                percentage: 0,
              });
              setEditId("");
              handleModal();
            }}
          >
            Cancel
          </button>
          <button
            className="add-popup-wrapper__cta-btn add-popup-wrapper__cta-btn--primary"
            onClick={() => handleSubmit()}
            disabled={submitLoading}
          >
            {submitLoading ? "Please wait..." : editId ? "Update" : "Save"}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default PerDiemRateTravelHoursPopup;
