import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

const bestSelling = [
  {
    image: "/images/dashboard/trips/online/bali.png",
    alt: "",
    destination: "Bhutan ",
    price: "14500",
  },
  {
    image: "/images/dashboard/trips/online/north-east-india.png",
    alt: "",
    destination: "North East",
    price: "24500",
  },
  {
    image: "/images/dashboard/trips/online/mauritus.png",
    alt: "",
    destination: "Mauritus",
    price: "34500",
  },
  {
    image: "/images/dashboard/trips/online/singapore.png",
    alt: "",
    destination: "Singapore",
    price: "44500",
  },
  {
    image: "/images/dashboard/trips/online/hongkong.png",
    alt: "",
    destination: "Hong Kong",
    price: "54500",
  },
  {
    image: "/images/dashboard/trips/online/bali.png",
    alt: "",
    destination: "Bhutan ",
    price: "14500",
  },
];

const dataSelector = [
  {
    value: "option1",
    label: "1 to 3 days",
  },
  {
    value: "option2",
    label: "4 to 6 days",
  },
  {
    value: "option3",
    label: "7 to 9 days",
  },
  {
    value: "option4",
    label: "10 to 12 days",
  },
  {
    value: "option5",
    label: "13 days & more",
  },
];

const BestSelling = () => {
  const [selectedOpt, setSelectedOpt] = useState("option1");

  const handleOption = (event) => {
    setSelectedOpt(event.target.value);
  };

  return (
    <div className="container-lg my-5 py-5">
      <div>
        <div className="d-flex gap-5 align-items-center">
          <h3 className="dashbaord-wrapper__header-title">
            Explore best selling deals for
          </h3>
          <div className="d-flex gap-4">
            <div className="d-flex gap-3 align-items-center">
              <label
                className="m-0 position-relative"
                htmlFor="header-checkbox"
              >
                <input
                  type="checkbox"
                  className="default-checkbox"
                  id="header-checkbox"
                  checked
                  placeholder="Within India"
                />
                <div className="custom-checkbox">
                  <img
                    src="/images/uploaded-details/tick.svg"
                    className="img-fluid uploaded-tick"
                  />
                </div>
              </label>
              <span>Within India</span>
            </div>
            <div className="d-flex gap-3 align-items-center">
              <label
                className="m-0 position-relative"
                htmlFor="header-checkbox"
              >
                <input
                  type="checkbox"
                  className="default-checkbox"
                  id="header-checkbox"
                  checked
                  placeholder="Within India"
                />
                <div className="custom-checkbox">
                  <img
                    src="/images/uploaded-details/tick.svg"
                    className="img-fluid uploaded-tick"
                  />
                </div>
              </label>
              <span>International</span>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center gap-3 mt-3">
            {dataSelector.map((option) => (
              <div key={option.value} className="">
                <input
                  type="radio"
                  id={option.value}
                  name="dateFilter"
                  value={option.value}
                  checked={selectedOpt === option.value}
                  onChange={handleOption}
                  className="trip-radio py-2"
                />
                <label htmlFor={option.value}>
                  <div className="d-flex align-items-center justify-content-center trip-selector gap-2">
                    {selectedOpt === option.value ? (
                      <img
                        src="/images/dashboard/trips/online/radio_button_checked.svg"
                        width={22}
                      />
                    ) : (
                      <img
                        src="/images/dashboard/trips/online/radio_button_unchecked.svg"
                        width={22}
                      />
                    )}
                    <p>{option.label}</p>
                  </div>
                </label>
              </div>
            ))}
          </div>
          <div className="mySwiper d-flex gap-3">
            <button className="arrow mr-2 mr-md-4 CustomerCard-slide-left">
              <img
                src="/images/dashboard/trips/online/arrow-back.svg"
                width={22}
              />
            </button>
            <button className="arrow CustomerCard-slide-right">
              <img
                src="/images/dashboard/trips/online/arrow-forward.svg"
                width={22}
              />
            </button>
          </div>
        </div>
      </div>
      <div className="d-flex gap-4 mt-4">
        <Swiper
          spaceBetween={20}
          slidesPerView={5}
          navigation={{
            nextEl: ".CustomerCard-slide-right",
            prevEl: ".CustomerCard-slide-left",
          }}
          modules={[Navigation]}
          className="mySwiper"
        >
          {bestSelling.map((selling, index) => (
            <SwiperSlide key={index}>
              <div className="theme-destination-card">
                <img
                  src={selling.image}
                  alt=""
                  className="destination-images"
                />
                <h5 className="mt-2">{selling.destination}</h5>
                <p className="gray-color header_text__14">
                  <span className="text-danger">Rs {selling.price}</span>{" "}
                  <span className="gray-color">Onwards</span>
                </p>
                <div className="header_text__14 mt-1 d-flex gap-2 align-items-center header_text_12">
                  <span className="gray-color">Best Time: </span>
                  <div className="dot-seprator"></div>
                  <span>Mar - Nov</span>
                  <div className="dot-seprator"></div>
                  <span className=" color_primary">29+ Deals</span>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default BestSelling;
