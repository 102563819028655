import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import InputField from "@common/InputRuleFeild";
import { getValue } from "@utils/lodash";
import { CopyToClipboard } from "@common/text-helpers";
import "./right-popup.scss";
import SingleSelectSearchDropdown from "@components/common/CustomSearchableDropdown/SingleSelectSearchDropdown";
function PaidThroughAccountPopup(props: any) {
  const {
    showUpdatePopup,
    setShowUpdatePopup,
    request,
    handleChangeText,
    editorState,
    onEditorStateChange,
    submitLoading,
    handleSubmit,
    editId,
    setRequest,
  } = props;
  return (
    <div
      className={`compelte-task-wrapper ${
        showUpdatePopup ? "compelte-task-wrapper--active" : ""
      }`}
      style={{ width: "40%" }}
    >
      <div className="compelte-task-wrapper__header d-flex align-items-center justify-content-between">
        <h6 className="compelte-task-wrapper__title">Paid Through Accounts</h6>
        <img
          src="/images/close-white.svg"
          className="img-fluid navigation-bar-wrapper__close-btn"
          onClick={() => {
            setShowUpdatePopup(false);
          }}
        />
      </div>

      <div className="expense-active-category-add-form-wrap">
        <div className="user-details-wrapper__form-group form-group">
          <label className="checkpoint-active-details__label form-label">
            Account Name <span className="form-label-error">*</span>{" "}
          </label>
          <InputField
            inputType="TEXT"
            placeholder="Enter Account Name"
            className="form-control"
            name="account_name"
            id="account_name"
            label="Account Name"
            value={getValue(request, `account_name`, "")}
            onChange={(e: any) => handleChangeText(e)}
            // validator={simpleValidator}
          />
        </div>
        <div className="user-details-wrapper__form-group form-group">
          <label className="checkpoint-active-details__label form-label">
            Account Code <span className="form-label-error">*</span>{" "}
          </label>
          <InputField
            inputType="TEXT"
            placeholder="Enter Account Code"
            className="form-control"
            name="account_code"
            id="account_code"
            label="Account Code"
            value={getValue(request, `account_code`, "")}
            onChange={(e: any) => handleChangeText(e)}
            // validator={simpleValidator}
          />
        </div>
        <div className="user-details-wrapper__form-group form-group ">
          <label className="form-label form-label">
            Account Type <span className="form-label-error">*</span>{" "}
          </label>
          <SingleSelectSearchDropdown
            label={"label"}
            selectKey={"value"}
            name="Type"
            value={getValue(request, `account_type`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                account_type: getValue(e, `value`, ""),
              })
            }
            placeholder={"Choose Type"}
            data={getValue(props, `typeList`, "")}
            width={"100%"}
            // validator={simpleValidator}
          />
        </div>
        <div className="user-details-wrapper__form-group form-group ">
          <label className="checkpoint-active-details__label form-label">
            Description <span className="form-label-error">*</span>{" "}
          </label>
          <InputField
            inputType="TEXTAREA"
            placeholder="Enter Description"
            className="form-control"
            name="description"
            id="description"
            label="Description"
            value={getValue(request, `description`, "")}
            onChange={(e: any) => handleChangeText(e)}
            // validator={simpleValidator}
          />
        </div>
      </div>

      <div className="fixed_moda_footer_wrapper">
        <button
          className="deeptravel-button deeptravel-button--header-buttons deeptravel-button--secondary  "
          onClick={() => setShowUpdatePopup(false)}
        >
          Cancel
        </button>
        <button
          className="deeptravel-button deeptravel-button--header-buttons deeptravel-button--primary ms-3"
          onClick={() => handleSubmit()}
          disabled={submitLoading}
        >
          {submitLoading ? "Please wait..." : editId ? "Update" : "Create"}
        </button>
      </div>
    </div>
  );
}

export default PaidThroughAccountPopup;
