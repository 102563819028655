import { getValue } from "@utils/lodash";
import React from "react";

function VerifiedSvgComponent(props: any) {
  return (
    <div>
      <svg
        id="verified_black_24dp"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
      >
        <g id="Group_10257" data-name="Group 10257">
          <rect
            id="Rectangle_17576"
            data-name="Rectangle 17576"
            width="24"
            height="24"
            fill="none"
          />
        </g>
        <g id="Group_10259" data-name="Group 10259">
          <g id="Group_10258" data-name="Group 10258">
            <path
              id="Path_46887"
              data-name="Path 46887"
              d="M23,11.99,20.56,9.2l.34-3.69-3.61-.82L15.4,1.5,12,2.96,8.6,1.5,6.71,4.69,3.1,5.5l.34,3.7L1,11.99l2.44,2.79-.34,3.7,3.61.82L8.6,22.5,12,21.03l3.4,1.46,1.89-3.19,3.61-.82-.34-3.69Zm-3.95,1.48-.56.65.08.85.18,1.95-1.9.43-.84.19-.44.74-.99,1.68-1.78-.77-.8-.34-.79.34-1.78.77-.99-1.67L8,17.55l-.84-.19-1.9-.43.18-1.96.08-.85-.56-.65L3.67,12l1.29-1.48.56-.65-.09-.86L5.25,7.07l1.9-.43.84-.19.44-.74.99-1.68,1.78.77.8.34.79-.34,1.78-.77.99,1.68.44.74.84.19,1.9.43-.18,1.95-.08.85.56.65,1.29,1.47Z"
              fill={
                getValue(props, `color`, "")
                  ? getValue(props, `color`, "")
                  : "#fd9933"
              }
            />
            <path
              id="Path_46888"
              data-name="Path 46888"
              d="M10.09,13.75,7.77,11.42,6.29,12.91l3.8,3.81,7.34-7.36L15.95,7.87Z"
              fill={
                getValue(props, `color`, "")
                  ? getValue(props, `color`, "")
                  : "#fd9933"
              }
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default VerifiedSvgComponent;
