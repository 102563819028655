import { NavLink } from "react-router-dom";
import "./location-navigation.scss";
import "./inner-page-navigation.scss";
import { getValue } from "@utils/lodash";
import BackSvgComponent from "@assets/svg-components/back";

export default function RolesNavigation(props: any) {
  return (
    <div className="common-sticky-header" style={{ zIndex: "7" }}>
      <section className="inner-page-navigation-with-back-cta-buttons position-relative">
        <div className="container-fluid  d-flex justify-content-between align-items-center py-1">
          <div className="d-flex">
            <NavLink
              to={getValue(props, `url`, "") ? getValue(props, `url`, "") : `#`}
            >
              <BackSvgComponent />
            </NavLink>
            <div className="inner-page-navigation-with-back-cta-button__header">
              <h4 className="inner-page-navigation-with-back-cta-button__header-title">
                {getValue(props, `title`, "")}
              </h4>
            </div>
          </div>
          {!getValue(props, `hideHeaderMenu`, false) && (
            <ul className="inner-page-navigation-with-back-cta-buttons__navigation d-inline-flex position-absolute">
              {!props.id && (
                <li className="inner-page-navigation-with-back-cta-buttons__nav-list-item">
                  <NavLink
                    to={`/admin/user-controls/roles/add`}
                    className="inner-page-navigation-with-back-cta-buttons__nav-list-item-link"
                  >
                    {getValue(props, `headerTitle`, "")
                      ? getValue(props, `headerTitle`, "")
                      : "Create User"}
                  </NavLink>
                </li>
              )}
              {props.id && props.id !== "undefined" && (
                <>
                  <li className="inner-page-navigation-with-back-cta-buttons__nav-list-item">
                    <NavLink
                      to={`/admin/user-controls/roles/details/${props.id}`}
                      className="inner-page-navigation-with-back-cta-buttons__nav-list-item-link"
                    >
                      Update Role
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
          )}
          {getValue(props, `permissions`, []).includes("update") ? (
            <div className="d-flex align-items-center gap-2">
              <NavLink to={props.url}>
                <button className="deeptravel-button deeptravel-button--header-buttons deeptravel-button--secondary">
                  Cancel
                </button>
              </NavLink>
              {props.isLoading ? (
                <button className="deeptravel-button deeptravel-button--header-buttons deeptravel-button--primary">
                  Please wait...
                </button>
              ) : (
                <button
                  className={`deeptravel-button deeptravel-button--header-buttons deeptravel-button--${
                    !props.isChanged ? "primary" : "secondary"
                  }`}
                  onClick={props.handleSubmit}
                  disabled={props.isChanged}
                >
                  {props.id ? "Update" : "Save"}
                </button>
              )}
            </div>
          ) : (
            <button
              className={`deeptravel-button deeptravel-button--header-buttons deeptravel-button-secondary`}
            >
              .
            </button>
          )}
        </div>
      </section>
    </div>
  );
}
