import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllExpenseCategories = (queryRequest: string) =>
  get(`${config.API_URL}/expense-category?${queryRequest}`);

export const getSpecificExpenseCategory = (id: string) =>
  get(`${config.API_URL}/expense-category/${id}`);

export const createExpenseCategory = (payload: object) =>
  post(`${config.API_URL}/expense-category`, payload);

export const updateExpenseCategory = (id: string, payload: object) =>
  patch(`${config.API_URL}/expense-category/${id}`, payload);

export const deleteExpenseCategory = (id: string) =>
  Delete(`${config.API_URL}/expense-category/${id}`);

export const updateExpenseCategoryBulk = (payload: object) =>
  patch(`${config.API_URL}/expense-category/bulk/active-inactive`, payload);
