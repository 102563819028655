import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import ModulesNavigation from "../common/sub-header";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { deleteTravelDocument } from "@services/settings.service";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import { getSpecificUser } from "@services/users.service";
import "./index.scss";
import { convertCurrentDate, convertDate } from "@common/date-helpers";
import PersonSvgComponent from "@assets/svg-components/person";
import NoAccessPage from "@components/common/NoAccess";
import Loader from "@components/common/Loader";
import { getMyProfile } from "@services/users.service";
function SettingsBasicInformation(props: any) {
  let navigate = useNavigate();

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const [isLoading, setIsLoading] = React.useState(true);
  const [list, setList] = React.useState<[]>([]);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const handleOpenPopup = () => {
    setShowUpdatePopup(!showUpdatePopup);
  };

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length === 0 && props.permissionAPITriggered) {
      getData();
    }
  }, [props.permissionAPITriggered]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length > 0 && props.permissionAPITriggered) {
      getData();
    }
  }, [window.location.href, props.permissionAPITriggered]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [userInfo, setUserInfo] = useState({});
  const getData = async () => {
    let user = JSON.parse(getValue(localStorage, `user`, {}));
    // const urlSearchParams = new URLSearchParams(window.location.search);
    // const params = Object.fromEntries(urlSearchParams.entries());
    // let payload: any = {
    //   page_no: params.page_no ? parseInt(params.page_no) : 1,
    //   page_size: params.limit ? parseInt(params.limit) : limit,
    //   search_text: params.search_text,
    //   // status: params.status,
    //   sort_by: params.sort_by,
    // };
    if (getValue(user, `id`, ""))
      try {
        setIsLoading(true);
        let resp = await getMyProfile('');
        if (resp) {
          setIsLoading(false);
          setUserInfo(getValue(resp, `data`, {}));
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
  };


  /* -------------------------------------------------------------------------- */
  /*                                 Delete section                             */
  /* -------------------------------------------------------------------------- */

  const [deleteValue, setDeleteValue] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const handleDeleteFun = (id: string, value: string) => {
    setDeleteValue(value);
    setDeleteId(id);
    handleDelete();
  };
  const [isDelete, setIsDelete] = useState(false);
  const handleDelete = () => {
    setIsDelete(!isDelete);
  };
  const handleDeleteFunction = async () => {
    try {
      let resp = await deleteTravelDocument(deleteId);
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        handleDelete();
        getData();
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      {!isLoading &&
      !getValue(props, `subTabPermissionList`, []).includes(
        "settings_personal_information"
      ) ? (
        <NoAccessPage />
      )  : (
        <>
          <div className="dashboard-wrapper__header d-flex align-items-center justify-content-between">
            <h4 className="dashbaord-wrapper__header-title">
              Personal Information
            </h4>
          </div>
          <div className="personal-information_container">
            <div className="personalInfo__main_container">
              <div className="personalInfo__body_container">
                <div className="header">
                  {/* <div className="d-flex align-items-center"> */}
                    {/* <PersonSvgComponent /> */}
                    <p> Personal Details</p>
                  {/* </div> */}
                </div>
                <div className="personalInfo__container">
                  <div className="column">
                    <div className="personalInfo__container_labelfields_wrap">
                      {/* <div className="w-50"> */}
                        <p className="personalInfo__container_label">Name</p>
                      {/* </div>
                      <div className="w-50"> */}
                        {/* <p className="header_text__16 ">
                    {getValue(userInfo, `first_name`, "")}{" "}
                    {getValue(userInfo, `middle_name`, "")}{" "}
                    {getValue(userInfo, `last_name`, "")}
                  </p> */}
                        <p className="personalInfo__container_value ">
                          {getValue(userInfo, `first_name`, "")}{" "}
                          {getValue(userInfo, `middle_name`, "")}{" "}
                          {getValue(userInfo, `last_name`, "")}{" "}
                        </p>
                      {/* </div> */}
                    </div>
                    <div className="personalInfo__container_labelfields_wrap">
                      {/* <div className="w-50"> */}
                        <p className="personalInfo__container_label">Email Address</p>
                      {/* </div> */}
                      <p className="personalInfo__container_value ">
                        {getValue(userInfo, `email`, "-")}
                      </p>
                    </div>

                    <div className="personalInfo__container_labelfields_wrap">
                      {/* <div className="w-50"> */}
                        <p className="personalInfo__container_label">Employee ID</p>
                      {/* </div> */}
                      <p className="personalInfo__container_value ">
                        {getValue(userInfo, `employee_id`, "-")}
                      </p>
                    </div>
                    <div className="personalInfo__container_labelfields_wrap">
                      {/* <div className="w-50"> */}
                        <p className="personalInfo__container_label">Mobile</p>
                      {/* </div> */}
                      <p className="personalInfo__container_value ">
                        {getValue(userInfo, `mobile_number`, "-")}
                      </p>
                    </div>
                    <div className="personalInfo__container_labelfields_wrap">
                      {/* <div className="w-50"> */}
                        <p className="personalInfo__container_label">Department</p>
                      {/* </div> */}
                      <p className="personalInfo__container_value ">
                        {getValue(userInfo, `department.department_name`, "-")}
                      </p>
                    </div>
                    <div className="personalInfo__container_labelfields_wrap">
                      {/* <div className="w-50"> */}
                        <p className="personalInfo__container_label">
                          Date of joining
                        </p>
                      {/* </div> */}
                      <p className="personalInfo__container_value ">
                        {getValue(userInfo, `date_of_joining`, "-")?convertCurrentDate(
                          getValue(userInfo, `date_of_joining`, "-")
                        ):'-'}
                      </p>
                    </div>
                    <div className="personalInfo__container_labelfields_wrap">
                      {/* <div className="w-50"> */}
                        <p className="personalInfo__container_label">Date of birth</p>
                      {/* </div> */}
                      <p className="personalInfo__container_value ">
                        {getValue(userInfo, `date_of_birth`, "")?convertCurrentDate(
                          getValue(userInfo, `date_of_birth`, "")
                        ):'-'}
                      </p>
                    </div>
                    <div className="personalInfo__container_labelfields_wrap">
                      {/* <div className="w-50"> */}
                        <p className="personalInfo__container_label">Designation</p>
                      {/* </div> */}
                      <p className="personalInfo__container_value ">
                        {getValue(userInfo, `designation.text`, "-")}
                      </p>
                    </div>
                  </div>
                  <div className="column">
                    <div className="personalInfo__container_labelfields_wrap">
                      {/* <div className="w-50"> */}
                        <p className="personalInfo__container_label">Role</p>
                      {/* </div> */}
                      <p className="personalInfo__container_value ">
                        {getValue(userInfo, `role.title`, "-")}
                      </p>
                    </div>
                    <h6 className="header_text__14 mt-2">ASSOCIATED POLICIES</h6>
                    <div className="personalInfo__container_labelfields_wrap mt-3">
                      {/* <div className="w-50"> */}
                        <p className="personalInfo__container_label">Policy</p>
                      {/* </div> */}
                      <p className="personalInfo__container_value ">
                        {getValue(userInfo, `policy.policy_name`, "-")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
}

export default SettingsBasicInformation;
