import HeaderLayout from "@layouts/withoutNaviagtion/withoutNavigationLayout";
import React, { useState } from "react";
import CabSearch from "../components/OnlineBooking/CabSearch";
import CabFilter from "../components/CabFilter/CabFIlter";
import { Link } from "react-router-dom";

const filteredCabs = [
  {
    image: "/images/dashboard/trips/online/etios.png",
    car: "Toyota Etios",
  },
  {
    image: "/images/dashboard/trips/online/crysta.png",
    car: "Toyota Innova",
  },
  {
    image: "/images/dashboard/trips/online/ertiga.png",
    car: "Maruti Ertiga",
  },
  {
    image: "/images/dashboard/trips/online/etios.png",
    car: "Toyota Etios",
  },
  {
    image: "/images/dashboard/trips/online/crysta.png",
    car: "Toyota Innova",
  },
  {
    image: "/images/dashboard/trips/online/ertiga.png",
    car: "Maruti Ertiga",
  },
  {
    image: "/images/dashboard/trips/online/crysta.png",
    car: "Toyota Innova",
  },
  {
    image: "/images/dashboard/trips/online/crysta.png",
    car: "Toyota Innova",
  },
];

const FilteredTransport = () => {
  const [activeTab, setActiveTab] = useState("Inclusions");

  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
    const element = document.getElementById(tabName);
    // if (element) {
    //   element.scrollIntoView({ behavior: "smooth" });
    // }
  };

  return (
    <HeaderLayout>
      <div className="d-flex justify-content-center position-relative online-booking-wrapper py-4">
        <CabSearch value1="Banglore" value2="Goa" />
      </div>
      <div className="container-lg d-flex">
        <CabFilter />
        <div className="p-5 w-100">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h3 className="dashbaord-wrapper__header-title">
                110 cars found
              </h3>
              <p className="gray-color">
                Total price excludes taxes + fees . Other fees may apply.
              </p>
            </div>
            <div className="calender-wrapper">
              <img src="/images/dashboard/trips/online/filter.svg" />
              <span>Popularity</span>
              <img
                src="/images/dashboard/trips/online/arrow_drop_down.svg"
                className="ps-5"
              />
            </div>
          </div>
          <div className="d-flex gap-3 my-4">
            <div className="cab-options cab-selected">
              <span className="color_primary header_text__14">SUV</span>
              <img src="/images/dashboard/trips/online/cross.png" />
            </div>
            <div className="cab-options cab-selected">
              <span className="color_primary header_text__14">
                40,000 to 60,000{" "}
              </span>
              <img src="/images/dashboard/trips/online/cross.png" />
            </div>
            <div className="cab-options cab-selected">
              <span className="color_primary header_text__14"> Just Deals</span>
              <img src="/images/dashboard/trips/online/cross.png" />
            </div>
          </div>
          <div>
            <div className="mb-4 filtered-property">
              <div className="  w-100   p-4">
                <div className="d-flex justify-content-between mb-3">
                  <div className=" d-flex gap-4">
                    <div>
                      <img src="/images/dashboard/trips/online/etios.png" />
                    </div>
                    <div>
                      <Link
                        to="/client/trips/online-booking/transport/booking"
                        className="text-black"
                      >
                        <h5 className="text-black">Toyota Etios</h5>
                      </Link>
                      <p className="gray-color header_text__14">
                        Includes AC Cabs like Amaze, Dzire, Fiesta, Manza
                      </p>
                      <button className="deeptravel-button deeptravel-button--secondary deeptravel-button--header-buttons mt-3">
                        Reserve Selection
                      </button>
                    </div>
                  </div>
                  <div className="d-flex gap-4">
                    <div>
                      <div className="d-flex gap-1 header_text__16">
                        <span className="original-price">₹10,000</span>
                        <span className="text-danger">₹9,000</span>
                      </div>
                      <p className="gray-color header_text__12 mt-1 text-end">
                        Upto 253 km
                      </p>
                    </div>
                    <img
                      src="/images/dashboard/trips/online/chevron-up.svg"
                      className="arrows-container cursor-pointer"
                    />
                  </div>
                </div>
                <div className="travel-offers pt-3">
                  <div className="d-flex  gap-5  w-100 mb-3">
                    {[
                      "Inclusions",
                      "Exclusions",
                      "Facilities",
                      "Terms & Conditions",
                    ].map((tab) => (
                      <div
                        key={tab}
                        className={`cursor-pointer position-relative ${
                          activeTab === tab ? " color_primary" : ""
                        }`}
                        onClick={() => handleTabClick(tab)}
                      >
                        <h6
                          className={`cursor-pointer header_text__16 ${
                            activeTab === tab
                              ? " color_primary tab-active"
                              : "tab-notactive"
                          }`}
                        >
                          {tab}
                        </h6>
                      </div>
                    ))}
                  </div>
                  {activeTab === "Inclusions" && (
                    <div className="d-flex gap-3">
                      <div>
                        <img
                          src="/images/dashboard/trips/property-details/playlist_checked.svg"
                          alt="tag"
                          className="border border-radius__8 p-2"
                        />
                      </div>
                      <div>
                        <h6>Inclusions</h6>
                        <ul className="custom-tick mt-1 d-flex flex-column">
                          <li className="ticked-list">Base fare for 253 km</li>
                          <li className="ticked-list">Driver allowance</li>
                          <li className="ticked-list">State tax & toll</li>
                          <li className="ticked-list">GST (5%)</li>
                        </ul>
                      </div>
                    </div>
                  )}
                  {activeTab === "Exclusions" && (
                    <div className="d-flex gap-3">
                      <div>
                        <img
                          src="/images/dashboard/trips/property-details/playlist_remove.svg"
                          alt="tag"
                          className="border border-radius__8 p-2"
                        />
                      </div>
                      <div>
                        <h6>Exclusions</h6>
                        <ul className="custom-tick mt-1 d-flex flex-column">
                          <li className="ticked-list">
                            Pay ₹13.5/km after 253 km
                          </li>
                          <li className="ticked-list">
                            Multiple pickups/drops
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                  {activeTab === "Facilities" && (
                    <div className="d-flex gap-3">
                      <div>
                        <img
                          src="/images/dashboard/trips/property-details/label_important.svg"
                          alt="tag"
                          className="border border-radius__8 p-2"
                        />
                      </div>
                      <div>
                        <h6>Facilities</h6>
                        <ul className="custom-tick mt-1 d-flex flex-column">
                          <li className="ticked-list">4 Seater</li>
                          <li className="ticked-list">2 Airbags</li>
                          <li className="ticked-list">Air conditioned</li>
                        </ul>
                      </div>
                    </div>
                  )}
                  {activeTab === "Terms & Conditions" && (
                    <div className="d-flex gap-3">
                      <div>
                        <img
                          src="/images/dashboard/trips/property-details/manage_accounts.svg"
                          alt="tag"
                          className="border border-radius__8 p-2"
                        />
                      </div>
                      <div>
                        <h6>Terms & Conditions</h6>
                        <ul className="custom-tick mt-1 d-flex flex-column">
                          <li className="ticked-list">
                            Your trip has a KM limit. If your usage exceeds this
                            limit, you will be charged for the excess KM used.
                          </li>
                          <li className="ticked-list">
                            Your trip includes one pick up in Pick up city and
                            one drop in destination city. It does not include
                            within city travel.
                          </li>
                          <li className="ticked-list">
                            If your trip has hill climbs, cab AC may be switched
                            off during such climbs.
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {filteredCabs.map((cab, index) => (
              <div key={index} className="mb-4 filtered-property">
                <div className="d-flex  w-100  justify-content-between p-4">
                  <div className=" d-flex gap-4">
                    <div>
                      <img src={cab.image} />
                    </div>
                    <div>
                      <Link
                        to="/client/trips/online-booking/transport/booking"
                        className="text-black"
                      >
                        <h5 className="text-black">{cab.car}</h5>
                      </Link>
                      <p className="gray-color header_text__14">
                        Includes AC Cabs like Amaze, Dzire, Fiesta, Manza
                      </p>
                      <div className="d-flex align-items-center gap-3 mt-4 header_text__14 gray-color">
                        <div className="d-flex align-items-center gap-1">
                          <img src="/images/dashboard/trips/online/seater.png" />{" "}
                          4 Seater
                        </div>
                        <div className="d-flex align-items-center gap-1">
                          <img src="/images/dashboard/trips/online/trolley.png" />
                          2-3 Bags
                        </div>
                        <div className="d-flex align-items-center gap-1">
                          <img src="/images/dashboard/trips/online/air-conditioning.png" />
                          Air Conditioned
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex gap-4">
                    <div>
                      <div className="d-flex gap-1 header_text__16">
                        <span className="original-price">₹10,000</span>
                        <span className="text-danger">₹9,000</span>
                      </div>
                      <p className="gray-color header_text__12 mt-1 text-end">
                        Upto 253 km
                      </p>
                    </div>
                    <img
                      src="/images/dashboard/trips/online/expand_more.png"
                      className="arrows-container cursor-pointer"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-end my-5">
            <div className="d-flex justify-content-center align-items-center gap-3 gray-color">
              <span className="color_primary">1</span>
              <span>2</span>
              <span>3</span>
              <span>4</span>
              <span>5</span>
              <span className="color_primary">Next</span>
              <div className="calender-wrapper ">
                <span>09 per page</span>
                <img
                  src="/images/dashboard/trips/online/arrow_drop_down.svg"
                  className="ps-5"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </HeaderLayout>
  );
};

export default FilteredTransport;
