import DownloadSvgComponent from "@assets/svg-components/download";
import ReloadSvgComponent from "@assets/svg-components/reload";
import ExpenseSvgComponent from "@assets/svg-components/sidebar/expense";
import { getColorStyle } from "@common/color";
import { handleDownloadOnClick } from "@common/downloadfile";
import { formatText } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { Tooltip } from "react-tooltip";

function ReportFileLogsTable(props: any) {
  const dummy = () => {};
  return (
    <div>
      <div>
        <table className="datatable_table">
          <thead className="datatable_table_thead">
            <th className="datatable_table_th" style={{ width: "70px" }}></th>
            <th className="datatable_table_th">
              <h6>Job ID</h6>
            </th>
            <th className="datatable_table_th">
              <h6>Report Type</h6>
            </th>
            <th className="datatable_table_th">
              <h6>Report Name</h6>
            </th>
            <th className="datatable_table_th">
              <h6>Report Description</h6>
            </th>
            <th className="datatable_table_th">
              <h6>Status</h6>
            </th>

            <th className="datatable_table_th"></th>
          </thead>
          <tbody className="datatable_table_tbody">
            {getValue(props, `list`, []).map((item: any, index: number) => {
              return (
                <tr key={index} className="datatable_table_tr">
                  <td className="datatable_table_td" style={{ width: "70px" }}>
                    <ExpenseSvgComponent color={"#fabb2d"} size={22} />
                  </td>
                  <td className="datatable_table_td align-items-center">
                    <h6 className=" color_primary">
                      {getValue(item, `job_id`, "-")}
                    </h6>
                  </td>
                  <td className="datatable_table_td align-items-center">
                    <h6>{formatText(getValue(item, `report_type`, "-"))}</h6>
                  </td>

                  <td className="datatable_table_td align-items-center">
                    <h6>{getValue(item, `report_name`, "-")}</h6>
                  </td>
                  <td className="datatable_table_td align-items-center">
                    <h6>{getValue(item, `report_description`, "-")}</h6>
                  </td>
                  <td className="datatable_table_td align-items-center">
                    <h6>{formatText(getValue(item, `queue_status`, "-"))}</h6>
                  </td>
                  <td
                    className="datatable_table_td align-items-center"
                    onClick={() =>
                      getValue(item, `report_url`, "")
                        ? handleDownloadOnClick(
                            getValue(item, `report_url`, ""),
                            getValue(item, `job_id`, "")
                          )
                        : dummy()
                    }
                  >
                    {getValue(item, `report_url`, "") ? (
                      <div className="d-flex align-items-center">
                        <DownloadSvgComponent color="#1966A1" size={26} />
                        <p className="small_text__14 color_primary">DOWNLOAD</p>
                      </div>
                    ) : (
                      <div
                        onClick={() => props.getData()}
                        className="cursor-pointer"
                      >
                        <ReloadSvgComponent color={"#276EF1"} />
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Tooltip id="my-tooltip" place="bottom" />
      <Tooltip id="my-tooltip-document" place="bottom" />
    </div>
  );
}

export default ReportFileLogsTable;
