import { getValue } from "@utils/lodash";
import React from "react";
import "./index.scss";
import FlightSvgComponent from "@assets/svg-components/trips/flight";
import TripsArrowSvgComponent from "@assets/svg-components/trips/tripsarrow";
import {
  findValueFromOptions,
  formatText,
  sortJSONObjectArray,
} from "@common/text-helpers";
import {
  airTravelClass,
  mealType,
  seatType,
  timePreferences,
} from "@common/constants";
import Notfound from "@components/Not-Found";
import NotfoundSmall from "@components/Not-Found/not-found-small";
import { convertCurrentDate } from "@common/date-helpers";
function TripsFlightItinary(props: any) {
  return (
    <div className="itenary_main_section">
      <div className="itenary_main_section_cards_holder">
        {getValue(props, `flightList.length`, 0) > 0 ? (
          sortJSONObjectArray(getValue(props, `flightList`, []), "order").map(
            (item: object, index: number) => {
              return (
                <div
                  className="flight_trip_itenary border border-radius__8 position-relative"
                  key={index}
                >
                  <div className="d-flex align-items-center justify-content-center flight_trip_itenary_tripname_wrapper">
                    <div className="itenary-flight-icon-wrapper">
                      <FlightSvgComponent size={24} />
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <div className="d-flex align-items-center">
                          <h6 className="header_text__16 flight_trip_itenary_tripname">
                            {getValue(item, `flight.departure_city`, "")}
                          </h6>
                          <h6 className="header_text__16 flight_trip_itenary_tripname">
                            &nbsp;-{" "}
                            {getValue(
                              item,
                              `flight.departure_airport_code`,
                              ""
                            )}
                          </h6>
                        </div>
                        <p className="flight_trip_itenary_tripname_airportname">
                          {getValue(item, `flight.departure_airport_name`, "")}
                        </p>
                      </div>
                      <div className="flight_trip_itenary_centerarrow_holder">
                        <TripsArrowSvgComponent />
                      </div>
                      <div>
                        <div className="d-flex align-items-center">
                          <h6 className="header_text__16 flight_trip_itenary_tripname">
                            {getValue(item, `flight.arrival_city`, "")}
                          </h6>
                          <h6 className="header_text__16 flight_trip_itenary_tripname">
                            &nbsp;-{" "}
                            {getValue(item, `flight.arrival_airport_code`, "")}
                          </h6>
                        </div>
                        <p className="flight_trip_itenary_tripname_airportname">
                          {getValue(item, `flight.arrival_airport_name`, "")}
                        </p>
                      </div>
                    </div>
                    {index === 0 && (
                      <div className="position-absolute trip_info">
                        <p>
                          {formatText(getValue(item, `air_travel_type`, ""))}
                        </p>
                      </div>
                    )}
                    <div></div>
                  </div>
                  <div className="dashed-border"></div>
                  <div className="d-flex align-items-center justify-content-center flight_trip_itenary_flight_contentdetails_wrapper">
                    <div className="flight_trip_itenary_flight_contentdetails">
                      <h6 className="header_text__14">
                        {convertCurrentDate(
                          getValue(item, `flight.departure_date`, "")
                        )}
                        <span className="dot-holder"></span>
                        {findValueFromOptions(
                          timePreferences,
                          getValue(item, `flight.time_preference`, "")
                        )}
                      </h6>
                      <p className="small_text flight_itenary_details_label">
                        Date & Preferred Time
                      </p>
                    </div>
                    <div className="flight_trip_itenary_flight_contentdetails">
                      <h6 className="header_text__14">
                        {getValue(item, `flight.class`, "") ? (
                          findValueFromOptions(
                            airTravelClass,
                            getValue(item, `flight.class`, "")
                          )
                        ) : (
                          <p className="text-center">{"-"}</p>
                        )}
                      </h6>
                      <p className="small_text flight_itenary_details_label">
                        Flight Preference
                      </p>
                    </div>
                    <div className="flight_trip_itenary_flight_contentdetails">
                      <h6 className="header_text__14">
                        {getValue(item, `flight.seat_type`, "") ? (
                          findValueFromOptions(
                            seatType,
                            getValue(item, `flight.seat_type`, "")
                          )
                        ) : (
                          <p className="text-center">{"-"}</p>
                        )}
                      </h6>
                      <p className="small_text flight_itenary_details_label">
                        Seat Preference
                      </p>
                    </div>
                    <div className="flight_trip_itenary_flight_contentdetails">
                      <h6 className="header_text__14">
                        {getValue(item, `flight.meal_type`, "") ? (
                          findValueFromOptions(
                            mealType,
                            getValue(item, `flight.meal_type`, "")
                          )
                        ) : (
                          <p className="text-center">{"-"}</p>
                        )}
                      </h6>
                      <p className="small_text flight_itenary_details_label">
                        Meal Preference
                      </p>
                    </div>
                  </div>
                  {getValue(item, `description`, "") && (
                    <div
                      style={{ background: "#E8F0FE" }}
                      className="p-2 mt-3 text-center"
                    >
                      <p style={{ color: "#204891" }} className="small_text">
                        Description: &nbsp;
                        {getValue(item, `description`, "")}
                      </p>
                    </div>
                  )}
                </div>
              );
            }
          )
        ) : (
          <NotfoundSmall />
        )}
      </div>
    </div>
  );
}

export default TripsFlightItinary;
