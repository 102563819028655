import * as React from "react";
import "./index.scss";
interface INotFoundPageProps {}

const Loader: React.FunctionComponent<INotFoundPageProps> = (props) => {
	return (
		<div className="loading-wrapper  d-flex w-100 my-5">
			<div className="loading-wrapper__details">
				<img src="/gif/loading.gif" className="loading-image img-fluid" />
			</div>
		</div>
	);
};

export default Loader;
