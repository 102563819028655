import AdminSettingsApproval from "@pages/Admin/Settings/Approval";
import ApprovalPreferences from "@pages/Admin/Settings/Approval/Preferences";
import PrferenceApprovalDetailPage from "@pages/Admin/Settings/Approval/Preferences/Detail";
import SettingsBasicInformation from "@pages/Admin/Settings/BasicInformation";
import SettingsTravelDocuments from "@pages/Admin/Settings/TravelDocuments";

export const AdminSettingsRoutes = [
  {
    path: "/admin/settings/approval",
    name: "admin_settings_approval_flows",
    component: AdminSettingsApproval,
  },

  {
    path: "/admin/settings/approval/preferences/:name",
    name: "admin_settings_approval_flows",
    component: ApprovalPreferences,
  },
  {
    path: "/admin/settings/approval/preferences/:name/detail",
    name: "admin_settings_approval_flows",
    component: PrferenceApprovalDetailPage,
  },
  {
    path: "/admin/settings/approval/preferences/:name/detail/:id",
    name: "admin_settings_approval_flows",
    component: PrferenceApprovalDetailPage,
  },
  {
    path: "/admin/settings/basic-information",
    name: "admin_list_management_choice_list",
    component: SettingsBasicInformation,
  },
  {
    path: "/admin/settings/travel-documents",
    name: "admin_settings_travel_documents",
    component: SettingsTravelDocuments,
  },
];
