import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import { useNavigate } from "react-router-dom";
import { QueryRequestHelper } from "@common/query-request-helper";
import { deleteSpecificExpenseEntry } from "@services/expense-entry.service";
import { getValue } from "@utils/lodash";
import { toast } from "react-toastify";
import DeleteModal from "@common/popup/DeleteModal";
import PaginationPage from "@components/pagination/pagination";
import NavigationBar from "@components/NotificationBar";
import SearchHeader from "@components/common/SearchHeader";
import Loader from "@components/common/Loader";
import Notfound from "@components/Not-Found";
import ReportsTable from "./table";
import {
  createExpenseReport,
  getAllExpenseFormReport,
  getAllExpenseReports,
  getAllExpenseReportsAdmin,
} from "@services/expense-reports.service";
import SimpleReactValidator from "simple-react-validator";
import ReportsPopup from "../../../components/Reports/components/report-add-popup";
import { FormRequestHelper } from "@components/common/FormBuilder/request-helper";
import {
  formatText,
  removeNullOrUndefinedProperties,
} from "@common/text-helpers";
import NoAccessPage from "@components/common/NoAccess";
import GearLoader from "@components/common/GearLoader";
import { adminReportsDownload } from "@services/upload.service";
import SkeletonTable from "@components/common/Skeleton/skeleton-table";

function AdminReports(props: any) {
  let navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  let mainQueryRequest = QueryRequestHelper(urlParams);

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const [isLoading, setIsLoading] = React.useState(true);
  const [list, setList] = React.useState<[]>([]);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const handleOpenPopup = () => {
    setShowUpdatePopup(!showUpdatePopup);
  };

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (Object.keys(urlParams).length === 0 && props.permissionAPITriggered) {
      getData();
      getFormData();
    }
  }, [props.permissionAPITriggered]);

  useEffect(() => {
    if (Object.keys(urlParams).length > 0 && props.permissionAPITriggered) {
      getData();
      getFormData();
      if (urlParams.page_no) {
        setPage_no(parseInt(urlParams.page_no));
      }
      if (urlParams.search_text) {
        setSearchInput(urlParams.search_text);
        setShowSearchTextbox(true);
      }
      if (urlParams.limit) {
        setLimit(parseInt(urlParams.limit));
      }
    }
  }, [window.location.href, props.permissionAPITriggered]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const getData = async () => {
    let payload: any = {
      page_no: urlParams.page_no ? parseInt(urlParams.page_no) : 1,
      page_size: urlParams.limit ? parseInt(urlParams.limit) : limit,
      search_text: urlParams.search_text,
      filter_type: "list",
      sort_by: urlParams.sort_by,
      // approval_status: getValue(urlParams, `approval_status`, ""),
      approval_status: urlParams.approval_status
        ? urlParams.approval_status
        : "approved",
    };
    let queryRequest = QueryRequestHelper(payload);
    try {
      setIsLoading(true);
      let resp = await getAllExpenseReportsAdmin(queryRequest);
      if (resp) {
        setList(getValue(resp, `data.expense_reports`, []));
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Search section                               */
  /* -------------------------------------------------------------------------- */
  const [showSearchTextbox, setShowSearchTextbox] = useState(false);
  const [search_text, setSearchInput] = useState<any>("");

  const toggleSearch = () => {
    setShowSearchTextbox((prevState) => !prevState);
  };
  const toggleSearchClose = () => {
    setSearchInput("");
    toggleSearch();
    if (urlParams.search_text) {
      delete urlParams.search_text;
      let payload: any = { ...urlParams };
      let queryRequest = QueryRequestHelper(payload);

      navigate(`${window.location.pathname}?${queryRequest}`);
      getData();
    }
  };
  const handleChangeSearch = async (e: any) => {
    setSearchInput(e.target.value);

    let payload: any = {
      ...urlParams,
      page_no: 1,
      search_text: e.target.value,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    if (!e.target.value) {
      getData();
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const [selectedIDs, setSelectedIDs] = useState<any>([]);
  const handleCheckUsers = (id: string) => {
    let userIDs =
      getValue(selectedIDs, `length`, 0) > 0
        ? selectedIDs.map((item: string) => item)
        : [];
    if (userIDs.includes(id)) {
      let filterIDs = selectedIDs.filter((item: string) => item !== id);
      setSelectedIDs(filterIDs);
    } else {
      selectedIDs.push(id);
      setSelectedIDs([...selectedIDs]);
    }
  };
  const handleCheckAll = (status: string) => {
    if (status === "All") {
      setSelectedIDs([]);
    } else {
      let userIDs =
        getValue(list, `length`, 0) > 0
          ? list.map((item: object) => getValue(item, `id`, ""))
          : [];
      setSelectedIDs(userIDs);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Delete section                             */
  /* -------------------------------------------------------------------------- */

  const [deleteValue, setDeleteValue] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const handleDeleteFun = (id: string, value: string) => {
    setDeleteValue(value);
    setDeleteId(id);
    handleDelete();
  };
  const [isDelete, setIsDelete] = useState(false);
  const handleDelete = () => {
    setIsDelete(!isDelete);
  };
  const handleDeleteFunction = async () => {
    try {
      let resp = await deleteSpecificExpenseEntry(deleteId);
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        handleDelete();
        getData();
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    let payload = {
      ...urlParams,
      page_no: page_no,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  const handleChangeLimit = (limit: string) => {
    let payload: any = {
      ...urlParams,
      limit: limit,
      page_no: "1",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  const handleChangeActiveStatus = async (value: string) => {
    // try {
    //   let resp = await adminBulkUpdateStatus({
    //     ids: selectedIDs,
    //     is_active: value === "active" ? true : false,
    //   });
    //   if (resp) {
    //     toast.success(getValue(resp, `message`, ""));
    //     setSelectedIDs([]);
    //     getData();
    //   } else {
    //     getData();
    //   }
    // } catch (error) {}
  };
  const [check, setCheck] = useState(false);
  const handleRemoveNavItems = () => {
    setCheck(!check);
    setSelectedIDs([]);
  };
  const handleNavigateUserEntity = () => {
    if (getValue(selectedIDs, `[${0}]`, "")) {
      navigate(
        `/admin/user/entity-mapping/${getValue(selectedIDs, `[${0}]`, "")}`
      );
    } else {
      toast.error("Please select User");
    }
  };
  const handleNavigate = (id: string) => {
    navigate(`/admin/reports/detail/${id}?${mainQueryRequest}`);
  };
  /* -------------------------------------------------------------------------- */
  /*                                 Create section                             */
  /* -------------------------------------------------------------------------- */
  const getFormData = async () => {
    let payload: any = {};
    let queryRequest = QueryRequestHelper(payload);
    try {
      setIsLoading(true);
      let resp = await getAllExpenseFormReport(queryRequest);
      if (resp) {
        setFormInfo(getValue(resp, `data`, {}));
        setForm(getValue(resp, `data.form_layout`, []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [form, setForm] = React.useState<[]>([]);
  const [formInfo, setFormInfo] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setSubmitLoading(true);
        let fields = JSON.parse(JSON.stringify(form));
        let payload = FormRequestHelper(fields);
        let requestPayload: any = payload;
        let resp = await createExpenseReport(
          removeNullOrUndefinedProperties(requestPayload)
        );
        if (resp) {
          toast.success(getValue(resp, `message`, ""));
          handleOpenPopup();
          setForm([]);
          setFormInfo({});
          getFormData();
          getData();
          if (getValue(resp, `data.id`, "")) {
            navigate(
              `/admin/reports/detail/${getValue(
                resp,
                `data.id`,
                ""
              )}?${mainQueryRequest}`
            );
          } else {
            navigate(`/admin/reports?${mainQueryRequest}`);
          }
          simpleValidator.current.hideMessages();
          forceUpdate(0);
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Filter section                             */
  /* -------------------------------------------------------------------------- */
  const handleNavigateURL = (key: string, value: string) => {
    if (value) {
      let payload = {
        ...urlParams,
        [key]: value,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
    } else {
      delete urlParams[key];
      let payload = {
        ...urlParams,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
      getData();
    }
  };
  const [filterOptions] = useState([
    { value: "unsubmitted", label: formatText("unsubmitted") },
    { value: "awaiting_approval", label: formatText("awaiting_approval") },
    { value: "approved", label: formatText("approved") },
    { value: "rejected", label: formatText("rejected") },
    { value: "reimbursed", label: formatText("reimbursed") },
    { value: "expired", label: formatText("expired") },
    { value: "archived", label: formatText("archived") },
  ]);
  const dropdownOptions = [{ label: "Reports", value: "EXPENSE_REPORT" }];

  const handleDownload = async () => {
    try {
      let payload = {
        report_name: `expense-report`,
        report_description: "expense-report",
        approval_status: getValue(urlParams, `approval_status`, ""),
      };
      let resp = await adminReportsDownload(
        removeNullOrUndefinedProperties(payload)
      );
      if (resp) {
        navigate(`/report-file-logs/ADMIN_EXPENSE_REPORTS`);
      }
    } catch (error) {}
  };
  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      {!isLoading &&
      !getValue(props, `subTabPermissionList`, []).includes("admin_reports") ? (
        <NoAccessPage />
      ) : (
        <>
          <div className="dashboard-wrapper__header">
            <h4 className="dashbaord-wrapper__header-title">Reports</h4>
            <div className="d-flex justify-content-end align-items-center gap-2">
              {getValue(selectedIDs, `length`, 0) > 0 && (
                <NavigationBar
                  selectedIDs={selectedIDs}
                  // isMarkInactiveSectionVisible={true}
                  handleChangeActiveStatus={handleChangeActiveStatus}
                  handleRemoveNavItems={handleRemoveNavItems}
                  multi={
                    // getValue(props, `permissions`, []).includes("update")
                    //   ? true
                    //   : false
                    false
                  }
                  permissions={getValue(props, `permissions`, [])}
                  entityMapping={
                    getValue(props, `products`, []).includes("SELFCOMP")
                      ? true
                      : false
                  }
                  handleNavigateUserEntity={handleNavigateUserEntity}
                />
              )}
              <div className="admin-wrapper position-relative">
                <div className="admin-wrapper position-relative">
                  <SearchHeader
                    route={"/admin/reports/add"}
                    add={"false"}
                    searchInput={search_text}
                    showSearchTextbox={showSearchTextbox}
                    toggleSearch={toggleSearch}
                    toggleSearchClose={toggleSearchClose}
                    handleChangeSearch={handleChangeSearch}
                    // add={
                    //   getValue(props, `permissions`, []).includes("create")
                    //     ? "true"
                    //     : "false"
                    // }
                    // isAdd
                    handleAddFun={handleOpenPopup}
                    reportFilter
                    filterOptions={filterOptions}
                    handleNavigateURL={handleNavigateURL}
                    filter_key={"approval_status"}
                    commonUpload={
                      getValue(props, `permissions`, []).includes("update")
                        ? true
                        : false
                    }
                    dropdownOptions={dropdownOptions}
                    downloadURL={
                      "https://asccuriodata.s3.ap-south-1.amazonaws.com/documents/Bulk_Reimbursement_Import_Template.xlsx"
                    }
                    download_with_logs={
                      getValue(props, `permissions`, []).includes("update")
                        ? true
                        : false
                    }
                    handleDownload={handleDownload}
                    download_type={"ADMIN_EXPENSE_REPORTS"}
                    log_name={"ADMIN_EXPENSE_REPORTS"}
                    report_logs
                  />
                </div>
              </div>
            </div>
          </div>

          {isLoading || !props.permissionAPITriggered ? (
            // <GearLoader />
            <SkeletonTable />
          ) : (
            <>
              {getValue(list, `length`, 0) > 0 ? (
                <div className="datatable-main-wrapper">
                  <div
                    className={
                      getValue(selectedIDs, `length`, 0) > 0
                        ? "datatable_main_container_notifiction"
                        : "datatable_main_container"
                    }
                    style={{ overflowY: isLoading ? "hidden" : "auto" }}
                  >
                    <ReportsTable
                      list={list}
                      selectedIDs={selectedIDs}
                      handleCheckUsers={handleCheckUsers}
                      handleCheckAll={handleCheckAll}
                      isLoading={isLoading}
                      handleDeleteFun={handleDeleteFun}
                      handleNavigate={handleNavigate}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <Notfound />
                </>
              )}
            </>
          )}
          <div className="fixed_footer_wrapper d-flex justify-content-end">
            {/* {list.length > 0 && (
              <img
                src="/images/building-illustration.svg"
                className="img-fluid"
              />
            )} */}
            {totalCount > 10 ? (
              <PaginationPage
                totalCount={totalCount}
                limit={limit}
                pageNumber={page_no}
                handleChangePagination={handleChangePagination}
                handleChangeLimit={handleChangeLimit}
              />
            ) : (
              <p className="show-rows-count d-flex">
                Total Count:&nbsp;&nbsp;{" "}
                <p style={{ color: "rgb(13, 110, 253)" }}>{totalCount}</p>
              </p>
            )}
          </div>
          <DeleteModal
            isOpen={isDelete}
            handleModal={handleDelete}
            handleSubmit={handleDeleteFunction}
            deleteValue={deleteValue}
          />
          <ReportsPopup
            showUpdatePopup={showUpdatePopup}
            setShowUpdatePopup={setShowUpdatePopup}
            form={form}
            setForm={setForm}
            formInfo={formInfo}
            simpleValidator={simpleValidator}
            handleSubmit={handleSubmit}
            submitLoading={submitLoading}
          />
        </>
      )}
    </DashboardLayout>
  );
}

export default AdminReports;
