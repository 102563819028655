import EyeSvgComponent from "@assets/svg-components/eye";
import FileLogSvgComponent from "@assets/svg-components/file-log";
import { getValue } from "@utils/lodash";
import React from "react";
import { useNavigate } from "react-router-dom";

function LogsScreen(props: any) {
  const navigate = useNavigate();

  const handleNavigate = (name: string) => {
    navigate(`/file-logs/${name}`);
  };
  return (
    <div
      className="cursor-pointer filterbar-icon-button-holder"
      // ms-3 
      onClick={() => handleNavigate(getValue(props, `log_name`, ""))}
    >
      <div className="text-center">
      {/* ms-1 */}
        <FileLogSvgComponent size={24} color="#5f6368" />
      </div>
      <p className="small_text__12">{"LOGS"}</p>
    </div>
  );
}

export default LogsScreen;
