import { getValue } from "@utils/lodash";
import React from "react";

function FlightSvgComponent(props: any) {
  return (
    <div>
      <svg
        id="flight_black_24dp"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
      >
        <path
          id="Path_46309"
          data-name="Path 46309"
          d="M0,0H24V24H0Z"
          fill="none"
        />
        <path
          id="Path_46310"
          data-name="Path 46310"
          d="M21,16V14L13,9V3.5a1.5,1.5,0,0,0-3,0V9L2,14v2l8-2.5V19L8,20.5V22l3.5-1L15,22V20.5L13,19V13.5Z"
          fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "#fd9933"
          }
        />
      </svg>
    </div>
  );
}

export default FlightSvgComponent;
