import HeaderLayout from "@layouts/withoutNaviagtion/withoutNavigationLayout";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PropertyImages from "../components/PropertyDetails/PropertyImages";
import "../trips.scss";
import { Link } from "react-router-dom";
import "./property-details.scss";
import PropertyOverview from "../components/PropertyDetails/PropertyOverview";
import PropertyRooms from "../components/PropertyDetails/PropertyRooms";
import PropertyReview from "../components/PropertyDetails/PropertyReview";
import PropertyPhotoes from "../components/PropertyDetails/PropertyPhotoes";
import PropertyRules from "../components/PropertyDetails/PropertyRules";

const filteredProperty = [
  {
    property_name: "1",
    image: "/images/dashboard/trips/online/property-1.png",
  },
  {
    property_name: "2",
    image: "/images/dashboard/trips/online/property-2.png",
  },
  {
    property_name: "3",
    image: "/images/dashboard/trips/online/property-3.png",
  },
  {
    property_name: "4",
    image: "/images/dashboard/trips/online/property-4.png",
  },
  {
    property_name: "5",
    image: "/images/dashboard/trips/online/property-5.png",
  },
  {
    property_name: "6",
    image: "/images/dashboard/trips/online/property-6.png",
  },
];

const amenities = [
  "1 swimming pool",
  "Airport shuttle",
  "Tea/Coffee Maker in All Rooms",
  "Bar",
  "Spa",
  "Very good breakfast",
  "Good fitness center",
  "Laptop-friendly workspace",
  "Room service",
  "Free parking",
  "Restaurant",
];

function PropertyDetails() {
  const { property_name } = useParams();
  const property = filteredProperty.find(
    (property) => property.property_name === property_name
  );
  const [activeTab, setActiveTab] = useState("Overview");

  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
  };

  return (
    <HeaderLayout>
      <div className="d-flex justify-content-center gap-5 py-3 property-nav ">
        {["Overview", "Rooms", "Review", "Photos", "House Rules"].map((tab) => (
          <div
            key={tab}
            className={`cursor-pointer position-relative ${
              activeTab === tab ? " color_primary" : ""
            }`}
            onClick={() => handleTabClick(tab)}
          >
            <h6
              className={`cursor-pointer header_text__16 ${
                activeTab === tab
                  ? " color_primary tab-active"
                  : "tab-notactive"
              }`}
            >
              {tab}
            </h6>
          </div>
        ))}
        <div
          className="d-flex gap-3 align-items-center position-absolute left-0"
          style={{ top: "14px", left: "20px" }}
        >
          <img
            src="/images/back-link.svg"
            onClick={() => window.history.back()}
            className="cursor-pointer"
          />
        </div>
      </div>
      {activeTab === "Overview" && <PropertyOverview amenities={amenities} />}
      {activeTab === "Rooms" && <PropertyRooms amenities={amenities} />}
      {activeTab === "Review" && <PropertyReview />}
      {activeTab === "Photos" && <PropertyPhotoes images={filteredProperty} hideHeading={false}/>}
      {activeTab === "House Rules" && <PropertyRules />}
    </HeaderLayout>
  );
}

export default PropertyDetails;
