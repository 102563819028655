import React from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import AddSvgComponent from "@assets/svg-components/add";

export default function TripsNotfound() {
  let navigate = useNavigate();

  const handleNavigateAdd = () => {
    navigate(`/client/trips/add`);
  };

  return (
    <>
      <div className="not-found-wrapper d-flex w-100 align-items-center justify-content-center new-not-found-wrapper">
        <div className="not-found-wrapper__details text-center">
          <img
            src="/images/notfound/trips-not-found.svg"
            className="not-found-image img-fluid trips-image"
          />
          <p className="header_text__19 text-center not-found-text">
            No Records Found. <br /> Start your journey by adding your first trip request now!
          </p>

          <button className="btn btn-primary btn-add-on_not_found" onClick={handleNavigateAdd}>
            <span className="d-flex align-items-center">
              <AddSvgComponent color={"#fff"} size={20} />
              Submit Request
            </span>
          </button>
        </div>
      </div>
    </>
  );
}
