import { getValue } from "@utils/lodash";
import React from "react";

function ReportsSvgComponent(props: any) {
  return (
    <>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        // width="18"
        // height="18"
        viewBox="0 0 18 18"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"
        }
      >
        <g id="topic_black_24dp" transform="translate(0.037 0.037)">
          <rect
            id="Rectangle_17159"
            data-name="Rectangle 17159"
            width="18"
            height="18"
            transform="translate(-0.037 -0.037)"
            fill="none"
          />
          <path
            id="Path_46272"
            data-name="Path 46272"
            d="M15.445,5.494H9.469L7.976,4H3.494A1.492,1.492,0,0,0,2.007,5.494L2,14.457a1.5,1.5,0,0,0,1.494,1.494H16.02a.923.923,0,0,0,.919-.919V6.988A1.5,1.5,0,0,0,15.445,5.494Zm0,8.963H3.494V5.494H7.356L8.85,6.988h6.6ZM13.951,9.976H4.988V8.482h8.963Zm-2.988,2.988H4.988V11.469h5.976Z"
            transform="translate(-0.506 -1.012)"
            // fill="#3c4043"
            fill={
              getValue(props, `color`, "")
                ? getValue(props, `color`, "")
                : "#3c4043"
            }
          />
        </g>
      </svg> */}

      <svg xmlns="http://www.w3.org/2000/svg"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"
        }
      viewBox="0 0 18 18">
        <g id="topic_black_24dp" transform="translate(0.037 0.037)">
          <rect id="Rectangle_17159" data-name="Rectangle 17159" width="18" height="18" transform="translate(-0.037 -0.037)" fill="none"/>
          <path id="Path_46272" data-name="Path 46272" d="M15.445,5.494H9.469L7.976,4H3.494A1.492,1.492,0,0,0,2.007,5.494L2,14.457a1.5,1.5,0,0,0,1.494,1.494H16.02a.923.923,0,0,0,.919-.919V6.988A1.5,1.5,0,0,0,15.445,5.494Zm0,8.963H3.494V5.494H7.356L8.85,6.988h6.6ZM13.951,9.976H4.988V8.482h8.963Zm-2.988,2.988H4.988V11.469h5.976Z" transform="translate(-0.506 -1.012)" fill={
              getValue(props, `color`, "")
                ? getValue(props, `color`, "")
                : "#3c4043"
            } />
        </g>
      </svg>
    </>
  );
}

export default ReportsSvgComponent;
