import HeaderLayout from "@layouts/withoutNaviagtion/withoutNavigationLayout";
import React, { useState } from "react";
import "../online/trip-online.scss";
import HotelSearch from "../components/OnlineBooking/HotelSearch";
import CabSearch from "../components/OnlineBooking/CabSearch";
import InputField from "@common/InputRuleFeild";
import Checkbox from "@components/common/Forms/Checkbox";
import BestSelling from "./BestSelling";
import ThemeDestination from "./ThemeDestination";

// import {  } from "swiper/modules";

const bookingType = [
  {
    value: "div1",
    label: "Hotels",
    image: "/images/dashboard/trips/online/hotel.svg",
    content: <HotelSearch />,
  },
  {
    value: "div2",
    label: "Cabs",
    image: "/images/dashboard/trips/online/local-taxi.svg",
    content: <CabSearch />,
  },
  {
    value: "div3",
    label: "Flights",
    image: "/images/dashboard/trips/online/flight.svg",
    content: <div>Search for flights</div>,
  },
];

const OnlineBooking = () => {
  const [selectedOption, setSelectedOption] = useState("div1");

  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value);
  };


  return (
    <HeaderLayout>
      <div className="d-flex justify-content-center position-relative online-booking-wrapper pb-5">
        <img
          src="/images/dashboard/trips/online/left-side.png"
          className="left-side-image"
        />
        <img
          src="/images/dashboard/trips/online/right-side.png"
          className="right-side-image"
        />
        <div className="d-flex flex-column align-items-center pt-5">
          <h1 className="text-center mb-2"> Travel</h1>
          <p className="text-center w-75 gray-color">
            Planning a trip takes fewer steps with TripKartz. Explore cheap
            cabs, hotels, & discover can’t-miss sights & attractions with a
            simple search.
          </p>
          <div className="d-flex gap-3 mt-4">
            {bookingType.map((option) => (
              <div key={option.value} className="radio-container">
                <input
                  type="radio"
                  id={option.value}
                  name="divOption"
                  value={option.value}
                  checked={selectedOption === option.value}
                  onChange={handleOptionChange}
                  className="custom-radio"
                />
                <label htmlFor={option.value}>
                  <div className="d-flex flex-column align-items-center justify-content-center option-selector">
                    <img src={option.image} alt={option.label} />
                    <p>{option.label}</p>
                  </div>
                </label>
              </div>
            ))}
          </div>
          <div className="mt-4">
            {
              bookingType.find((option) => option.value === selectedOption)
                ?.content
            }
          </div>
        </div>
      </div>
      <ThemeDestination/>
      <BestSelling/>
    </HeaderLayout>
  );
};

export default OnlineBooking;
