import { getValue } from "@utils/lodash";
import PenSvgComponent from "@assets/svg-components/pen";
import React from "react";
import { NavLink } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import BackSvgComponent from "@assets/svg-components/back";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { X } from "lucide-react";
import { Dropdown, Menu, MenuProps, Space } from "antd";

function StickyHeader(props: any) {
  const navigate = useNavigate();
  const { route } = props;
  const items = [];
  if (
    getValue(props, `permissions`, []).includes("delete") &&
    getValue(props, `formInfo.status`, "") === "associated"
  ) {
    items.push({
      key: "1",
      label: (
        <span
          onClick={() =>
            navigate(
              `/client/reports/detail/${getValue(
                props,
                `formInfo.expense_report.id`,
                ""
              )}`
            )
          }
        >
          Go to Report (
          {getValue(props, `formInfo.expense_report.report_name`, "")})
        </span>
      ),
    });
  }

  if (
    getValue(props, `permissions`, []).includes("delete") &&
    getValue(props, `formInfo.status`, "") === "not_associated"
  ) {
    items.push({
      key: "2",
      label: (
        <span
          onClick={() =>
            props.handleDeleteFun(getValue(props, `formInfo.id`, ""))
          }
        >
          Delete
        </span>
      ),
    });
  }

  const menu = <Menu items={items} />;

  return (
    <div className="common-sticky-header">
      <section className="inner-page-navigation-with-back-cta-buttons position-relative">
        <div className="container-fluid  d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-between align-items-center">
            <NavLink to={route ? `${route}` : "#"}>
              <X className="me-3" size={20} />
            </NavLink>
            <p className="header_text"> {getValue(props, `title`, "")}</p>
          </div>

          <div className="d-flex align-items-center p-1">
            {getValue(props, `permissions`, []).includes("update") &&
              getValue(props, `formInfo.status`, "") === "not_associated" && (
                <div className="header_square" onClick={props.handleEdit}>
                  <img
                    src="/images/edit-gray.svg"
                    className="header_square_image img-fluid"
                  />
                </div>
              )}
            <Dropdown overlay={menu}>
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <div className="header_square ms-2">
                    <img
                      src="/images/more-hz.svg"
                      className="header_square_image img-fluid"
                    />
                  </div>
                </Space>
              </a>
            </Dropdown>
            {/* <div className="more-dropdown-main align-items-center">
              <UncontrolledDropdown>
                <DropdownToggle>
                  <div className="header_square ms-2">
                    <img
                      src="/images/more-hz.svg"
                      className="header_square_image img-fluid"
                    />
                  </div>
                </DropdownToggle>
                <DropdownMenu>
                  {getValue(props, `permissions`, []).includes("delete") &&
                    getValue(props, `formInfo.status`, "") === "associated" && (
                      <DropdownItem>
                        <div
                          className={`more-dropdown-main-item `}
                          onClick={() =>
                            navigate(
                              `/client/reports/detail/${getValue(
                                props,
                                `formInfo.expense_report.id`,
                                ""
                              )}`
                            )
                          }
                        >
                          <p>
                            Go to Report (
                            {getValue(
                              props,
                              `formInfo.expense_report.report_name`,
                              ""
                            )}
                            )
                          </p>
                        </div>
                      </DropdownItem>
                    )}
                  {getValue(props, `permissions`, []).includes("delete") &&
                    getValue(props, `formInfo.status`, "") ===
                      "not_associated" && (
                      <DropdownItem
                        onClick={() =>
                          props.handleDeleteFun(
                            getValue(props, `formInfo.id`, "")
                          )
                        }
                      >
                        <div className={`more-dropdown-main-item `}>
                          <p>Delete</p>
                        </div>
                      </DropdownItem>
                    )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default StickyHeader;
