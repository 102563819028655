import ViolationSvgComponent from "@assets/svg-components/violation";
import { Info } from "lucide-react";

const WarningMessage = ({
  showInfo,
  infoHeading,
  infoMessage,
  infoIcon,
  infoChildren,
  showNoti,
  notiMessage,
  notiHeading,
  notiIcon,
  notiChildren,
}: any) => {
  return (
    <div className="position-sticky top-0" style={{ zIndex: "998" }}>
      {showNoti && (
        <div className="noti-warning d-flex align-items-center gap-4 justify-content-between">
          <div className="d-flex align-items-center gap-2">
            {notiIcon ? notiIcon : <Info size={18} color={"#212024"} />}
            <h6 className="">{notiHeading}</h6>
            <small className="">{notiMessage}</small>
          </div>
          {notiChildren && notiChildren}
        </div>
      )}
      {showInfo && (
        <div
          className={`info-warning d-flex align-items-center gap-4 justify-content-between ${
            showNoti && showInfo ? "my-2" : ""
          }`}
        >
          <div className="d-flex align-items-center gap-2">
            {infoIcon ? (
              infoIcon
            ) : (
              <ViolationSvgComponent size={18} color={"#212024"} />
            )}
            <h6 className="">{infoHeading}</h6>
            <small className="">{infoMessage}</small>
          </div>
          {infoChildren && infoChildren}
        </div>
      )}
    </div>
  );
};

export default WarningMessage;
