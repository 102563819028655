import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropertyPhotoes from "../PropertyDetails/PropertyPhotoes";

const filteredProperty = [
  {
    property_name: "1",
    image: "/images/dashboard/trips/online/property-1.png",
  },
  {
    property_name: "2",
    image: "/images/dashboard/trips/online/property-2.png",
  },
  {
    property_name: "3",
    image: "/images/dashboard/trips/online/property-3.png",
  },
  {
    property_name: "4",
    image: "/images/dashboard/trips/online/property-4.png",
  },
  {
    property_name: "5",
    image: "/images/dashboard/trips/online/property-5.png",
  },
  {
    property_name: "6",
    image: "/images/dashboard/trips/online/property-6.png",
  },
];

const amenities = [
  "1 swimming pool",
  "Airport shuttle",
  "Tea/Coffee Maker in All Rooms",
  "Bar",
  "Spa",
  "Very good breakfast",
  "Good fitness center",
  "Laptop-friendly workspace",
  "Room service",
  "Free parking",
  "Restaurant",
];

function ReviewSelection({ hotelDetails }) {
  const [activeTab, setActiveTab] = useState(
    hotelDetails ? "Options" : "Inclusions"
  );

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className=" d-flex flex-column align-items-center gap-4 mb-5 pt-4">
      <div className="w-75 d-flex justify-content-between">
        <div>
          {hotelDetails ? (
            <div className="d-flex gap-3 align-items-center small_text__14">
              <span className="gray-color">2 Nights</span>
              <div className="dot-seprator"></div>
              <span className="gray-color">2 Adults</span>
              <div className="dot-seprator"></div>
              <span className="gray-color">1 Child</span>
            </div>
          ) : (
            <p className="small_text__14">Compact</p>
          )}
          <h2>{hotelDetails ? "3 Rooms" : "2 Cars"}</h2>
          {hotelDetails ? (
            <p className="small_text__14">Hotel Grand Godwin</p>
          ) : (
            <div className="d-flex gap-3 align-items-center small_text__14">
              <span className="gray-color">Toyota Etios</span>
              <div className="dot-seprator"></div>
              <span className="gray-color">Maruti Ertiga</span>
            </div>
          )}
        </div>
        <div>
          <p className="small_text__14 text-end">Total price</p>
          <div className="d-flex justify-content-end align-items-center gap-2">
            <h2>{hotelDetails ? "₹9,500" : "₹19,500"}</h2>
            <img
              src="/images/dashboard/trips/online/expand-more.svg"
              className="cursor-pointer"
            />
          </div>
          <p className="color_primary cursor-pointer text-end">
            Hide Fare Breakup
          </p>
        </div>
      </div>
      <div className="w-75 mt-1">
        <p className="mb-2 header_text__18">Fare Summary</p>
        <div className=" border border-radius__8 p-4 d-flex flex-column gap-1">
          <div className="d-flex justify-content-between">
            <h6>TOTAL</h6>
            <h6>{hotelDetails ? "₹9,500" : "₹19,500"}</h6>
          </div>
          {hotelDetails ? (
            <>
              <div className="d-flex justify-content-between">
                <p className="small_text__14">
                  Base Fare (For 3 nights & all guests)
                </p>
                <p className="small_text__14">₹9,000</p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="small_text__14">Property Service Tax</p>
                <p className="small_text__14">₹200</p>
              </div>
            </>
          ) : (
            <div className="d-flex justify-content-between">
              <p className="small_text__14">Car Rental Fee</p>
              <p className="small_text__14">₹19,000</p>
            </div>
          )}
          <div className="d-flex justify-content-between">
            <p className="small_text__14">GST</p>
            <p className="small_text__14">₹300</p>
          </div>
          <div className="d-flex justify-content-between">
            <p className="small_text__14">Other charges</p>
            <p className="small_text__14">₹200</p>
          </div>
        </div>
        {hotelDetails ? (
          <>
            <div className="mt-3 border border-radius__8">
              <div className="p-4 d-flex justify-content-between">
                <div className="d-flex gap-3">
                  <img
                    src="/images/dashboard/trips/online/property-1.png"
                    alt="room"
                    className="property-thumbnail"
                  />
                  <div>
                    <h5>Superior Room with City View</h5>
                    <p className="small_text__14 mt-1">1 Queen Bed</p>
                    <div className="d-flex align-items-center gap-3 small_text__14 mt-3">
                      <h6 className="color_primary cursor-pointer header_text__14">
                        Change selection
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="d-flex gap-3 align-items-start">
                  <div>
                    <h6 className="color_primary cursor-pointer header_text__16">
                      2 Rooms Selected
                    </h6>
                  </div>
                  <img
                    src="/images/dashboard/trips/online/chevron-up.svg"
                    alt="room"
                    className="cursor-pointer"
                  />
                </div>
              </div>
              <div className="popular-amenities p-4">
                <div className="d-flex  gap-5  w-100 mb-3">
                  {["Options", "Room Details", "Photos"].map((tab) => (
                    <div
                      key={tab}
                      className={`cursor-pointer position-relative ${
                        activeTab === tab ? " color_primary" : ""
                      }`}
                      onClick={() => handleTabClick(tab)}
                    >
                      <h6
                        className={`cursor-pointer header_text__16 ${
                          activeTab === tab
                            ? " color_primary tab-active"
                            : "tab-notactive"
                        }`}
                      >
                        {tab}
                      </h6>
                    </div>
                  ))}
                </div>
                {activeTab === "Options" && (
                  <div className="d-flex justify-content-between">
                    <div className="d-flex gap-3">
                      <div>
                        <img
                          src="/images/dashboard/trips/property-details/tags.svg"
                          alt="tag"
                          className="border border-radius__8 p-2"
                        />
                      </div>
                      <div>
                        <h6>Option 1</h6>
                        <p className="small_text__14">
                          Sleeps 3 People, Including upto 1 child
                        </p>
                        <ul className="custom-tick mt-2 d-flex flex-column">
                          <li className="ticked-list">Free Wifi</li>
                          <li className="ticked-list">
                            Breakfast available, pay at property
                          </li>
                          <li className="ticked-list">
                            Upto 2 children stay free
                          </li>
                          <li className="ticked-list">
                            Free cancellation until 6 Sept, 2020
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="d-flex flex-column gap-1">
                      <div className="d-flex gap-1 header_text__16 justify-content-end">
                        <span className="original-price">₹10,000</span>
                        <span className="text-danger">₹9,000</span>
                      </div>
                      <p className="small_text__14 mt-1 text-end">
                        +₹ 330 and Charges
                      </p>
                      <p className="small_text__14 mt-1 text-end">
                        2 Nights, 2 Adults, 1 Child
                      </p>
                      <div className="d-flex gap-4">
                        <div className="d-flex gap-2 align-items-center cursor-pointer">
                          <img
                            src="/images/dashboard/trips/online/radio_button_checked.svg"
                            width={20}
                          />
                          <p className="small_text__14">1 King Bed</p>
                        </div>
                        <div className="d-flex gap-2 align-items-center cursor-pointer">
                          <img
                            src="/images/dashboard/trips/online/radio_button_unchecked.svg"
                            width={20}
                          />
                          <p className="small_text__14">2 Twin Beds</p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end align-items-center gap-2">
                        <p className="small_text__14">Select Room(s)</p>
                        <div
                          className="passengers-container cursor-pointer"
                          style={{ height: "36px", width: "70px" }}
                        >
                          02
                          <img
                            src="/images/dashboard/trips/online/arrow_drop_down.png"
                            alt="Arrow"
                          />
                        </div>
                      </div>
                      <Link
                        className="deeptravel-button deeptravel-button--secondary deeptravel-button--header-buttons mt-3 d-flex justify-content-center"
                        // onClick={() =>
                        //   navigate("/client/trips/online-booking/properties/booking")
                        // }
                        to="/client/trips/online-booking/properties/booking"
                      >
                        Reserve Selection
                      </Link>
                    </div>
                  </div>
                )}
                {activeTab === "Room Details" && (
                  <div className="">
                    <div className="d-flex gap-3">
                      <div>
                        <img
                          src="/images/dashboard/trips/property-details/bedroom_parent.svg"
                          alt="tag"
                          className="border border-radius__8 p-2"
                        />
                      </div>
                      <div>
                        <h6>Room Size 31m2</h6>
                        <p className="small_text__14 mb-3">
                          This double room has a bathrobe, soundproofing and
                          mini-bar. Inclusions: Early Check-In by 09:00 am and
                          late check- out by 16:00 Hrs (Subject to availability)
                          Up to 2 children below 6 years is complimentary. Extra
                          person will be charged INR 1500 plus tax per room per
                          night. Rates are exclusive of taxes.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex gap-3 w-100">
                      <div>
                        <img
                          src="/images/dashboard/trips/property-details/filter_frames.svg"
                          alt="tag"
                          className="border border-radius__8 p-2"
                        />
                      </div>
                      <div>
                        <h6 className="header_text__16 mb-1">
                          Popular Amenities
                        </h6>
                        <ul className="custom-tick">
                          {amenities.map((amenities, index) => (
                            <li key={index} className="ticked-list">
                              {amenities}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === "Photos" && (
                  <PropertyPhotoes
                    hideHeading={true}
                    images={filteredProperty}
                  />
                )}
              </div>
            </div>
            <div className=" border border-radius__8 mt-3 mb-5">
              <div className="p-4 d-flex justify-content-between">
                <div className="d-flex gap-3">
                  <img
                    src="/images/dashboard/trips/online/property-1.png"
                    alt="room"
                    className="property-thumbnail"
                  />
                  <div>
                    <h5>Deluxe Room with Lake View</h5>
                    <p className="small_text__14 mt-1">1 Queen Bed</p>
                    <div className="d-flex align-items-center gap-3 small_text__14 mt-3">
                      <p>Very Good Breakfast Included</p>
                      <div className="dot-seprator"></div>
                      <p>FREE Cancellation (Until Sun, 6 Sep, 2024)</p>
                      <div className="dot-seprator"></div>
                      <p>Travel Offer (15%Discount on Room Rate)</p>
                    </div>
                  </div>
                </div>
                <div className="d-flex gap-3 align-items-start">
                  <div>
                    <h6 className="color_primary">1 Room Selected</h6>
                  </div>
                  <img
                    src="/images/dashboard/trips/online/chevron-down.svg"
                    alt="room"
                    className="cursor-pointer"
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="my-4 filtered-property">
              <div className="  w-100   p-4">
                <div className="d-flex justify-content-between mb-3">
                  <div className=" d-flex gap-4">
                    <div>
                      <img src="/images/dashboard/trips/online/etios.png" />
                    </div>
                    <div>
                      <Link
                        to="/client/trips/online-booking/transport/booking"
                        className="text-black"
                      >
                        <h5 className="text-black">Toyota Etios</h5>
                      </Link>
                      <p className="gray-color header_text__14">
                        Includes AC Cabs like Amaze, Dzire, Fiesta, Manza
                      </p>
                      <div className="d-flex gap-3 align-items-center small_text__14 mt-3">
                        <span className="gray-color">Bangalore to Goa</span>
                        <div className="dot-seprator"></div>
                        <span className="gray-color">
                          13th April 2024 at 7:00 AM
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex gap-4">
                    <div>
                      <div className="d-flex gap-1 header_text__16">
                        <span className="original-price">₹10,000</span>
                        <span className="text-danger">₹9,000</span>
                      </div>
                      <p className="gray-color header_text__12 mt-1 text-end">
                        Upto 253 km
                      </p>
                    </div>
                    <img
                      src="/images/dashboard/trips/online/chevron-up.svg"
                      className="arrows-container cursor-pointer"
                    />
                  </div>
                </div>
                <div className="travel-offers pt-3">
                  <div className="d-flex  gap-5  w-100 mb-3">
                    {[
                      "Inclusions",
                      "Exclusions",
                      "Facilities",
                      "Terms & Conditions",
                    ].map((tab) => (
                      <div
                        key={tab}
                        className={`cursor-pointer position-relative ${
                          activeTab === tab ? " color_primary" : ""
                        }`}
                        onClick={() => handleTabClick(tab)}
                      >
                        <h6
                          className={`cursor-pointer header_text__16 ${
                            activeTab === tab
                              ? " color_primary tab-active"
                              : "tab-notactive"
                          }`}
                        >
                          {tab}
                        </h6>
                      </div>
                    ))}
                  </div>
                  {activeTab === "Inclusions" && (
                    <div className="d-flex gap-3">
                      <div>
                        <img
                          src="/images/dashboard/trips/property-details/playlist_checked.svg"
                          alt="tag"
                          className="border border-radius__8 p-2"
                        />
                      </div>
                      <div>
                        <h6>Inclusions</h6>
                        <ul className="custom-tick mt-1 d-flex flex-column">
                          <li className="ticked-list">Base fare for 253 km</li>
                          <li className="ticked-list">Driver allowance</li>
                          <li className="ticked-list">State tax & toll</li>
                          <li className="ticked-list">GST (5%)</li>
                        </ul>
                      </div>
                    </div>
                  )}
                  {activeTab === "Exclusions" && (
                    <div className="d-flex gap-3">
                      <div>
                        <img
                          src="/images/dashboard/trips/property-details/playlist_remove.svg"
                          alt="tag"
                          className="border border-radius__8 p-2"
                        />
                      </div>
                      <div>
                        <h6>Exclusions</h6>
                        <ul className="custom-tick mt-1 d-flex flex-column">
                          <li className="ticked-list">
                            Pay ₹13.5/km after 253 km
                          </li>
                          <li className="ticked-list">
                            Multiple pickups/drops
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                  {activeTab === "Facilities" && (
                    <div className="d-flex gap-3">
                      <div>
                        <img
                          src="/images/dashboard/trips/property-details/label_important.svg"
                          alt="tag"
                          className="border border-radius__8 p-2"
                        />
                      </div>
                      <div>
                        <h6>Facilities</h6>
                        <ul className="custom-tick mt-1 d-flex flex-column">
                          <li className="ticked-list">4 Seater</li>
                          <li className="ticked-list">2 Airbags</li>
                          <li className="ticked-list">Air conditioned</li>
                        </ul>
                      </div>
                    </div>
                  )}
                  {activeTab === "Terms & Conditions" && (
                    <div className="d-flex gap-3">
                      <div>
                        <img
                          src="/images/dashboard/trips/property-details/manage_accounts.svg"
                          alt="tag"
                          className="border border-radius__8 p-2"
                        />
                      </div>
                      <div>
                        <h6>Terms & Conditions</h6>
                        <ul className="custom-tick mt-1 d-flex flex-column">
                          <li className="ticked-list">
                            Your trip has a KM limit. If your usage exceeds this
                            limit, you will be charged for the excess KM used.
                          </li>
                          <li className="ticked-list">
                            Your trip includes one pick up in Pick up city and
                            one drop in destination city. It does not include
                            within city travel.
                          </li>
                          <li className="ticked-list">
                            If your trip has hill climbs, cab AC may be switched
                            off during such climbs.
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mb-4 filtered-property">
              <div className="d-flex  w-100  justify-content-between p-4">
                <div className=" d-flex gap-4">
                  <div>
                    <img src="/images/dashboard/trips/online/ertiga.png" />
                  </div>
                  <div>
                    <Link
                      to="/client/trips/online-booking/transport/booking"
                      className="text-black"
                    >
                      <h5 className="text-black">Maruthi Ertiga</h5>
                    </Link>
                    <p className="gray-color header_text__14">
                      Includes AC Cabs like Amaze, Dzire, Fiesta, Manza
                    </p>
                    <div className="d-flex gap-3 align-items-center small_text__14 mt-3">
                      <span className="gray-color">Bangalore to Goa</span>
                      <div className="dot-seprator"></div>
                      <span className="gray-color">
                        13th April 2024 at 7:00 AM
                      </span>
                    </div>
                  </div>
                </div>
                <div className="d-flex gap-4">
                  <div>
                    <div className="d-flex gap-1 header_text__16">
                      <span className="original-price">₹10,000</span>
                      <span className="text-danger">₹9,000</span>
                    </div>
                    <p className="gray-color header_text__12 mt-1 text-end">
                      Upto 253 km
                    </p>
                  </div>
                  <img
                    src="/images/dashboard/trips/online/expand_more.png"
                    className="arrows-container cursor-pointer"
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ReviewSelection;
