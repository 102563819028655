import { getValue } from "@utils/lodash";
import React from "react";
import ExpenseUpload from "../DetailContainer/ExpenseUpload";
import DocumentImageList from "@common/FileUpload/document-image-list";
import { convertCurrentDate } from "@common/date-helpers";
import UploadLoader from "@components/common/UploadLoader";

function ExpenseDocumentSection(props: any) {
  const {
    documents,
    handleUploadDocuments,
    uploadLoading,
    handleRemoveDocuments,
    formInfo,
  } = props;
  return (
    <div>
      <div className="expense-detail-wrapper_report_documentsection">
        <div className="d-flex justify-content-end align-items-center">
          {getValue(documents, `length`, 0) < 5 && (
            <ExpenseUpload handleUploadDocuments={handleUploadDocuments} />
          )}
        </div>

        {uploadLoading ? (
          // <p className="text-center mt-3 mb-3">Uploading....</p>
          <div className="mt-5">
          <UploadLoader />
          </div>
        ) : getValue(documents, `length`, 0) === 0 ? (
          <p className="mt-5 mb-5 text-center">No document found</p>
        ) : (
          <div className="d-flex  mt-3" style={{ flexWrap: "wrap" }}>
            <DocumentImageList
              documents={documents}
              containerStayle={{ height: "100px", width: "150px" }}
              textContainerStayle={{ height: "40px", width: "150px" }}
              textStyle={{ fontSize: "12px" }}
              length={10}
              handleRemoveDocuments={handleRemoveDocuments}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ExpenseDocumentSection;
