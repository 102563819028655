import { getColorStyle } from "@common/color";
import {
  convertCurrentDate,
  convertCurrentDateWithTime,
} from "@common/date-helpers";
import {
  getFirstLetterOfEachString,
  sortJSONObjectArray,
} from "@common/text-helpers";
import { formatText } from "@common/text-helpers";
import { getValue } from "@utils/lodash";

function CommonCommentsHistoryPopup(props: any) {
  const { isOpen, toggle, list } = props;
  const getStepColors = (value: number) => {
    switch (value) {
      case 1:
        return "#FEF5E7";
      case 2:
        return "#FFEBD0";
      case 3:
        return "#FFDFB6";
      case 4:
        return "#FFD4A0";
      case 5:
        return "#FFC987";
      case 6:
        return "#FFBD71";
      case 7:
        return "#FFB25B";
      case 8:
        return "#FFA746";
      case 9:
        return "#FF9B30";
      case 10:
        return "#FF8F1A";

      default:
        return "#FF8F1A";
    }
  };
  return (
    <div
      className={`compelte-task-wrapper comment_history_modal_wrapper ${
        isOpen ? "compelte-task-wrapper--active" : ""
      }`}
      // style={{ width: "792px" }}
    >
      <div className="compelte-task-wrapper__header d-flex align-items-center justify-content-between">
        <h6 className="compelte-task-wrapper__title">History</h6>
        <button className="btn close-modal-btn" onClick={() => toggle()}>
          <img
            src="/images/close-white.svg"
            className="img-fluid navigation-bar-wrapper__close-btn" />
        </button>
      </div>
      <div className="approver_flow_main_container">
        {getValue(props, `list.length`, 0) > 0 ? (
          getValue(props, `list`, []).map((item: object) => {
            return (
              <div className="position-relative approver_flow_wrap">
                <div className="d-flex align-items-start">
                  <div
                    className="profile_circle"
                    style={{
                      background: "#FF8F1A",
                      border: "1px solid #FF8F1A",
                    }}
                  >
                    <h5 className="title" style={{ color: "white" }}>
                      {getFirstLetterOfEachString(
                        getValue(item, `approver.first_name`, "")
                      )}
                    </h5>
                  </div>
                  <div className="">
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="small_text__blue_14 approver_name">
                        {getValue(item, `approver.first_name`, "")}{" "}
                        {getValue(item, `approver.middle_name`, "")}{" "}
                        {getValue(item, `approver.last_name`, "")}
                      </p>
                    </div>
                    <div>
                      <p className="approver_time">
                        {convertCurrentDateWithTime(
                          getValue(item, `created_at`, "")
                        )}
                      </p>
                    </div>
                    <h6 className="approver_desc">
                      {getValue(item, `description`, "")}
                    </h6>
                  </div>
                </div>
                <h6
                  className="header_text status-poistion-absolute"
                  style={getColorStyle(getValue(item, `approval_status`, ""))}
                >
                  {formatText(getValue(item, `approval_status`, ""))}
                </h6>
              </div>
            );
          })
        ) : (
          <h6 className="header_text_blue text-center mt-5">
            No History Found
          </h6>
        )}
      </div>
    </div>
  );
}

export default CommonCommentsHistoryPopup;
