import {
  convertCurrentDate,
  convertCurrentDateWithTime,
} from "@common/date-helpers";
import { formatText, getFirstLetterOfEachString } from "@common/text-helpers";
import ProfileCircle from "@components/ProfileCircle";
import { getValue } from "@utils/lodash";
import React from "react";

function CustomerTable(props: any) {
  const { list } = props;
  return (
    <div>
      <table className="datatable_table">
        <thead className="datatable_table_thead">
          <th className="datatable_table_th" style={{ width: "70px" }}>
            <label className="m-0 position-relative" htmlFor="header-checkbox">
              <input
                type="checkbox"
                className="default-checkbox"
                id="header-checkbox"
                onChange={() =>
                  props.handleCheckAll(
                    getValue(props, `list.length`, 0) ===
                      getValue(props, `selectedIDs.length`, 0)
                      ? "All"
                      : "None"
                  )
                }
                checked={
                  getValue(props, `list.length`, 0) ===
                  getValue(props, `selectedIDs.length`, 0)
                }
              />
              <div className="custom-checkbox">
                <img
                  src="/images/uploaded-details/tick.svg"
                  className="img-fluid uploaded-tick"
                />
              </div>
            </label>
          </th>
          <th className="datatable_table_th">
            <h6 className="">Company Name</h6>
          </th>
          <th className="datatable_table_th">
            <h6 className="">Name</h6>
            <p className="department_tbody_p">Email Address</p>
          </th>
          <th className="datatable_table_th">
            <h6 className="">Phone Number</h6>
          </th>
          <th className="datatable_table_th">
            <h6 className="">Status</h6>
          </th>
          <th className="datatable_table_th">
            <h6 className="">Added On</h6>
          </th>
          {/* <th className="datatable_table_th">
            <h6 className="">Action</h6>
          </th> */}
        </thead>
        <tbody className="datatable_table_tbody">
          {getValue(list, `length`, 0) > 0 &&
            list.map((bodyObj: object, index: number) => {
              return (
                <tr>
                  <td className="datatable_table_td" style={{ width: "70px" }}>
                    <label
                      className="m-0 position-relative"
                      htmlFor={`header-checkbox-${index}`}
                    >
                      <input
                        type="checkbox"
                        className="default-checkbox"
                        id={`header-checkbox-${index}`}
                        onChange={() =>
                          props.handleCheckUsers(getValue(bodyObj, `id`, ""))
                        }
                        checked={getValue(props, `selectedIDs`, []).includes(
                          getValue(bodyObj, `id`, "")
                        )}
                      />
                      <div className="custom-checkbox">
                        <img
                          src="/images/uploaded-details/tick.svg"
                          className="img-fluid uploaded-tick"
                        />
                      </div>
                    </label>
                  </td>
                  <td
                    className="datatable_table_td"
                    onClick={() =>
                      props.handleNavigate(getValue(bodyObj, `id`, ""))
                    }
                  >
                    <h6 className="">
                      {formatText(getValue(bodyObj, `company_name`, ""))}
                    </h6>
                  </td>
                  <td
                    className="datatable_table_td d-flex align-items-center"
                    onClick={() =>
                      props.handleNavigate(getValue(bodyObj, `id`, ""))
                    }
                  >
                    <ProfileCircle
                      name="current_approver"
                      item={bodyObj}
                      value={getValue(bodyObj, `contact_display_name`, "")}
                    />
                    <div>
                      <h6 className="">
                        {formatText(
                          getValue(bodyObj, `contact_display_name`, "")
                        )}
                      </h6>{" "}
                      <p className="users_tbody_td_p">
                        {getValue(bodyObj, `email`, "")}
                      </p>
                    </div>
                  </td>
                  <td
                    className="datatable_table_td"
                    onClick={() =>
                      props.handleNavigate(getValue(bodyObj, `id`, ""))
                    }
                  >
                    <h6 className="">
                      {formatText(getValue(bodyObj, `phone_no`, ""))}
                    </h6>
                  </td>
                  <td
                    className="datatable_table_td"
                    onClick={() =>
                      props.handleNavigate(getValue(bodyObj, `id`, ""))
                    }
                  >
                    <h6 className="">
                      {getValue(bodyObj, `is_active`, false)
                        ? "Active"
                        : "Inactive"}
                    </h6>
                  </td>
                  <td className="datatable_table_td">
                    <h6 className="">
                      {getValue(bodyObj, `created_at`, null)
                        ? convertCurrentDateWithTime(
                            getValue(bodyObj, `created_at`, null)
                          )
                        : null}
                    </h6>
                  </td>
                  {/* <td className="datatable_table_td">
                    <div className="body-container d-flex">
                      <img
                        src="/images/list-management/country/edit-icon.svg"
                        className="img-fluid close-btn cursor-pointer"
                        style={{
                          height: "24px",
                          width: "24px",
                          // marginLeft: "30px",
                        }}
                        onClick={() =>
                          props.handleEditEntity(getValue(bodyObj, `id`, ""))
                        }
                      />
                    </div>
                  </td> */}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default CustomerTable;
