import React from "react";
import ReportTitleHeader from "./report-title-header";
import ReportTabNavigation from "./report-tab-navigation";
import ReportExpenseHeaderSection from "./report-expense-header";
import { getValue } from "@utils/lodash";
import ReportExpenseListSection from "./report-expense-list";
import { convertCurrentDate } from "@common/date-helpers";
import EyeSvgComponent from "@assets/svg-components/eye";
import ReimbursementTooltipOnHover from "@components/Reports/tooltip-on-hover";
import { Alert } from "reactstrap";
import WarningMessage from "@components/WarningMessages/WarningMessage";

function ReportsLeftSection(props: any) {
  const {
    formInfo,
    params,
    setShowUpdatePopup,
    showUpdatePopup,
    list,
    handleCheckUsers,
    selectedIDs,
    handleNavigate,
    handleDeleteFun,
    handleEditExpense,
    openGallery,
    hideExpenseHeader,
  } = props;
  console.log(formInfo, "---formInfo---");
  return (
    <div>
      <div className={"report-detail-wrapper_left_container "}>
        {getValue(formInfo, `is_policy_violated`, false) && (
          <WarningMessage
            showNoti
            notiHeading="You have violated the payment policy! You have exceeded the
            expense amount"
          />
        )}
        {getValue(formInfo, `approval_status`, "") === "reimbursed" && (
          <div className="px-3 pt-3">
            <div className="reimbursement_container bg-white border rounded-2">
              <div className="reimbursement_container_header p-3 d-flex align-items-center justify-content-between border-bottom">
                <h6 className="header_text__16 color_success">
                  Reimbursement details
                </h6>
                <ReimbursementTooltipOnHover
                  header={
                    <div>
                      <h6 className="header_text__16 color_success">
                        View Summary
                      </h6>
                    </div>
                  }
                  show
                  background={"#fff"}
                  width={"400px"}
                  formInfo={formInfo}
                />
              </div>
              <div className="d-flex justify-content-around p-3">
                <div>
                  <h6 className="header_text__16">
                    {convertCurrentDate(
                      getValue(formInfo, `reimbursement.created_at`, "")
                    )}
                  </h6>
                  <p className="small_text__14">Reimbursed Date:</p>
                </div>
                <div>
                  <h6 className="header_text__16">
                    {getValue(formInfo, `reimbursement.reference_number`, "")
                      ? getValue(formInfo, `reimbursement.reference_number`, "")
                      : "-"}
                  </h6>
                  <p className="small_text__14">Reference#:</p>
                </div>
                <div>
                  <h6 className="header_text__16">
                    {getValue(formInfo, `reimbursement.paid_through`, "")
                      ? getValue(formInfo, `reimbursement.paid_through`, "")
                      : "-"}
                  </h6>
                  <p className="small_text__14">Paid Through:</p>
                </div>
                <div>
                  <h6 className="header_text__16">
                    {getValue(formInfo, `reimbursement.note`, "")
                      ? getValue(formInfo, `reimbursement.note`, "")
                      : "-"}
                  </h6>
                  <p className="small_text__14">Notes:</p>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* <ReportTabNavigation length={getValue(list, `length`, 0)} /> */}
        <div className="px-5 pt-5">
          <div className=" mb-3 px-3 d-flex flex-column flex-xl-row justify-content-between  align-items-center">
            <div className="reports-more-details">
              Expenses ( {getValue(list, `length`, 0)} )
            </div>

            {getValue(props, `permissions`, []).includes("update") &&
              !hideExpenseHeader && (
                <ReportExpenseHeaderSection
                  params={params}
                  setShowUpdatePopup={setShowUpdatePopup}
                  showUpdatePopup={showUpdatePopup}
                  formInfo={formInfo}
                  disabled={
                    getValue(formInfo, `approval_status`, "") === "rejected" ||
                    getValue(formInfo, `approval_status`, "") ===
                      "awaiting_approval" ||
                    getValue(formInfo, `approval_status`, "") === "approved"
                      ? true
                      : false
                  }
                />
              )}
          </div>
          <div className="mt-2 px-3">
            <ReportExpenseListSection
              list={list}
              hideExpenseHeader={hideExpenseHeader}
              handleCheckUsers={handleCheckUsers}
              selectedIDs={selectedIDs}
              handleNavigate={handleNavigate}
              handleDeleteFun={handleDeleteFun}
              formInfo={formInfo}
              handleEditExpense={handleEditExpense}
              openGallery={openGallery}
              getList={props.getList}
              getAssociatedExpenseList={props.getAssociatedExpenseList}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportsLeftSection;
