import { getValue } from "@utils/lodash";
import React, { useState } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ImportPopup from "./importPopup";
import "../common/BulkUpload/bulk-upload.scss";
import UploadSvgComponent from "@assets/svg-components/upload";

function BulkUploadDropdown(props: any) {
  const { dropdownOptions, url, type } = props;
  const [selectedOption, setSelectedOption] = useState("");
  const [isBulkUploadPopupVisible, setBulkuploadPopupVisibility] = useState(
    false
  );
  return (
    <div className="filterbar-icon-button-holder">
      {/* mx-1 */}
      <UncontrolledDropdown className="common-dropdown">
        <DropdownToggle className="px-0 text-center">
          <div className="add-filter-wrapper__filter-container ms-0">
            <div className="cursor-pointer">
              <UploadSvgComponent color='#5f6368 '/>
              <p className="small_text__12">{"UPLOAD"}</p>
            </div>
          </div>
        </DropdownToggle>
        <DropdownMenu>
          {getValue(dropdownOptions, `length`, 0) > 0 &&
            dropdownOptions.map((item: object) => {
              return (
                <DropdownItem
                  onClick={() => {
                    setSelectedOption(getValue(item, `value`, ""));
                    setBulkuploadPopupVisibility(true);
                  }}
                >
                  {getValue(item, `label`, "")}
                </DropdownItem>
              );
            })}
        </DropdownMenu>
      </UncontrolledDropdown>
      {isBulkUploadPopupVisible && (
        <ImportPopup
          url={url}
          type={selectedOption}
          setBulkuploadPopupVisibility={setBulkuploadPopupVisibility}
          isBulkUploadPopupVisible={isBulkUploadPopupVisible}
          setSelectedOption={setSelectedOption}
        />
      )}
    </div>
  );
}

export default BulkUploadDropdown;
