import * as React from "react";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import { getValue } from "@utils/lodash";
import Lottie from "lottie-react";
import bin from "@lottie/bin.json";
import SingleSelectSearchDropdown from "@components/common/CustomSearchableDropdown/SingleSelectSearchDropdown";

interface IReportSubmitModalProps {
  isOpen: boolean;
  handleModal: () => void;
  handleSubmit: () => void;
}
const ReportSubmitModal = (props: any) => {
  return (
    <Modal
      isOpen={getValue(props, `isOpen`, "")}
      //   toggle={handleModal}
    >
      <ModalHeader style={{ background: "#f8f8fd" }}>
        <h6 className="header_text__18">Submit Report</h6>
      </ModalHeader>
      <div className="m-4">
        <SingleSelectSearchDropdown
          label={"label"}
          name="Select an approver for the report *"
          value={props.approverId}
          onChange={(e: any) => props.setApproverId(getValue(e, `id`, ""))}
          placeholder={"Choose Approver"}
          width={"100%"}
          lookup_api="users"
        />
      </div>
      <ModalFooter>
        <div className="delete-popup-wrapper__cta-wrapper">
          <button
            onClick={() => {
              props.handleModal();
              props.setApproverId("");
            }}
            className="delete-popup-wrapper__cta-btn delete-popup-wrapper__cta-btn--secondary"
          >
            Cancel
          </button>
          <button
            className="delete-popup-wrapper__cta-btn delete-popup-wrapper__cta-btn--primary"
            onClick={()=>props.handleSubmit('')}
            disabled={props.submitLoading}
          >
            {props.submitLoading ? "Please wait..." : "Submit"}
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ReportSubmitModal;
