import React, { useEffect, useRef, useState } from "react";
import "./search.scss";
import AddSvgComponent from "@assets/svg-components/add";
import MinusSvgComponent from "@assets/svg-components/minus";

function Passengers() {
  const [showPopover, setShowPopover] = useState(false);
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const [adultCount, setAdultCount] = useState(1);
  const [childCount, setChildCount] = useState(0);
  const [childAges, setChildAges] = useState<number[]>([]);
  const [totalCount, setTotal] = useState(1);

  const handleButtonClick = () => {
    setShowPopover(!showPopover);
  };

  const handleAdultIncrement = () => {
    setAdultCount((prevCount) => prevCount + 1);
  };

  const handleAdultDecrement = () => {
    if (adultCount > 1) {
      setAdultCount((prevCount) => prevCount - 1);
    }
  };

  const handleChildIncrement = () => {
    setChildCount((prevCount) => prevCount + 1);
  };

  const handleChildDecrement = () => {
    if (childCount > 0) {
      setChildCount((prevCount) => prevCount - 1);
    }
  };

  useEffect(() => {
    const options: number[] = [];
    for (let i = 1; i <= 12; i++) {
      options.push(i);
    }
    setChildAges(options);
  }, [childCount]);

  const handleSubmit = () => {
    setTotal(adultCount + childCount);
    if (showPopover) {
      setChildAges([]); 
    }
    setShowPopover(!showPopover);
    setChildCount(childCount);
  };
  

  return (
    <div className="position-relative">
      <button onClick={handleButtonClick} className="passengers-container">
        <img src="/images/dashboard/trips/online/people.png" alt="People" />
        <span>{totalCount}</span>
        <img
          src="/images/dashboard/trips/online/arrow_drop_down.png"
          alt="Arrow"
        />
      </button>
      {showPopover && (
        <div className="passengers-popover">
          <div className="passenger-details">
            <span className="gray-color header_text__14 add-nowrap">
              Adults
            </span>
            <div className="d-flex align-items-center gap-3 justify-content-between">
              <button className="count-buttons" onClick={handleAdultDecrement}>
                <MinusSvgComponent size="16" color="#1A73E8" />
              </button>
              <span className="header_text__14">{adultCount}</span>
              <button className="count-buttons" onClick={handleAdultIncrement}>
                <AddSvgComponent size="18" color="#1A73E8" />
              </button>
            </div>
            <span className="gray-color header_text__14 add-nowrap">
              Children
            </span>
            <div className="d-flex align-items-center gap-3 justify-content-between">
              <button className="count-buttons" onClick={handleChildDecrement}>
                <MinusSvgComponent size="16" color="#1A73E8" />
              </button>
              <span className="header_text__14">{childCount}</span>
              <button className="count-buttons" onClick={handleChildIncrement}>
                <AddSvgComponent size="18" color="#1A73E8" />
              </button>
            </div>
            {childCount > 0 && (
              <>
                {Array.from({ length: childCount }).map((_, index) => (
                  <>
                    <div key={index} className="d-flex align-items-center">
                      <span className="gray-color header_text__14 add-nowrap">
                        Age of child {index + 1}
                      </span>
                    </div>

                    <div className="d-flex justify-content-end custom-select">
                      <select
                        name={`child${index + 1}Age`}
                        id={`child${index + 1}Age`}
                      >
                        {childAges.map((age) => (
                          <option key={age} value={age}>
                            {age}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                ))}
              </>
            )}
          </div>
          <div className="d-flex justify-content-end gap-3 mt-4">
            <h6 onClick={() => setShowPopover(false)} className="small_text__14 color_primary cursor-pointer">Cancel</h6>
            <h6 onClick={handleSubmit} className="small_text__14 color_primary cursor-pointer">Done</h6>
          </div>
        </div>
      )}
    </div>
  );
}

export default Passengers;
