import AddSvgComponent from "@assets/svg-components/add";
import DragAndDropFileComponent from "@common/FileUpload/file-drag-drop";
import DragAndDropFileComponentModal from "@common/FileUpload/file-drag-drop-modal";
import { getValue } from "@utils/lodash";
import React, { useRef } from "react";
import Popup from "reactjs-popup";

const CustomButton = React.forwardRef(({ open, ...props }, ref) => (
  <div
    className="d-flex justify-content-between align-items-center cursor-pointer"
    ref={ref}
    {...props}
  >
    <img
      src="/images/add-blue.svg"
      width="24px"
      height="24px"
      className="img-fluid"
    />
    <h5 className="header_text_blue ms-2">Add Files</h5>
  </div>
));

function ExpenseUpload(props) {
  const POSITION_TYPES = [
    "bottom right",
    "bottom center",
    "top center",
    "left top",
    "left bottom",
    "right top",
    "top left",
    "top center",
    "right center",
    "right bottom",
    "bottom left",
    "left center",
    "center center",
  ];
  const fileTypes = ["PDF", "JPEG", "XLSX", "CSV", "XLSM", "XLX", "JPG", "PNG"];
  const { handleUploadDocuments } = props;

  const popupRef = useRef();
  const handleClose = (e) => {
    e.stopPropagation();
    if (popupRef.current) {
      popupRef.current.close();
    }
  };
  const handleOpen = () => {
    if (popupRef.current) {
      popupRef.current.open();
    }
  };

  const CustomButtons = React.forwardRef(({ open, ...props }, ref) => (
    <div
      className="d-flex w-100 align-items-center cursor-pointer"
      {...props}
      ref={ref}
    >
      {getValue(props, `reports`, false) &&
        getValue(props, `permissions`, []).includes("update") && (
          <div className="d-flex justify-content-between align-items-center w-100 trips_mandate_expese_documents_upload">
            <p className="desc_text trips_mandate_expese_documents_upload_label">
              Documents:
            </p>
            <span className="trips_mandate_expese_documents_addfiles_label d-flex align-items-center color_primary">
              <AddSvgComponent size={18} color={"#1966a1"} /> Add Files
            </span>
          </div>
        )}
      {getValue(props, `permissions`, []).includes("update") &&
        !getValue(props, `reports`, false) && (
          <>
            <div className="d-flex align-items-center justify-content-between report_right_documents_upload w-100">
              <p class="desc_text report_right_documents_upload_label mt-0">
                Documents:
              </p>
              <span className="d-flex align-items-center">
                <AddSvgComponent size={18} color={"#1966a1"} />
                <h5 className="header_text_blue report_right_documents_addFiles_label color_primary">
                  Add Files
                </h5>
              </span>
            </div>
          </>
        )}
    </div>
  ));
  return (
    <Popup
      trigger={(open) => (
        <CustomButtons
          open={open}
          reports={props.reports}
          permissions={getValue(props, `permissions`, [])}
        />
      )}
      position={POSITION_TYPES}
      closeOnDocumentClick
      on={"click"}
      contentStyle={{
        width: getValue(props, `width`, ""),
        zIndex: 9999,
        background: "white",
        boxShadow:
          "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
      }}
      nested
      ref={popupRef}
      onOpen={handleOpen}
    >
      <div>
        <div className="w-100 mt-3">
          <DragAndDropFileComponentModal
            uploadFile={handleUploadDocuments}
            name="file"
            types={fileTypes}
            multiple
            classes="bulk-upload_drag-n-drop-wrapper position-absolute"
            fileName={getValue(props, `importedFile.name`, "")}
            setImportTedFile={props.setImportTedFile}
            handleClose={handleClose}
          />
        </div>
      </div>
    </Popup>
  );
}

export default ExpenseUpload;
