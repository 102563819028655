import InputField from "@common/InputRuleFeild";
import { QueryRequestHelper } from "@common/query-request-helper";
import { formatText } from "@common/text-helpers";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import PromptModal from "@prompt/modal";
import { getValue } from "@utils/lodash";
import React, { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import _ from "lodash";
import { useCallbackPrompt } from "@prompt/useCallbackPrompt";
import { NavLink } from "react-router-dom";
import {
  createCustomField,
  getCustomDatatypes,
  getSpecificField,
  updateSpecificField,
} from "@services/modules.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import SingleSelectSearchDropdown from "@components/common/CustomSearchableDropdown/SingleSelectSearchDropdown";
import NoAccessPage from "@components/common/NoAccess";
import GearLoader from "@components/common/GearLoader";
function TripFieldDetails(props: any) {
  const navigate = useNavigate();
  const params = useParams();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (props.permissionAPITriggered) {
      if (getValue(params, `id`, "")) {
        getInfo(getValue(params, `id`, ""));
        getDataTypes();
      } else {
        getDataTypes();
      }
    }
  }, [props.permissionAPITriggered]);
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [dataTypeList, setDataTypeList] = useState([]);
  const [request, setRequest] = useState({
    entity_type: "trip",
    sub_data_type: "",
    display_name: "",
    field_value: "",
    is_mandatory: false,
    is_enabled: true,
  });
  const [dirtyFields, setDirtyFields] = useState({
    entity_type: "trip",
    sub_data_type: "",
    display_name: "",
    field_value: "",
    is_mandatory: false,
    is_enabled: true,
  });

  /* -------------------------------------------------------------------------- */
  /*                                   API Section                              */
  /* -------------------------------------------------------------------------- */

  const getInfo = async (id: string) => {
    try {
      let resp = await getSpecificField(id);
      if (resp) {
        let obj: any = {
          entity_type: "trip",
          sub_data_type: getValue(resp, `data.sub_data_type`, ""),
          display_name: getValue(resp, `data.display_name`, ""),
          field_value: getValue(resp, `data.field_value`, ""),
          is_mandatory: getValue(resp, `data.is_mandatory`, ""),
          is_enabled: getValue(resp, `data.is_enabled`, ""),
        };

        setRequest((prevRequest: any) => {
          return { ...prevRequest, ...obj };
        });
        setDirtyFields((prevRequest: any) => {
          return { ...prevRequest, ...obj };
        });
      }
    } catch (error) {}
  };
  const getDataTypes = async () => {
    let payload = {
      entity_type: "trip",
    };
    let queryRequest = QueryRequestHelper(payload);
    try {
      let resp = await getCustomDatatypes(queryRequest);
      if (resp) {
        setDataTypeList(
          getValue(resp, `data`, []).map((item: string) => ({
            value: item,
            label: formatText(item),
          }))
        );
      }
    } catch (error) {}
  };

  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        let resp;
        if (getValue(params, `id`, "")) {
          resp = await updateSpecificField(getValue(params, `id`, ""), request);
        } else {
          resp = await createCustomField(request);
        }

        if (resp) {
          toast.success(getValue(resp, `message`, ""));
          setIsLoading(false);
          navigate(`/admin/modules/trips/fields`);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                Prompt Section                              */
  /* -------------------------------------------------------------------------- */
  const [isChanged, setIsChanged] = useState(false);
  useEffect(() => {
    setIsChanged(
      _.isEqualWith(request, dirtyFields, (a: any, b: any) => {
        // if both are null or equal to an empty string then they are equal
        if ((_.isNull(a) || a === "") && (_.isNull(b) || b === "")) return true;
      })
    );
  });
  const [showPrompt, confirmNavigation, cancelNavigation]: any =
    useCallbackPrompt(!isChanged);
  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      {!getValue(props, `subTabPermissionList`, []).includes(
        "admin_modules_trips"
      ) ? (
        <NoAccessPage />
      ) : (
        <>
          <PromptModal
            showDialog={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
          />
          <div className="modulefields_detail_container trips-add-feilds-wrapper">
            <div className="modulefields_detail_header_container bg-white px-5">
              <p className="modulefields_detail_header_text">
                {getValue(params, `id`, "") ? "Update" : "New"} Custom Field
              </p>
              {/* <NavLink to={`/admin/modules/trips/fields`}>
                <img
                  src="/images/close-black.svg"
                  className="modulefields_detail_close"
                />
              </NavLink> */}
            </div>
            <div className="modulefields_form_container">
              <div className="user-details-wrapper m-0">
                <div className="checkpoint-active-details-wrapper__form-wrapper pt-0">
                  <div className="checkpoint-active-details-wrapper__form-content_new">
                    <div className="user-details-wrapper__form  user-details-wrapper__form--org-profile">
                      <div className="user-details-wrapper__form-group form-group">
                        <label className="checkpoint-active-details__label form-label">
                          Display Name{" "}
                          <span className="form-label-error">*</span>
                        </label>
                        <InputField
                          inputType="TEXT"
                          placeholder="Enter Display Name"
                          className="form-control w-100"
                          name="display_name"
                          id="display_name"
                          value={getValue(request, `display_name`, "")}
                          onChange={(e: any) =>
                            setRequest({
                              ...request,
                              display_name: e.target.value,
                            })
                          }
                          label="Dispaly Name"
                          validator={simpleValidator}
                        />
                      </div>
                    </div>

                    <div className="user-details-wrapper__form  user-details-wrapper__form--org-profile">
                      <div className="user-details-wrapper__form-group form-group">
                        <label className="checkpoint-active-details__label form-label">
                          Data Type <span className="form-label-error">*</span>
                        </label>
                        <SingleSelectSearchDropdown
                          label={"label"}
                          selectKey={"value"}
                          name="Date Separator"
                          value={getValue(request, `sub_data_type`, "")}
                          onChange={(e: any) =>
                            setRequest({
                              ...request,
                              sub_data_type: getValue(e, `value`, ""),
                            })
                          }
                          placeholder={"Choose Data Type"}
                          data={dataTypeList}
                          // width={"408px"}
                          validator={simpleValidator}
                        />
                      </div>
                    </div>

                    <div className="user-details-wrapper__form-group form-group mt-3">
                      <label className="checkpoint-active-details__label form-label">
                        Mandatory <span className="form-label-error">*</span>
                      </label>
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center mt-1">
                          <input
                            type="radio"
                            className="form-radio"
                            checked={getValue(request, `is_mandatory`, false)}
                            onChange={() =>
                              setRequest({ ...request, is_mandatory: true })
                            }
                          />
                          <label className="ms-2">Yes</label>
                        </div>
                        <div className="d-flex align-items-center mt-1 ms-3">
                          <input
                            type="radio"
                            className="form-radio"
                            checked={!getValue(request, `is_mandatory`, false)}
                            onChange={() =>
                              setRequest({ ...request, is_mandatory: false })
                            }
                          />
                          <label className="ms-2">No</label>
                        </div>
                      </div>
                    </div>

                    <div className="user-details-wrapper__form-group form-group mt-3">
                      <label className="checkpoint-active-details__label form-label">
                        Enabled <span className="form-label-error">*</span>
                      </label>
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center mt-1">
                          <input
                            type="radio"
                            className="form-radio"
                            checked={getValue(request, `is_enabled`, false)}
                            onChange={() =>
                              setRequest({ ...request, is_enabled: true })
                            }
                          />
                          <label className="ms-2">Yes</label>
                        </div>
                        <div className="d-flex align-items-center mt-1 ms-3">
                          <input
                            type="radio"
                            className="form-radio"
                            checked={!getValue(request, `is_enabled`, false)}
                            onChange={() =>
                              setRequest({ ...request, is_enabled: false })
                            }
                          />
                          <label className="ms-2">No</label>
                        </div>
                      </div>
                    </div>

                    <div className="user-details-wrapper__cta-btns mt-5 d-flex align-items-center gap-2">
                      <NavLink to={`/admin/modules/trips/fields`}>
                        <button className="deeptravel-button deeptravel-button--header-buttons deeptravel-button--secondary">
                          Cancel
                        </button>
                      </NavLink>
                      <button
                        className={`deeptravel-button deeptravel-button--header-buttons deeptravel-button--${
                          isChanged ? "secondary" : "primary"
                        }`}
                        onClick={handleSubmit}
                        disabled={isChanged}
                      >
                        {isLoading
                          ? "Please wait..."
                          : getValue(params, `id`, "")
                          ? "Update"
                          : "Save"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
}

export default TripFieldDetails;
