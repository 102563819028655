import Lottie from "lottie-react";
// import f1 from "@lottie/flight_animation_1.json";
// import f1 from "@lottie/flight1.json";
// import f1 from "@lottie/flight2.json";
import f2 from "@lottie/flight_animation_2.json";
import { getValue } from "@utils/lodash";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function InfoSection(props: any) {
  let user = getValue(localStorage, `view`, "");
  function openEmailPopup() {
    const email = "mailto:support@deeptravel.in";
    const options =
      "toolbar=no,scrollbars=yes,resizable=yes,width=500,height=500";
    window.open(email, "Email Popup", options);
  }
  return (
    <div
      className={`dashbaord-wrapper_info-section position-relative ${
        props.classname ? props.classname : "mt-4"
      }`}
    >
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div>
            {/* <AssistantSvgComponent size={44} /> */}
            <img
              src={require(`../../../../assets/svg-components/dashboard/tele.png`)}
              height={"60px"}
              width={"60px"}
              alt=""
            />
          </div>
          <div className="ms-4">
            <h6 className="header_text__22">
              2 hours of <span className="text-danger">FREE</span> Rapid Setup
              Assistance! 2 hours of Complimentary Rapid Setup Support!
            </h6>
            <p className="desc_text__14 mt-2">
              Our team of product consultants will assist you in customizing
              Expense to align with your specific needs.
            </p>
          </div>
        </div>
        <div
          className={
            user === "client" ? "lottie_container_client" : "lottie_container"
          }
        >
          {/* <Lottie animationData={user === 'client'?f1:f2} loop={true} /> */}
          {/* <Lottie animationData={user !== "client" && f2} loop={true} /> */}
        </div>
        <button
          className="deeptravel-button deeptravel-button--header-buttons deeptravel-button--secondary"
          onClick={openEmailPopup}
        >
          Contact Support
        </button>
      </div>
    </div>
  );
}

export default InfoSection;
