// export const config = {
//   API_URL: "",
//   AUTH_SHARED_KEY: "",
//   SOURCE:'',
// };

export const config = {
  API_URL: "https://backend.beta-staging.deep-travel.in/client-service/api/v1",
  AUTH_SHARED_KEY: "Q6gsoyBRagz7NMXejmKR7hzLK3PyEF",
  SOURCE: "demo.deep-travel.in",
};
