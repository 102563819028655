import { getValue } from "@utils/lodash";
import React from "react";

function NodataSvgComponent(props: any) {
  return (
    <div>
      <svg
        // width="357"
        // height="256"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "357"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "256"
        }
        viewBox="0 0 357 256"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M50.8981 72.161C56.4364 72.161 60.9261 67.6713 60.9261 62.133C60.9261 56.5947 56.4364 52.105 50.8981 52.105C45.3598 52.105 40.8701 56.5947 40.8701 62.133C40.8701 67.6713 45.3598 72.161 50.8981 72.161Z"
          stroke="#B1C1CA"
          stroke-width="2"
        />
        <path
          d="M286.722 50.1052C289.917 50.1052 292.508 47.5147 292.508 44.3192C292.508 41.1237 289.917 38.5332 286.722 38.5332C283.526 38.5332 280.936 41.1237 280.936 44.3192C280.936 47.5147 283.526 50.1052 286.722 50.1052Z"
          stroke="#B1C1CA"
          stroke-width="2"
        />
        <path
          d="M349.496 238.577C353.244 238.577 356.282 235.539 356.282 231.791C356.282 228.043 353.244 225.005 349.496 225.005C345.748 225.005 342.71 228.043 342.71 231.791C342.71 235.539 345.748 238.577 349.496 238.577Z"
          fill="#B1C1CA"
        />
        <path
          d="M6.786 185.983C10.5338 185.983 13.572 182.945 13.572 179.197C13.572 175.449 10.5338 172.411 6.786 172.411C3.0382 172.411 0 175.449 0 179.197C0 182.945 3.0382 185.983 6.786 185.983Z"
          fill="#B1C1CA"
        />
        <path
          d="M171.245 9.34401H162.939V1.03801C162.939 0.762584 162.83 0.498438 162.635 0.303681C162.44 0.108925 162.176 -0.000488281 161.901 -0.000488281C161.625 -0.000488281 161.361 0.108925 161.166 0.303681C160.971 0.498438 160.862 0.762584 160.862 1.03801V9.34401H152.556C152.281 9.34401 152.017 9.45342 151.822 9.64818C151.627 9.84294 151.518 10.1071 151.518 10.3825C151.518 10.6579 151.627 10.9221 151.822 11.1168C152.017 11.3116 152.281 11.421 152.556 11.421H160.862V19.727C160.862 20.0024 160.971 20.2666 161.166 20.4613C161.361 20.6561 161.625 20.7655 161.901 20.7655C162.176 20.7655 162.44 20.6561 162.635 20.4613C162.83 20.2666 162.939 20.0024 162.939 19.727V11.421H171.245C171.521 11.421 171.785 11.3116 171.979 11.1168C172.174 10.9221 172.284 10.6579 172.284 10.3825C172.284 10.1071 172.174 9.84294 171.979 9.64818C171.785 9.45342 171.521 9.34401 171.245 9.34401V9.34401Z"
          fill="#B1C1CA"
        />
        <path
          d="M305.672 136.933H300.551V131.812C300.551 131.642 300.483 131.479 300.363 131.359C300.243 131.239 300.081 131.172 299.911 131.172C299.741 131.172 299.578 131.239 299.458 131.359C299.338 131.479 299.271 131.642 299.271 131.812V136.933H294.149C293.979 136.933 293.816 137 293.696 137.12C293.576 137.24 293.509 137.403 293.509 137.573C293.509 137.743 293.576 137.905 293.696 138.025C293.816 138.145 293.979 138.213 294.149 138.213H299.27V143.334C299.27 143.504 299.337 143.666 299.457 143.786C299.577 143.906 299.74 143.974 299.91 143.974C300.08 143.974 300.242 143.906 300.362 143.786C300.482 143.666 300.55 143.504 300.55 143.334V138.214H305.671C305.841 138.214 306.003 138.146 306.123 138.026C306.243 137.906 306.311 137.744 306.311 137.574C306.311 137.404 306.243 137.241 306.123 137.121C306.003 137.001 305.841 136.934 305.671 136.934L305.672 136.933Z"
          fill="#B1C1CA"
        />
        <path
          d="M66.3749 234.61H61.2539V229.489C61.2539 229.319 61.1865 229.157 61.0665 229.037C60.9464 228.917 60.7837 228.849 60.6139 228.849C60.4442 228.849 60.2814 228.917 60.1614 229.037C60.0413 229.157 59.9739 229.319 59.9739 229.489V234.61H54.8519C54.6822 234.61 54.5194 234.678 54.3994 234.798C54.2793 234.918 54.2119 235.08 54.2119 235.25C54.2119 235.42 54.2793 235.583 54.3994 235.703C54.5194 235.823 54.6822 235.89 54.8519 235.89H59.9729V241.011C59.9729 241.181 60.0403 241.344 60.1604 241.464C60.2804 241.584 60.4432 241.651 60.6129 241.651C60.7827 241.651 60.9454 241.584 61.0655 241.464C61.1855 241.344 61.2529 241.181 61.2529 241.011V235.891H66.3739C66.5437 235.891 66.7064 235.824 66.8265 235.704C66.9465 235.584 67.0139 235.421 67.0139 235.251C67.0139 235.081 66.9465 234.919 66.8265 234.799C66.7064 234.679 66.5437 234.611 66.3739 234.611L66.3749 234.61Z"
          fill="#B1C1CA"
        />
        <path
          d="M202.048 72.5537H114.539C111.791 72.5521 109.069 73.0923 106.529 74.1434C103.99 75.1945 101.682 76.7358 99.7389 78.6793C97.7954 80.6228 96.254 82.9302 95.203 85.4698C94.1519 88.0094 93.6117 90.7312 93.6133 93.4797V211.427C93.6116 214.175 94.1517 216.897 95.2027 219.437C96.2537 221.977 97.795 224.284 99.7385 226.228C101.682 228.171 103.99 229.713 106.529 230.764C109.069 231.815 111.791 232.355 114.539 232.354H176.48C169.582 222.985 165.874 211.648 165.903 200.014C165.913 185.385 171.729 171.358 182.073 161.014C192.417 150.67 206.444 144.854 221.072 144.844C221.707 144.831 222.341 144.856 222.972 144.92V93.4797C222.974 90.7314 222.434 88.0097 221.383 85.4703C220.332 82.9308 218.791 80.6234 216.847 78.68C214.904 76.7365 212.597 75.1952 210.058 74.144C207.518 73.0928 204.797 72.5524 202.048 72.5537V72.5537Z"
          fill="#F5F6FA"
        />
        <path
          d="M192.536 148.649H124.05C122.032 148.649 120.096 147.847 118.669 146.42C117.242 144.993 116.44 143.057 116.44 141.039C116.44 139.021 117.242 137.086 118.669 135.658C120.096 134.231 122.032 133.43 124.05 133.43H192.536C194.554 133.43 196.49 134.231 197.917 135.658C199.344 137.086 200.145 139.021 200.145 141.039C200.145 143.057 199.344 144.993 197.917 146.42C196.49 147.847 194.554 148.649 192.536 148.649V148.649Z"
          fill="#90A4AE"
        />
        <path
          d="M162.098 179.087H124.05C122.032 179.087 120.096 178.285 118.669 176.858C117.242 175.431 116.44 173.495 116.44 171.477C116.44 169.459 117.242 167.524 118.669 166.096C120.096 164.669 122.032 163.868 124.05 163.868H162.098C164.116 163.868 166.052 164.669 167.479 166.096C168.906 167.524 169.707 169.459 169.707 171.477C169.707 173.495 168.906 175.431 167.479 176.858C166.052 178.285 164.116 179.087 162.098 179.087Z"
          fill="#90A4AE"
        />
        <path
          d="M154.488 118.211H124.05C122.032 118.211 120.096 117.409 118.669 115.982C117.242 114.555 116.44 112.619 116.44 110.601C116.44 108.583 117.242 106.648 118.669 105.22C120.096 103.793 122.032 102.992 124.05 102.992H154.488C156.506 102.992 158.442 103.793 159.869 105.22C161.296 106.648 162.097 108.583 162.097 110.601C162.097 112.619 161.296 114.555 159.869 115.982C158.442 117.409 156.506 118.211 154.488 118.211Z"
          fill="#90A4AE"
        />
        <path
          d="M221.072 239.963C213.171 239.963 205.447 237.619 198.877 233.23C192.307 228.84 187.187 222.601 184.163 215.301C181.139 208.001 180.348 199.968 181.89 192.219C183.431 184.469 187.236 177.351 192.823 171.764C198.41 166.176 205.529 162.372 213.278 160.83C221.028 159.289 229.06 160.08 236.36 163.104C243.66 166.127 249.9 171.248 254.289 177.817C258.679 184.387 261.022 192.111 261.022 200.013C261.01 210.604 256.798 220.759 249.308 228.249C241.819 235.738 231.664 239.951 221.072 239.963V239.963ZM221.072 175.282C216.181 175.282 211.399 176.732 207.332 179.449C203.265 182.167 200.095 186.029 198.224 190.548C196.352 195.067 195.862 200.04 196.816 204.837C197.771 209.635 200.126 214.041 203.585 217.5C207.043 220.959 211.45 223.314 216.247 224.268C221.045 225.223 226.017 224.733 230.536 222.861C235.055 220.989 238.918 217.819 241.635 213.752C244.353 209.685 245.803 204.904 245.803 200.013C245.796 193.456 243.188 187.17 238.551 182.533C233.915 177.897 227.629 175.289 221.072 175.282V175.282Z"
          fill="#90A4AE"
        />
        <path
          d="M268.631 255.18C267.632 255.181 266.642 254.984 265.719 254.602C264.796 254.219 263.957 253.658 263.251 252.95L238.52 228.221C237.094 226.794 236.292 224.859 236.292 222.841C236.292 220.823 237.094 218.888 238.52 217.461C239.947 216.034 241.883 215.232 243.9 215.232C245.918 215.232 247.854 216.034 249.28 217.461L274.011 242.192C275.075 243.256 275.799 244.612 276.093 246.087C276.386 247.563 276.235 249.093 275.659 250.483C275.084 251.873 274.109 253.061 272.858 253.897C271.607 254.733 270.136 255.18 268.631 255.18V255.18Z"
          fill="#90A4AE"
        />
      </svg>
    </div>
  );
}

export default NodataSvgComponent;
