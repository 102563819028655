import AdminReports from "@pages/Admin/Reports";
import AdminReportDetail from "@pages/Admin/Reports/Detail";
import ReimbursementPage from "@pages/Admin/Reports/Detail/Reimbursement";

export const AdminReportRoutes = [
  //expenses
  {
    path: "/admin/reports",
    name: "admin_reports",
    component: AdminReports,
  },
  {
    path: "/admin/reports/detail/:id",
    name: "admin_reports",
    component: AdminReportDetail,
  },
  {
    path: "/admin/reports/reimbursement/:id",
    name: "admin_reports",
    component: ReimbursementPage,
  },
  
];
