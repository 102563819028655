import React from "react";
import { useNavigate } from "react-router-dom";
import "../../online/trip-online.scss";
import HotelLocation from "@components/Trip/components/hotel/location";
import Passengers from "@components/Trip/components/hotel/passengers";

interface HotelSearchProps {
  value?: string;
}

const HotelSearch = ({ value }: HotelSearchProps) => {
  const navigate = useNavigate();

  return (
    <div className="d-flex gap-4 flex-wrap">
      {/* <div className="position-relative">
        <input
          placeholder="Search for places or hotels"
          className="search-input"
          value={value}
        />
        <img
          src="/images/dashboard/trips/online/search.png"
          className="search-icon"
        />
      </div> */}
      <HotelLocation
        caption="Search for places or hotels"
        placeholder="Type a Destination or Hotel"
      />
      <div className="calender-wrapper">
        <img src="/images/dashboard/trips/online/calender.png" />
        <span className="calender-border-left">Tue, Apr 12</span>
        <span>Wed, Apr 13</span>
      </div>
      <Passengers />
      <button
        onClick={() => navigate("/client/trips/online-booking/properties")}
        className="deeptravel-button deeptravel-button--secondary deeptravel-button--header-buttons"
      >
        Search
      </button>
    </div>
  );
};

export default HotelSearch;
