import React, { useState } from "react";
import { getValue } from "@utils/lodash";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { convertCurrentDate } from "@common/date-helpers";

function ExpensePerDiemRateListPopup(props: any) {
  return (
    <>
      <Modal
        isOpen={getValue(props, `isOpen`, "")}
        toggle={props.handleModal}
        backdropClassName={"readMoreBackDrop"}
        modalClassName={"readMoreModalReports"}
        centered
        size="lg"
      >
        <ModalHeader toggle={props.handleModal}>
          Per Diem Expense Rate List
        </ModalHeader>
        <ModalBody>
          <div className="p-3 per-diem-expens-rate-list"><table className="datatable_table" style={{border: "1px solid #efefef"}}>
            <thead className="datatable_table_thead">
                <th className="datatable_table_th" style={{width:"150px", minWidth: "150px", textAlign: "left"}}>
                  <h6>Date</h6>
                </th>
              <th className="datatable_table_th">
                <h6>Start Time</h6>
              </th>
              <th className="datatable_table_th">
                <h6>End Time</h6>
              </th>

              <th className="datatable_table_th">
                <h6>Duration</h6>
              </th>
              <th className="datatable_table_th">
                <h6>Amount</h6>
              </th>
            </thead>
            <tbody className="datatable_table_tbody ">
              {getValue(props, `perDiemExpenseRateListInfo`, []).map(
                (item: object, index: number) => {
                  return (
                    <tr key={index} className="datatable_table_tr">
                      <td className="datatable_table_td" style={{width:"150px", minWidth: "150px", textAlign: "left"}}>
                        <h6>{convertCurrentDate(getValue(item, `date`, ""))}</h6>
                      </td>
                      <td className="datatable_table_td">
                        <h6>{getValue(item, `start_time`, "")}</h6>
                      </td>
                      <td className="datatable_table_td">
                        <h6>{getValue(item, `end_time`, "")}</h6>
                      </td>

                      <td className="datatable_table_td">
                        {getValue(item, `duration`, "")} Hrs
                      </td>

                      <td className="datatable_table_td">
                        {getValue(item, `currency.currency_symbol`, "")}{" "}
                        {getValue(item, `amount`, "")}
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table></div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default ExpensePerDiemRateListPopup;
