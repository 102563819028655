import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

interface DialogBoxProps {
  showDialog: boolean;
  cancelNavigation: any;
  confirmNavigation: any;
}

const PromptModal: React.FC<DialogBoxProps> = ({
  showDialog,
  cancelNavigation,
  confirmNavigation,
}) => {
  return (
    <Modal isOpen={showDialog}>
      <ModalHeader>
        <h5 className="header_text">Are you sure?</h5>
      </ModalHeader>
      <ModalBody>
        <p className="desc_text">
          Are you sure you want to leave this page? All your modifications will
          be lost !
        </p>
      </ModalBody>
      <ModalFooter>
        <button
          className="deeptravel-button deeptravel-button--header-buttons deeptravel-button--primary"
          onClick={cancelNavigation}
        >
          No
        </button>
        <button
          className="deeptravel-button  deeptravel-button--header-buttons deeptravel-button--secondary"
          onClick={confirmNavigation}
        >
          Yes
        </button>
      </ModalFooter>
    </Modal>
  );
};
export default PromptModal;
