import { getValue } from "@utils/lodash";
import React from "react";

function LinkSvgComponent(props: any) {
  return (
    <>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"
        }
        fill={
          getValue(props, `color`, "")
            ? getValue(props, `color`, "")
            : "#3c4043"
        }
      >
        <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h560v-280h80v280q0 33-23.5 56.5T760-120H200Zm188-212-56-56 372-372H560v-80h280v280h-80v-144L388-332Z" />
      </svg> */}

      <span style={{transform:"rotate(315deg)", display:"inline-block", marginTop:"-2px"}}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"
        }
        fill={
          getValue(props, `color`, "")
            ? getValue(props, `color`, "")
            : "#3c4043"
        }
      
      >
          <path d="M440-280H280q-83 0-141.5-58.5T80-480q0-83 58.5-141.5T280-680h160v80H280q-50 0-85 35t-35 85q0 50 35 85t85 35h160v80ZM320-440v-80h320v80H320Zm200 160v-80h160q50 0 85-35t35-85q0-50-35-85t-85-35H520v-80h160q83 0 141.5 58.5T880-480q0 83-58.5 141.5T680-280H520Z"/>
      </svg>
      </span>
    </>
  );
}

export default LinkSvgComponent;
