import * as React from "react";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import { getValue } from "@utils/lodash";
import Lottie from "lottie-react";
import bin from "@lottie/bin.json";
import SingleSelectSearchDropdown from "@components/common/CustomSearchableDropdown/SingleSelectSearchDropdown";
import InfoSvgComponent from "@assets/svg-components/info";

interface IConfirmModalProps {
  isOpen: boolean;
  handleModal: () => void;
  handleSubmit: () => void;
}
const SubmitConfirmModal = (props: any) => {
  return (
    <Modal
      isOpen={getValue(props, `isOpen`, "")}
      //   toggle={handleModal}
    >
      <ModalHeader style={{ background: "#f8f8fd" }}>
        <div className="d-flex align-items-center w-100">
          <InfoSvgComponent size={28} color={'#FD9933'}/>
          <h6 className="header_text__18 ms-2">Confirm</h6>
        </div>
      </ModalHeader>
      <div className="m-3">
        <h6 className="header_text__17">Are you sure you want to continue?</h6>
      </div>
      <ModalFooter>
        <div className="delete-popup-wrapper__cta-wrapper">
          <button
            onClick={() => {
              props.handleModal();
            }}
            className="delete-popup-wrapper__cta-btn delete-popup-wrapper__cta-btn--secondary"
          >
            No
          </button>
          <button
            className="delete-popup-wrapper__cta-btn delete-popup-wrapper__cta-btn--primary"
            onClick={props.handleSubmit}
          >
            {"Yes"}
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default SubmitConfirmModal;
