import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getModulesFields = (queryRequest: string) =>
  get(`${config.API_URL}/form-properties/fields?${queryRequest}`);

export const getSpecificCustomField = (id: string) =>
  get(`${config.API_URL}/form-properties/fields/${id}`);

export const updateModuleFields = (payload: object) =>
  patch(`${config.API_URL}/form-properties/fields`, payload);

export const getCustomDatatypes = (queryRequest: string) =>
  get(`${config.API_URL}/form-properties/list/data-type?${queryRequest}`);

export const createCustomField = (payload: object) =>
  post(`${config.API_URL}/form-properties/custom-fields`, payload);

export const updateCustomField = (id: string, payload: object) =>
  post(`${config.API_URL}/form-properties/custom-fields/${id}`, payload);

export const removeCustomField = (id: string) =>
  Delete(`${config.API_URL}/form-properties/fields/${id}`);

export const getSpecificField = (id: string) =>
  get(`${config.API_URL}/form-properties/${id}`);

export const getSpecificCustomFieldwithMileage = (id: string) =>
  get(`${config.API_URL}/form-properties/${id}/expense-entry/custom-field`);

export const updateSpecificField = (id: string, payload: object) =>
  patch(`${config.API_URL}/form-properties/${id}`, payload);

export const updateFieldsSwap = (payload: object) =>
  patch(`${config.API_URL}/form-properties/seq-no/swap`, payload);

export const getDeletedFields = (queryRequest: string) =>
  get(`${config.API_URL}/form-properties/deleted/fields?${queryRequest}`);

export const restoreDeletedFields = (id: string) =>
  get(`${config.API_URL}/form-properties/restore/field/${id}`);
