import { AdminDashboardRoutes } from "./dashboard.routes";
import { AdminListManagementRoutes } from "./listmanagement.routes";
import { AdminOrganisationRoutes } from "./organisation.routes";
import { AdminModulesRoutes } from "./modules.routes";
import { AdminUserControlRoutes } from "./user-controls.routes";
import { AdminSettingsRoutes } from "./settings.routes";
import { AdminTripsRoutes } from "./trips.routes";
import { AdminReportRoutes } from "./reports.routes";
import { AdminAnalyticsRoutes } from "./analytics.routes";

export const AdminRoutes = [
  ...AdminDashboardRoutes,
  ...AdminOrganisationRoutes,
  ...AdminUserControlRoutes,
  ...AdminListManagementRoutes,
  ...AdminModulesRoutes,
  ...AdminSettingsRoutes,
  ...AdminTripsRoutes,
  ...AdminReportRoutes,
  ...AdminAnalyticsRoutes,
];
