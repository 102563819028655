import AddSvgComponent from "@assets/svg-components/add";
import ArrowDownSvgComponent from "@assets/svg-components/arrow-down";
import ForwardSvgComponent from "@assets/svg-components/forward";
import {
  convertDate,
  convertDateTime,
  convertDateWithSlash,
} from "@common/date-helpers";
import { QueryRequestHelper } from "@common/query-request-helper";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import ReportsPopup from "@components/Reports/components/report-add-popup";
import WarningMessage from "@components/WarningMessages/WarningMessage";
import { FormRequestHelper } from "@components/common/FormBuilder/request-helper";
import {
  createExpenseEntry,
  updateExpenseEntry,
} from "@services/expense-entry.service";
import {
  attachExpenseEntryInReport,
  createExpenseReport,
  getAllExpenseFormReport,
} from "@services/expense-reports.service";
import { getValue } from "@utils/lodash";
import { ArrowRight, SquareChevronRight } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import ExpenseViolationSection from "./ExpenseViolationSection";

function ExpenseAssociatedSection(props: any) {
  let navigate = useNavigate();
  let params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  console.log(props, "EXPENSEASSOCIATEDSEC");
  useEffect(() => {
    getFormData();
  }, [props.permissionAPITriggered]);

  useEffect(() => {
    if (Object.keys(urlParams).length > 0 && props.permissionAPITriggered) {
      getFormData();
    }
  }, [window.location.href, props.permissionAPITriggered]);
  const [expenseEntryIds, setExpenseEntryIds] = useState<any>([]);

  const handleAddtoReport = async (report_id: any) => {
    // let requestPayload = {
    //   amount: getValue(props, `formInfo.amount`, ""),
    //   // currency_id: getValue(props,`formInfo.`),
    //   currency_id: 1,
    //   description: getValue(props, `formInfo.description`, ""),
    //   expense_date: getValue(props, `formInfo.expense_date`, ""),
    //   expense_report_id: report_id,
    //   expense_type: getValue(props, `formInfo.expense_type.id`, ""),
    //   paid_through: getValue(props, `formInfo.paid_through.id`, ""),
    //   personal: getValue(props, `formInfo.personal`, false),
    //   receipt_ids: getValue(props, `formInfo.receipts`, []).map(
    //     (receipt: any) => receipt.id
    //   ),
    // };

    // let resp;
    // resp = await updateExpenseEntry(
    //   getValue(props, `formInfo.id`, ""),
    //   removeNullOrUndefinedProperties(requestPayload)
    // );
    // if (resp) {
    //   toast.success(getValue(resp, `message`, ""));
    //   navigate(`/client/reports/detail/${report_id}`);
    // }

    let payload = {
      expense_entry_ids: [getValue(props, `formInfo.id`, "")], // Wrap report_id in an array
      amount: {}, // Add amount if needed
    };
    try {
      let resp = await attachExpenseEntryInReport(report_id, payload);
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        navigate(`/client/reports/detail/${report_id}`);
      }
    } catch (error) {}
  };

  const handleCreateReport = async () => {
    handleOpenPopup();
  };

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const handleOpenPopup = () => {
    setShowUpdatePopup(!showUpdatePopup);
  };

  const [isLoading, setIsLoading] = React.useState(true);

  const getFormData = async () => {
    let payload: any = {};
    let queryRequest = QueryRequestHelper(payload);
    try {
      setIsLoading(true);
      let resp = await getAllExpenseFormReport(queryRequest);
      if (resp) {
        setReportFormInfo(getValue(resp, `data`, {}));
        let form = getValue(resp, `data.form_layout`, []).map((item: any) => {
          if (getValue(item, `field_name`, "") === "duration_from") {
            const now = new Date();
            const firstDayOfMonth = new Date(
              now.getFullYear(),
              now.getMonth(),
              1
            );
            item["field_value"] = convertDate(firstDayOfMonth);
          } else if (getValue(item, `field_name`, "") === "duration_to") {
            item["field_value"] = convertDate(new Date());
          }
          return item; // Always return the item after making modifications
        });
        setReportForm(form);
        // setReportForm(getValue(resp, `data.form_layout`, []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [reportForm, setReportForm] = React.useState<[]>([]);
  const [reportFormInfo, setReportFormInfo] = useState({});
  const [submitLoadingReport, setSubmitLoadingReport] = useState(false);
  const [expense_report_id, setExpenseReportId] = useState("");

  const handleSubmitSaveReport = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setSubmitLoadingReport(true);
        let fields = JSON.parse(JSON.stringify(reportForm));
        let payload = FormRequestHelper(fields);
        let requestPayload: any = payload;
        let resp = await createExpenseReport(
          removeNullOrUndefinedProperties(requestPayload)
        );

        if (resp) {
          setExpenseReportId(getValue(resp, `data.id`, ""));

          setSubmitLoadingReport(true);
          // let requestPayloadExpense = {
          //   amount: getValue(props, `formInfo.amount`, ""),
          //   currency_id: 1,
          //   description: getValue(props, `formInfo.description`, ""),
          //   expense_date: getValue(props, `formInfo.expense_date`, ""),
          //   expense_report_id: getValue(resp, `data.id`, ""),
          //   expense_type: getValue(props, `formInfo.expense_type.id`, ""),
          //   paid_through: getValue(props, `formInfo.paid_through.id`, ""),
          //   personal: getValue(props, `formInfo.personal`, false),
          //   receipt_ids: getValue(props, `formInfo.receipts`, []).map(
          //     (receipt: any) => receipt.id
          //   ),
          // };

          // let respForm;

          //   respForm = await updateExpenseEntry(
          //     getValue(props, `formInfo.id`, ""),
          //     removeNullOrUndefinedProperties(requestPayloadExpense)
          //   );

          // if (respForm) {
          //     toast.success(getValue(resp, `message`, ""));
          //     navigate(
          //       `/client/reports/detail/${getValue(resp, `data.id`, "")}`
          //     );
          //   setSubmitLoadingReport(false);
          // } else {
          //   setSubmitLoadingReport(false);
          // }

          let payload = {
            expense_entry_ids: [getValue(props, `formInfo.id`, "")],
            amount: {}, // Add amount if needed
          };
          try {
            let attachResp = await attachExpenseEntryInReport(
              getValue(resp, `data.id`, ""),
              payload
            );
            if (attachResp) {
              toast.success(getValue(resp, `message`, ""));
              navigate(
                `/client/reports/detail/${getValue(resp, `data.id`, "")}`
              );
            }
          } catch (error) {}
          /* ---------------- */

          simpleValidator.current.hideMessages();
          forceUpdate(0);
          setSubmitLoadingReport(false);
        } else {
          setSubmitLoadingReport(false);
        }
      } catch (error) {
        setSubmitLoadingReport(false);
      }
    }
  };

  const message = (htmlString: string) => {
    if (htmlString) return htmlString.replace(/₹ null/g, "");
    else return "";
  };

  return (
    <>
      {getValue(props, `formInfo.status`, "") == "not_associated" ? (
        <>
          <WarningMessage
            notiIcon={<SquareChevronRight size="18" />}
            notiHeading="What's Next?"
            notiMessage="Add this expense to a report and submit it to claim reimbursement"
            notiChildren={
              <UncontrolledDropdown direction="end">
                <DropdownToggle
                  caret
                  color="primary"
                  className="associate-create-report-btn"
                >
                  <div className="d-flex align-items-center gap-2">
                    Add to Report
                    <ArrowDownSvgComponent color={"#ffffff"} />
                  </div>
                </DropdownToggle>
                <DropdownMenu className="dd-reportlist">
                  <div className="dd-reportlist-holder">
                    {getValue(props, `reportList`, []).map(
                      (report: any, index: any) => (
                        <div
                          key={index}
                          className="dd-reportname-list-wrap"
                          onClick={() => handleAddtoReport(report.id)}
                        >
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="dd-reportname-id">
                              {report.report_id}
                            </div>

                            <div className="dd-reportname-currency">
                              {report.currency_symbol}
                              {""}
                              {report.total}
                            </div>
                          </div>

                          <div className="dd-reportname-name">
                            {report.report_name}
                          </div>

                          <div className="dd-reportname-duration">
                            {convertDateWithSlash(report.duration_from)} -{" "}
                            {convertDateWithSlash(report.duration_to)}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  <DropdownItem divider />
                  <DropdownItem
                    onClick={() => handleCreateReport()}
                    className="d-flex align-items-center associate-add-report-button"
                  >
                    <AddSvgComponent color={"#69A4FC"} />
                    <div className="ms-2">Add to New Report</div>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            }
            infoHeading={`Warning Violations (${getValue(
              props,
              `formInfo.policy_violations.length`,
              0
            )})`}
            showNoti
            infoMessage={getValue(props, `formInfo.policy_violations`, []).map(
              (item: object) => {
                return (
                  <p className="small_text__13 ">
                    - {message(getValue(item, `display_msg`, ""))}
                  </p>
                );
              }
            )}
            showInfo={
              getValue(props, `formInfo.policy_violations.length`, 0) > 0
            }
          />

          <ReportsPopup
            showUpdatePopup={showUpdatePopup}
            setShowUpdatePopup={setShowUpdatePopup}
            form={reportForm}
            setForm={setReportForm}
            formInfo={reportFormInfo}
            simpleValidator={simpleValidator}
            handleSubmit={handleSubmitSaveReport}
            submitLoading={submitLoadingReport}
          />
        </>
      ) : null}
    </>
  );
}

export default ExpenseAssociatedSection;
