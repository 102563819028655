import { getColorCode, getTextColor } from "@common/color";
import { getFirstLetter } from "@common/text-helpers";
import { getFirstLetterOfEachString } from "@common/text-helpers";
import { getValue } from "@utils/lodash";

function ProfileCircle(props: any) {
  const { item, name, value } = props;
  return (
    <div>
      {(value || (value ? value : getValue(item, `[${name}].email`, ""))) && (
        <div
          className="profile_circle"
          style={{
            background: getColorCode(
              getFirstLetter(
                value ? value : getValue(item, `[${name}].email`, "")
              )
            ),
          }}
        >
          <h5
            className="title"
            style={{
              color: getTextColor(
                getFirstLetter(
                  value ? value : getValue(item, `[${name}].email`, "")
                )
              ),
            }}
          >
            {getFirstLetterOfEachString(
              value ? value : getValue(item, `[${name}].email`, "")
            )}
          </h5>
        </div>
      )}
    </div>
  );
}

export default ProfileCircle;
