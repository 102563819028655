import { getValue } from "@utils/lodash";
import { ArrowUp } from "lucide-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";

const baseDataStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "12px",
  borderWidth: "2px",
  borderRadius: "4px",
  borderColor: "#ececf9",
  borderStyle: "dashed",
  backgroundColor: "#fff",
  color: "#202124",
  height: "180px",
  justifyContent: "center",
  transition: "border .3s ease-in-out",
  width: "150px",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#adff2f",
};

const rejectStyle = {
  borderColor: "red",
};

function DragAndDropFileComponent(props) {
  const baseStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "24.28px",
    borderWidth: "3px",
    borderRadius: "8px",
    borderColor: "#ececf9",
    borderStyle: "dashed",
    backgroundColor: "#fff",
    color: "#202124",
    height: props.customHeight ? props.customHeight : "166px",
    // height: "166px",
    justifyContent: "center",
    transition: "border .3s ease-in-out",
    width: "94%",
    margin: "0 auto",
  };
  const [files, setFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    props.uploadFile(acceptedFiles);
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    onDrop,
    accept: ".csv",
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const thumbs = acceptedFiles.map((file) => (
    <div key={file.name}>
      <p>{file.name} </p>
    </div>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <li key={file.path}>
        {file.path} - {file.size} bytes
        <ul>
          {errors.map((e) => (
            <li key={e.code}>{e.message}</li>
          ))}
        </ul>
      </li>
    );
  });
  // clean up
  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  return (
    <section className="cursor-pointer expense-add-custom-inputfile">
      <div
        {...getRootProps({
          style: props.fileAvailable ? baseDataStyle : style,
        })}
      >
        <input {...getInputProps()} />
        <div className="custom-inputfile">
          <div className="mb-3">
            <ArrowUp
              size={22}
              color="#202124"
              className="arrow-upload-container"
            />
          </div>
          <p className="custom-inputfile__text">
            Attach receipts{" "}
            {getValue(props, `mainInfo.is_receipt_mandatory`, false) && (
              <span className="form-label-error">*</span>
            )}
          </p>
          <p className="custom-inputfile__text">OR</p>
          <a href="#0">Browse Files</a>
        </div>
        <p style={{ color: "red" }}> {fileRejectionItems} </p>
      </div>
    </section>
  );
}

export default DragAndDropFileComponent;
