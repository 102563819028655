import AdminOrganisationCurrencies from "@pages/Admin/Organisation/Currencies";
import AdminOrgCurrencyDetailInfo from "@pages/Admin/Organisation/Currencies/Detail";
import AdminOrganisationProfile from "@pages/Admin/Organisation/OrgProfile";

export const AdminOrganisationRoutes = [
  {
    path: "/admin/organisation/profile",
    name: "admin_organisations_org_profile",
    component: AdminOrganisationProfile,
  },
  {
    path: "/admin/organisation/currencies",
    name: "admin_organisations_currencies",
    component: AdminOrganisationCurrencies,
  },
  {
    path: "/admin/organisation/currency/add",
    name: "admin_organisations_currencies",
    component: AdminOrgCurrencyDetailInfo,
  },
  {
    path: "/admin/organisation/currency/edit/:id",
    name: "admin_organisations_currencies",
    component: AdminOrgCurrencyDetailInfo,
  },
];
