import "./index.scss";
import LoginPageLyout from "@layouts/login";
import { useState, useRef } from "react";
import { adminLogin, getAllPermissionsWithToken } from "@services/auth.service";
import { getValue } from "@utils/lodash";
import SimpleReactValidator from "simple-react-validator";
import InputField from "@common/InputRuleFeild";
import { useNavigate } from "react-router-dom";
import { getAllOrganisationByToken } from "@services/organisation.service";
import { aesGcmEncrypt } from "@common/text-helpers";
import _ from "lodash";
import { PrivateRoutes } from "../../../routes/private.routes";
interface ILoginPageProps {}

const LoginPage: React.FunctionComponent<ILoginPageProps> = (props: any) => {
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setIsLoading(true);
        let pass = await aesGcmEncrypt(password);
        let resp = await adminLogin({ email: email, password: pass });
        if (resp) {
          setIsLoading(false);
          localStorage.setItem(
            "accessToken",
            getValue(resp, `data.accessToken`, "")
          );
          localStorage.setItem(
            "refreshToken",
            getValue(resp, `data.refreshToken`, "")
          );
          localStorage.setItem(
            "user",
            JSON.stringify(getValue(resp, `data.user`, ""))
          );
          if (getValue(resp, `data.accessToken`, "")) {
            getData(getValue(resp, `data.accessToken`, ""));
          }
          let route = await getPermissions(
            getValue(resp, `data.accessToken`, "")
          );
          if (route) {
            window.location.href = route;
          } else {
            window.location.href = `/`;
          }
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const getData = async (token: string) => {
    try {
      let resp = await getAllOrganisationByToken(token);
      if (resp) {
        if (
          getValue(resp, `data.organisations[${0}].organisation_logo.url`, "")
        ) {
          localStorage.setItem(
            "organisation_logo",
            getValue(resp, `data.organisations[${0}].organisation_logo.url`, "")
          );
        }
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  const navigate = useNavigate();

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && e.target.tagName.toLowerCase() !== "button") {
      e.preventDefault();
      handleSubmit(e);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                             Permission section                             */
  /* -------------------------------------------------------------------------- */
  const getPermissions = async (token: string) => {
    try {
      let resp = await getAllPermissionsWithToken(token);
      if (resp) {
        return formatPermissions(getValue(resp, `data.permissions`, []));
        // formatPermissions(rolesMock)
      } else {
      }
    } catch (error) {}
  };
  const covertSecondString = (s: string) => {
    var nth = 0;
    s = s.replace(/:/g, function (match, i, original) {
      nth++;
      return nth === 2 ? "-" : match;
    });
    return s;
  };
  const formatPermissions = (rolesMock: any) => {
    const filteredRoles = rolesMock.filter(Boolean);

    const list = filteredRoles.map((item: any) => {
      const itemToSplit = covertSecondString(item);
      return itemToSplit.includes("-")
        ? itemToSplit.split("-")
        : itemToSplit.split(":");
    });

    const arr = list.map(([tab, permission]: string[]) => ({
      tab,
      permission,
    }));

    const groupedPermission = arr.reduce(
      (acc: any, { tab, permission }: any) => {
        acc[tab] = [...(acc[tab] || []), { tab, permission }];
        return acc;
      },
      {}
    );

    const arr1: any = Object.entries(groupedPermission).map(
      ([key, value]: any) => {
        const [tab, tabKey] = key.split(":");
        const permissions = value.map(({ permission }: any) => permission);
        return { tab, tabKey: tabKey ? `${tab}_${tabKey}` : tab, permissions };
      }
    );

    const subTabList = _.uniq(arr1.map(({ tabKey }: any) => tabKey));

    const adminPermissions = subTabList.filter((item: any) =>
      item.startsWith("admin_")
    );
    const clientPermissions = subTabList.filter(
      (item: any) => !item.startsWith("admin_")
    );

    if (getValue(clientPermissions, `length`, 0) > 0) {
      localStorage.setItem("view", "client");
      let firstRoute = clientPermissions[0];
      let findRoute: any = PrivateRoutes.find(
        (item: object) => getValue(item, `name`, "") == firstRoute
      );
      return getValue(findRoute, `path`, "");
    } else {
      localStorage.setItem("view", "admin");
      let firstRoute = adminPermissions[0];
      let findRoute: any = PrivateRoutes.find(
        (item: object) => getValue(item, `name`, "") == firstRoute
      );
      return getValue(findRoute, `path`, "");
    }
  };

  return (
    <>
      <LoginPageLyout>
        {/* <div className="flex flex-column"> */}
        <div className="form-wrapper__form">
          {/* <img src="/images/logo.png" className="signin-mobile-logo" /> */}
          {/* m-auto  */}
          <form autoComplete="on">
            <h2>Sign in</h2>
            <p>To continue to portal</p>
            <div className="login-fields-wrap">
              <label htmlFor="email">Email</label>
              <InputField
                inputType="EMAIL"
                id="email"
                name="email"
                value={email}
                onChange={(e: any) => setEmail(e.target.value.toLowerCase())}
                placeholder="Enter Email ID"
                className="form-control"
                label="Email"
                validator={simpleValidator}
                onKeyDown={handleKeyDown}
                autoComplete="on"
              />
            </div>
            <div className="login-fields-wrap">
              <label>Password</label>
              <InputField
                inputType="PASSWORD"
                id="password"
                name="password"
                value={password}
                onChange={(e: any) => setPassword(e.target.value)}
                placeholder="Enter Password"
                className="form-control"
                validator={simpleValidator}
                label={"Password"}
                onKeyDown={handleKeyDown}
                autoComplete="on"
              />
            </div>
            <div className="form-cta-button">
              <button
                className="form-cta-button__secondary-btn"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/auth/forgot-password`);
                }}
              >
                Forgot password?
              </button>
              {isLoading ? (
                <button className="form-cta-button__primary-btn">
                  Please wait...
                </button>
              ) : (
                <button
                  type={"submit"}
                  onClick={handleSubmit}
                  className="form-cta-button__primary-btn"
                >
                  Next
                </button>
              )}
            </div>
          </form>
        </div>
        {/* </div> */}
      </LoginPageLyout>
    </>
  );
};

export default LoginPage;
