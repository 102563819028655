import React, { useRef, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import InputField from "@common/InputRuleFeild";
import Header from "@components/common/Header";
import { getValue } from "@utils/lodash";
import SimpleReactValidator from "simple-react-validator";
// import {
// 	adminCreateRole,
// 	adminGetRolesListPermissions,
// 	adminGetSpecificRole,
// 	adminUpdateSpecificRole,
// } from "@services/roles.service";
import _ from "lodash";
import { Col, Row } from "reactstrap";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import "./roles-details.scss";
import { useCallbackPrompt } from "@prompt/useCallbackPrompt";
import PromptModal from "@prompt/modal";
// import NoAccessPage from "@components/common/NoAccess";
import { formatText } from "@common/text-helpers";
import {
  createRole,
  getSpecificRole,
  rolesAdminPermissionList,
  rolesPermissionList,
  updateRole,
} from "@services/roles.service";
import AdminNavigation from "@components/Admin/admin-navigation";
import Layout from "@layouts/withoutNaviagtion/withoutNavigationLayout";
import RolesNavigation from "@components/Admin/roles-navigation";
import ExpenseTabNavigation from "@pages/Client/Expense/Detail/DetailContainer/TabHeader/tabheader-navigation";
import RolesTabNavigateion from "./tabheader-navigation";
import RolesSection from "./roles-section";
import NoAccessPage from "@components/common/NoAccess";

interface IAdminUserControlRolesDetailsProps {}

const AdminUserControlRolesDetails: React.FunctionComponent<
  IAdminUserControlRolesDetailsProps
> = (props: any) => {
  let params = useParams();
  let navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [isLoading, setIsLoading] = React.useState(true);
  const [request, setRequest] = React.useState({
    title: "",
    description: "",
  });

  const [dirtyFields, setDirtyFields] = useState({
    title: "",
    description: "",
  });

  const [permissionList, setPermissionList] = useState<any>({});
  const [selectedPermissions, setSelectedPermissions] = useState<any>([]);
  const [mainPermissions, setMainPermissions] = useState([]);
  const [allList, setAllList] = useState<any>([]);

  const [permissionList1, setPermissionList1] = useState<any>({});
  const [selectedPermissions1, setSelectedPermissions1] = useState<any>([]);
  const [mainPermissions1, setMainPermissions1] = useState([]);
  const [allList1, setAllList1] = useState<any>([]);
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (props.permissionAPITriggered) {
      getData();
      if (getValue(params, `id`, "")) {
        getRole(getValue(params, `id`, ""));
      }
    }
  }, [props.permissionAPITriggered]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const getRole = async (id: string) => {
    try {
      let resp = await getSpecificRole(id);
      if (resp) {
        setDirtyFields({
          ...dirtyFields,
          title: getValue(resp, `data.title`, ""),
          description: getValue(resp, `data.description`, ""),
        });
        setRequest({
          ...request,
          title: getValue(resp, `data.title`, ""),
          description: getValue(resp, `data.description`, ""),
        });
        if (getValue(resp, `data.permissions.length`, 0) > 0) {
          getValue(resp, `data.permissions`, []).map((item: any) => {
            if (item.split("admin").length === 2) {
              selectedPermissions1.push(item);
            } else {
              selectedPermissions.push(item);
            }
          });
        } else {
        }
        // setSelectedPermissions(getValue(resp, `data.permissions`, []));
      }
    } catch (error) {}
  };
  const getData = async () => {
    try {
      setIsLoading(true);
      let resp = await rolesPermissionList();
      let adminResp = await rolesAdminPermissionList();
      if (adminResp) {
        let arr: any = [];
        getValue(adminResp, `data.permissions`, []).filter((item: any) => {
          if (item.is_tab_group) {
            arr.push(item);
          } else {
            arr.push({
              component: item.component,
              is_tab_group: true,
              items: [
                { component: item.component, permissions: item.permissions },
              ],
            });
          }
        });
        setMainPermissions1(arr);
        var grouped = _.mapValues(
          _.groupBy(getValue(adminResp, `data.permissions`, []), "component")
        );
        setPermissionList1(grouped);
        for (let i = 0; i < getValue(arr, `length`, 0); i++) {
          getValue(arr[i], `items`, []).map((item: any) => {
            return getValue(item, `permissions`, []).map((per: any) => {
              return allList1.push(getValue(per, `value`, ""));
            });
          });
        }
        setAllList1([...allList1]);
      }

      if (resp) {
        let arr: any = [];
        getValue(resp, `data.permissions`, []).filter((item: any) => {
          if (item.is_tab_group) {
            arr.push(item);
          } else {
            arr.push({
              component: item.component,
              is_tab_group: true,
              items: [
                { component: item.component, permissions: item.permissions },
              ],
            });
          }
        });
        setMainPermissions(arr);
        var grouped = _.mapValues(
          _.groupBy(getValue(resp, `data.permissions`, []), "component")
        );
        setPermissionList(grouped);
        for (let i = 0; i < getValue(arr, `length`, 0); i++) {
          getValue(arr[i], `items`, []).map((item: any) => {
            return getValue(item, `permissions`, []).map((per: any) => {
              return allList.push(getValue(per, `value`, ""));
            });
          });
        }
        setAllList([...allList]);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      if (
        getValue(selectedPermissions, `length`, 0) > 0 ||
        getValue(selectedPermissions1, `length`, 0) > 0
      ) {
        try {
          let payload = {
            title: request.title,
            description: request.description,
            permissions: selectedPermissions.concat(selectedPermissions1),
          };
          setSubmitLoading(true);
          if (getValue(params, `id`, "")) {
            let resp = await updateRole(getValue(params, `id`, ""), payload);
            if (resp) {
              navigate(`/admin/user-controls/roles`);
              toast.success("Updated successfully");
              setSubmitLoading(false);
              // window.location.reload();
            } else {
              setSubmitLoading(false);
            }
          } else {
            let resp = await createRole(payload);
            if (resp) {
              navigate(`/admin/user-controls/roles`);
              toast.success("Created successfully");
              setSubmitLoading(false);
            } else {
              setSubmitLoading(false);
            }
          }
        } catch (error) {
          setSubmitLoading(false);
        }
      } else {
        toast.error("All fields are mandatory");
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                          Client Onchange section                           */
  /* -------------------------------------------------------------------------- */
  const handleChangePermission = (value: string) => {
    let selected = selectedPermissions.filter((item: String) => item === value);
    if (getValue(selected, `length`, 0) > 0) {
      let selected = selectedPermissions.filter(
        (item: String) => item !== value
      );
      setSelectedPermissions(selected);
    } else {
      selectedPermissions.push(value);
      setSelectedPermissions([...selectedPermissions]);
    }
  };
  const handleChangeAllCheckList = (
    value: any,
    tab: string,
    status: string
  ) => {
    if (status === "true") {
      getValue(permissionList, `[${tab}][${0}].items.length`, 0) > 0
        ? getValue(permissionList, `[${tab}][${0}].items`, []).filter(
            (item: object) => {
              if (
                getValue(value, `component`, "") ===
                getValue(item, `component`, "")
              ) {
                getValue(item, `permissions`, []).map((per: any) => {
                  let checkExisted = selectedPermissions.filter(
                    (check: any) => check === getValue(per, `value`, "")
                  );
                  if (checkExisted.length === 0) {
                    selectedPermissions.push(getValue(per, `value`, ""));
                    setSelectedPermissions([...selectedPermissions]);
                  }
                });
              }
            }
          )
        : getValue(permissionList, `[${tab}][${0}].component`, "") ===
            getValue(value, `component`, "") &&
          getValue(permissionList, `[${tab}][${0}].permissions`, "").map(
            (per: any) => {
              let checkExisted = selectedPermissions.filter(
                (check: any) => check === getValue(per, `value`, "")
              );
              if (checkExisted.length === 0) {
                selectedPermissions.push(getValue(per, `value`, ""));
                setSelectedPermissions([...selectedPermissions]);
              }
            }
          );
    } else {
      getValue(permissionList, `[${tab}][${0}].items.length`, 0) > 0
        ? getValue(permissionList, `[${tab}][${0}].items`, []).filter(
            (item: object) => {
              if (
                getValue(value, `component`, "") ===
                getValue(item, `component`, "")
              ) {
                let array1 = getValue(value, `permissions`, []).map(
                  (item: any) => item.value
                );
                let array2 = selectedPermissions;
                setSelectedPermissions(
                  array2.filter((val: any) => !array1.includes(val))
                );
              }
            }
          )
        : getValue(permissionList, `[${tab}][${0}].component`, "") ===
            getValue(value, `component`, "") &&
          getValue(permissionList, `[${tab}][${0}].permissions`, "").map(
            (per: any) => {
              let array1 = getValue(value, `permissions`, []).map(
                (item: any) => item.value
              );
              let array2 = selectedPermissions;
              setSelectedPermissions(
                array2.filter((val: any) => !array1.includes(val))
              );
            }
          );
    }
  };
  const handleSelectAll = (status: any) => {
    if (status === "true") {
      let arr: any = [];
      allList.map((per: any) => {
        return arr.push(per);
      });
      setSelectedPermissions(arr);
    } else {
      setSelectedPermissions([]);
    }
  };
  function sameMembers(arr1: any, arr2: any) {
    return arr1.some((r: any) => arr2.indexOf(r) >= 0);
  }

  /* -------------------------------------------------------------------------- */
  /*                           Admin Onchange section                           */
  /* -------------------------------------------------------------------------- */
  const handleChangePermission1 = (value: string) => {
    let selected = selectedPermissions1.filter(
      (item: String) => item === value
    );
    if (getValue(selected, `length`, 0) > 0) {
      let selected = selectedPermissions1.filter(
        (item: String) => item !== value
      );
      setSelectedPermissions1(selected);
    } else {
      selectedPermissions1.push(value);
      setSelectedPermissions1([...selectedPermissions1]);
    }
  };
  const handleChangeAllCheckList1 = (
    value: any,
    tab: string,
    status: string
  ) => {
    if (status === "true") {
      getValue(permissionList1, `[${tab}][${0}].items.length`, 0) > 0
        ? getValue(permissionList1, `[${tab}][${0}].items`, []).filter(
            (item: object) => {
              if (
                getValue(value, `component`, "") ===
                getValue(item, `component`, "")
              ) {
                getValue(item, `permissions`, []).map((per: any) => {
                  let checkExisted = selectedPermissions1.filter(
                    (check: any) => check === getValue(per, `value`, "")
                  );
                  if (checkExisted.length === 0) {
                    selectedPermissions1.push(getValue(per, `value`, ""));
                    setSelectedPermissions1([...selectedPermissions1]);
                  }
                });
              }
            }
          )
        : getValue(permissionList1, `[${tab}][${0}].component`, "") ===
            getValue(value, `component`, "") &&
          getValue(permissionList1, `[${tab}][${0}].permissions`, "").map(
            (per: any) => {
              let checkExisted = selectedPermissions1.filter(
                (check: any) => check === getValue(per, `value`, "")
              );
              if (checkExisted.length === 0) {
                selectedPermissions1.push(getValue(per, `value`, ""));
                setSelectedPermissions1([...selectedPermissions1]);
              }
            }
          );
    } else {
      getValue(permissionList1, `[${tab}][${0}].items.length`, 0) > 0
        ? getValue(permissionList1, `[${tab}][${0}].items`, []).filter(
            (item: object) => {
              if (
                getValue(value, `component`, "") ===
                getValue(item, `component`, "")
              ) {
                let array1 = getValue(value, `permissions`, []).map(
                  (item: any) => item.value
                );
                let array2 = selectedPermissions1;
                setSelectedPermissions1(
                  array2.filter((val: any) => !array1.includes(val))
                );
              }
            }
          )
        : getValue(permissionList1, `[${tab}][${0}].component`, "") ===
            getValue(value, `component`, "") &&
          getValue(permissionList1, `[${tab}][${0}].permissions`, "").map(
            (per: any) => {
              let array1 = getValue(value, `permissions`, []).map(
                (item: any) => item.value
              );
              let array2 = selectedPermissions1;
              setSelectedPermissions1(
                array2.filter((val: any) => !array1.includes(val))
              );
            }
          );
    }
  };
  const handleSelectAll1 = (status: any) => {
    if (status === "true") {
      let arr: any = [];
      allList1.map((per: any) => {
        return arr.push(per);
      });
      setSelectedPermissions1(arr);
    } else {
      setSelectedPermissions1([]);
    }
  };

  const [activeTab, setActiveTab] = useState(1);
  const handleActiveTab = (tab: any) => {
    setActiveTab(tab);
  };
  return (
    <Layout>
      <Header />
      {!isLoading &&
      !getValue(props, `subTabPermissionList`, []).includes(
        "admin_user_controls_roles_permission"
      ) ? (
        <NoAccessPage />
      ) : (
        <>
          {/* <PromptModal
          showDialog={showPrompt}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
        /> */}
          <RolesNavigation
            id={params.id}
            handleSubmit={handleSubmit}
            isLoading={submitLoading}
            url={`/admin/user-controls/roles`}
            headerTitle={"Create Role"}
            permissions={props.permissions}
          />
          <div className="add-rolls-main-wrapper">
            <div className="rolls-wrapper">
              <div className="rolls-wrapper__form-wrapper">
                <div className="rolls-wrapper__form">
                  <div className="rolls-wrapper__form-group form-group">
                    <label htmlFor="title" className="rolls-label rolls-label">
                      Role Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Role Name"
                      className="form-control rolls-form-control"
                      id="title"
                      autoComplete="off"
                      value={getValue(request, `title`, "")}
                      onChange={(e: any) =>
                        setRequest({ ...request, title: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="rolls-wrapper__form-group form-group">
                  <label
                    htmlFor="descriptiom"
                    className="rolls-label rolls-label"
                  >
                    Description <span className="text-danger">*</span>
                  </label>
                  <InputField
                    inputType="TEXTAREA"
                    placeholder="Enter Description"
                    className="form-control w-100"
                    name="description"
                    id="description"
                    value={getValue(request, `description`, "")}
                    onChange={(e: any) =>
                      setRequest({ ...request, description: e.target.value })
                    }
                    label="Description"
                    validator={simpleValidator}
                  />
                </div>
                <RolesTabNavigateion
                  activeTab={activeTab}
                  handleActiveTab={handleActiveTab}
                />
                {activeTab === 1 && (
                  <RolesSection
                    allList={allList}
                    handleSelectAll={handleSelectAll}
                    selectedPermissions={selectedPermissions}
                    isLoading={isLoading}
                    mainPermissions={mainPermissions}
                    handleChangeAllCheckList={handleChangeAllCheckList}
                    handleChangePermission={handleChangePermission}
                    sameMembers={sameMembers}
                    name="client"
                  />
                )}
                {activeTab === 2 && (
                  <RolesSection
                    allList={allList1}
                    handleSelectAll={handleSelectAll1}
                    selectedPermissions={selectedPermissions1}
                    isLoading={isLoading}
                    mainPermissions={mainPermissions1}
                    handleChangeAllCheckList={handleChangeAllCheckList1}
                    handleChangePermission={handleChangePermission1}
                    sameMembers={sameMembers}
                    name="admin"
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default AdminUserControlRolesDetails;
