import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getEmailTemplates = (queryRequest: string) =>
  get(`${config.API_URL}/email-template?${queryRequest}`);

export const createEmailTemplate = (payload: object) =>
  post(`${config.API_URL}/email-template`, payload);

export const getSpecificEmailTemplate = (id: string) =>
  get(`${config.API_URL}/email-template/${id}`);

export const getEmailTemplateKeys = (queryRequest: string) =>
  get(`${config.API_URL}/email-template/get-keys?${queryRequest}`);

export const getEmailTemplateTypes = (queryRequest: string) =>
  get(`${config.API_URL}/email-template/get-types?${queryRequest}`);

export const updateEmailTemplate = (id: string, payload: object) =>
  patch(`${config.API_URL}/email-template/${id}`, payload);

export const deleteEmailTemplate = (id: string) =>
  Delete(`${config.API_URL}/email-template/${id}`);

export const updateExpenseEmailTemplateBulk = (payload: object) =>
  patch(`${config.API_URL}/email-template/bulk/active-inactive`, payload);
