import React from "react";
import { Link } from "react-router-dom";

const reviews = [
  {
    initial: "R",
    background: "#C3315C",
    user: "Ramesh Kumar",
    rating: "3.5",
  },
  {
    initial: "A",
    background: "#33691E",
    user: "Anthony S",
    rating: "4.0",
  },
  {
    initial: "A",
    background: "#16579B",
    user: "Aarti Sharma",
    rating: "4.1",
  },
  {
    initial: "B",
    background: "#7C32A2",
    user: "Bijoy Sadananda",
    rating: "3.8",
  },
  {
    initial: "P",
    background: "#16579B",
    user: "Payal Sharma",
    rating: "3.0",
  },
];

const PropertyReview = () => {
  return (
    <div className=" d-flex flex-column align-items-center gap-4 mb-5 pt-4">
      <div className="w-75">
        <h5>Review Summary</h5>
        <div className="d-flex gap-1 align-items-center gray-color my-2 ">
          3.8
          <div className="calender-border-left d-flex gap-1 align-items-center ps-2">
            <img src="/images/dashboard/trips/online/star.png" />
            <img src="/images/dashboard/trips/online/star.png" />
            <img src="/images/dashboard/trips/online/star.png" />
            <img src="/images/dashboard/trips/online/star-half.png" />
            <img src="/images/dashboard/trips/online/star-path.png" />
          </div>
          <Link href="/" className="color_primary ps-3">
            120 Reviews
          </Link>
        </div>
      </div>
      {reviews.map((review, index) => (
        <div className="w-75 border border-radius__8 d-flex gap-4 p-4">
          <div
            className="d-flex justify-content-center align-items-center text-white"
            style={{
              background: review.background,
              width: "74px",
              height: "40px",
              borderRadius: "50%",
            }}
          >
            {review.initial}
          </div>
          <div>
            <div className="d-flex justify-content-between">
              <div>
                <h6>{review.user}</h6>
                <p className="small_text__14">3 Days ago</p>
              </div>
              <h4>{review.rating}/5</h4>
            </div>
            <p className="small_text__16 mt-3">
              Loved this place…you can hold your beer and enjoy the scenario
              from rooftop. And the space, ambience and hygienic conditions are
              so good. You may find this place a little bit noisy because of its
              location but trust me this place stands out among many hotels of
              jaipur.
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PropertyReview;
