import SettingsBasicInformation from "@pages/Client/Settings/BasicInformation";
import SettingsTravelDocuments from "@pages/Client/Settings/TravelDocuments";
import SettingsTravelProfile from "@pages/Client/Settings/TravelProfile";

export const ClientSettingsRoutes = [

  {
    path: "/client/settings/personal-information",
    name: "settings_personal_information",
    component: SettingsBasicInformation,
  },
  {
    path: "/client/settings/travel-profile",
    name: "settings_travel_profile",
    component: SettingsTravelProfile,
  },
  {
    path: "/client/settings/travel-documents",
    name: "settings_travel_documents",
    component: SettingsTravelDocuments,
  },
];
