import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "../index.scss";
import StickyHeader from "./StickyHeader";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import { getValue } from "@utils/lodash";
import {
  expenseRecordReimbursement,
  getAllExpenseReportFormEdit,
} from "@services/expense-reports.service";
import { getSpecificExpenseReport } from "@services/expense-reports.service";
import InputField from "@common/InputRuleFeild";
import SimpleReactValidator from "simple-react-validator";
import SingleSelectSearchDropdown from "@components/common/CustomSearchableDropdown/SingleSelectSearchDropdown";
import { toast } from "react-toastify";
import NoAccessPage from "@components/common/NoAccess";
import GearLoader from "@components/common/GearLoader";
function ReimbursementPage(props: any) {
  const navigate = useNavigate();
  const params = useParams();
  const [request, setRequest] = useState({
    date: "",
    expense_report_id: getValue(params, `id`, ""),
    paid_through: "",
    reference_number: "",
    note: "",
    reimbursed_amount: 1,
  });
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (props.permissionAPITriggered) {
      getData();
    }
  }, [props.permissionAPITriggered]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [info, setInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const getData = async () => {
    try {
      setIsLoading(true);
      let resp = await getSpecificExpenseReport(getValue(params, `id`, ""));
      if (resp) {
        setInfo(getValue(resp, `data`, {}));
        setRequest({
          ...request,
          reimbursed_amount: getValue(resp, `data.total`, "")
            ? parseFloat(getValue(resp, `data.total`, ""))
            : 0,
        });
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                                 Submit section                             */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      setSubmitLoading(true);
      try {
        let resp = await expenseRecordReimbursement(request);
        if (resp) {
          toast.success(getValue(resp, `message`, ""));
          navigate(`/admin/reports/detail/${getValue(params, `id`, "")}`);
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };
  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      <div className="reimbursement_create_container">
        <StickyHeader
          info={info}
          route={`/admin/reports/detail/${getValue(params, `id`, "")}`}
        />
        {!isLoading &&
        !getValue(props, `subTabPermissionList`, []).includes(
          "admin_reports"
        ) ? (
          <NoAccessPage />
        ) : (
          <>
            {/* <div className="d-flex align-items-start reimbursement_create_container_section pb-5"> */}
            <div className="d-flex align-items-start reimbursement_create_container_section pb-5 justify-content-center">
              <div className="reimbursement_form_container border border-radius__8 ml-0 ms-0">
                <h6 className="header_text__20 pt-3 px-4">
                  Manual Reimbursement
                </h6>
                <hr />
                <div className="px-5 pb-3 reimbursement-form-wrap">
                  <div className="user-details-wrapper__form-group form-group">
                    <label className="checkpoint-active-details__label">
                      Amount to be Reimbursed
                      <span></span>
                    </label>
                    <InputField
                      inputType="TEXT"
                      label="Reference#"
                      placeholder={"Enter Reference Number"}
                      value={getValue(request, `reimbursed_amount`, "")}
                      onChange={(e: any) =>
                        setRequest({
                          ...request,
                          reimbursed_amount: e.target.value,
                        })
                      }
                      name="reimbursed_amount"
                      disabled
                    />
                  </div>

                  <div className="user-details-wrapper__form-group form-group">
                    <label className="checkpoint-active-details__label form-label">
                      Reimbursed on <span className="form-label-error">*</span>
                    </label>
                    <InputField
                      inputType="DATETYPE-DOB"
                      label="Reimbursed on"
                      placeholder={"Reimbursed on"}
                      name="date"
                      selected={getValue(request, `date`, "")}
                      value={getValue(request, `date`, "")}
                      onChange={(e: any) =>
                        setRequest({
                          ...request,
                          date: e,
                        })
                      }
                      validator={simpleValidator}
                    />
                  </div>
                  <div className="user-details-wrapper__form-group form-group">
                    <label className="checkpoint-active-details__label">
                      Paid Through<span></span>
                    </label>
                    <SingleSelectSearchDropdown
                      label={"label"}
                      name="paid_through"
                      value={getValue(request, `paid_through`, "")}
                      onChange={(e: any) =>
                        setRequest({
                          ...request,
                          paid_through: getValue(e, `value`, ""),
                        })
                      }
                      placeholder={"Choose Paid Through"}
                      data={[
                        { value: "petty_cash", label: "Petty Cash" },
                        { value: "card", label: "Card" },
                        { value: "netbanking", label: "Net Banking" },
                        { value: "salary", label: "Salary" },
                      ]}
                      width={"415px"}
                      selectKey={"value"}
                      // validator={simpleValidator}
                    />
                  </div>
                  <div className="user-details-wrapper__form-group form-group">
                    <label className="checkpoint-active-details__label">
                      Notes<span></span>
                    </label>
                    <InputField
                      inputType="TEXTAREA"
                      label="Notes"
                      placeholder={"Enter Notes"}
                      value={getValue(request, `note`, "")}
                      onChange={(e: any) =>
                        setRequest({
                          ...request,
                          note: e.target.value,
                        })
                      }
                      name="note"
                      // validator={simpleValidator}
                    />
                  </div>
                  <div className="user-details-wrapper__form-group form-group">
                    <label className="checkpoint-active-details__label">
                      Reference#<span></span>
                    </label>
                    <InputField
                      inputType="TEXT"
                      label="Reference#"
                      placeholder={"Enter Reference Number"}
                      value={getValue(request, `reference_number`, "")}
                      onChange={(e: any) =>
                        setRequest({
                          ...request,
                          reference_number: e.target.value,
                        })
                      }
                      name="reference_number"
                    />
                  </div>
                  <div className="delete-popup-wrapper__cta-wrapper mt-3">
                    {/* <button
              className="delete-popup-wrapper__cta-btn delete-popup-wrapper__cta-btn--secondary"
            >
              Cancel
            </button> */}
                    <button
                      className="delete-popup-wrapper__cta-btn delete-popup-wrapper__cta-btn--primary"
                      onClick={handleSubmit}
                      disabled={submitLoading}
                    >
                      {submitLoading
                        ? "Please wait..."
                        : "Record Reimbursement"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="reimbursement_right_container reimbursement_form_container border border-radius__8 bg-white">
                <h6 className="header_text__20 pt-3 px-4">
                  {/* REIMBURSEMENT SUMMARY */}
                  Reimbursement Summary
                </h6>
                <hr />
                <div className="p-4">
                  <div className="d-flex justify-content-between">
                    <h6 className="header_text__14">
                      Total Reimbursable Amount
                    </h6>
                    <p className="desc_text__14">
                      {getValue(info, `currency_symbol`, "")}{" "}
                      {getValue(request, `reimbursed_amount`, 0)}
                    </p>
                  </div>
                  <div className="success_container p-3 mt-4 border-radius__8">
                    <div className="d-flex justify-content-between align-items-center">
                      <h6 className="header_text__20 color_success">
                        Amount to be Reimbursed
                      </h6>
                      <p className="desc_text__20 color_success">
                        {getValue(info, `currency_symbol`, "")}{" "}
                        {getValue(request, `reimbursed_amount`, 0)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </DashboardLayout>
  );
}

export default ReimbursementPage;
