import { getValue } from "@utils/lodash";
import { formHandleTextArrayChangeByKey } from "@common/handleChange";
import CloseSmallSvgComponent from "@assets/svg-components/close-small";
import InputField from "@common/InputRuleFeild";
import TripCityComponent from "../common/trip_city";
import TripDateComponent from "../common/trip_date";
import AddSvgComponent from "@assets/svg-components/add";
import { CirclePlus } from "lucide-react";

function TripCreateBusSection(props: any) {
  /* -------------------------------------------------------------------------- */
  /*                               Car CRUD Section                             */
  /* -------------------------------------------------------------------------- */
  const addSection = () => {
    let obj = {
      departure_date: "",
      departure_city: "",
      departure_city_name: "",
      arrival_city: "",
      arrival_city_name: "",
      // is_personal_travel: false,
    };
    props.itenaries.push(obj);
    props.setItenaries([...props.itenaries]);
  };
  const removeSection = (index: number) => {
    let filtered =
      getValue(props, `itenaries.length`, 0) > 0
        ? getValue(props, `itenaries`, []).filter(
            (item: object, i: number) => index !== i
          )
        : [];
    props.setItenaries(filtered);
    props.hideValidation();
  };
  return (
    <div>
      <div className="trips-bus-tab-main-container">
        {" "}
        {/*px-4 mt-4 w-100*/}
        <div className="mt-4">
          {getValue(props, `itenaries.length`, 0) > 0 ? (
            getValue(props, `itenaries`, []).map(
              (item: object, index: number) => {
                return (
                  <div className="multiple-dyno-wrap">
                    <div className="d-flex justify-content-sm-between align-items-center w-100">
                      <div className="w-100">
                        <div className="d-flex flex-wrap_wrap">
                          <div className="">
                            <TripCityComponent
                              item={item}
                              index={index}
                              validator={props.validator}
                              setItenaries={props.setItenaries}
                              label={"Departure City"}
                              city_key={"departure_city"}
                              city_name_key={"departure_city_name"}
                              locationIcon
                            />
                          </div>
                          <div className="">
                            <TripCityComponent
                              item={item}
                              index={index}
                              validator={props.validator}
                              setItenaries={props.setItenaries}
                              label={"Arrival City"}
                              city_key={"arrival_city"}
                              city_name_key={"arrival_city_name"}
                              locationIcon
                            />
                          </div>
                          <div className="">
                            <TripDateComponent
                              validator={props.validator}
                              item={item}
                              index={index}
                              date_key={"departure_date"}
                              setItenaries={props.setItenaries}
                              label={"Departure Date"}
                            />
                          </div>
                        </div>
                        <div className="flight_description_wrap mb-16">
                          <InputField
                            inputType="TEXTAREA"
                            placeholder="Add Description (Max 200 Characters)"
                            rows={2}
                            name="description"
                            value={getValue(item, `description`, "")}
                            onChange={(e: any) =>
                              formHandleTextArrayChangeByKey(
                                index,
                                e,
                                props.setItenaries
                              )
                            }
                          />
                        </div>
                      </div>
                      <div
                        className=" mt-2"
                        onClick={() => removeSection(index)}
                      >
                        <CloseSmallSvgComponent />
                      </div>
                    </div>
                  </div>
                );
              }
            )
          ) : (
            <div className="mt-5 pt-5">
              <div className="d-flex justify-content-center mb-4">
                <img src="/images/notfound/trips-not-found.svg" />
              </div>
              <p className="header_text__19 text-center not-found-text">
                No Bus have been included. <br /> Kindly select the option below
                to add them.
              </p>
              <div
                className="d-flex justify-content-center align-items-center cursor-pointer  mt-4"
                onClick={addSection}
              >
                <div className="border border-radius__8 d-flex justify-content-center p-2 px-3">
                  <AddSvgComponent size={28} color="#1966A1" />
                  <h6 className="header_text__18 ms-2 color_primary">
                    Add Bus
                  </h6>
                </div>
              </div>
            </div>
          )}
        </div>
        {getValue(props, `itenaries.length`, 0) > 0 && (
          <div
            className="custom_field_button_container mt-3"
            onClick={addSection}
          >
            <CirclePlus color="#1966A1" size={18} />
            <h6 className="header_text__16 color_primary ms-2">
              {getValue(props, `itenaries.length`, 0) > 0 && "Add another bus"}
            </h6>
          </div>
        )}
      </div>
    </div>
  );
}

export default TripCreateBusSection;
