import React, { useEffect, useState, useRef } from "react";
import "./add-user-info.scss";
import AdminNavigation from "@components/Admin/admin-navigation";
import { toast } from "react-toastify";
import { getValue } from "@utils/lodash";
import SimpleReactValidator from "simple-react-validator";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "@layouts/withoutNaviagtion/withoutNavigationLayout";
import _ from "lodash";
import GearLoader from "@components/common/GearLoader";
import {
  createUser,
  getUserForm,
  getUserFormEdit,
  updateUser,
} from "@services/users.service";
import {
  FormAppendDataRequestHelper,
  FormRequestHelper,
} from "@components/common/FormBuilder/request-helper";
import { RenderForm } from "@components/common/FormBuilder/render-form";
import { removeEmptyProperties } from "@common/text-helpers";
import NoAccessPage from "@components/common/NoAccess";

interface IAdminUserControlUserDetailsProps {}

const AdminUserControlUserDetails: React.FunctionComponent<IAdminUserControlUserDetailsProps> = (
  props: any
) => {
  let orgParams = useParams();
  let params = useParams();
  let navigate = useNavigate();

  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (props.permissionAPITriggered) {
      getUserForms();
    }
  }, [props.permissionAPITriggered]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const [form, setForm] = useState<any>([]);
  const [formInfo, setFormInfo] = useState({});
  const getUserForms = async () => {
    try {
      let resp;
      if (getValue(params, `id`, "")) {
        resp = await getUserFormEdit(getValue(params, `id`, ""));
      } else {
        resp = await getUserForm("");
      }
      if (resp) {
        setFormInfo(getValue(resp, `data`, {}));
        let array = getValue(resp, `data.form_layout`, []);
        if (getValue(params, `id`, "")) {
          for (let i = 0; i < getValue(array, `length`, 0); i++) {
            FormAppendDataRequestHelper(
              array[i],
              getValue(resp, `data.user`, {})
            );
          }
          setForm(getValue(resp, `data.form_layout`, []));
          setLoading(false);
        } else {
          setLoading(false);
          let user = FormRequestHelper(getValue(resp, `data.form_layout`, []));
          for (let i = 0; i < getValue(array, `length`, 0); i++) {
            FormAppendDataRequestHelper(array[i], user);
          }
          setForm(array);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        // let payload: any = request;
        let fields = JSON.parse(JSON.stringify(form));
        let payload = FormRequestHelper(fields);
        let requestPayload: any = removeEmptyProperties(payload);
        setIsLoading(true);
        if (getValue(params, `id`, "")) {
          requestPayload["middle_name"] = requestPayload["middle_name"]
            ? requestPayload["middle_name"]
            : "";
          let resp = await updateUser(
            getValue(params, `id`, ""),
            requestPayload
          );
          if (resp) {
            setIsLoading(false);
            toast.success("Updated successfully");
            navigate(`/admin/user-controls/users`);
          } else {
            setIsLoading(false);
          }
        } else {
          let resp = await createUser(requestPayload);
          if (resp) {
            setIsLoading(false);
            toast.success("Created successfully");
            navigate(`/admin/user-controls/users`);
          } else {
            setIsLoading(false);
          }
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };
  return (
    <>
      <Layout>
        {!loading &&
        !getValue(props, `subTabPermissionList`, []).includes(
          "admin_user_controls_users"
        ) ? (
          <NoAccessPage />
        ) : (
          <>
            <AdminNavigation
              id={orgParams.id}
              handleSubmit={handleSubmit}
              isLoading={isLoading}
              url={`/admin/user-controls/users`}
              permissions={props.permissions}
            />
            {
              <div className="user-details-wrapper mt-0 user-details-overview">
                {loading || !props.permissionAPITriggered ? (
                  <GearLoader />
                ) : (
                  <div className="">
                    <div className="form_flex_container">
                      <div className="form_trips_wrapper">
                        {getValue(form, `length`, 0) > 0
                          ? form.map((item: any, index: number) => {
                              return (
                                <div className="form_users_wrapper_container">
                                  <RenderForm
                                    item={item}
                                    setFields={setForm}
                                    index={index}
                                    formOptions={formInfo}
                                    simpleValidator={simpleValidator}
                                    id={getValue(params, `id`, "")}
                                    getUserForms={getUserForms}
                                  />
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </div>
                  </div>
                )}
                {/* <div className="map-vendor__footer w-100">
                <img src="/images/footer-image.svg" className="img-fluid" />
              </div> */}
              </div>
            }
          </>
        )}
      </Layout>
    </>
  );
};

export default AdminUserControlUserDetails;
