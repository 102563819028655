import { getValue } from "@utils/lodash";
import React from "react";

function FileLogSvgComponent(props: any) {
  return (
    <>
      <svg id="task_black_24dp" xmlns="http://www.w3.org/2000/svg" width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"
        }
         viewBox="0 0 24 24">
        <g id="Group_9547" data-name="Group 9547">
          <path id="Path_44679" data-name="Path 44679" d="M0,0H24V24H0Z" fill="none"/>
        </g>
        <g id="Group_9548" data-name="Group 9548">
          <path id="Path_44680" data-name="Path 44680" d="M14,2H6A2,2,0,0,0,4.01,4L4,20a2,2,0,0,0,1.99,2H18a2.006,2.006,0,0,0,2-2V8Zm4,18H6V4h7V9h5ZM8.82,13.05,7.4,14.46,10.94,18l5.66-5.66-1.41-1.41-4.24,4.24Z"
          fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "#5f6368"
          }/>
        </g>
      </svg>
    </>
  );
}

export default FileLogSvgComponent;
