import CashAdvanceSvgComponent from "@assets/svg-components/dashboard/advance";
import TripsSvgComponent from "@assets/svg-components/dashboard/trips";
import UploadSvgComponent from "@assets/svg-components/dashboard/upload";
import DoneSvgComponent from "@assets/svg-components/done";
import ExpenseSvgComponent from "@assets/svg-components/sidebar/expense";
import ReportsSvgComponent from "@assets/svg-components/sidebar/reports";
import { getValue } from "@utils/lodash";
import { CircleCheck, Clock, HandCoins, TicketsPlane } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";

function TaskSection(props: any) {
  const navigate = useNavigate();
  const handleNavigate = (url: string) => {
    navigate(url);
  };

  const data = [
    {
      icon: <HandCoins color="#5A2D5C " />,
      heading: "Expense <br/>Overview",
      approval: getValue(
        props,
        `dashboardInfo.awaiting_manager_approval_expenses`,
        ""
      ),
      pending: getValue(
        props,
        `dashboardInfo.my_approval_pending_expenses`,
        ""
      ),
      unsubmitted: getValue(props, `dashboardInfo.unsubmitted_expenses`, ""),
    },
    {
      icon: <TicketsPlane color="#5A2D5C " />,
      heading: "Trips<br/> Overview",
      approval: getValue(
        props,
        `dashboardInfo.awaiting_manager_approval_trips`,
        ""
      ),
      pending: getValue(props, `dashboardInfo.my_approval_pending_trips`, ""),
      unsubmitted: getValue(props, `dashboardInfo.unsubmitted_trips`, ""),
    },
  ];

  return (
    <div className="dashbaord-wrapper_info-section2 mb-4">
      {data.map((item, index) => (
        <div className="dashbaord-wrapper_info-section2_left" key={index}>
          <div className="dashbaord-wrapper_info-section2_left_header">
            <h6 className="header_text__22 p-5">
              {getValue(item, "icon", "")}
              <div
                contentEditable="true"
                dangerouslySetInnerHTML={{
                  __html: getValue(item, "heading", ""),
                }}
                className="text-start"
              ></div>
            </h6>
          </div>
          <div className="dashbaord-wrapper_info-section2_left_section">
            <div className="dashbaord-wrapper_info-section2_left_section_sub_item">
              <p className="header_text__count">
                {getValue(item, "approval", "")}
              </p>
              <div className="d-flex align-items-center ">
                <Clock color={"#ff8a00"} size={16} />
                <p className="small_text__20 ms-2">Awaiting Manager Approval</p>
              </div>
            </div>

            <div className="dashbaord-wrapper_info-section2_left_section_sub_item">
              <p className="header_text__count">
                {getValue(item, "pending", "")}
              </p>
              <div className="d-flex align-items-center">
                {/* <ExpenseSvgComponent color={"#5f6368"} /> */}
                <CircleCheck color={"#007FFF"} size={16} />
                <p className="small_text__20 ms-2">My Approval Pending</p>
              </div>
            </div>
            <div className="dashbaord-wrapper_info-section2_left_section_sub_item">
              <p className="header_text__count">
                {getValue(item, "unsubmitted", "")}
              </p>
              <div className="d-flex align-items-center">
                <ExpenseSvgComponent color={"#E23D28"} />
                <p className="small_text__20 ms-2">Unsubmitted Expenses</p>
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* <div className="dashbaord-wrapper_info-section2_right w-100">
        <div className="dashbaord-wrapper_info-section2_left_header">
          <h6 className="header_text p-2 ms-3">Quick Add</h6>
        </div>
        <div className="dashbaord-wrapper_info-section2_right_body">
          <div className="dashbaord-wrapper_info-section2_right_body_1">
            <div
              className="flex-container"
              onClick={() => handleNavigate(`/client/expenses/add`)}
            >
              <div className="dashbaord-wrapper_info-section2_right_body_circle">
                <div className="mt-2">
                  <UploadSvgComponent size={24} color={"#57d390"} />
                </div>
              </div>
              <p className="desc_text">Drag Receipts</p>
            </div>
          </div>
          <div className="dashbaord-wrapper_info-section2_right_body_1">
            <div
              className="flex-container"
              onClick={() => handleNavigate(`/client/expenses/add`)}
            >
              <div className="dashbaord-wrapper_info-section2_right_body_circle">
                <ExpenseSvgComponent size={24} color={"#7ab0fb"} />
              </div>
              <p className="desc_text">Create Expense</p>
            </div>
          </div>
          <div className="dashbaord-wrapper_info-section2_right_body_1">
            <div
              className="flex-container"
              onClick={() => handleNavigate(`/client/reports`)}
            >
              <div className="dashbaord-wrapper_info-section2_right_body_circle">
                <ReportsSvgComponent size={24} color={"#fdb763"} />
              </div>
              <p className="desc_text">Create Report</p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default TaskSection;
