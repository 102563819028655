import AddSvgComponent from "@assets/svg-components/add";
import DragAndDropFileComponent from "@common/FileUpload/file-drag-drop";
import DragAndDropFileComponentModal from "@common/FileUpload/file-drag-drop-modal";
import InputField from "@common/InputRuleFeild";
import {
  handleTextArrayChange,
  handleTextArraySubmit,
} from "@common/handleChange";
import { getValue } from "@utils/lodash";
import React, { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";

function NotePopup(props) {
  const POSITION_TYPES = [
    "bottom right",
    "bottom center",
    "top center",
    "left top",
    "left bottom",
    "right top",
    "top left",
    "top center",
    "right center",
    "right bottom",
    "bottom left",
    "left center",
    "center center",
  ];
  const fileTypes = ["PDF", "JPEG", "XLSX", "CSV", "XLSM", "XLX", "JPG", "PNG"];
  const { handleUploadDocuments } = props;

  const popupRef = useRef();
  const handleClose = (e) => {
    e.stopPropagation();
    if (popupRef.current) {
      popupRef.current.close();
    }
  };
  const handleOpen = () => {
    if (popupRef.current) {
      popupRef.current.open();
    }
  };

  const CustomButtons = React.forwardRef(({ open, ...props }, ref) => (
    <div
      className="d-flex justify-content-between align-items-center cursor-pointer"
      {...props}
      ref={ref}
    >
      {props.header}
    </div>
  ));
  const [notes, setNotes] = useState("");
  const handleSubmit = () => {
    handleTextArraySubmit(
      props.index,
      "note",
      notes,
      "approval_config_flow",
      props.setRequest
    );
    if (popupRef.current) {
      popupRef.current.close();
    }
  };
  useEffect(() => {
    if (props.value) {
      setNotes(props.value);
    }
  }, []);
  return (
    <Popup
      trigger={(open) => (
        <CustomButtons
          open={open}
          reports={props.reports}
          header={props.header}
        />
      )}
      position={POSITION_TYPES}
      closeOnDocumentClick
      on={"click"}
      contentStyle={{
        width: "350px",
        zIndex: 9999,
        background: "white",
        boxShadow:
          "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
      }}
      nested
      ref={popupRef}
      onOpen={handleOpen}
    >
      <div>
        <div className="w-75 m-auto mt-4">
          <InputField
            inputType="TEXTAREA"
            style={{ width: "75%" }}
            placeholder={"Notes"}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
          <div className="border-bottom mt-3 mb-3"></div>

          <div className="small_button__green mb-3" onClick={handleSubmit}>
            <p> Save</p>
          </div>
        </div>
      </div>
    </Popup>
  );
}

export default NotePopup;
