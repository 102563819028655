import { getValue } from "@utils/lodash";
import React from "react";

function SwapSvgComponent(props: any) {
  return (
    <div>
      <svg
        id="swap_horiz-24px"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
      >
        <path
          id="Path_6598"
          data-name="Path 6598"
          d="M0,0H24V24H0Z"
          fill="none"
        />
        <path
          id="Path_6599"
          data-name="Path 6599"
          d="M6.99,11,3,15l3.99,4V16H14V14H6.99ZM21,9,17.01,5V8H10v2h7.01v3Z"
          fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "#757575"
          }
        />
      </svg>
    </div>
  );
}

export default SwapSvgComponent;
