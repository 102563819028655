import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PropertyPhotoes from "./PropertyPhotoes";

const filteredProperty = [
  {
    property_name: "1",
    image: "/images/dashboard/trips/online/property-1.png",
  },
  {
    property_name: "2",
    image: "/images/dashboard/trips/online/property-2.png",
  },
  {
    property_name: "3",
    image: "/images/dashboard/trips/online/property-3.png",
  },
  {
    property_name: "4",
    image: "/images/dashboard/trips/online/property-4.png",
  },
  {
    property_name: "5",
    image: "/images/dashboard/trips/online/property-5.png",
  },
  {
    property_name: "6",
    image: "/images/dashboard/trips/online/property-6.png",
  },
];

const PropertyRooms = ({ amenities }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Options");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    const element = document.getElementById(tabName);
    // if (element) {
    //   element.scrollIntoView({ behavior: "smooth" });
    // }
  };

  return (
    <>
      <div className="w-100 d-flex flex-column align-items-center gap-4 mb-4 pt-4">
        <div className="w-75">
          <h4 className="">All Available Rooms</h4>
          <p className="small_text__14 mt-1">For 2 Nights, 3 Adults, 1Child</p>
        </div>
        <div className="w-75 border border-radius__8">
          <div className="p-4 d-flex justify-content-between">
            <div className="d-flex gap-3">
              <img
                src="/images/dashboard/trips/online/property-1.png"
                alt="room"
                className="property-thumbnail"
              />
              <div>
                <h5>Superior Room with City View</h5>
                <p className="small_text__14 mt-1">
                  2 Twin Beds or 1 Queen Bed
                </p>
                <div className="d-flex align-items-center gap-3 small_text__14 mt-3">
                  <p>Very Good Breakfast Included</p>
                  <div className="dot-seprator"></div>
                  <p>FREE Cancellation (Until Sun, 6 Sep, 2024)</p>
                  <div className="dot-seprator"></div>
                  <p>Travel Offer (15%Discount on Room Rate)</p>
                </div>
              </div>
            </div>
            <div className="d-flex gap-3 align-items-start">
              <div>
                <div className="d-flex gap-1 header_text__16 justify-content-end">
                  <span className="original-price">₹10,000</span>
                  <span className="text-danger">₹9,000</span>
                </div>
                <p className="small_text__14 mt-1 add-nowrap">
                  +₹ 330 and Charges
                </p>
              </div>
              <img
                src="/images/dashboard/trips/online/chevron-up.svg"
                alt="room"
                className="cursor-pointer"
              />
            </div>
          </div>
          <div className="popular-amenities p-4">
            <div className="d-flex  gap-5  w-100 mb-3">
              {["Options", "Room Details", "Photos"].map((tab) => (
                <div
                  key={tab}
                  className={`cursor-pointer position-relative ${
                    activeTab === tab ? " color_primary" : ""
                  }`}
                  onClick={() => handleTabClick(tab)}
                >
                  <h6
                    className={`cursor-pointer header_text__16 ${
                      activeTab === tab
                        ? " color_primary tab-active"
                        : "tab-notactive"
                    }`}
                  >
                    {tab}
                  </h6>
                </div>
              ))}
            </div>
            {activeTab === "Options" && (
              <div className="d-flex justify-content-between">
                <div className="d-flex gap-3">
                  <div>
                    <img
                      src="/images/dashboard/trips/property-details/tags.svg"
                      alt="tag"
                      className="border border-radius__8 p-2"
                    />
                  </div>
                  <div>
                    <h6>Option 1</h6>
                    <p className="small_text__14">
                      Sleeps 3 People, Including upto 1 child
                    </p>
                    <ul className="custom-tick mt-2 d-flex flex-column">
                      <li className="ticked-list">Free Wifi</li>
                      <li className="ticked-list">
                        Breakfast available, pay at property
                      </li>
                      <li className="ticked-list">Upto 2 children stay free</li>
                      <li className="ticked-list">
                        Free cancellation until 6 Sept, 2020
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="d-flex flex-column gap-1">
                  <div className="d-flex gap-1 header_text__16 justify-content-end">
                    <span className="original-price">₹10,000</span>
                    <span className="text-danger">₹9,000</span>
                  </div>
                  <p className="small_text__14 mt-1 text-end">
                    +₹ 330 and Charges
                  </p>
                  <p className="small_text__14 mt-1 text-end">
                    2 Nights, 2 Adults, 1 Child
                  </p>
                  <div className="d-flex gap-4">
                    <div className="d-flex gap-2 align-items-center cursor-pointer">
                      <img
                        src="/images/dashboard/trips/online/radio_button_checked.svg"
                        width={20}
                      />
                      <p className="small_text__14">1 King Bed</p>
                    </div>
                    <div className="d-flex gap-2 align-items-center cursor-pointer">
                      <img
                        src="/images/dashboard/trips/online/radio_button_unchecked.svg"
                        width={20}
                      />
                      <p className="small_text__14">2 Twin Beds</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end align-items-center gap-2">
                    <p className="small_text__14">Select Room(s)</p>
                    <div
                      className="passengers-container cursor-pointer"
                      style={{ height: "36px", width: "70px" }}
                    >
                      02
                      <img
                        src="/images/dashboard/trips/online/arrow_drop_down.png"
                        alt="Arrow"
                      />
                    </div>
                  </div>
                  <Link
                    className="deeptravel-button deeptravel-button--secondary deeptravel-button--header-buttons mt-3 d-flex justify-content-center"
                    // onClick={() =>
                    //   navigate("/client/trips/online-booking/properties/booking")
                    // }
                    to="/client/trips/online-booking/properties/booking"
                  >
                    Reserve Selection
                  </Link>
                </div>
              </div>
            )}
            {activeTab === "Room Details" && (
              <div className="">
                <div className="d-flex gap-3">
                  <div>
                    <img
                      src="/images/dashboard/trips/property-details/bedroom_parent.svg"
                      alt="tag"
                      className="border border-radius__8 p-2"
                    />
                  </div>
                  <div>
                    <h6>Room Size 31m2</h6>
                    <p className="small_text__14 mb-3">
                      This double room has a bathrobe, soundproofing and
                      mini-bar. Inclusions: Early Check-In by 09:00 am and late
                      check- out by 16:00 Hrs (Subject to availability) Up to 2
                      children below 6 years is complimentary. Extra person will
                      be charged INR 1500 plus tax per room per night. Rates are
                      exclusive of taxes.
                    </p>
                  </div>
                </div>
                <div className="d-flex gap-3 w-100">
                  <div>
                    <img
                      src="/images/dashboard/trips/property-details/filter_frames.svg"
                      alt="tag"
                      className="border border-radius__8 p-2"
                    />
                  </div>
                  <div>
                    <h6 className="header_text__16 mb-1">Popular Amenities</h6>
                    <ul className="custom-tick">
                      {amenities.map((amenities, index) => (
                        <li key={index} className="ticked-list">
                          {amenities}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            )}
            {activeTab === "Photos" && (
              <PropertyPhotoes hideHeading={true} images={filteredProperty} />
            )}
          </div>
        </div>
        <div className="w-75 border border-radius__8">
          <div className="p-4 d-flex justify-content-between">
            <div className="d-flex gap-3">
              <img
                src="/images/dashboard/trips/online/property-1.png"
                alt="room"
                className="property-thumbnail"
              />
              <div>
                <h5>Deluxe Room</h5>
                <p className="small_text__14 mt-1">
                  2 Twin Beds or 1 Queen Bed
                </p>
                <div className="d-flex align-items-center gap-3 small_text__14 mt-3">
                  <p>Very Good Breakfast Included</p>
                  <div className="dot-seprator"></div>
                  <p>FREE Cancellation (Until Sun, 6 Sep, 2024)</p>
                  <div className="dot-seprator"></div>
                  <p>Travel Offer (15%Discount on Room Rate)</p>
                </div>
              </div>
            </div>
            <div className="d-flex gap-3 align-items-start">
              <div>
                <div className="d-flex gap-1 header_text__16 justify-content-end">
                  <span className="original-price">₹10,000</span>
                  <span className="text-danger">₹9,100</span>
                </div>
                <p className="small_text__14 mt-1">+₹ 340 and Charges</p>
              </div>
              <img
                src="/images/dashboard/trips/online/chevron-down.svg"
                alt="room"
                className="cursor-pointer"
              />
            </div>
          </div>
        </div>
        <div className="w-75 border border-radius__8">
          <div className="p-4 d-flex justify-content-between">
            <div className="d-flex gap-3">
              <img
                src="/images/dashboard/trips/online/property-1.png"
                alt="room"
                className="property-thumbnail"
              />
              <div>
                <h5>Deluxe Room with Lake View</h5>
                <p className="small_text__14 mt-1">
                  2 Twin Beds or 1 Queen Bed
                </p>
                <div className="d-flex align-items-center gap-3 small_text__14 mt-3">
                  <p>Very Good Breakfast Included</p>
                  <div className="dot-seprator"></div>
                  <p>FREE Cancellation (Until Sun, 6 Sep, 2024)</p>
                  <div className="dot-seprator"></div>
                  <p>Travel Offer (15%Discount on Room Rate)</p>
                </div>
              </div>
            </div>
            <div className="d-flex gap-3 align-items-start">
              <div>
                <div className="d-flex gap-1 header_text__16 justify-content-end">
                  <span className="original-price">₹10,000</span>
                  <span className="text-danger">₹9,100</span>
                </div>
                <p className="small_text__14 mt-1">+₹ 340 and Charges</p>
              </div>
              <img
                src="/images/dashboard/trips/online/chevron-down.svg"
                alt="room"
                className="cursor-pointer"
              />
            </div>
          </div>
        </div>
        <div className="w-75 border border-radius__8">
          <div className="p-4 d-flex justify-content-between">
            <div className="d-flex gap-3">
              <img
                src="/images/dashboard/trips/online/property-1.png"
                alt="room"
                className="property-thumbnail"
              />
              <div>
                <h5>Suite</h5>
                <p className="small_text__14 mt-1">
                  2 Twin Beds or 1 Queen Bed
                </p>
                <div className="d-flex align-items-center gap-3 small_text__14 mt-3">
                  <p>Very Good Breakfast Included</p>
                  <div className="dot-seprator"></div>
                  <p>FREE Cancellation (Until Sun, 6 Sep, 2024)</p>
                  <div className="dot-seprator"></div>
                  <p>Travel Offer (15%Discount on Room Rate)</p>
                </div>
              </div>
            </div>
            <div className="d-flex gap-3 align-items-start">
              <div>
                <div className="d-flex gap-1 header_text__16 justify-content-end">
                  <span className="original-price">₹10,000</span>
                  <span className="text-danger">₹9,100</span>
                </div>
                <p className="small_text__14 mt-1">+₹ 340 and Charges</p>
              </div>
              <img
                src="/images/dashboard/trips/online/chevron-down.svg"
                alt="room"
                className="cursor-pointer"
              />
            </div>
          </div>
        </div>
        <div className="w-75 border border-radius__8">
          <div className="p-4 d-flex justify-content-between">
            <div className="d-flex gap-3">
              <img
                src="/images/dashboard/trips/online/property-1.png"
                alt="room"
                className="property-thumbnail"
              />
              <div>
                <h5>Junior Suite</h5>
                <p className="small_text__14 mt-1">
                  2 Twin Beds or 1 Queen Bed
                </p>
                <div className="d-flex align-items-center gap-3 small_text__14 mt-3">
                  <p>Very Good Breakfast Included</p>
                  <div className="dot-seprator"></div>
                  <p>FREE Cancellation (Until Sun, 6 Sep, 2024)</p>
                  <div className="dot-seprator"></div>
                  <p>Travel Offer (15%Discount on Room Rate)</p>
                </div>
              </div>
            </div>
            <div className="d-flex gap-3 align-items-start">
              <div>
                <div className="d-flex gap-1 header_text__16 justify-content-end">
                  <span className="original-price">₹10,000</span>
                  <span className="text-danger">₹9,100</span>
                </div>
                <p className="small_text__14 mt-1">+₹ 340 and Charges</p>
              </div>
              <img
                src="/images/dashboard/trips/online/chevron-down.svg"
                alt="room"
                className="cursor-pointer"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyRooms;
