import React from "react";
import InputField from "@common/InputRuleFeild";
import { getValue } from "@utils/lodash";
// import "./right-popup.scss";
function ExpenseActiveCategoryPopup(props: any) {
  const {
    showUpdatePopup,
    setShowUpdatePopup,
    request,
    handleChangeText,
    submitLoading,
    handleSubmit,
    editId,
    setRequest,
  } = props;
  return (
    <div
      className={`compelte-task-wrapper ${
        showUpdatePopup ? "compelte-task-wrapper--active" : ""
      }`}
      style={{ width: "40%" }}
    >
      <div className="compelte-task-wrapper__header d-flex align-items-center justify-content-between">
        <h6 className="compelte-task-wrapper__title">
          Expense Active Category
        </h6>
        <img
          src="/images/close-white.svg"
          className="img-fluid navigation-bar-wrapper__close-btn"
          onClick={() => {
            setShowUpdatePopup(false);
            props.handleReset();
          }}
        />
      </div>

      <div className="main_popup_container">
        <div className="expense-active-category-add-form-wrap">
          <div className="user-details-wrapper__form-group form-group">
            <label className="checkpoint-active-details__label form-label-error">
              Category Name <span>*</span>{" "}
            </label>
            <InputField
              inputType="TEXT"
              placeholder="Enter Account Name"
              className="form-control"
              name="category_name"
              id="category_name"
              label="Category Name"
              value={getValue(request, `category_name`, "")}
              onChange={(e: any) => handleChangeText(e)}
              // validator={simpleValidator}
            />
          </div>
          <div className="user-details-wrapper__form-group form-group">
            <label className="checkpoint-active-details__label form-label-error">
              Category Code <span>*</span>{" "}
            </label>
            <InputField
              inputType="TEXT"
              placeholder="Enter Account Code"
              className="form-control"
              name="category_code"
              id="category_code"
              label="Category Code"
              value={getValue(request, `category_code`, "")}
              onChange={(e: any) => handleChangeText(e)}
              // validator={simpleValidator}
            />
          </div>
          <div className="user-details-wrapper__form-group form-group mt-3">
            <label className="checkpoint-active-details__label form-label-error">
              Description <span>*</span>{" "}
            </label>
            <InputField
              inputType="TEXTAREA"
              placeholder="Enter Description"
              className="form-control"
              name="description"
              id="description"
              label="Description"
              value={getValue(request, `description`, "")}
              onChange={(e: any) => handleChangeText(e)}
              // validator={simpleValidator}
            />
          </div>
          {/* <label className="form-label">
          Expense Type<span>*</span>{" "}
        </label>
        <SingleSelectSearchDropdown
          label={"label"}
          selectKey={"value"}
          name="Type"
          value={getValue(request, `expense_type`, "")}
          onChange={(e: any) =>
            setRequest({
              ...request,
              expense_type: getValue(e, `id`, ""),
            })
          }
          placeholder={"Choose Type"}
          width={"100%"}
          lookup_api={"expense_type"}
          // validator={simpleValidator}
        /> */}
        </div>
      </div>
      {/* <div style={{ float: "right", marginRight: "8%" }}>
        <button
          className="deeptravel-button deeptravel-button--primary"
          onClick={() => setShowUpdatePopup(false)}
        >
          Cancel
        </button>
        <button
          className="deeptravel-button deeptravel-button--secondary"
          onClick={() => handleSubmit()}
          disabled={submitLoading}
        >
          {submitLoading ? "Please wait..." : editId ? "Update" : "Create"}
        </button>
      </div> */}
      <div className="fixed_moda_footer_wrapper">
        <div style={{ marginRight: "20px" }}>
          <button
            className="deeptravel-button deeptravel-button--header-buttons deeptravel-button--primary "
            onClick={() => {
              setShowUpdatePopup(false);
              props.handleReset();
            }}
          >
            Cancel
          </button>
          <button
            className="deeptravel-button deeptravel-button--header-buttons deeptravel-button--secondary ms-3"
            onClick={() => handleSubmit()}
            disabled={submitLoading}
          >
            {submitLoading ? "Please wait..." : editId ? "Update" : "Create"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ExpenseActiveCategoryPopup;
