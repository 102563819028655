import ClientApprovalReports from "@pages/Client/Approval/Reports";
import ClientApprovalReportDetail from "@pages/Client/Approval/Reports/Detail";
import MyViewsTripsApproval from "@pages/Client/Approval/Trips";
import ClientApprovalTripsDetail from "@pages/Client/Approval/Trips/detail";

export const ClientApprovalReportRoutes = [
  //expenses
  {
    path: "/client/approval/reports",
    name: "approvals_reports",
    component: ClientApprovalReports,
  },
  {
    path: "/client/approval/reports/detail/:id",
    name: "approvals_reports",
    component: ClientApprovalReportDetail,
  },
  {
    path: "/client/approval/trips",
    name: "approvals_trips",
    component: MyViewsTripsApproval,
  },
  {
    path: "/client/approval/trips/detail/:id",
    name: "approvals_trips",
    component: ClientApprovalTripsDetail,
  },
];
