import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllPolicies = (queryRequest: string) =>
  get(`${config.API_URL}/policy?${queryRequest}`);

export const getSpecificPolicy = (id: string) =>
  get(`${config.API_URL}/policy/${id}`);

export const createPolicy = (payload: object) =>
  post(`${config.API_URL}/policy`, payload);

export const updatePolicy = (id: string, payload: object) =>
  patch(`${config.API_URL}/policy/${id}`, payload);

export const deletePolicy = (id: string) =>
  Delete(`${config.API_URL}/policy/${id}`);

export const updatePolicyBulk = (payload: object) =>
  patch(`${config.API_URL}/policy/bulk/active-inactive`, payload);

export const createPolicyExpenseLimit = (payload: object) =>
  post(`${config.API_URL}/policy/expense-limit`, payload);

export const getSpecificPolicyExpenseLimit = (
  id: string,
  queryRequest: string
) => get(`${config.API_URL}/policy/${id}/expense-limit?${queryRequest}`);

export const getAllPolicyExpenseLimit = (id: string, queryRequest: string) =>
  get(`${config.API_URL}/policy/expense-limit/${id}?${queryRequest}`);

export const updatePolicyExpenseLimit = (id: string, payload: object) =>
  patch(`${config.API_URL}/policy/expense-limit/${id}`, payload);

export const deletePolicyExpenseLimit = (id: string) =>
  Delete(`${config.API_URL}/policy/expense-limit/${id}`);
