import { useNavigationData } from "@components/common/LeftNavbar/NavigationContext";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import "./components/home.scss";
import { Link } from "react-router-dom";
import {
  CircleUserRound,
  IdCard,
  Info,
  Plug,
  SlidersHorizontal,
  List,
  Settings,
  Bot,
  SquareMousePointer,
  Headset,
  CircleHelp,
  Notebook,
  Compass,
  Play,
  UserRound,
} from "lucide-react";

const ClientHome = () => {
  const { user } = useNavigationData();

  const HelpCircle = [
    {
      icon: <SquareMousePointer size={18} color="#e879f9" />,
      title: "Request a demo",
    },
    {
      icon: <Headset size={18} color="#0ea5e9" />,
      title: "Contact Support",
    },
    {
      icon: <Compass size={18} color="#f59e0b" />,
      title: "Take a Tour",
    },
    {
      icon: <Notebook size={18} color="#e11d48" />,
      title: "Help Documnetation",
    },
    {
      icon: <CircleHelp size={18} color="#10b981" />,
      title: "FAQ",
    },
  ];

  const homeData = [
    {
      icon: <List size={18} color="#5F3761" />,
      heading: "Expense Categories",
      descrption:
        "Add and manage categories to categorize expenses and track business spend",
      button: "Manage",
    },
    {
      icon: <SlidersHorizontal size={18} color="#5F3761" />,
      heading: "Preferences",
      descrption:
        "Choose preferences and configure settings for each module based on organizational needs",
      button: "Configure",
      link: "Setup approval flows",
    },
    {
      icon: <Info size={18} color="#5F3761" />,
      heading: "Policies",
      descrption:
        "Create policies to define spending limits, mileage reimbursements, and per diem allowances.",
      button: "Setup",
    },
    {
      icon: <IdCard size={18} color="#5F3761" />,
      heading: "Corporate Cards",
      descrption:
        "Add and assign corporate cards to users and set up automatic import of card transactions",
      button: "Configure",
    },
    {
      icon: <CircleUserRound size={18} color="#5F3761" />,
      heading: "Users",
      descrption:
        "Invite users to this organization and assign roles that give them access to the necessary modules",
      button: "Invite",
      link: "Create departments",
    },
    {
      icon: <Plug size={18} color="#5F3761" />,
      heading: "Integrations",
      descrption:
        "Setup seamless integrations with other applications for added benefits",
      button: "Connect",
    },
  ];

  const adminData = [
    {
      icon: <CircleUserRound size={18} color="#5F3761" />,
      heading: "Users and Control",
      description:
        "Add users, assign roles, define policies, set budgets and automate compliance.",
    },
    {
      icon: <SlidersHorizontal size={18} color="#5F3761" />,
      heading: "Customization",
      description:
        "Manage preferences and create custom entities to extend app functionalit",
    },
    {
      icon: <Settings size={18} color="#5F3761" />,

      heading: "Functionality",
      description:
        "Manage corporate cards, provide travel booking options, and record reimbursements.",
    },
    {
      icon: <Bot size={16} color="#5F3761" />,
      heading: "Automation",
      description:
        "Automate expense reporting, approval, and other business processes.",
    },
  ];

  return (
    <DashboardLayout>
      <div className="home-wrapper">
        <div className="home-container">
          <div className="d-flex align-items-center gap-3">
            <img src="/images/user-new.svg" alt="" />
            <div>
              <h6 className="header_text__22 mb-1">
                Welcome {user.first_name} 👋
              </h6>
              <p className="small_text__15">
                Learn to manage organizational business travel and spend.
              </p>
            </div>
          </div>
          <div className="home-product-details-section my-5 p-5 d-flex align-items-baseline">
            <div className="home-helper-card-left">
              <h6 className="header_text__18 mb-1 text-center">
                Get a quick overview
              </h6>
              <div className="home-video-container me-5 mt-3">
                <div className="video-play-button">
                  <Play fill="#fff" color="#fff" size={20} />
                </div>
              </div>
            </div>
            <div className="home-helper-card-right ps-5">
              <h6 className="header_text__18 mb-1 text-center">
                Help & Support
              </h6>
              <div className="d-grid gap-3 mt-3">
                {HelpCircle.map((item, index) => (
                  <div
                    key={index}
                    className="d-flex align-items-centers gap-2 home-links"
                  >
                    {item.icon}
                    {item.title}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="home-product-details-section my-5">
            <div className="home-product-details-header">
              <h6>Let's get started!</h6>
            </div>
            <div className="p-5 d-grid gap-3">
              {homeData.map((item, index) => (
                <div key={index} className="home-product-card">
                  <div className="d-flex align-items-center gap-3">
                    <div className="home-card-icon-wrapper">{item.icon}</div>
                    <div>
                      <h6 className="header_text__17 mb-1">{item.heading}</h6>
                      <p className="small_text__15 pb-2">{item.descrption}</p>
                      {item.link && <Link to="/">{item.link}</Link>}
                    </div>
                  </div>
                  <div className="home-card-button">{item.button}</div>{" "}
                </div>
              ))}
            </div>
          </div>
          <div className="home-product-details-section my-5">
            <div className="home-product-details-header">
              <h6>
                Manage admin tasks and organization settings from Admin View
              </h6>
            </div>
            <div className="px-5 pt-5 pb-4 d-grid gap-3">
              {adminData.map((item, index) => (
                <div key={index} className="home-product-card">
                  <div className="d-flex align-items-center gap-3">
                    <div className="home-card-icon-wrapper">{item.icon}</div>
                    <div>
                      <h6 className="header_text__17 mb-1">{item.heading}</h6>
                      <p className="small_text__15 pb-2">{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <button className="btn btn-primary btn-add-on_not_found py-2 px-4 mx-5 mb-5">
              Switch to Admin view
            </button>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ClientHome;
