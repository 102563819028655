import { getMyProfile, getSpecificUser } from "@services/users.service";
import { getValue } from "@utils/lodash";
import { createContext, useContext, useState, useEffect } from "react";

const NavigationContext = createContext();

export function NavigationProvider({ children }) {
  const [clientNavigation, setClientNavigation] = useState([]);
  const [adminNavigation, setAdminNavigation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Your optimizeNavigationMenus function and useEffect code here...
  const [user, setUser] = useState({});
  useEffect(() => {
    if (getValue(localStorage, `accessToken`, "")) {
      let user = JSON.parse(getValue(localStorage, `user`, ""));
      setUser(user);
      if (getValue(localStorage, `user`, "")) {
        let user = JSON.parse(getValue(localStorage, `user`, ""));
        getUser(getValue(user, `id`, ""));
      }
    }
  }, []);
  const [info, setInfo] = useState({});
  const getUser = async (id) => {
    try {
      let resp = await getMyProfile(id);
      if (resp) {
        setInfo(getValue(resp, `data`, {}));
      }
    } catch (error) {}
  };

  return (
    <NavigationContext.Provider
      value={{
        clientNavigation,
        setClientNavigation,
        adminNavigation,
        setAdminNavigation,
        isLoading,
        setIsLoading,
        info,
        user,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
}

export function useNavigationData() {
  return useContext(NavigationContext);
}
