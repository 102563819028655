import { getValue } from "@utils/lodash";
import React, { ChangeEvent } from "react";
import "./form.scss";
type Props = {
  disabled: boolean;
  name: string;
  type: string;
  value: any;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  maxLength: number;
  placeholder: string;
  validator: any;
  label: string;
  mandatory: boolean;
  hideLabel: boolean;
  validatoryType: string;
  style: any;
};

export default function Input({
  disabled,
  name,
  type,
  value,
  onChange,
  maxLength,
  placeholder,
  validator,
  label,
  mandatory,
  hideLabel,
  validatoryType,
  style,
}: Props) {
  return (
    <div
    // className={`${"signup-form-wrapper__form-group"} ${
    //   validator && validator.current.message(label, value, "required")
    //     // ? "signup-form-wrapper__form-group--error"
    //     ?""
    //     : ""
    // } form-group mb-3`}
    >
      {!hideLabel && (
        <label className={"form-label"}>
          {/* <label className={mandatory ? "form-label-error" : "form-label"}> */}
          {label}{" "}
          <span className={mandatory ? "form-label-error" : "form-label"}>
            {mandatory && "*"}
          </span>
        </label>
      )}
      {type === "number" ||
      type === "numerical" ||
      type === "decimal" ||
      type === "percentage" ? (
        <input
          className={`${"custom-form-control"} form-control`}
          disabled={false || disabled}
          name={name}
          type={"text"}
          value={value}
          min={0}
          onChange={(e) => onChange(e)}
          placeholder={placeholder}
          maxLength={maxLength}
          style={style}
          pattern="[0-9.]+"
        />
      ) : (
        <input
          className={`${"custom-form-control"} form-control`}
          disabled={false || disabled}
          name={name}
          type={type}
          value={value}
          onChange={(e) => onChange(e)}
          placeholder={placeholder}
          maxLength={maxLength}
          required
        />
      )}

      {/* <p
        className={`${"error-text"} ${
          // getValue(props, `validLeft`, "") ? "" :
          "text-center"
        }`}
      >
        {validator &&
          validator.current.message(
            label,
            value,
            validatoryType ? validatoryType : "required"
          )}
      </p> */}
      {validator && (
        <p className={`${"error-text"}`}>
          {validator.current.message(
            label,
            value,
            validatoryType ? validatoryType : "required"
          )}
        </p>
      )}
    </div>
  );
}
