import { getValue } from "@utils/lodash";
import React from "react";

function ArrowDownSvgComponent(props: any) {
  return (
    <div>
      <svg
        id="arrow_drop_down_black_24dp"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
      >
        <path
          id="Path_45632"
          data-name="Path 45632"
          d="M0,0H24V24H0Z"
          fill="none"
        />
        <path
          id="Path_45633"
          data-name="Path 45633"
          d="M7,10l5,5,5-5Z"
          fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "#3c4043"
          }
        />
      </svg>
    </div>
  );
}

export default ArrowDownSvgComponent;
