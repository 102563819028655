import { getValue } from "@utils/lodash";
import React from "react";

function Switch(props: any) {
  return (
    <div className="choicelist-wrapper custom-switch-wrapper">
      <label className="switch">
        <input
          type="checkbox"
          checked={getValue(props, `is_active`, false)}
          onChange={props.onChange}
        />
        <span className="slider round"></span>
      </label>
    </div>
  );
}

export default Switch;
