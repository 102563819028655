import InputField from "@common/InputRuleFeild";
import { handleTextChange } from "@common/handleChange";
import { getValue } from "@utils/lodash";
import React, { useState } from "react";

function Step1(props: any) {
  const { request, setRequest } = props;
  return (
    <div className="custom-approval-step1">
      <div className="">
        <div className="approval-label-field-wrap">
          <label className="approval-label-wrap form-label-error">Approval Name *</label>
          <div className="w-30">
            <InputField
              inputType="TEXT"
              name="approval_name"
              value={getValue(request, `approval_name`, "")}
              onChange={(e: any) => handleTextChange(e, request, setRequest)}
              validator={props.simpleValidator}
              label="Approval Name"
              placeholder='Approval Name'
            />
          </div>
        </div>
        <div className="approval-label-field-wrap ">
          <label className="approval-label-wrap ">Description</label>
          <div className="w-50">
            <InputField
              inputType="TEXTAREA"
              name={"description"}
              value={getValue(request, `description`, "")}
              onChange={(e: any) => handleTextChange(e, request, setRequest)}
              placeholder='Description'
            />
          </div>
        </div>
      </div>
      <div className="border-bottom approval-detail-border-bottom"></div>
    </div>
  );
}

export default Step1;
