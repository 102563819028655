import { getValue } from "@utils/lodash";
import React from "react";

const CreateTripsStickyFooter = (props: any) => {
  const { handleSubmit, submitLoading, handleCreateTrip, tripLoading } = props;
  return (
    <div className="create-trip-sticky-footer">
      {getValue(props, `permissions`, []).includes("update") && (
        <div className="d-flex align-items-center gap-2">
          <button
            onClick={handleSubmit}
            className="deeptravel-button deeptravel-button--header-buttons deeptravel-button--secondary"
            disabled={submitLoading || tripLoading}
          >
            {submitLoading
              ? "Please wait..."
              : getValue(props, `id`, "")
              ? "Update"
              : "Save as Draft"}
          </button>
          {getValue(props, `id`, "") ? null : (
            <button
              onClick={handleCreateTrip}
              disabled={tripLoading || submitLoading}
              className="deeptravel-button deeptravel-button--header-buttons deeptravel-button--primary"
            >
              {tripLoading ? "Please wait..." : "Save & Submit"}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default CreateTripsStickyFooter;
