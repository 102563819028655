import { getValue } from "@utils/lodash";
import { NavLink } from "react-router-dom";
import BackSvgComponent from "@assets/svg-components/back";
import { X } from "lucide-react";

function CreateTripsStickyHeader(props: any) {
  const { handleSubmit, submitLoading, handleCreateTrip, route, tripLoading } =
    props;
  return (
    <div className="create-trip-sticky-header container-fluid  d-flex justify-content-between align-items-center">
      <div className="d-flex justify-content-between align-items-center">
        <p className="header_text">
          {" "}
          {getValue(
            props,
            `title`,
            getValue(props, `id`, "") ? "Update Trip" : "New Trip"
          )}
        </p>
      </div>
      <div className="border rounded-2 p-1">
        <NavLink to={route ? `${route}` : "#"}>
          <X color="#2e3151" size={18} />
        </NavLink>
      </div>
    </div>
  );
}

export default CreateTripsStickyHeader;
