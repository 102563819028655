import React from "react";
import TripsAirportPopup from "../../Dropdown/airport-popup";
import { getValue } from "@utils/lodash";
import FlightTakeOffSvgComponent from "@assets/svg-components/trips/flight-takeoff";
import {
  formHandleSelectArrayChangeByKey,
  formHandleSelectArrayChangeByKeyValue,
} from "@common/handleChange";
import CloseSmallSvgComponent from "@assets/svg-components/close-small";
import ArrowDownSvgComponent from "@assets/svg-components/arrow-down";
import FlightLandSvgComponent from "@assets/svg-components/trips/flight-land";
import { Circle, MapPin } from "lucide-react";

function TripAirportComponent(props: any) {
  const {
    validator,
    item,
    index,
    setItenaries,
    airport_code,
    airport_name,
    label,
  } = props;
  return (
    <div>
      <div className="d-flex">
        <label className="trip-label">{label}</label>
        <span className="form-label-error_red">*</span>
      </div>
      <TripsAirportPopup
        header={
          <div
            className={`d-flex justify-content-between align-items-center ${
              validator &&
              validator.current.message(
                "From",
                getValue(item, `${airport_code}`, ""),
                "required"
              )
                ? "border_red"
                : "border"
            } border-radius__8 cursor-pointer flight_search_section`}
          >
            <div className="d-flex align-items-center">
              {airport_code === "arrival_airport_code" ? (
                <div className="">
                  <MapPin color="#3c4043" size={18} />
                </div>
              ) : (
                <div className="ps-2">
                  <Circle color="#3c4043" size={16} />
                </div>
              )}
              <p className="small_text__16 ml-8">
                {getValue(item, `${airport_name}`, "")
                  ? getValue(item, `${airport_code}`, "") +
                    " (" +
                    getValue(item, `${airport_name}`, "") +
                    ")"
                  : label + "" + `?`}
              </p>
            </div>

            {getValue(item, `${airport_code}`, "") ? (
              <div
                className=""
                onClick={() => {
                  formHandleSelectArrayChangeByKey(
                    index,
                    "",
                    airport_code,
                    setItenaries
                  );
                  formHandleSelectArrayChangeByKey(
                    index,
                    "",
                    airport_name,
                    setItenaries
                  );
                }}
              >
                <CloseSmallSvgComponent />
              </div>
            ) : (
              <div className="">
                <ArrowDownSvgComponent color={"gray"} />
              </div>
            )}
          </div>
        }
        value={getValue(item, `${airport_code}`, "")}
        onChange={(e: object) => {
          formHandleSelectArrayChangeByKey(
            index,
            e,
            airport_code,
            setItenaries
          );
          formHandleSelectArrayChangeByKeyValue(
            index,
            e,
            airport_name,
            "label",
            setItenaries
          );
        }}
      />
    </div>
  );
}

export default TripAirportComponent;
