import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import InputField from "@common/InputRuleFeild";
import { getValue } from "@utils/lodash";
import { CopyToClipboard } from "@common/text-helpers";
import "./right-popup.scss";
import SingleSelectSearchDropdown from "@components/common/CustomSearchableDropdown/SingleSelectSearchDropdown";
import RichTextEditor from "react-rte";

function EmailTemplateEditPopup(props: any) {
  const {
    showUpdatePopup,
    setShowUpdatePopup,
    request,
    handleChangeText,
    editorState,
    onEditorStateChange,
    submitLoading,
    handleSubmit,
    editId,
    setRequest,
  } = props;
  const toolbarConfig: any = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: [
      "INLINE_STYLE_BUTTONS",
      "BLOCK_TYPE_BUTTONS",
      "LINK_BUTTONS",
      "BLOCK_TYPE_DROPDOWN",
      "HISTORY_BUTTONS",
    ],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: "Normal", style: "unstyled" },
      { label: "Heading Large", style: "header-one" },
      { label: "Heading Medium", style: "header-two" },
      { label: "Heading Small", style: "header-three" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };
  return (
    <div
      className={`compelte-task-wrapper email-add-edit-popup-wrapper ${
        showUpdatePopup ? "compelte-task-wrapper--active" : ""
      }`}
      // style={{ width: "60%" }}
    >
      <div className="compelte-task-wrapper__header d-flex align-items-center justify-content-between">
        <h6 className="compelte-task-wrapper__title">Expense Types</h6>
        <img
          src="/images/close-white.svg"
          className="img-fluid navigation-bar-wrapper__close-btn"
          onClick={() => {
            setShowUpdatePopup(false);
            props.reset();
          }}
        />
      </div>
      <div className="main_popup_container">
        <div className="d-flex">
          <div className="user-details-wrapper-modal m-0 w-60">
            <div className="checkpoint-active-details-wrapper__form-wrapper pt-0">
              <div className="checkpoint-active-details-wrapper__form-content_email_template">
                {/* <h4 className="mt-3 mb-3">New task assigned)</h4> */}
                <div className="user-details-wrapper__form-group form-group">
                  <label className="form-label">Template type</label>
                  <div className="email_template_type_dd">
                    <SingleSelectSearchDropdown
                      label={"label"}
                      data={getValue(props, `typeList`, [])}
                      value={getValue(request, `type`, "")}
                      selectKey={"value"}
                      onChange={(e: any) => {
                        props.getKeys(getValue(e, `value`, ""));
                        setRequest({
                          ...request,
                          type: getValue(e, `value`, ""),
                        });
                      }}
                      width="100%"
                      validator={props.validator}
                      name={"Template Type"}
                    />
                    {/* <InputField
                          inputType="INPUT_REACT_SELECT_MODAL"
                          placeholder="Choose Type"
                          // className="form-control w-100"
                          name="state_id"
                          id="state_id"
                          options={getValue(props, `typeList`, [])}
                          value={getValue(props, `typeList`, []).filter(
                            (item: any) =>
                              getValue(item, `value`, "") ==
                              getValue(request, `type`, "")
                          )}
                          class={"model-grid__form-control"}
                          onChange={(e: any) => {
                            props.getKeys(getValue(e, `value`, ""));
                            setRequest({
                              ...request,
                              type: getValue(e, `value`, ""),
                            });
                          }}
                          isClear
                        /> */}
                  </div>
                </div>
                <div className="user-details-wrapper__form-group form-group">
                  {/* mt-2 mb-2 */}
                  <label className="checkpoint-active-details__label form-label">
                    Subject <span className="form-label-error">*</span>
                  </label>
                  <InputField
                    inputType="TEXTAREA"
                    placeholder="Enter Subject"
                    className="form-control w-100"
                    name="subject"
                    id="subject"
                    value={getValue(request, `subject`, "")}
                    onChange={(e: any) => handleChangeText(e)}
                    label="Vendor Name"
                    // validator={simpleValidator}
                  />
                </div>

                <div className="user-details-wrapper__form-group form-group">
                  <div className="d-flex justify-content-between">
                    <label className="checkpoint-active-details__label form-label">
                      Email content <span className="form-label-error">*</span>
                    </label>
                  </div>
                  {/* <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={onEditorStateChange}
                    // placeholder={"Enter Email Content"}
                  /> */}
                  <RichTextEditor
                    value={editorState}
                    onChange={onEditorStateChange}
                    toolbarConfig={toolbarConfig}
                    className="rte-editor-height"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div style={{ marginLeft: "30px" }}></div> */}
          <div className="user-details-wrapper_right-modal w-40">
            {/* ms-5 pt-5 */}
            <p className="key-header">Placeholders</p>
            {getValue(props, `keysInfo.keys.length`, 0) > 0 ? (
              <div className="d-flex keys-container">
                {getValue(props, `keysInfo.keys`, []).map((item: any) => {
                  return (
                    <div
                      className="key-title-container"
                      onClick={() => CopyToClipboard(`<%= ${item} %>`)}
                    >
                      <p className="key-title">{item}</p>
                    </div>
                  );
                })}
              </div>
            ) : (
              <p className="text-center mt-4">No Data Found</p>
            )}
          </div>
        </div>
      </div>
      <div className="fixed_moda_footer_wrapper">
        <button
          className="deeptravel-button deeptravel-button--header-buttons deeptravel-button--secondary  "
          onClick={() => {
            setShowUpdatePopup(false);
            props.reset();
          }}
        >
          Cancel
        </button>
        <button
          className="deeptravel-button deeptravel-button--header-buttons deeptravel-button--primary ms-3"
          onClick={() => handleSubmit()}
          disabled={submitLoading}
        >
          {submitLoading ? "Please wait..." : editId ? "Update" : "Create"}
        </button>
      </div>
    </div>
  );
}

export default EmailTemplateEditPopup;
