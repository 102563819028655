import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../online/trip-online.scss";
import HotelLocation from "@components/Trip/components/hotel/location";

interface CabSearchProps {
  value1?: string;
  value2?: string;
}

const tripType = [
  {
    value: "one_way",
    label: "One Way",
  },
  {
    value: "round_trip",
    label: "Round Trip",
  },
  {
    value: "local",
    label: "Local",
  },
  {
    value: "airport",
    label: "Airport",
  },
];

const CabSearch = ({ value1, value2 }: CabSearchProps) => {
  const navigate = useNavigate();

  const [selectedOpt, setSelectedOpt] = useState("one_way");

  const handleOption = (event: any) => {
    setSelectedOpt(event.target.value);
  };

  return (
    <div>
      <div className="d-flex gap-3 my-3">
        {tripType.map((option) => (
          <div key={option.value} className="">
            <input
              type="radio"
              id={option.value}
              name="tripOption"
              value={option.value}
              checked={selectedOpt === option.value}
              onChange={handleOption}
              className="trip-radio"
            />
            <label htmlFor={option.value}>
              <div className="d-flex align-items-center justify-content-center trip-selector gap-2">
                <img src="/images/dashboard/trips/online/arrow_right.png" />
                <p>{option.label}</p>
              </div>
            </label>
          </div>
        ))}
      </div>
      <div className="d-flex gap-4">
        <HotelLocation
          caption="Car Pick-up location"
          placeholder="Type a city or place"
        />
        <HotelLocation
          caption="Car Drop-off location"
          placeholder="Type a city or place"
        />
        <div className="calender-wrapper">
          <img src="/images/dashboard/trips/online/calender.png" />
          <span className="calender-border-left grayed-text">Pickup date</span>
          <span className="pe-5 grayed-text">Time</span>
        </div>
        <button
          onClick={() => navigate("/client/trips/online-booking/transport")}
          className="deeptravel-button deeptravel-button--secondary deeptravel-button--header-buttons"
        >
          Search
        </button>
      </div>
    </div>
  );
};

export default CabSearch;
