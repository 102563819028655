import React from "react";

const policies = [
  {
    heading: "Cancellation  ",
    description:
      "Cancellation policies vary according to accommodations type. Check what conditions might apply to each option when making your selection.",
  },
  {
    heading: "Children and Bed",
    description:
      "Children of all ages are welcome. Children 13 and above are considered adults at this property. To see correct prices and occupancy info, add the number and ages of children in your group to your search.",
  },
  {
    heading: "Crib and extra bed policies",
    description:
      "Prices for extra beds aren’t included in the total price. They’ll have to be paid for separately during your stay.The number of extra beds allowed depends on the option you choose. Check your selected option for more info.",
  },
  {
    heading: "Age Restriction",
    description: "The minimum age for check-in is 18",
  },
  {
    heading: "Pets",
    description: "Pets are not allowed.",
  },
];

const PropertyRules = () => {
  return (
    <div className=" d-flex flex-column align-items-center gap-4 mb-5 pt-4">
      <div className="w-75">
        <h5>House Rules</h5>
        <p className="small_text__14 mt-1">Hotel Grand Godwin, Delhi, India</p>
      </div>
      <div className="w-75 border border-radius__8 p-4">
        <h5>Policies</h5>
        <p className="small_text__16 my-3">
          Only registered guests are allowed in the guestrooms. Please note that
          cultural norms and guest policies may differ by country and by
          property. The policies listed are provided by the property. For
          transfers, guests must contact the property before arrival at the
          number on the booking confirmation.
        </p>
        <div className="popular-amenities pt-3 d-flex gap-3">
          <div>
            <img
              src="/images/dashboard/trips/property-details/calendar.svg"
              alt="tag"
              className="border border-radius__8 p-2"
            />
          </div>
          <div>
            <div className="d-flex gap-5">
              <div>
                <h6>Check-in</h6>
                <p className="small_text__14 teritiary-color mt-1 mb-2">
                  From 2:00 PM
                </p>
                <div className="time-progress-bar d-flex justify-content-end">
                  <div className="current-progress"></div>
                </div>
              </div>
              <div>
                <h6>Check-out</h6>
                <p className="small_text__14 teritiary-color mt-1 mb-2">
                  From 2:00 PM
                </p>
                <div className="time-progress-bar ">
                  <div className="current-progress w-50"></div>
                </div>
              </div>
            </div>
            <p className="small_text__14 mt-2 mb-4">
              Guests are required to show a photo ID and credit card at check-in
            </p>
            {policies.map((policy, index) => (
              <div key={index} className="mb-4">
                <h6>{policy.heading}</h6>
                <p className="my-1 small_text__14">{policy.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyRules;
