import React, { useEffect, useState } from "react";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import { getValue } from "@utils/lodash";
import "./approval.scss";
import TripsSvgComponent from "@assets/svg-components/dashboard/trips";
import ReportsSvgComponent from "@assets/svg-components/sidebar/reports";
import { useNavigate } from "react-router-dom";
import Switch from "@components/common/switch";
import VerifiedSvgComponent from "@assets/svg-components/verified";
import EditSvgComponent from "@assets/svg-components/edit";
import NoAccessPage from "@components/common/NoAccess";
import GearLoader from "@components/common/GearLoader";
import SkeletonTable from "@components/common/Skeleton/skeleton-table";
function AdminSettingsApproval(props: any) {
  const navigate = useNavigate();
  const [list] = useState([
    {
      module_name: "Reports",
      module_type: "Hierarchical Approval",
      name: "reports",
      status: false,
    },
    {
      module_name: "Trips",
      module_type: "Hierarchical Approval",
      name: "trips",
      status: false,
    },
  ]);

  const getIcons = (name: string) => {
    switch (name) {
      case "trips":
        return <TripsSvgComponent color={"#276ef1"} />;
      case "reports":
        return <ReportsSvgComponent color={"#276ef1"} size={22} />;

      default:
        return null;
    }
  };
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 150);
  }, []);
  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      {!isLoading &&
      !getValue(props, `subTabPermissionList`, []).includes(
        "admin_settings_approval_flows"
      ) ? (
        <NoAccessPage />
      ) : isLoading && !props.permissionAPITriggered ? (
        // <GearLoader />
        <SkeletonTable />
      ) : (
        <>
          <div className="dashboard-wrapper__header d-flex align-items-center justify-content-between">
            <h4 className="dashbaord-wrapper__header-title">Approvals</h4>
            <div></div>
          </div>
          <div className="approval_table_section">
            <table className="mt-3">
              <thead>
                <tr>
                  <td>
                    <h6 className="header_text__16">Module Name</h6>
                  </td>
                  <td>
                    {/* <h6 className="header_text__16">Approval Type</h6> */}
                  </td>
                  {/* <td>
                <h6 className="header_text__16">Action</h6>
                <p className="desc_text">One Step Approval</p>
              </td> */}
                </tr>
              </thead>
              <tbody>
                {list.map((item: object) => {
                  return (
                    <tr
                      onClick={() =>
                        getValue(props, `permissions`, []).includes("update") &&
                        navigate(
                          `/admin/settings/approval/preferences/${getValue(
                            item,
                            `name`,
                            ""
                          )}`
                        )
                      }
                    >
                      <td className="d-flex align-items-center">
                        {/* {getIcons(getValue(item, `name`, ""))} */}
                        <VerifiedSvgComponent />
                        <div className="ms-3">
                          <p className="header_text__14">
                            {getValue(item, `module_name`, "")}
                          </p>
                          <p className="small_text__12">
                            {getValue(item, `module_type`, "")}
                          </p>
                        </div>
                      </td>
                      <td>
                        {getValue(props, `permissions`, []).includes(
                          "update"
                        ) && <EditSvgComponent />}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </DashboardLayout>
  );
}

export default AdminSettingsApproval;
