import HeaderLayout from "@layouts/withoutNaviagtion/withoutNavigationLayout";
import React from "react";
import { useNavigate } from "react-router-dom";

const TripThankYou = () => {
  const navigate = useNavigate();
  return (
    <HeaderLayout>
      <div className="d-flex justify-content-center align-items-center flex-column gap-4">
        <img
          src="/images/dashboard/trips/cab-booking/thankyou1.png"
          className="pt-5 "
          width="60%"
        />
        <button
          className="deeptravel-button deeptravel-button--header-buttons deeptravel-button--primary "
          onClick={() => navigate("/client/trips")}
        >
          Go to Trips Page
        </button>
      </div>
    </HeaderLayout>
  );
};

export default TripThankYou;
