import { getValue } from "@utils/lodash";
import React from "react";

function DateSvgComponent(props: any) {
  return (
    <div>
      <svg
        id="date_range_black_24dp"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
      >
        <path
          id="Path_45636"
          data-name="Path 45636"
          d="M0,0H24V24H0Z"
          fill="none"
        />
        <path
          id="Path_45637"
          data-name="Path 45637"
          d="M7,11H9v2H7ZM21,6V20a2.006,2.006,0,0,1-2,2H5a2,2,0,0,1-2-2L3.01,6A1.991,1.991,0,0,1,5,4H6V2H8V4h8V2h2V4h1A2.006,2.006,0,0,1,21,6ZM5,8H19V6H5ZM19,20V10H5V20Zm-4-7h2V11H15Zm-4,0h2V11H11Z"
          fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "#fd9933"
          }
        />
      </svg>
    </div>
  );
}

export default DateSvgComponent;
