import InputField from "@common/InputRuleFeild";
import {
    formHandleTextAreaArrayChange,
  handleNumberChange,
  handleTextBoolean,
} from "@common/handleChange";
import { getValue } from "@utils/lodash";
import { Modal } from "reactstrap";

function ExpenseLimitsCommentPopup(props: any) {
    const {
        request,
        setRequest,
      } = props;
  return (
    <Modal isOpen={getValue(props, `isOpen`, false)} toggle={props.handleModal}>
      <div className="m-2">
        <div className="mt-2 px-4 d-flex justify-content-between align-items-center">
          <h6 className="header_text">Expense Comment</h6>
          <div
            className="close-image_container ms-3"
            onClick={props.handleModal}
          >
            <img className="close_image" src={`/images/close-black.svg`} />
          </div>
        </div>
        <hr />
      </div>

      <div className="px-4 mb-4 general-limits-form-holder">
        <div className="add-popup-wrapper__cta-wrapper mt-0">
          <div className="w-100 align-items-center">
            <h6 className="desc_text" style={{textAlign: "left"}}>Comments</h6>
            <div>
                <InputField
                    inputType="TEXTAREA"
                    name={"comment"}
                    onChange={(e: any) =>
                        setRequest({
                          ...props.request,
                          comments: getValue(e, `target.value`, ""),
                        })
                    }
                    value={getValue(request, `comments`, "")}
                    placeholder={"Enter Comments"}
                />
            </div>
          </div>
          <hr />
          <button
            className="add-popup-wrapper__cta-btn add-popup-wrapper__cta-btn--secondary"
            onClick={() => {
              props.handleModal();
            }}
          >
            Cancel
          </button>
          <button
            className="add-popup-wrapper__cta-btn add-popup-wrapper__cta-btn--primary"
            onClick={() => props.handleSubmit()}
            disabled={props.isLoading}
          >
            {props.isLoading ? "Please wait..." : "Submit"}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ExpenseLimitsCommentPopup;
