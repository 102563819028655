import { getValue } from "@utils/lodash";
import React from "react";
import FlightSvgComponent from "@assets/svg-components/trips/flight";
import TripsArrowSvgComponent from "@assets/svg-components/trips/tripsarrow";
import {
  findValueFromOptions,
  sortJSONObjectArray,
} from "@common/text-helpers";
import {
  airTravelClass,
  mealType,
  seatType,
  timePreferences,
} from "@common/constants";
import Notfound from "@components/Not-Found";
import HotelSolidSvgComponent from "@assets/svg-components/trips/hotel-solid";
import {
  convertCurrentDateWithTime,
  convertCurrentTime,
  convertDate,
} from "@common/date-helpers";
import { convertCurrentDate } from "@common/date-helpers";
import NotfoundSmall from "@components/Not-Found/not-found-small";
function TripsHotelItinary(props: any) {
  return (
    <div className="itenary_main_section">
      <div className="itenary_main_section_cards_holder">
        {getValue(props, `hotelList.length`, 0) > 0 ? (
          sortJSONObjectArray(getValue(props, `hotelList`, []), "order").map(
            (item: object, index: number) => {
              return (
                <div
                  className="flight_trip_itenary border border-radius__8 position-relative hotel_trip_itenary"
                  key={index}
                >
                  <div className="d-flex align-items-center justify-content-center flight_trip_itenary_tripname_wrapper">
                    <div className="itenary-flight-icon-wrapper">
                      <HotelSolidSvgComponent size={24} />
                    </div>

                    <div className="d-flex align-items-center justify-content-center">
                      <h5 className="header_text__16 text_danger flight_trip_itenary_tripname">
                        {getValue(item, `hotel.city.name`, "")}
                      </h5>
                    </div>
                  </div>

                  <div className="dashed-border"></div>
                  <div className="d-flex align-items-center justify-content-center hotel_timing_details_wrap">
                    <div className="text-right hotel_timing_details_checkin">
                      <div className="d-flex align-items-center text-right">
                        <h6 className="header_text__16">
                          {convertCurrentDate(
                            getValue(item, `hotel.checkin_date`, "")
                          )}
                          ,
                          {convertCurrentTime(
                            getValue(item, `hotel.checkin_time`, "")
                          )}
                        </h6>
                      </div>
                      <p className="small_text__12 hotel_itenary_details_label text-right">
                        Check-in
                      </p>
                    </div>

                    <div className="hotel_trip_itenary_centerarrow_holder">
                      <TripsArrowSvgComponent />
                    </div>

                    <div className="text-left hotel_timing_details_checkout">
                      <div className="d-flex align-items-center text-left">
                        <h6 className="header_text__16">
                          {convertCurrentDate(
                            getValue(item, `hotel.checkout_date`, "")
                          )}
                          ,
                          {convertCurrentTime(
                            getValue(item, `hotel.checkout_time`, "")
                          )}
                        </h6>
                      </div>
                      <p className="small_text__12 hotel_itenary_details_label text-left">
                        Check-out
                      </p>
                    </div>
                    <div></div>
                  </div>
                  {getValue(item, `description`, "") && (
                    <div
                      style={{ background: "#E8F0FE" }}
                      className="itenary_description_wrapper"
                    >
                      <p style={{ color: "#204891" }} className="small_text">
                        {getValue(item, `description`, "")}
                      </p>
                    </div>
                  )}
                </div>
              );
            }
          )
        ) : (
          <NotfoundSmall />
        )}
      </div>
    </div>
  );
}

export default TripsHotelItinary;
