import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  Title,
  PointElement,
  LineElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

function Summarysection(props: any) {
  useEffect(() => {
    ChartJS.register({
      id: "custom_canvas_background_color",
      beforeDraw: (chart: any) => {
        const ctx = chart.canvas.getContext("2d");
        ctx.save();
        ctx.globalCompositeOperation = "destination-over";
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
      },
    });
  }, []);
  const options = {
    responsive: true,
    bezierCurve: false,
    maintainAspectRatio: false, //width
    elements: {
      line: {
        // tension: 0.3,
      },
    },
    plugins: {
      datalabels: {
        display: true,
        // anchor: 'center',
        // align: 'center',
        font: {
          size: 12,
        },
        color: "#000000",
      },
      legend: {
        position: "top" as const,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };

  return (
    <div className="admin_dashbaord-wrapper_info-section2 mt-34">
      <h6 className="header_text__20 mb-2">Spend Summary</h6>
      <div className="dashbaord-wrapper_info-section2_left">
        <div className="line-graph mb-3">
          <Line options={options} data={props.data} />
        </div>
      </div>
      {/* <div className="dashbaord-wrapper_info-section2_right">
        <div className="dashbaord-wrapper_info-section2_left_header">
          <h6 className="header_text p-2 ms-3">Ovarall Summary</h6>
        </div>
        <div className="p-4">
          <div>
            <div className="d-flex align-items-center">
              <div className="icon_circle">
                <ExpenseSvgComponent size={24} color={"#5f6368"} />
              </div>
              <div className="ms-3">
                <p className="small_text__14">Total Expense</p>
                <h6 className="header_text__14">
                  {getValue(
                    props,
                    `info.overallSummary.reimbursements.base_currency_code`,
                    ""
                  )}{" "}
                  {getValue(
                    props,
                    `info.overallSummary.total_epenses.amount`,
                    0
                  )}
                </h6>
              </div>
            </div>
            <div className="border-bottom mt-3 mb-3"></div>
          </div>
          <div>
            <div className="d-flex align-items-center">
              <div className="icon_circle">
                <AmountSvgComponent size={22} color={"#5f6368"} />
              </div>
              <div className="ms-3">
                <p className="small_text__14">Reimbursements</p>
                <h6 className="header_text__14">
                  {getValue(
                    props,
                    `info.overallSummary.reimbursements.base_currency_code`,
                    ""
                  )}{" "}
                  {getValue(
                    props,
                    `info.overallSummary.reimbursements.amount`,
                    0
                  )}
                </h6>
              </div>
            </div>
            <div className="border-bottom mt-3 mb-3"></div>
          </div>
          <div>
            <div className="d-flex align-items-center">
              <div className="icon_circle">
                <TripsSvgComponent size={22} color={"#5f6368"} />
              </div>
              <div className="ms-3">
                <p className="small_text__14">Total Trips</p>
                <h6 className="header_text__14">
                  {getValue(props, `tripInfo.count`, 0)}
                </h6>
              </div>
            </div>
            <div className="border-bottom mt-3 mb-3"></div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Summarysection;
