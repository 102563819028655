import { getValue } from "@utils/lodash";
import React from "react";

function TripsSvgComponent(props: any) {
  return (
    <>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        className="icon align-text-top mr-1"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"
        }
        fill={
          getValue(props, `color`, "")
            ? getValue(props, `color`, "")
            : "#3c4043"
        }
      >
        <path d="M420.6 121.4h-34.5V69.5c0-28.1-22.9-51-51-51H177.7c-28.1 0-51 22.9-51 51v51.9H92.2c-50.2 0-91 40.8-91 91v189.4c0 50.2 40.8 91 91 91h328.4c50.2 0 91-40.8 91-91V212.4c0-50.2-40.8-91-91-91zM168.7 69.5c0-5 4-9 9-9h157.5c5 0 9 4 9 9v51.9H168.7V69.5zm-76.5 93.9h328.5c25.4 0 46.3 19.4 48.8 44.1-.5.1-1 .3-1.5.4l-206.4 71.8c-3 1-6.2 1-9.2 0L44.9 207.9c-.5-.2-1-.3-1.5-.4 2.5-24.7 23.5-44.1 48.8-44.1zm328.4 287.5H92.2c-27 0-49-22-49-49V251.8l195.4 67.6c5.9 2 12.1 3.1 18.3 3.1 6.2 0 12.5-1 18.4-3.1l194.3-67.6v150.1c0 27-22 49-49 49z"></path>
      </svg> */}
      <svg xmlns="http://www.w3.org/2000/svg"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"
        }
        viewBox="0 0 18 18">
        <defs>
          <clipPath id="clip-path-trips">
            <rect id="Rectangle_17086" data-name="Rectangle 17086" width="18" height="18" transform="translate(-14173 -8041)" fill="#3c4043"/>
          </clipPath>
        </defs>
        <g id="Mask_Group_1" data-name="Mask Group 1" transform="translate(14173 8041)" clip-path="url(#clip-path-trips)">
          <g id="work_outline_black_24dp" transform="translate(-14173 -8041)">
            <path id="Path_45616" data-name="Path 45616" d="M0,0H18V18H0Z" fill="none"/>
            <path id="Path_45617" data-name="Path 45617" d="M10.5,4.5V3h-3V4.5ZM3,6v8.25H15V6ZM15,4.5A1.5,1.5,0,0,1,16.5,6v8.25a1.5,1.5,0,0,1-1.5,1.5H3a1.5,1.5,0,0,1-1.5-1.5L1.507,6A1.488,1.488,0,0,1,3,4.5H6V3A1.5,1.5,0,0,1,7.5,1.5h3A1.5,1.5,0,0,1,12,3V4.5Z" fill={
          getValue(props, `color`, "")
            ? getValue(props, `color`, "")
            : "#3c4043"
        }/>
          </g>
        </g>
      </svg>

    </>
  );
}

export default TripsSvgComponent;
