import React from "react";
import { getValue } from "@utils/lodash";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { concatString } from "@common/text-helpers";
import { NavLink } from "react-router-dom";
import EditSvgComponent from "@assets/svg-components/edit";
import DeleteSvgComponent from "@assets/svg-components/delete";
import { getColorStyle } from "@common/color";

function RolesTable(props: any) {
  return (
    <div>
      <table className="datatable_table">
        <thead className="datatable_table_thead">
          <th className="datatable_table_th" style={{ width: "70px" }}>
            {/* <label className="m-0 position-relative" htmlFor="header-checkbox">
              <input
                type="checkbox"
                className="default-checkbox"
                id="header-checkbox"
                onChange={() =>
                  props.handleCheckAll(
                    getValue(props, `rolesList.length`, 0) ===
                      getValue(props, `selectedIDs.length`, 0)
                      ? "All"
                      : "None"
                  )
                }
                checked={
                  getValue(props, `rolesList.length`, 0) ===
                  getValue(props, `selectedIDs.length`, 0)
                }
              />
              <div className="custom-checkbox">
                <img
                  src="/images/uploaded-details/tick.svg"
                  className="img-fluid uploaded-tick"
                />
              </div>
            </label> */}
            <h6 className="">Enable</h6>
          </th>
          <th className="datatable_table_th">
            <h6 className="">Role Name</h6>
          </th>
          <th className="datatable_table_th" style={{ width: "575px" }}>
            <h6 className="">Role Description</h6>
          </th>
          <th className="datatable_table_th" style={{ width: "100px" }}>
            <h6 className="">Status</h6>
          </th>
          <th className="datatable_table_th" style={{ width: "100px" }}>
            <h6 className="">Action</h6>
          </th>
        </thead>
        <tbody className="datatable_table_tbody">
          {getValue(props, `rolesList`, []).map((item: any, index: number) => {
            return (
              <tr key={index} className="datatable_table_tr">
                <td className="datatable_table_td" style={{ width: "70px" }}>
                  <label
                    className="m-0 position-relative "
                    htmlFor={`header-checkbox-${index}`}
                  >
                    <input
                      type="checkbox"
                      className="default-checkbox"
                      id={`header-checkbox-${index}`}
                      onChange={() =>
                        props.handleCheckUsers(getValue(item, `id`, ""))
                      }
                      checked={getValue(props, `selectedIDs`, []).includes(
                        getValue(item, `id`, "")
                      )}
                    />
                    <div className="custom-checkbox">
                      <img
                        src="/images/uploaded-details/tick.svg"
                        className="img-fluid uploaded-tick"
                      />
                    </div>
                  </label>
                </td>
                <td
                  className="datatable_table_td"
                  onClick={() => props.handleNavigate(getValue(item, `id`, ""))}
                >
                  <h6 className=" color_primary">
                    {getValue(item, `title`, "")}
                  </h6>
                </td>
                <td
                  className="datatable_table_td"
                  onClick={() => props.handleNavigate(getValue(item, `id`, ""))}
                  style={{ width: "400px" }}
                >
                  <h6 className="">
                    {concatString(getValue(item, `description`, ""), 30)}
                  </h6>
                </td>
                <td
                  className="datatable_table_td"
                  onClick={() => props.handleNavigate(getValue(item, `id`, ""))}
                  style={{ width: "100px" }}
                >
                  <h6
                    className=""
                    style={getColorStyle(
                      getValue(item, `is_active`, false) ? "Active" : "Inactive"
                    )}
                  >
                    {getValue(item, `is_active`, false) ? "Active" : "Inactive"}
                  </h6>
                </td>
                {/* <td className="datatable_table_td">
                  <div className="more-dropdown">
                    <UncontrolledDropdown>
                      <DropdownToggle>
                        <div className="switch_header__dropdown m-0">
                          <div className="dropdown-content d-flex cursor-pointer">
                            <img
                              src="/images/more.svg"
                              width="24px"
                              height="24px"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() =>
                            props.handleNavigate(getValue(item, `id`, ""))
                          }
                        >
                          <div className={`more-dropdown-item `}>
                            <p>Edit</p>
                          </div>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            props.sendInviteUser(getValue(item, `id`, ""))
                          }
                        >
                          <div className={`more-dropdown-item `}>
                            <p>View</p>
                          </div>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            props.handleDeleteFun(
                              getValue(item, `id`, ""),
                              getValue(item, `title`, "")
                            )
                          }
                        >
                          <div className={`more-dropdown-item `}>
                            <p>Delete</p>
                          </div>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </td> */}
                <td
                  className="datatable_table_td action-icon-td"
                  style={{ width: "100px" }}
                >
                  {/* <div className="d-flex align-items-center ">
                    <div
                      onClick={() =>
                        props.handleNavigate(getValue(item, `id`, ""))
                      }
                    >
                      <EditSvgComponent />
                    </div>
                    <div
                      onClick={() =>
                        props.handleDeleteFun(
                          getValue(item, `id`, ""),
                          getValue(item, `title`, "")
                        )
                      }
                    >
                      <DeleteSvgComponent />
                    </div>
                  </div> */}
                  {getValue(props, `permissions`, []).includes("update") && (
                    <UncontrolledDropdown
                      className="table-action-icon-wrap me-4"
                      direction="start"
                      container={"body"}
                    >
                      <DropdownToggle>
                        <div className="switch_header__dropdown m-0 align-items-center">
                          <div className="close-image_container action-icon-wrap">
                            <img
                              src={"/images/more.svg"}
                              className=" close_image"
                            />
                          </div>
                        </div>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem>
                          <div
                            className={`more-dropdown-main-item `}
                            onClick={() =>
                              props.handleNavigate(getValue(item, `id`, ""))
                            }
                          >
                            Edit
                          </div>
                        </DropdownItem>
                        <DropdownItem>
                          <div
                            className={`more-dropdown-main-item `}
                            onClick={() =>
                              props.handleDeleteFun(
                                getValue(item, `id`, ""),
                                getValue(item, `title`, "")
                              )
                            }
                          >
                            Delete
                          </div>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default RolesTable;
