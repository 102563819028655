import "./search.scss";
import { useState } from "react";
import { getValue } from "@utils/lodash";

export default function Search(props: any) {
  return (
    <section className="common-search-wrapper__filter d-flex align-items-center filterbar-icon-button-holder">
      {/* {props.showSearchTextbox && ( */}
      <>
        <div className="common-search-wrapper__filter-form-group form-group m-0 ">
          <input
            type="text"
            placeholder={props.placeholder ? props.placeholder : "Search"}
            className="common-search-wrapper__filter-search-input"
            value={getValue(props, `searchInput`, "")}
            onChange={props.handleChangeSearch}
          />
          <img
            src="/images/search.svg"
            className="img-fluid common-search-wrapper__filter-input-image"
          />

          <img
            src="/images/close-black.svg"
            className="img-fluid common-search-wrapper__filter-image-close"
            onClick={() => {
              props.toggleSearchClose();
            }}
          />
        </div>
      </>
      {/* )} */}

      {/* <div
				className="common-search-wrapper__filter-container cursor-pointer"
				onClick={() => {
					props.toggleSearchClose()
				}}
			>
				<div className="d-flex">
					<img
						src="/images/search.svg"
						className="img-fluid common-search-wrapper__filter-image"
					/>
				</div>
				<h6 className="common-search-wrapper__filter-title">
					{props.searchText || "SEARCH"}
				</h6>
			</div> */}
    </section>
  );
}
