import InputField from "@common/InputRuleFeild";
import SingleSelectSearchDropdown from "@components/common/CustomSearchableDropdown/SingleSelectSearchDropdown";
import { getValue } from "@utils/lodash";
import { Modal } from "reactstrap";

function VehicleAddPopup(props: any) {
  const {
    isOpen,
    handleModal,
    request,
    setRequest,
    handleSubmit,
    submitLoading,
    editId,
    setEditId,
  } = props;

  return (
    <Modal isOpen={isOpen} toggle={handleModal}>
      <div className="m-2">
        <div className="mt-2 px-4 d-flex justify-content-between align-items-center">
          <h6 className="header_text">New Vehicle</h6>
          <div className="close-image_container ms-3" onClick={handleModal}>
            <img className="close_image" src={`/images/close-black.svg`} />
          </div>
        </div>
        <hr />
      </div>

      <div className="px-4 mb-4 general-limits-form-holder">
        <div>
          <h6 className=" desc_text">Vehicle Name </h6>
          <div className="">
            <InputField
              inputType="TEXT"
              name="vehicle_name"
              placeholder="Vehicle Name"
              value={getValue(request, `vehicle_name`, "")}
              onChange={(e: any) =>
                setRequest({ ...request, vehicle_name: e.target.value })
              }
            />
          </div>
        </div>
        {/* <div className="">
          <SingleSelectSearchDropdown
            placeholder={`Choose Mileage Type`}
            onChange={(e: any) =>
              props.setRequest({
                ...props.request,
                mileage_type: getValue(e, `value`, ""),
              })
            }
            label={`label`}
            //  validator={
            //    getValue(item, `is_mandatory`, false) && simpleValidator
            //  }
            data={[{ value: "manual", label: "Manual" }]}
            value={getValue(request, `mileage_type`, "")}
            selectKey={"value"}
            name={"Mileage Type"}
            //   mandatory
            showLabel
            disabled
          />
        </div> */}
        <div>
          <h6 className=" desc_text">Description </h6>
          <div className="">
            <InputField
              inputType="TEXTAREA"
              name="description"
              placeholder="Description"
              value={getValue(request, `description`, "")}
              onChange={(e: any) =>
                setRequest({ ...request, description: e.target.value })
              }
            />
          </div>
        </div>
        <div className="add-popup-wrapper__cta-wrapper">
          <hr />

          <button
            className="add-popup-wrapper__cta-btn add-popup-wrapper__cta-btn--secondary"
            onClick={() => {
              setRequest({
                ...request,
                vehicle_name: "",
                mileage_type: "manual",
                description: "",
              });
              setEditId("");
              handleModal();
            }}
          >
            Cancel
          </button>
          <button
            className="add-popup-wrapper__cta-btn add-popup-wrapper__cta-btn--primary"
            onClick={() => handleSubmit()}
            disabled={submitLoading}
          >
            {submitLoading ? "Please wait..." : editId ? "Update" : "Save"}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default VehicleAddPopup;
