import { getValue } from "@utils/lodash";
import React from "react";

function TripsArrowSvgComponent(props: any) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 36 36"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "36"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "36"
        }
      >
        <g
          id="Group_10018"
          data-name="Group 10018"
          transform="translate(-409 -372)"
        >
          <g
            id="Ellipse_3649"
            data-name="Ellipse 3649"
            transform="translate(409 372)"
            fill="#fff"
            stroke="#e8f0fe"
            stroke-width="1"
          >
            <circle cx="18" cy="18" r="18" stroke="none" />
            <circle cx="18" cy="18" r="17.5" fill="none" />
          </g>
          <g id="arrow_right_alt_black_24dp" transform="translate(415 378)">
            <path
              id="Path_46311"
              data-name="Path 46311"
              d="M0,0H24V24H0Z"
              fill="none"
            />
            <path
              id="Path_46312"
              data-name="Path 46312"
              d="M16.01,11H4v2H16.01v3L20,12,16.01,8Z"
              fill="#5f6368"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default TripsArrowSvgComponent;
