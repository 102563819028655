import { NavLink } from "react-router-dom";
import "@components/Admin/location-navigation.scss";
import "@components/Admin/inner-page-navigation.scss";
import { getValue } from "@utils/lodash";

export default function ModulesNavigation(props: any) {
  return (
    <div className="common-sticky-header">
      <section className="inner-page-navigation-with-back-cta-buttons position-relative">
        <div className="container-fluid  d-flex justify-content-between align-items-center">
          <div></div>
          {!getValue(props, `hideHeaderMenu`, false) && (
            <ul className="inner-page-navigation-with-back-cta-buttons__navigation d-inline-flex position-absolute">
              <>
                <li className="inner-page-navigation-with-back-cta-buttons__nav-list-item">
                  <NavLink
                    to={`/admin/modules/${getValue(props, `name`, "")}/fields`}
                    className="inner-page-navigation-with-back-cta-buttons__nav-list-item-link"
                  >
                    Fields
                  </NavLink>
                </li>
                <li className="inner-page-navigation-with-back-cta-buttons__nav-list-item">
                  <NavLink
                    to={`/admin/modules/${getValue(
                      props,
                      `name`,
                      ""
                    )}/inactive-fields`}
                    className="inner-page-navigation-with-back-cta-buttons__nav-list-item-link"
                  >
                    Inactive Fields
                  </NavLink>
                </li>
                {getValue(props, `name`, "") === "expenses" && (
                  <>
                    <li className="inner-page-navigation-with-back-cta-buttons__nav-list-item">
                      <NavLink
                        to={`/admin/modules/${getValue(
                          props,
                          `name`,
                          ""
                        )}/active-categories`}
                        className="inner-page-navigation-with-back-cta-buttons__nav-list-item-link"
                      >
                        Active Categories
                      </NavLink>
                    </li>
                    <li className="inner-page-navigation-with-back-cta-buttons__nav-list-item">
                      <NavLink
                        to={`/admin/modules/${getValue(
                          props,
                          `name`,
                          ""
                        )}/expense-types`}
                        className="inner-page-navigation-with-back-cta-buttons__nav-list-item-link"
                      >
                        Expense Types
                      </NavLink>
                    </li>
                  </>
                )}
              </>
              {/* )} */}
            </ul>
          )}

          <div className="d-flex align-items-center">
            {getValue(props, `permissions`, []).includes("update") &&
              getValue(props, `addField`, false) && (
                <div
                  className="custom_field_button_container"
                  onClick={props.handleNavigate}
                >
                  <img
                    className="custom_field_button_container_image"
                    src={`/images/add_circle.svg`}
                  />
                  <h6 className="custom_field_button_container_text">
                    Add New Field
                  </h6>
                </div>
              )}
            {getValue(props, `permissions`, []).includes("update") && (
              <div className="ms-3">
                {!getValue(props, `hideSubmit`, false) ? (
                  <div>
                    {/* <NavLink to={props.url}>
                <button className="deeptravel-button deeptravel-button--header-buttons deeptravel-button--primary mx-3">
                  Cancel
                </button>
              </NavLink> */}

                    <button
                      className={`deeptravel-button deeptravel-button--header-buttons deeptravel-button--${
                        !props.isChanged ? "secondary" : "primary"
                      }`}
                      onClick={props.handleSubmit}
                      disabled={props.isChanged}
                    >
                      {props.isLoading
                        ? "Please wait..."
                        : props.id
                        ? "Update"
                        : "Save"}
                    </button>
                  </div>
                ) : (
                  <button
                    className={`deeptravel-button deeptravel-button--header-buttons deeptravel-button-secondary`}
                  ></button>
                )}
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}
