import React, { useEffect, useState } from "react";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getValue } from "@utils/lodash";
import {
  deleteCustomer,
  getAllCustomers,
  updateCustomerBulk,
} from "@services/customers.service";
import { toast } from "react-toastify";
import DeleteModal from "@common/popup/DeleteModal";
import SearchHeader from "@components/common/SearchHeader";
import GearLoader from "@components/common/GearLoader";
import CustomerTable from "./table";
import Notfound from "@components/Not-Found";
import PaginationPage from "@components/pagination/pagination";
import NavigationBar from "@components/NotificationBar";

function AdminModuleCustomers(props: any) {
  const navigate = useNavigate();
  const params = useParams();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  /* -------------------------------------------------------------------------- */
  /*                               UseState section                             */
  /* -------------------------------------------------------------------------- */

  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length === 0 && props.permissionAPITriggered) {
      getData();
    }
  }, []);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length > 0 && props.permissionAPITriggered) {
      getData();
      if (params.page_no) {
        setPage_no(parseInt(params.page_no));
      }
      if (params.search_text) {
        setSearchInput(params.search_text);
        setShowSearchTextbox(true);
      }
      if (params.limit) {
        setLimit(parseInt(params.limit));
      }
    }
  }, [window.location.href]);

  /* -------------------------------------------------------------------------- */
  /*                                 API section                                */
  /* -------------------------------------------------------------------------- */
  const getData = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    try {
      let payload = {
        page_no: params.page_no ? parseInt(params.page_no) : 1,
        page_size: params.limit ? parseInt(params.limit) : limit,
        search_text: params.search_text,
      };
      let queryRequest = QueryRequestHelper(payload);
      setIsLoading(true);
      let resp = await getAllCustomers(queryRequest);
      if (resp) {
        setList(getValue(resp, `data.data`, []));
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload = {
      ...params,
      page_no: page_no,
    };
    let queryRequest = QueryRequestHelper(payload);

    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleChangeLimit = (limit: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload: any = {
      ...params,
      limit: limit,
      page_no: "1",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Search section                               */
  /* -------------------------------------------------------------------------- */
  const [showSearchTextbox, setShowSearchTextbox] = useState(false);
  const [search_text, setSearchInput] = useState<any>("");

  const toggleSearch = () => {
    setShowSearchTextbox((prevState) => !prevState);
  };
  const toggleSearchClose = () => {
    setSearchInput("");
    toggleSearch();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.search_text) {
      delete params.search_text;
      let payload: any = { ...params };
      // removing null values
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
      getData();
    }
  };

  const handleChangeSearch = async (e: any) => {
    setSearchInput(e.target.value);
    let payload: any = {
      ...urlParams,
      page_no: 1,
      search_text: e.target.value,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    if (!e.target.value) {
      getData();
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Delete section                             */
  /* -------------------------------------------------------------------------- */

  const [deleteValue, setDeleteValue] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const handleDeleteEntity = (id: string, value: string) => {
    setDeleteValue(value);
    setDeleteId(selectedIDs[0]);
    handleDelete();
  };
  const [isDelete, setIsDelete] = useState(false);
  const handleDelete = () => {
    setIsDelete(!isDelete);
  };

  const handleDeleteFunction = async () => {
    try {
      let resp = await deleteCustomer(deleteId);
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        setSelectedIDs([]);
        handleDelete();
        getData();
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const [selectedIDs, setSelectedIDs] = useState<any>([]);

  const handleCheckUsers = (id: string) => {
    let userIDs =
      getValue(selectedIDs, `length`, 0) > 0
        ? selectedIDs.map((item: string) => item)
        : [];
    if (userIDs.includes(id)) {
      let filterIDs = selectedIDs.filter((item: string) => item !== id);
      setSelectedIDs(filterIDs);
    } else {
      selectedIDs.push(id);
      setSelectedIDs([...selectedIDs]);
    }
  };
  const handleCheckAll = (status: string) => {
    if (status === "All") {
      setSelectedIDs([]);
    } else {
      let userIDs =
        getValue(list, `length`, 0) > 0
          ? list.map((item: object) => getValue(item, `id`, ""))
          : [];
      setSelectedIDs(userIDs);
    }
  };
  const handleChangeActiveStatus = async (value: string) => {
    try {
      let resp = await updateCustomerBulk({
        ids: selectedIDs,
        is_active: value === "active" ? true : false,
      });
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        setSelectedIDs([]);
        getData();
      } else {
        getData();
      }
    } catch (error) {}
  };

  const handleNavigate = (id: string) => {
    navigate(`/admin/modules/customers/detail/${id}`);
  };
  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      <div className="dashboard-wrapper__header">
        <h4 className="dashbaord-wrapper__header-title">Customers</h4>
      </div>
      {getValue(selectedIDs, `length`, 0) > 0 && (
        <NavigationBar
          selectedIDs={selectedIDs}
          delete
          handleDeleteFun={handleDeleteEntity}
          handleChangeActiveStatus={handleChangeActiveStatus}
          multi={
            getValue(props, `permissions`, []).includes("update") ? true : false
          }
          permissions={getValue(props, `permissions`, [])}
        />
      )}
      <div className="admin-wrapper position-relative">
        <div className="admin-wrapper position-relative">
          <SearchHeader
            searchInput={search_text}
            showSearchTextbox={showSearchTextbox}
            toggleSearch={toggleSearch}
            toggleSearchClose={toggleSearchClose}
            handleChangeSearch={handleChangeSearch}
            route={"/admin/modules/customers/add"}
          />
        </div>
      </div>
      <div
        className={
          getValue(selectedIDs, `length`, 0) > 0
            ? "datatable_main_container_notifiction"
            : "datatable_main_container"
        }
        style={{ overflowY: isLoading ? "hidden" : "auto" }}
      >
        {isLoading ? (
          <GearLoader />
        ) : getValue(list, `length`, 0) > 0 ? (
          <>
            <CustomerTable
              list={list}
              selectedIDs={selectedIDs}
              handleCheckUsers={handleCheckUsers}
              handleCheckAll={handleCheckAll}
              handleNavigate={handleNavigate}
            />
          </>
        ) : (
          <>{Object.keys(params).length !== 0 ? <Notfound /> : <Notfound />}</>
        )}
        <div className="fixed_footer_wrapper d-flex justify-content-end">
          {/* {list.length > 0 && (
            // <img
            //   src="/images/building-illustration.svg"
            //   className="img-fluid"
            // />
          )} */}
          {totalCount > 10 ? (
            <PaginationPage
              totalCount={totalCount}
              limit={limit}
              pageNumber={page_no}
              handleChangePagination={handleChangePagination}
              handleChangeLimit={handleChangeLimit}
            />
          ) : (
            <p className="show-rows-count d-flex">
              Total Count:&nbsp;&nbsp;{" "}
              <p style={{ color: "rgb(13, 110, 253)" }}>{totalCount}</p>
            </p>
          )}
        </div>
      </div>
      <DeleteModal
        isOpen={isDelete}
        handleModal={handleDelete}
        handleSubmit={handleDeleteFunction}
        deleteValue={deleteValue}
      />
    </DashboardLayout>
  );
}

export default AdminModuleCustomers;
