import React from "react";
import TripsFlightItinary from "./components/flight/itinary";
import TripsHotelItinary from "./components/hotel/itinary";
import TripsCarItinary from "./components/car/itinary";
import TripsTrainItinary from "./components/train/itinary";
import TripsBusItinary from "./components/bus/itinary";
import { getValue } from "@utils/lodash";

function TripsListSection(props: any) {
  const {
    activeTab,
    formInfo,
    flightList,
    hotelList,
    carList,
    trainList,
    busList,
  } = props;
  return (
    <div className="trips_details_navigation_tabs_wrapper">
      {activeTab === "flight" && (
        <TripsFlightItinary
          formInfo={formInfo}
          activeTab={activeTab}
          flightList={flightList}
        />
      )}
      {activeTab === "hotel" && getValue(hotelList, `length`, 0) > 0 && (
        <TripsHotelItinary hotelList={hotelList} />
      )}
      {activeTab === "car" && getValue(carList, `length`, 0) > 0 && (
        <TripsCarItinary list={carList} />
      )}
      {activeTab === "train" && getValue(trainList, `length`, 0) > 0 && (
        <TripsTrainItinary list={trainList} />
      )}
      {activeTab === "bus" && getValue(busList, `length`, 0) > 0 && (
        <TripsBusItinary list={busList} />
      )}
    </div>
  );
}

export default TripsListSection;
