import React from "react";
import "../../online/trip-online.scss";

const PropertyFilter = () => {
  return (
    <div className="property-filter-wrapper mt-5">
      <div className="d-flex justify-content-between online-booking-wrapper pb-2">
        <div className="d-flex gap-1 align-items-center cursor-pointer color_primary">
          <img src="/images/dashboard/trips/online/filter_list.png" />
          Filters
        </div>
        <div
          className="d-flex gap-1 align-items-center"
          style={{ color: "#DADCE0" }}
        >
          <img src="/images/dashboard/trips/online/refresh.png" />
          <img src="/images/dashboard/trips/online/Check.svg" />
          Apply
        </div>
      </div>
      <div className="online-booking-wrapper py-3">
        <div className="d-flex justify-content-between align-items-center header_text__16 cursor-pointer">
          Property Type
          <img src="/images/dashboard/trips/online/expand_more.png" />
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width  cursor-pointer"
          />
          Resorts
          <img
            src="/images/dashboard/trips/online/check_box.png"
            className="checkbox-width cursor-pointer"
          />
          Spa hotels
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width cursor-pointer"
          />
          Bed and breakfasts
        </div>
      </div>
      <div className="online-booking-wrapper py-3">
        <div className="d-flex justify-content-between align-items-center header_text__16 cursor-pointer">
          Deals
          <img src="/images/dashboard/trips/online/expand_more.png" />
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box.png"
            className="checkbox-width cursor-pointer"
          />
          Just deals
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width cursor-pointer"
          />
          Free cancellation
        </div>
      </div>
      <div className="online-booking-wrapper py-3">
        <div className="d-flex justify-content-between align-items-center header_text__16 cursor-pointer">
          Your budget per night (in Rs.)
          <img src="/images/dashboard/trips/online/expand_more.png" />
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width cursor-pointer"
          />
          Less than 10,000
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width cursor-pointer"
          />
          10,000 to 20,000
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box.png"
            className="checkbox-width cursor-pointer"
          />
          40,000 to 60,000
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width cursor-pointer"
          />
          60,000 to 80,000
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width cursor-pointer"
          />
          Above 80,000
        </div>
      </div>
      <div className="online-booking-wrapper py-3">
        <div className="d-flex justify-content-between align-items-center header_text__16 cursor-pointer">
          Hotel Class
          <img src="/images/dashboard/trips/online/expand_more.png" />
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width cursor-pointer"
          />
          <div className=" d-flex gap-1 align-items-center ">
            <img src="/images/dashboard/trips/online/star.png" />
            <img src="/images/dashboard/trips/online/star.png" />
            <img src="/images/dashboard/trips/online/star.png" />
            <img src="/images/dashboard/trips/online/star.png" />
            <img src="/images/dashboard/trips/online/star.png" />
          </div>
          5 Star
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width cursor-pointer"
          />
          <div className=" d-flex gap-1 align-items-center ">
            <img src="/images/dashboard/trips/online/star.png" />
            <img src="/images/dashboard/trips/online/star.png" />
            <img src="/images/dashboard/trips/online/star.png" />
            <img src="/images/dashboard/trips/online/star.png" />
          </div>
          4 Star
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box.png"
            className="checkbox-width cursor-pointer"
          />
          <div className=" d-flex gap-1 align-items-center ">
            <img src="/images/dashboard/trips/online/star.png" />
            <img src="/images/dashboard/trips/online/star.png" />
            <img src="/images/dashboard/trips/online/star.png" />
          </div>
          3 Star
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width cursor-pointer"
          />
          <div className=" d-flex gap-1 align-items-center ">
            <img src="/images/dashboard/trips/online/star.png" />
            <img src="/images/dashboard/trips/online/star.png" />
          </div>
          2 Star
        </div>
      </div>
      <div className="online-booking-wrapper py-3">
        <div className="d-flex justify-content-between align-items-center header_text__16 cursor-pointer">
          Amenities
          <img src="/images/dashboard/trips/online/expand_more.png" />
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width cursor-pointer"
          />
          Free Wifi
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width cursor-pointer"
          />
          Free Breakfast
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box.png"
            className="checkbox-width cursor-pointer"
          />
          Restaurant
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width cursor-pointer"
          />
          Bar
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width cursor-pointer"
          />
          Kid Friendly
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width cursor-pointer"
          />
          Pet Friendly
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width cursor-pointer"
          />
          Free Parking
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box.png"
            className="checkbox-width cursor-pointer"
          />
          Parking
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width cursor-pointer"
          />
          EV Charger
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width cursor-pointer"
          />
          Room Service
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width cursor-pointer"
          />
          Fitness Center
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width cursor-pointer"
          />
          Spa
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box.png"
            className="checkbox-width cursor-pointer"
          />
          Pool
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width cursor-pointer"
          />
          Air-conditioned
        </div>
        <div className="d-flex gap-2 align-items-center gray-color my-2">
          <img
            src="/images/dashboard/trips/online/check_box_outline.png"
            className="checkbox-width cursor-pointer"
          />
          All inclusive available
        </div>
      </div>
    </div>
  );
};

export default PropertyFilter;
