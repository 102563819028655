import { getValue } from "@utils/lodash";
import { toast } from "react-toastify";
import { Nav, NavItem, NavLink } from "reactstrap";

function NavViews(props: any) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());

  return (
    <>
      {getValue(props, `views.length`, 0) > 0 &&
        getValue(props, `views`, []).map((item: object, index: number) => {
          return (
            <div
              className={`navlink_new_policy ${
                getValue(props, `activeTab`, "") === index + 1
                  ? "active-tab"
                  : ""
              }`}
              onClick={() =>
                getValue(urlParams, `policy_id`, "") ||
                getValue(props, `params.id`, "")
                  ? props.handleChangeActiveTab(index + 1)
                  : toast.error("Please add policy settings to continue!")
              }
            >
              {getValue(item, `icon`, "")}
              {getValue(item, `name`, "")}
            </div>
          );
        })}
    </>
  );
}

export default NavViews;
