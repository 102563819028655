import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import React, { useEffect, useState } from "react";
import ModulesNavigation from "../../sub-header";
import {
  getModulesFields,
  removeCustomField,
  updateFieldsSwap,
} from "@services/modules.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getValue } from "@utils/lodash";
import GearLoader from "@components/common/GearLoader";
import Notfound from "@components/Not-Found";
import "../../fields.scss";
import { updateModuleFields } from "@services/modules.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import DeleteModal from "@common/popup/DeleteModal";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import useDraggableInPortal from "@common/useDraggableInPortal";
import DragIndicatorSvgComponent from "@assets/svg-components/drag-indicator";
import { formatText } from "@common/text-helpers";
import { getColorStyle } from "@common/color";
import NoAccessPage from "@components/common/NoAccess";

function AdminModulesReports(props: any) {
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const [isLoading, setIsLoading] = useState(true);
  const [fields, setFields] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (props.permissionAPITriggered) {
      getData(true);
    }
  }, [props.permissionAPITriggered]);

  /* -------------------------------------------------------------------------- */
  /*                                   API Section                              */
  /* -------------------------------------------------------------------------- */

  const getData = async (status: boolean) => {
    try {
      setIsLoading(status);
      let payload = {
        entity_type: "expense_report",
        page_size: 100,
        page_no: 1,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getModulesFields(queryRequest);
      if (resp) {
        setFields(getValue(resp, `data.fields`, []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onChange = (field: string, status: boolean, i: number) => {
    setFields((fields: any) => {
      return fields.map((x: object, index: number) => {
        if (index !== i) return x;
        return {
          ...x,
          [field]: status,
        };
      });
    });
  };
  const handleUpdate = async () => {
    try {
      setSubmitLoading(true);
      let resp = await updateModuleFields({
        fields: fields.map((item: object) => ({
          id: getValue(item, `id`, ""),
          is_enabled: getValue(item, `is_enabled`, false),
          is_mandatory: getValue(item, `is_mandatory`, false),
        })),
      });
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        getData(false);
        setSubmitLoading(false);
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                                 Delete section                             */
  /* -------------------------------------------------------------------------- */

  const [deleteValue, setDeleteValue] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const handleDeleteFun = (id: string, value: string) => {
    setDeleteValue(value);
    setDeleteId(id);
    handleDelete();
  };
  const [isDelete, setIsDelete] = useState(false);
  const handleDelete = () => {
    setIsDelete(!isDelete);
  };

  const handleDeleteFunction = async () => {
    try {
      let resp = await removeCustomField(deleteId);
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        handleDelete();
        getData(false);
      }
    } catch (error) {}
  };

  const handleNavigate = () => {
    navigate(`/admin/modules/reports/fields/add`);
  };
  /* -------------------------------------------------------------------------- */
  /*                                 Drag section                               */
  /* -------------------------------------------------------------------------- */
  const renderDraggable = useDraggableInPortal();

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;
    const { source, destination } = result;
    // Moving from same area in top to botomm direction
    if (source.droppableId === destination.droppableId) {
      handleSwap(source.index, destination.index);
      if (source.droppableId === "source") {
        const sourceOpenArrayCopy: any = [...fields];
        const [removed] = sourceOpenArrayCopy.splice(source.index, 1);
        sourceOpenArrayCopy.splice(destination.index, 0, removed);
        setFields(sourceOpenArrayCopy);
      }
    }
  };

  const handleSwap = async (source: number, destination: number) => {
     // Clone the array
     let updatedFields = [...fields];

     // Retrieve elements at specified indices
     let sourceItem = updatedFields[source];
     let destinationItem = updatedFields[destination];
 
     // Swap the elements
     updatedFields[source] = destinationItem;
     updatedFields[destination] = sourceItem;
     try {
       let payload = {
         swap_fields: updatedFields.map((item: object, index: number) => ({
           ...item,
           seq_no: index + 1,
         })),
       };
       let resp = await updateFieldsSwap(payload);
       if (resp) {
         getData(false);
         toast.success("Position Successfully Modified");
       }
     } catch (error) {}
  };
  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      {!isLoading &&
      !getValue(props, `subTabPermissionList`, []).includes(
        "admin_modules_reports"
      ) ? (
        <NoAccessPage />
      ) : (
        <>
          <ModulesNavigation
            headerTitle={"Fields"}
            name={`reports`}
            handleSubmit={handleUpdate}
            isLoading={submitLoading}
            handleNavigate={handleNavigate}
            addField
            permissions={getValue(props, `permissions`, [])}
          />

          {isLoading || !props.permissionAPITriggered ? (
            <GearLoader />
          ) : (
            <>
              <div className="modules_fields_container">
                <div className="m-4">
                  {getValue(fields, `length`, 0) > 0 ? (
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId="source">
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {fields.map((item: any, index: number) => {
                              return (
                                <Draggable
                                  key={index}
                                  draggableId={`${index}open`}
                                  index={index}
                                  // isDragDisabled={!index}
                                >
                                  {renderDraggable((provided: any) => (
                                    <div className="d-flex align-items-center">
                                      <p className="header_text drag_seq_text text-center">
                                        {index + 1}
                                      </p>
                                      <div
                                        className={`d-flex align-items-center mb-2 drag_common_card ms-2`}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <div className="position-relative w-100 d-flex align-items-center">
                                          <div className="ms-2">
                                            <DragIndicatorSvgComponent
                                              color={"lightgray"}
                                            />
                                          </div>
                                          <div className="ms-4 drag_field_name">
                                            <p className="header_text__14 ">
                                              {getValue(
                                                item,
                                                `display_name`,
                                                ""
                                              )}
                                            </p>
                                            <p className="small_text__12">
                                              {formatText(
                                                getValue(item, `data_type`, "")
                                              )}
                                            </p>
                                          </div>
                                          <div className="ms-5">
                                            <p className="small_text__14 mb-2">
                                              Enable
                                            </p>
                                            <input
                                              type="checkbox"
                                              className="common-checkbox"
                                              id={`header-checkbox-${index}`}
                                              onChange={() => {
                                                onChange(
                                                  "is_enabled",
                                                  !getValue(
                                                    item,
                                                    `is_enabled`,
                                                    false
                                                  ),
                                                  index
                                                );
                                                onChange(
                                                  "is_mandatory",
                                                  false,
                                                  index
                                                );
                                              }}
                                              disabled={getValue(
                                                item,
                                                `is_system_field`,
                                                ""
                                              )}
                                              checked={getValue(
                                                item,
                                                `is_enabled`,
                                                false
                                              )}
                                            />
                                          </div>

                                          <div className="ms-5">
                                            <p className="small_text__14 mb-2">
                                              Mandatory
                                            </p>
                                            <input
                                              type="checkbox"
                                              className="common-checkbox"
                                              id={`header-checkbox-${index}1`}
                                              onChange={() =>
                                                onChange(
                                                  "is_mandatory",
                                                  !getValue(
                                                    item,
                                                    `is_mandatory`,
                                                    false
                                                  ),
                                                  index
                                                )
                                              }
                                              checked={getValue(
                                                item,
                                                `is_mandatory`,
                                                false
                                              )}
                                              disabled={
                                                getValue(
                                                  item,
                                                  `is_system_field`,
                                                  ""
                                                ) ||
                                                !getValue(
                                                  item,
                                                  `is_enabled`,
                                                  false
                                                )
                                              }
                                            />
                                          </div>
                                          <div
                                            style={{
                                              position: "absolute",
                                              right: "-18px",
                                              top: "-16px",
                                            }}
                                          >
                                            <p className="small_text__10 mt-1">
                                              {getValue(
                                                item,
                                                `is_custom_field`,
                                                false
                                              ) && (
                                                <span
                                                  style={getColorStyle(
                                                    "awaiting_approval"
                                                  )}
                                                >
                                                  {formatText(
                                                    getValue(
                                                      item,
                                                      `field_name`,
                                                      ""
                                                    )
                                                  )}
                                                </span>
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      {getValue(
                                        props,
                                        `permissions`,
                                        []
                                      ).includes("update") &&
                                        getValue(
                                          item,
                                          `is_custom_field`,
                                          false
                                        ) && (
                                          <div>
                                            <UncontrolledDropdown>
                                              <DropdownToggle>
                                                <div>
                                                  <div className="common_image_container">
                                                    <img
                                                      src={`/images/settings.svg`}
                                                      className="common_image"
                                                    />
                                                  </div>
                                                </div>
                                              </DropdownToggle>
                                              <DropdownMenu className="bulk-dropdown_field">
                                                <DropdownItem
                                                  className="bulk-dropdown_item"
                                                  onClick={() =>
                                                    navigate(
                                                      `/admin/modules/reports/fields/detail/${getValue(
                                                        item,
                                                        `id`,
                                                        ""
                                                      )}`
                                                    )
                                                  }
                                                >
                                                  Edit
                                                </DropdownItem>
                                                <DropdownItem
                                                  className="bulk-dropdown_item"
                                                  onClick={() =>
                                                    handleDeleteFun(
                                                      getValue(item, `id`, ""),
                                                      getValue(
                                                        item,
                                                        `display_name`,
                                                        ""
                                                      )
                                                    )
                                                  }
                                                >
                                                  Disable
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </UncontrolledDropdown>
                                          </div>
                                        )}
                                    </div>
                                  ))}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  ) : (
                    <Notfound />
                  )}
                </div>
              </div>
            </>
          )}
          <DeleteModal
            isOpen={isDelete}
            handleModal={handleDelete}
            handleSubmit={handleDeleteFunction}
            deleteValue={deleteValue}
          />
        </>
      )}
    </DashboardLayout>
  );
}

export default AdminModulesReports;
