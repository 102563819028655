import React, { useState } from "react";
import DetailContainerDetail from "./Details";
import DetailContainerComments from "./Comments";
import DetailContainerHistory from "./History";

function DetailContainer(props: any) {
  const { activeTab } = props;
  return (
    <div className="expense_detail_container">
      {/* {activeTab == 1 ? (
        <DetailContainerDetail formInfo={props.formInfo} />
      ) : activeTab == 2 ? (
        <DetailContainerComments />
      ) 
      : (
        <DetailContainerHistory />
      )
      } */}
      {activeTab == 1 ? (
        <DetailContainerDetail formInfo={props.formInfo} />
      ) : (
        <DetailContainerComments
          handleSubmit={props.handleAddComment}
          comments={props.comments}
          setComments={props.setComments}
          allComments={props.allComments}
          loading={props.commentLoading}
          handleDeleteFun={props.handleDeleteComment}
        />
      )}
    </div>
  );
}

export default DetailContainer;
