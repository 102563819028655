import * as React from "react";
import DashboardLayout from "@layouts/withoutNaviagtion/withoutNavigationLayout";
import "./index.scss";
import Lottie from "lottie-react";
import PageNotFound from "./page-not-found.json";
interface INotFoundPageProps {}

const NotFoundPage: React.FunctionComponent<INotFoundPageProps> = (props) => {
	return (
		<DashboardLayout>
			{/* <div className="loading-wrapper  d-flex w-100 my-5">
				<div className="loading-wrapper__details"> */}
        <div className="centered">
					<div style={{ width: "40%", height: "40%" }}>
						{/* <img src="/gif/404.gif" className="no-file-image img-fluid" /> */}
						<Lottie animationData={PageNotFound} loop={true} />
					</div>
          </div>
				{/* </div>
			</div> */}
		</DashboardLayout>
	);
};

export default NotFoundPage;
