import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import AddSvgComponent from "@assets/svg-components/add";

export default function SettingTravelDocumentNotfound(props:any) {
  return (
    <>
      <div className="not-found-wrapper d-flex w-100 align-items-center justify-content-center new-not-found-wrapper">
        <div className="not-found-wrapper__details text-center">
          <img
            src="/images/notfound/travel-documents-not-found.svg"
            className="not-found-image img-fluid trips-image"
          />
          <p className="header_text__19 text-center not-found-text setting_traveldoc_notfound_text">
            Please upload your travel documents, including your visa and passport, so we can have them ready for your travel desk when needed for travel arrangements.
          </p>

          <button className="btn btn-primary btn-add-on_not_found" onClick={() => props.toggleTravel()}>
            <span className="d-flex align-items-center">
              <AddSvgComponent color={"#fff"} size={20} />
              Add Document
            </span>
          </button>
        </div>
      </div>
    </>
  );
}
