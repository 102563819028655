import CashAdvanceSvgComponent from "@assets/svg-components/dashboard/advance";
import AmountSvgComponent from "@assets/svg-components/dashboard/amount";
import CategoryExpenseSvgComponent from "@assets/svg-components/dashboard/category-expense";
import CategoryProjectSvgComponent from "@assets/svg-components/dashboard/category-project";
import NodataSvgComponent from "@assets/svg-components/dashboard/nodata";
import TripsSvgComponent from "@assets/svg-components/dashboard/trips";
import ExpenseSvgComponent from "@assets/svg-components/sidebar/expense";
import { getColorCode, getTextColor } from "@common/color";
import {
  formatAmount,
  getFirstLetter,
  getFirstLetterOfEachString,
  sortJSONObjectAmount,
} from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import React from "react";
import { useNavigate } from "react-router-dom";

function AdminDashboardReportSection(props: any) {
  const navigate = useNavigate();
  return (
    <div>
      <div className="admin_dashbaord-wrapper_info-section3 mt-5">
        <div className="dashbaord-wrapper_info-section_report">
          <div className="dashbaord-wrapper_info-section2_left_header d-flex align-items-center p-2">
            <TripsSvgComponent />
            <h6 className="header_text__14 ms-2 text-uppercase">
              Pending Trips
            </h6>
          </div>
          {getValue(props, `tripInfo.awaiting_approval.length`, 0) > 0 ? (
            <div className="p-4">
              {sortJSONObjectAmount(
                getValue(props, `tripInfo.awaiting_approval`, []),
                "total"
              ).map((item: any, index: number) => {
                if (index < 4)
                  return (
                    <div>
                      <div className="d-flex align-items-center">
                        <div
                          className="name_circle"
                          style={{
                            background: getColorCode(
                              getFirstLetter(
                                getValue(item, `created_by.first_name`, "")
                              )
                            ),
                            color: getTextColor(
                              getFirstLetter(
                                getValue(item, `created_by.first_name`, "")
                              )
                            ),
                          }}
                        >
                          {getFirstLetterOfEachString(
                            getValue(item, `created_by.first_name`, "")
                          )}
                        </div>
                        <div className="ms-3">
                          <h6 className="header_text__14">
                            {getValue(item, `created_by.first_name`, "")}{" "}
                            {getValue(item, `created_by.last_name`, "")}
                          </h6>
                          <p className="small_text__14">
                            {getValue(item, `created_by.email`, "")}
                          </p>
                          <p className="small_text__14 mt-2">
                            Amount : INR{" "}
                            {formatAmount(getValue(item, `total`, ""))}
                          </p>
                        </div>
                      </div>
                      <div className="border-bottom mt-3 mb-3"></div>
                    </div>
                  );
              })}
              {getValue(props, `tripInfo.awaiting_approval.length`, 0) > 4 && (
                <div
                  className="d-flex justify-content-end cursor-pointer"
                  onClick={() => navigate(`/admin/trips`)}
                >
                  <p className="small_text__12 color_primary">VIEW MORE</p>
                </div>
              )}
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center mt-5">
              <NodataSvgComponent size={175} />
            </div>
          )}
        </div>
        <div className="dashbaord-wrapper_info-section_report ms-4">
          <div className="dashbaord-wrapper_info-section2_left_header d-flex align-items-center p-2">
            <ExpenseSvgComponent />{" "}
            <h6 className="header_text__14 ms-2 text-uppercase">
              Pending Reports
            </h6>
          </div>
          {getValue(props, `expenseResponse.awaiting_approval.length`, 0) >
          0 ? (
            <div className="p-4">
              {sortJSONObjectAmount(
                getValue(props, `expenseResponse.awaiting_approval`, []),
                "total"
              ).map((item: any, index: number) => {
                if (index < 4)
                  return (
                    <div>
                      <div className="d-flex align-items-center">
                        <div
                          className="name_circle"
                          style={{
                            background: getColorCode(
                              getFirstLetter(
                                getValue(item, `created_by.first_name`, "")
                                  ? getValue(item, `created_by.first_name`, "S")
                                  : getValue(item, `approver_email`, "")
                              )
                            ),
                            color: getTextColor(
                              getFirstLetter(
                                getValue(item, `created_by.first_name`, "")
                                  ? getValue(item, `created_by.first_name`, "S")
                                  : getValue(item, `approver_email`, "")
                              )
                            ),
                          }}
                        >
                          {getFirstLetterOfEachString(
                            getValue(item, `created_by.first_name`, "")
                              ? getValue(item, `created_by.first_name`, "S")
                              : getValue(item, `approver_email`, "")
                          )}
                        </div>
                        <div className="ms-3">
                          <h6 className="header_text__14">
                            {getValue(item, `created_by.first_name`, "")}{" "}
                            {getValue(item, `created_by.last_name`, "")}
                          </h6>
                          <p className="small_text__14">
                            {getValue(item, `created_by.email`, "")}
                          </p>
                          <p className="small_text__14 mt-2">
                            Amount : INR{" "}
                            {formatAmount(getValue(item, `total`, ""))}
                          </p>
                        </div>
                      </div>
                      <div className="border-bottom mt-3 mb-3"></div>
                    </div>
                  );
              })}
              {getValue(props, `expenseResponse.awaiting_approval.length`, 0) >
                4 && (
                <div
                  className="d-flex justify-content-end cursor-pointer"
                  onClick={() => navigate(`/admin/reports`)}
                >
                  <p className="small_text__12 color_primary">VIEW MORE</p>
                </div>
              )}
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center mt-5">
              <NodataSvgComponent size={175} />
            </div>
          )}
        </div>
        <div className="dashbaord-wrapper_info-section_report ms-4">
          <div className="dashbaord-wrapper_info-section2_left_header d-flex align-items-center p-2">
            <CashAdvanceSvgComponent />
            <h6 className="header_text__14 ms-2 text-uppercase">
              Expenses By Department
            </h6>
          </div>
          {getValue(props, `departmentResponse.length`, 0) > 0 ? (
            <div className="p-4">
              {getValue(props, `departmentResponse`, []).map(
                (item: any, index: number) => {
                  if (index < 4)
                    return (
                      <div>
                        <div className="d-flex align-items-center">
                          <div
                            className="name_circle"
                            style={{
                              background: getColorCode(
                                getFirstLetter(
                                  getValue(item, `department_name`, "")
                                    ? getValue(item, `department_name`, "S")
                                    : getValue(item, `approver_email`, "")
                                )
                              ),
                              color: getTextColor(
                                getFirstLetter(
                                  getValue(item, `department_name`, "")
                                    ? getValue(item, `department_name`, "S")
                                    : getValue(item, `approver_email`, "")
                                )
                              ),
                            }}
                          >
                            {getFirstLetterOfEachString(
                              getValue(item, `department_name`, "")
                                ? getValue(item, `department_name`, "S")
                                : getValue(item, `approver_email`, "")
                            )}
                          </div>
                          <div className="ms-3">
                            <h6 className="header_text__14">
                              {getValue(item, `department_name`, "")}{" "}
                            </h6>
                            <p className="small_text__14 mt-2">
                              Amount : INR{" "}
                              {formatAmount(getValue(item, `amount`, ""))}
                            </p>
                          </div>
                        </div>
                        <div className="border-bottom mt-3 mb-3"></div>
                      </div>
                    );
                }
              )}
              {getValue(props, `departmentResponse.length`, 0) > 4 && (
                <div
                  className="d-flex justify-content-end cursor-pointer"
                  onClick={() => navigate(`/admin/reports`)}
                >
                  <p className="small_text__12 color_primary">VIEW MORE</p>
                </div>
              )}
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center mt-5">
              <NodataSvgComponent size={175} />
            </div>
          )}
        </div>
      </div>
      <div className="admin_dashbaord-wrapper_info-section3">
        <div className="dashbaord-wrapper_info-section_report">
          <div className="dashbaord-wrapper_info-section2_left_header d-flex align-items-center p-2">
            {/* <CardSvgComponent /> */}
            <AmountSvgComponent />
            <h6 className="header_text__14 ms-2 text-uppercase">
              Pending Reimbursement
            </h6>
          </div>
          {getValue(props, `expenseResponse.reimbursed.length`, 0) > 0 ? (
            <div className="p-4">
              {sortJSONObjectAmount(
                getValue(props, `expenseResponse.reimbursed`, []),
                "total"
              ).map((item: any, index: number) => {
                if (index < 4)
                  return (
                    <div>
                      <div className="d-flex align-items-center">
                        <div
                          className="name_circle"
                          style={{
                            background: getColorCode(
                              getFirstLetter(
                                getValue(item, `created_by.first_name`, "")
                                  ? getValue(item, `created_by.first_name`, "S")
                                  : getValue(item, `approver_email`, "")
                              )
                            ),
                            color: getTextColor(
                              getFirstLetter(
                                getValue(item, `created_by.first_name`, "")
                                  ? getValue(item, `created_by.first_name`, "S")
                                  : getValue(item, `approver_email`, "")
                              )
                            ),
                          }}
                        >
                          {getFirstLetterOfEachString(
                            getValue(item, `created_by.first_name`, "")
                              ? getValue(item, `created_by.first_name`, "S")
                              : getValue(item, `approver_email`, "")
                          )}
                        </div>
                        <div className="ms-3">
                          <h6 className="header_text__14">
                            {getValue(item, `created_by.first_name`, "")}{" "}
                            {getValue(item, `created_by.last_name`, "")}
                          </h6>
                          <p className="small_text__14">
                            {getValue(item, `created_by.email`, "")}
                          </p>
                          <p className="small_text__14 mt-2">
                            Amount : INR{" "}
                            {formatAmount(getValue(item, `total`, ""))}
                          </p>
                        </div>
                      </div>

                      <div className="border-bottom mt-3 mb-3"></div>
                    </div>
                  );
              })}
              {getValue(props, `expenseResponse.reimbursed.length`, 0) > 4 && (
                <div
                  className="d-flex justify-content-end cursor-pointer"
                  onClick={() => navigate(`/admin/reports`)}
                >
                  <p className="small_text__12 color_primary">VIEW MORE</p>
                </div>
              )}
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center mt-5">
              <NodataSvgComponent size={175} />
            </div>
          )}
        </div>
        <div className="dashbaord-wrapper_info-section_report ms-4">
          <div className="dashbaord-wrapper_info-section2_left_header d-flex align-items-center p-2">
            <CategoryExpenseSvgComponent />
            <h6 className="header_text__14 ms-2 text-uppercase">
              EXPENSES BY CATEGORY
            </h6>
          </div>
          {getValue(props, `expenseCategoryInfo.length`, 0) > 0 ? (
            <div className="p-4">
              {getValue(props, `expenseCategoryInfo`, []).map(
                (item: any, index: number) => {
                  if (index < 4)
                    return (
                      <div>
                        <div className="d-flex align-items-center">
                          <div className="ms-3">
                            <h6 className="header_text__14">
                              {getValue(item, `expense_type_name`, "")}
                            </h6>
                            <p className="small_text__14">
                              Amount : INR{" "}
                              {formatAmount(getValue(item, `amount`, ""))}
                            </p>
                          </div>
                        </div>
                        <div className="border-bottom mt-3 mb-3"></div>
                      </div>
                    );
                }
              )}
              {getValue(props, `expenseCategoryInfo.length`, 0) > 4 && (
                <div
                  className="d-flex justify-content-end cursor-pointer"
                  onClick={() => navigate(`/admin/reports`)}
                >
                  <p className="small_text__12 color_primary">VIEW MORE</p>
                </div>
              )}
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center mt-5">
              <NodataSvgComponent size={175} />
            </div>
          )}
        </div>
        <div className="dashbaord-wrapper_info-section_report ms-4">
          <div className="dashbaord-wrapper_info-section2_left_header d-flex align-items-center p-2">
            <CategoryProjectSvgComponent />
            <h6 className="header_text__14 ms-2 text-uppercase">
              EXPENSES BY PROJECT
            </h6>
          </div>
          {getValue(props, `projectResponse.length`, 0) > 0 ? (
            <div className="p-4">
              {getValue(props, `projectResponse`, []).map(
                (item: any, index: number) => {
                  if (index < 4)
                    return (
                      <div>
                        <div className="d-flex align-items-center">
                          <div className="ms-3">
                            <h6 className="header_text__14">
                              {getValue(item, `project_name`, "")}
                            </h6>
                            <p className="small_text__14">
                              Amount : INR{" "}
                              {formatAmount(getValue(item, `amount`, ""))}
                            </p>
                          </div>
                        </div>
                        <div className="border-bottom mt-3 mb-3"></div>
                      </div>
                    );
                }
              )}
              {getValue(props, `projectResponse.length`, 0) > 4 && (
                <div
                  className="d-flex justify-content-end cursor-pointer"
                  onClick={() => navigate(`/admin/reports`)}
                >
                  <p className="small_text__12 color_primary">VIEW MORE</p>
                </div>
              )}
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center mt-5">
              <NodataSvgComponent size={175} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminDashboardReportSection;
