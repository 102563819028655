import { getColorStyle } from "@common/color";
import { DateFormatList } from "@common/date-format";
import {
  convertCurrentDate,
  convertCurrentDateWithTime,
} from "@common/date-helpers";
import {
  getFirstLetterOfEachString,
  sortJSONObjectArray,
} from "@common/text-helpers";
import { formatText } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import CommonApproverFlowPopup from "./approver-flow";
import { useState } from "react";

function CommonTripsAssociatedPopup(props: any) {
  const { isOpen, toggle, list } = props;
  const getStepColors = (value: number) => {
    switch (value) {
      case 1:
        return "#FEF5E7";
      case 2:
        return "#FFEBD0";
      case 3:
        return "#FFDFB6";
      case 4:
        return "#FFD4A0";
      case 5:
        return "#FFC987";
      case 6:
        return "#FFBD71";
      case 7:
        return "#FFB25B";
      case 8:
        return "#FFA746";
      case 9:
        return "#FF9B30";
      case 10:
        return "#FF8F1A";

      default:
        return "#FF8F1A";
    }
  };

  const itineraries = getValue(props, `list.itineraries`, []);

  const itineraryCounts = itineraries.reduce((acc: any, itinerary: any) => {
    if (itinerary.flight) {
      acc.flight = (acc.flight || 0) + 1;
    }
    if (itinerary.bus) {
      acc.bus = (acc.bus || 0) + 1;
    }
    if (itinerary.car) {
      acc.car = (acc.car || 0) + 1;
    }
    if (itinerary.train) {
      acc.train = (acc.train || 0) + 1;
    }

    if (itinerary.hotel) {
      acc.hotel = (acc.hotel || 0) + 1;
    }
    return acc;
  }, {});

  const [showApprovalPopup, setShowApprovalPopup] = useState(false);

  return (
    <>
      <div
        className={`compelte-task-wrapper comment_history_modal_wrapper associated-trips-wrap ${
          isOpen ? "compelte-task-wrapper--active" : ""
        }`}
        // style={{ width: "792px" }}
      >
        <div className="compelte-task-wrapper__header d-flex align-items-center justify-content-between">
          <h6 className="compelte-task-wrapper__title">
            Trip Detail of {getValue(props, `list.trip_id`, "")}
          </h6>
          <button className="btn close-modal-btn" onClick={() => toggle()}>
            <img
              src="/images/close-white.svg"
              className="img-fluid navigation-bar-wrapper__close-btn"
            />
          </button>
        </div>
        <div className="p-4">
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center">
              <div className="profile_circle bg-success">
                <h5 className="title">
                  {getFirstLetterOfEachString(
                    getValue(props, `list.created_by.first_name`, "")
                  )}
                </h5>
              </div>
              <div>
                <div className="created-by-wrap">
                  {formatText(
                    getValue(props, `list.created_by.first_name`, "")
                  )}{" "}
                  {""}
                  {formatText(
                    getValue(props, `list.created_by.middle_name`, "")
                  )}{" "}
                  {formatText(getValue(props, `list.created_by.last_name`, ""))}
                </div>
                <div className="created-by-mail">
                  {getValue(props, `list.created_by.email`, "")}
                </div>
                <p className="small_text">
                  Created on :{" "}
                  {convertCurrentDate(getValue(props, `list.created_at`, ""))}
                </p>
              </div>
            </div>

            <div className="associate-trips-trip-info ms-4 ps-4">
              <b>{getValue(props, `list.trip_id`, "")}</b>
              <h6
                className="header_text "
                style={getColorStyle(
                  getValue(props, `list.approval_status`, "")
                )}
              >
                {formatText(getValue(props, `list.approval_status`, ""))}
              </h6>
            </div>
          </div>

          <div className="border-bottom my-3"></div>
          <div>
            <div className="trip-name header_text mb-2">
              {getValue(props, `list.trip_name`, "")}
            </div>

            <div className="mb-2">
              Approval Id: {getValue(props, `list.approval_config.id`, "")}
            </div>

            <div className="associated-trip-itenaries  mb-2">
              Itinerary {""}
              <div className="d-flex align-items-center">
                {Object.entries(itineraryCounts).map(([type, count]) => (
                  <div
                    className="itineraries-name itenaries-tags"
                    key={type}
                    style={getColorStyle("violet")}
                  >
                    <b>
                      {`${type.charAt(0).toUpperCase() + type.slice(1)}`}
                      <span className="itenaries-tags-count">{` ${count}`}</span>
                    </b>
                  </div>
                ))}
              </div>
            </div>

            <div className="at-budget-amount mb-2">
              Budget Amount
              <div>
                <b>
                  {" "}
                  {getValue(props, `list.bc_currency_symbol`, "")} {""}{" "}
                  {getValue(props, `list.budget_amount`, "")}
                </b>
              </div>
            </div>

            <div className="at-budget-amount mb-2">
              Travel Type
              <div>
                <b>{formatText(getValue(props, `list.travel_type`, ""))}</b>
              </div>
            </div>

            <div className="border-bottom my-3"></div>

            {getValue(props, `list.current_approver.email`, "") ? (
              <>
                <p className="trip-mandate-approval-info">Current Approver</p>
                <div className="d-flex align-items-center trip-mandate-profile-wrap">
                  <div className="profile_circle bg-warning">
                    <h5 className="title">
                      {getFirstLetterOfEachString(
                        getValue(props, `list.current_approver.first_name`, "")
                      )}
                    </h5>
                  </div>
                  <div>
                    <h6 className={``}>
                      {getValue(props, `list.current_approver.first_name`, "")}{" "}
                      {getValue(props, `list.current_approver.middle_name`, "")}{" "}
                      {getValue(props, `list.current_approver.last_name`, "")}
                    </h6>
                    <p className="small_text">
                      {getValue(props, `list.current_approver.email`, "")}
                    </p>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {getValue(props, `list.trip_approvers.length`, 0) > 0 ? (
              <div>
                <div
                  className=" w-100 cursor-pointer trip-mandate-view-approver-flow"
                  onClick={() => setShowApprovalPopup(true)}
                >
                  <h6 className="small_text__14 color_primary">
                    View approver flow
                  </h6>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <CommonApproverFlowPopup
        showUpdatePopup={showApprovalPopup}
        setShowUpdatePopup={setShowApprovalPopup}
        approvers={getValue(props, `list.trip_approvers`, [])}
      />
    </>
  );
}

export default CommonTripsAssociatedPopup;
