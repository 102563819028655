import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllVehicles = (queryRequest: string) =>
  get(`${config.API_URL}/vehicle?${queryRequest}`);

export const getSpecificVehicle = (id: string) =>
  get(`${config.API_URL}/vehicle/${id}`);

export const createVehicle = (payload: object) =>
  post(`${config.API_URL}/vehicle`, payload);

export const updateVehicle = (id: string, payload: object) =>
  patch(`${config.API_URL}/vehicle/${id}`, payload);

export const deleteVehicle = (id: string) =>
  Delete(`${config.API_URL}/vehicle/${id}`);
