import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Alert } from "antd";
import { getValue } from "@utils/lodash";
import { toast } from "react-toastify";

const baseStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  // padding: "10px",
  borderWidth: 1,
  borderRadius: 2,
  borderColor: "#dadce0",
  borderStyle: "dashed",
  backgroundColor: "#F7FBFF",
  color: "#bdbdbd",
  height: "50px",
  justifyContent: "center",
  transition: "border .3s ease-in-out",
};

const activeStyle = { borderColor: "#2196f3" };
const acceptStyle = { borderColor: "#adff2f" };
const rejectStyle = { borderColor: "red" };

const FILE_TYPES = {
  "application/pdf": [".pdf"],
  "image/jpeg": [".jpg", ".jpeg"],
  "image/png": [".png"],
  "image/svg+xml": [".svg"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
  "text/csv": [".csv"],
  "application/vnd.ms-excel.sheet.macroEnabled.12": [".xlsm"],
  "application/vnd.ms-excel": [".xls"],
  "video/mp4": [".mp4"], // Added MP4 support
};

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

function DragAndDropFileComponent(props) {
  const {
    uploadFile,
    isLoading,
    supportedFormats = ".csv,.xlsx; max file size 5 MB",
    setImportTedFile,
    file,
    fileName,
  } = props;
  const [files, setFiles] = useState([]);

  const validateFile = (file) => {
    // Check file size
    if (file.size > MAX_FILE_SIZE) {
      return {
        valid: false,
        error: `File ${file.name} exceeds maximum size of 10MB`,
      };
    }

    // Check file extension
    const extension = `.${file.name.split(".").pop().toLowerCase()}`;
    const validExtensions = Object.values(FILE_TYPES).flat();
    if (!validExtensions.includes(extension)) {
      return {
        valid: false,
        error: `File type ${extension} is not supported`,
      };
    }

    // Check for double extensions
    if (file.name.split(".").length > 2) {
      return {
        valid: false,
        error: `File ${file.name} contains multiple extensions`,
      };
    }

    // Check for invalid filenames
    if (file.name.startsWith(".") || file.name.toLowerCase() === "web.config") {
      return {
        valid: false,
        error: `Invalid filename: ${file.name}`,
      };
    }

    return { valid: true };
  };

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (isLoading) return;

      const validFiles = [];
      const invalidFiles = [];

      acceptedFiles.forEach((file) => {
        const validation = validateFile(file);
        if (validation.valid) {
          validFiles.push(file);
        } else {
          invalidFiles.push({ file, error: validation.error });
        }
      });

      // Handle rejected files
      [...rejectedFiles, ...invalidFiles].forEach((rejection) => {
        const error = rejection.error || rejection.errors?.[0]?.message;
        toast.error(error || `Failed to upload ${rejection.file.name}`);
      });

      if (validFiles.length > 0) {
        uploadFile(validFiles);
        setFiles(
          validFiles.map((file) => ({
            ...file,
            preview: URL.createObjectURL(file),
          }))
        );
      }
    },
    [isLoading, uploadFile]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: FILE_TYPES,
    maxSize: MAX_FILE_SIZE,
    multiple: false,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragAccept, isDragReject]
  );

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <section className="m-5" style={{ cursor: "pointer" }}>
      <div {...getRootProps({ style })} className="">
        {!isLoading ? (
          <>
            <input {...getInputProps()} />
            <div className="custom-inputfile">
              <div className="">
                <p className="drag-drop-text text-secondary mt-5">
                  Drag & Drop / Browse Files
                </p>
              </div>
              {getValue({ file, fileName }, "file", "") !== "false" &&
                fileName && (
                  <Alert
                    message={fileName}
                    type="success"
                    showIcon
                    closable
                    onClose={() => setImportTedFile("")}
                  />
                )}
            </div>
            <div className="mt-4 mb-2">
              <p className="helper-text mt-3">
                (Supported formats {supportedFormats})
              </p>
            </div>
          </>
        ) : (
          <>
            {/* <ProgressBarLoader /> */}
            <p className="small_text__16 mt-4">
              Uploading in progress, kindly wait.
            </p>
          </>
        )}
      </div>
    </section>
  );
}

export default DragAndDropFileComponent;

