import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllRoles = (queryRequest: string) =>
  get(`${config.API_URL}/roles?${queryRequest}`);

export const getSpecificRole = (id: string) =>
  get(`${config.API_URL}/roles/${id}`);

export const createRole = (payload: object) =>
  post(`${config.API_URL}/roles`, payload);

export const updateRole = (id: string, payload: object) =>
  patch(`${config.API_URL}/roles/${id}`, payload);

export const deleteRole = (id: string) =>
  Delete(`${config.API_URL}/roles/${id}`);

export const updateRoleStatusBulk = (payload: object) =>
  patch(`${config.API_URL}/roles/bulk/active-inactive`, payload);

export const rolesPermissionList = () =>
  get(`${config.API_URL}/roles/list/permissions`);

export const rolesAdminPermissionList = () =>
  get(`${config.API_URL}/roles/list/admin-permissions`);

//attach - detach roles

export const attachRolesInUser = (id: string, payload: object) =>
  patch(`${config.API_URL}/users/${id}/attach/roles`, payload);

export const dettachRolesInUser = (id: string, role_id: string) =>
  Delete(`${config.API_URL}/users/${id}/role?role_id=${role_id}`);
