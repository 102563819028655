import React from "react";
import FormBuilder from "@components/common/FormBuilder";
function ReportsPopup(props: any) {
  const {
    showUpdatePopup,
    setShowUpdatePopup,
    submitLoading,
    handleSubmit,
    editId,
  } = props;
  return (
    <div
      className={`compelte-task-wrapper ${
        showUpdatePopup ? "compelte-task-wrapper--active" : ""
      }`}
      style={{ width: "40%" }}
    >
      <div className="compelte-task-wrapper__header d-flex align-items-center justify-content-between">
        <h6 className="compelte-task-wrapper__title">Reports</h6>
        <img
          src="/images/close-white.svg"
          className="img-fluid navigation-bar-wrapper__close-btn"
          onClick={() => {
            setShowUpdatePopup(false);
          }}
        />
      </div>

      <div className="unassociated_main_container">
        <FormBuilder
          fields={props.form}
          setFields={props.setForm}
          formOptions={props.formInfo}
          simpleValidator={props.simpleValidator}
        />
      </div>

      <div className="fixed_moda_footer_wrapper">
        <div style={{ marginRight: "20px" }}>
          <button
            className="deeptravel-button deeptravel-button--header-buttons deeptravel-button--primary "
            onClick={() => setShowUpdatePopup(false)}
          >
            Cancel
          </button>
          <button
            className="deeptravel-button deeptravel-button--header-buttons deeptravel-button--secondary ms-3"
            onClick={() => handleSubmit()}
            disabled={submitLoading}
          >
            {submitLoading ? "Please wait..." : editId ? "Update" : "Create"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ReportsPopup;
