import React from "react";
import PropertyImages from "./PropertyImages";
import { Link } from "react-router-dom";

interface PropertyOverviewProps {
  amenities: any;
}

const PropertyOverview: React.FC<PropertyOverviewProps> = ({ amenities }) => {
  return (
    <div className=" d-flex flex-column align-items-center gap-4 mb-5">
      <div className="w-100">
        <PropertyImages />
      </div>
      <div className="w-75 border border-radius__8  p-4">
        <div className="d-flex justify-content-between gap-5">
          <div>
            <h3 className="">Hotel Grand Godwin</h3>
            <p className="small_text__14 mt-3">4-Star Hotel</p>
            <p className="small_text__14 my-2">
              8502/41 Arakashan Road Ram Nagar Pahar Ganj, Paharganj, 110055 New
              Delhi, India
            </p>
            <div className="d-flex gap-3 align-items-center small_text__14">
              <Link href="/" className="color_primary">
                Show on map
              </Link>
              <div className="dot-seprator"></div>
              <span>5 Km to city centre</span>
              <div className="dot-seprator"></div>
              <span>15 Km to International Airport</span>
            </div>
            <div className="d-flex gap-1 align-items-center gray-color my-3 ">
              3.8
              <div className="calender-border-left d-flex gap-1 align-items-center ps-2">
                <img src="/images/dashboard/trips/online/star.png" />
                <img src="/images/dashboard/trips/online/star.png" />
                <img src="/images/dashboard/trips/online/star.png" />
                <img src="/images/dashboard/trips/online/star-half.png" />
                <img src="/images/dashboard/trips/online/star-path.png" />
              </div>
              <Link href="/" className="color_primary ps-3">
                120 Reviews
              </Link>
            </div>
          </div>
          <div className="d-flex gap-2 align-items-baseline">
            <img
              src="/images/dashboard/trips/property-details/favorite.svg"
              className="cursor-pointer"
            />
            <img
              src="/images/dashboard/trips/property-details/share.svg"
              className="cursor-pointer"
            />
          </div>
        </div>
        <div className="d-flex gap-3 travel-offers pt-3">
          <div>
            <img
              src="/images/dashboard/trips/property-details/offer.svg"
              alt="tag"
              className="border border-radius__8 p-2"
            />
          </div>
          <div>
            <h6 className="header_text__16 mb-1">Travel Offers</h6>
            <p className="small_text__14">
              This hotel has a discount of 15% or more on some room rates booked
              between March 10 and January 4, 2024 with stay dates between June
              1 and January 4, 2021.
            </p>
          </div>
        </div>
      </div>
      <div className="w-75">
        <p className="header_text__18">TripKartz Recommendation</p>
        <div className="d-flex align-items-center gap-3 small_text__14 mt-1">
          <span>For 2 Nights, 3 Adults, 1Child</span>
          <div className="dot-seprator"></div>
          <span className="color_primary cursor-pointer">View More Rooms</span>
        </div>
      </div>
      <div className="w-75 border border-radius__8">
        <div className="p-4 d-flex justify-content-between">
          <div className="d-flex gap-3">
            <img
              src="/images/dashboard/trips/online/property-1.png"
              alt="room"
              className="property-thumbnail"
            />
            <div>
              <h5>Superior Room with City View</h5>
              <p className="small_text__14 mt-1">2 Twin Beds or 1 Queen Bed</p>
              <div className="d-flex align-items-center gap-3 small_text__14 mt-3">
                <p>Very Good Breakfast Included</p>
                <div className="dot-seprator"></div>
                <p>FREE Cancellation (Until Sun, 6 Sep, 2024)</p>
                <div className="dot-seprator"></div>
                <p>Travel Offer (15%Discount on Room Rate)</p>
              </div>
            </div>
          </div>
          <div className="d-flex gap-3 align-items-start">
            <div>
              <div className="d-flex gap-1 header_text__16 justify-content-end">
                <span className="original-price">₹10,000</span>
                <span className="text-danger">₹9,100</span>
              </div>
              <p className="small_text__14 mt-1">+₹ 340 and Charges</p>
            </div>
            <img
              src="/images/dashboard/trips/online/chevron-down.svg"
              alt="room"
              className="cursor-pointer"
            />
          </div>
        </div>
      </div>
      <div className="w-75 border border-radius__8">
        <div className="p-4 d-flex justify-content-between">
          <div className="d-flex gap-3">
            <img
              src="/images/dashboard/trips/online/property-1.png"
              alt="room"
              className="property-thumbnail"
            />
            <div>
              <h5>Deluxe Room</h5>
              <p className="small_text__14 mt-1">2 Twin Beds or 1 Queen Bed</p>
              <div className="d-flex align-items-center gap-3 small_text__14 mt-3">
                <p>Very Good Breakfast Included</p>
                <div className="dot-seprator"></div>
                <p>FREE Cancellation (Until Sun, 6 Sep, 2024)</p>
                <div className="dot-seprator"></div>
                <p>Travel Offer (15%Discount on Room Rate)</p>
              </div>
            </div>
          </div>
          <div className="d-flex gap-3 align-items-start">
            <div>
              <div className="d-flex gap-1 header_text__16 justify-content-end">
                <span className="original-price">₹10,000</span>
                <span className="text-danger">₹9,100</span>
              </div>
              <p className="small_text__14 mt-1">+₹ 340 and Charges</p>
            </div>
            <img
              src="/images/dashboard/trips/online/chevron-down.svg"
              alt="room"
              className="cursor-pointer"
            />
          </div>
        </div>
      </div>
      <div className="w-75 border border-radius__8  p-4">
        <h4 className="mb-2">About</h4>
        <p className="small_text__16 mb-3">
          Offering an outdoor pool, a fitness center and a spa and wellness
          center, WelcomHotel Bengaluru is located in Bangalore. It operates a
          24-hour front desk to welcome guests at all hours. The hotel also
          offers happy hours at the bar lounge Swizzle. Each room here will
          provide you with a TV, air conditioning and a mini-bar. There is also
          an electric kettle. Featuring a shower, private bathroom also comes
          with a hairdryer and bathrobes. You can enjoy garden view from the
          room. Extras include a seating area and satellite channels. At
          WelcomHotel Bengaluru you will find a restaurant and a fitness center.
          Other facilities offered at the property include meeting facilities, a
          shared lounge and a ticket service.
        </p>
        <div className="popular-amenities d-flex gap-3 travel-offers pt-3">
          <div>
            <img
              src="/images/dashboard/trips/property-details/label_important.svg"
              alt="tag"
              className="border border-radius__8 p-2"
            />
          </div>
          <div className="w-50">
            <h6 className="header_text__16 mb-1">Popular Amenities</h6>
            <ul className="custom-tick">
              {amenities.map((amenities: any, index: number) => (
                <li key={index} className="ticked-list">
                  {amenities}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyOverview;
