import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./routes";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer, Slide } from "react-toastify";
import "./assets/scss/common/base.scss";
import "react-toastify/dist/ReactToastify.css";
import { NavigationProvider } from "@components/common/LeftNavbar/NavigationContext";
import { GlobalStateProvider } from "@context/global-context";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <NavigationProvider>
      <GlobalStateProvider>
        <ToastContainer
          theme="dark"
          position="bottom-center"
          autoClose={5000}
          transition={Slide}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          limit={2}
          pauseOnFocusLoss
          pauseOnHover
        />
        <App />
      </GlobalStateProvider>
    </NavigationProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
