import * as React from "react";
import "./index.scss";
import Lottie from "lottie-react";
import Loader from "./upload.json";

interface INotFoundPageProps {}

const UploadLoader: React.FunctionComponent<INotFoundPageProps> = (props) => {
  return (
    <div className="uploading-wrapper">
      <div className="uploading-image">
        <Lottie animationData={Loader} loop={true} />
      </div>
    </div>
  );
};

export default UploadLoader;
