import TripsSvgComponent from "@assets/svg-components/dashboard/trips";
import { getColorStyle, getSingleColor } from "@common/color";
import { convertCurrentDate, convertDate } from "@common/date-helpers";
import {
  concatString,
  formatAmount,
  formatText,
  getFirstLetter,
  getFirstLetterOfEachString,
} from "@common/text-helpers";
import ProfileCircle from "@components/ProfileCircle";
import { getValue } from "@utils/lodash";
import { Tooltip } from "react-tooltip";

function TripsTable(props: any) {
  return (
    <div>
      <div>
        <table className="datatable_table">
          <thead className="datatable_table_thead">
            <th className="datatable_table_th" style={{ width: "70px" }}>
              {/* <label
                className="m-0 position-relative"
                htmlFor="header-checkbox"
              >
                <input
                  type="checkbox"
                  className="default-checkbox"
                  id="header-checkbox"
                  onChange={() =>
                    props.handleCheckAll(
                      getValue(props, `list.length`, 0) ===
                        getValue(props, `selectedIDs.length`, 0)
                        ? "All"
                        : "None"
                    )
                  }
                  checked={
                    getValue(props, `list.length`, 0) > 0 &&
                    getValue(props, `list.length`, 0) ===
                      getValue(props, `selectedIDs.length`, 0)
                  }
                />
                <div className="custom-checkbox">
                  <img
                    src="/images/uploaded-details/tick.svg"
                    className="img-fluid uploaded-tick"
                  />
                </div>
              </label> */}
              {/* <TripsSvgComponent /> */}
            </th>
            <th className="datatable_table_th">
              <h6 className="">Trip No</h6>
            </th>
            <th className="datatable_table_th">
              <h6 className="">Trip Details</h6>
              <p className="small_text">Travel Type</p>
            </th>
            <th className="datatable_table_th">
              <h6 className="">Total Amount</h6>
            </th>
            <th className="datatable_table_th">
              <h6 className="">Submitted On</h6>
            </th>
            <th className="datatable_table_th">
              <h6 className="">Current Approver</h6>
            </th>
            <th className="datatable_table_th">
              <h6 className="">Status</h6>
            </th>
          </thead>
          <tbody className="datatable_table_tbody">
            {getValue(props, `list`, []).map((item: any, index: number) => {
              return (
                <tr key={index} className="datatable_table_tr">
                  <td className="datatable_table_td" style={{ width: "70px" }}>
                    {/* <label
                      className="m-0 position-relative"
                      htmlFor={`header-checkbox-${index}`}
                    >
                      <input
                        type="checkbox"
                        className="default-checkbox"
                        id={`header-checkbox-${index}`}
                        onChange={() =>
                          props.handleCheckUsers(getValue(item, `id`, ""))
                        }
                        checked={getValue(props, `selectedIDs`, []).includes(
                          getValue(item, `id`, "")
                        )}
                      />
                      <div className="custom-checkbox">
                        <img
                          src="/images/uploaded-details/tick.svg"
                          className="img-fluid uploaded-tick"
                        />
                      </div>
                    </label> */}
                    <TripsSvgComponent color="#212024" size={20} />
                  </td>
                  <td
                    className="datatable_table_td align-items-center"
                    onClick={() =>
                      props.handleNavigate(getValue(item, `id`, ""))
                    }
                  >
                    <div className="d-flex">
                      <div>
                        <h6 className=" color_primary">
                          {getValue(item, `trip_id`, "")}
                        </h6>
                      </div>
                    </div>
                  </td>
                  <td
                    className="datatable_table_td align-items-center"
                    onClick={() =>
                      props.handleNavigate(getValue(item, `id`, ""))
                    }
                  >
                    <div className="d-flex">
                      <div>
                        <h6 className="">{getValue(item, `trip_name`, "")}</h6>
                        <p className="small_text">
                          {/* {convertDate(getValue(item, `submitted_at`, ""))} */}
                          {formatText(getValue(item, `travel_type`, ""))}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td
                    className="datatable_table_td"
                    onClick={() =>
                      props.handleNavigate(getValue(item, `id`, ""))
                    }
                  >
                    {parseFloat(getValue(item, `total`, "")) ? (
                      <h6
                        className={` ${
                          parseInt(getValue(item, `total`, 0)) < 0
                            ? "color_danger"
                            : ""
                        }`}
                      >
                        {getValue(item, `claimed_currency_symbol`, "")}{" "}
                        {formatAmount(getValue(item, `total`, ""))}
                      </h6>
                    ) : (
                      <p className="ms-4">-</p>
                    )}
                  </td>
                  <td
                    className="datatable_table_td"
                    onClick={() =>
                      props.handleNavigate(getValue(item, `id`, ""))
                    }
                  >
                    {/* {getValue(item, `created_by.email`, "") ? (
                      <div className="d-flex align-items-center">
                        <ProfileCircle
                          name="created_by"
                          item={item}
                          value={getValue(item, `created_by.first_name`, "")}
                        />
                        <div>
                          <h6>
                            {" "}
                            {getValue(item, `created_by.first_name`, "")}{" "}
                            {getValue(item, `created_by.middle_name`, "")}{" "}
                            {getValue(item, `created_by.last_name`, "")}
                          </h6>
                          <p>{getValue(item, `created_by.email`, "")}</p>
                        </div>
                      </div>
                    ) : (
                      <p className="text-center ms-5">-</p>
                    )} */}
                    {convertCurrentDate(getValue(item, `submitted_at`, ""))}
                  </td>
                  <td
                    className="datatable_table_td"
                    // d-flex align-items-center
                    onClick={() =>
                      props.handleNavigate(getValue(item, `id`, ""))
                    }
                  >
                    {getValue(item, `current_approver.email`, "") ? (
                      <div className="d-flex align-items-center">
                        {/* <ProfileCircle
                          name="current_approver"
                          item={item}
                          value={getValue(
                            item,
                            `current_approver.first_name`,
                            ""
                          )}
                        /> */}
                        <div className="table-profile-icon">
                          {getFirstLetter(
                            getValue(item, `current_approver.first_name`, "")
                          )}
                        </div>

                        <div>
                          <h6 className={``}>
                            {getValue(item, `current_approver.first_name`, "")}{" "}
                            {getValue(item, `current_approver.middle_name`, "")}{" "}
                            {getValue(item, `current_approver.last_name`, "")}
                          </h6>
                          <p className="small_text">
                            {getValue(item, `current_approver.email`, "")}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <p className="text-center ms-5">-</p>
                    )}
                  </td>
                  <td
                    className="datatable_table_td"
                    onClick={() =>
                      props.handleNavigate(getValue(item, `id`, ""))
                    }
                  >
                    <h6
                      className=""
                      style={getColorStyle(
                        `${getValue(item, `approval_status`, "")}`
                      )}
                    >
                      {formatText(getValue(item, `approval_status`, ""))}
                    </h6>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Tooltip id="my-tooltip" />
    </div>
  );
}

export default TripsTable;
