import InputField from "@common/InputRuleFeild";
import { handleTextArraySubmit } from "@common/handleChange";
import { getValue } from "@utils/lodash";
import { Check } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";

function TripsPopup(props) {
  const POSITION_TYPES = [
    "bottom right",
    "bottom center",
    "top center",
    "left top",
    "left bottom",
    "right top",
    "top left",
    "top center",
    "right center",
    "right bottom",
    "bottom left",
    "left center",
    "center center",
  ];

  const popupRef = useRef();
  const handleClose = (e) => {
    // e.stopPropagation();
    if (popupRef.current) {
      popupRef.current.close();
    }
  };
  const handleOpen = () => {
    if (popupRef.current) {
      popupRef.current.open();
    }
  };

  const CustomButtons = React.forwardRef(({ open, ...props }, ref) => (
    <div
      className="d-flex justify-content-between align-items-center cursor-pointer"
      {...props}
      ref={ref}
    >
      {props.header}
    </div>
  ));
  const [notes, setNotes] = useState("");
  const handleSubmit = () => {
    handleTextArraySubmit(
      props.index,
      "note",
      notes,
      "approval_config_flow",
      props.setRequest
    );
    if (popupRef.current) {
      popupRef.current.close();
    }
  };
  useEffect(() => {
    if (props.value) {
      setNotes(props.value);
    }
  }, []);
  return (
    <Popup
      trigger={(open) => (
        <CustomButtons
          open={open}
          reports={props.reports}
          header={props.header}
        />
      )}
      position={["bottom left"]}
      closeOnDocumentClick
      on={"click"}
      contentStyle={{
        width: "250px",
        zIndex: 9999,
        background: "white",
      }}
      nested
      ref={popupRef}
      onOpen={handleOpen}
    >
      {props.label && <div className="preference-heading">{props.label}</div>}
      <div className="trips_popup_options_container p-2 ">
        {getValue(props, `options`, []).map((item, index) => (
          <div
            className={`py-2 cursor-pointer trips_popup_options mt-1 border-radius__8 ${
              getValue(item, `value`, "") === getValue(props, `value`)
                ? "px-2 active"
                : "px-two-rem"
            }`}
            onClick={() => {
              props.onChange(getValue(item, `value`));
              handleClose();
            }}
          >
            {getValue(item, `value`, "") === getValue(props, `value`) && (
              <Check size={16} color="#fff" />
            )}
            <p className="small_text__14">{getValue(item, `label`, "")}</p>
          </div>
        ))}
      </div>
    </Popup>
  );
}

export default TripsPopup;
