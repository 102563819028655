import InputField from "@common/InputRuleFeild";
import {
  handleNumberChange,
  handleTextBoolean,
} from "@common/handleChange";
import { getValue } from "@utils/lodash";
import { Modal } from "reactstrap";

function ExpenseLimitsPopup(props: any) {
  return (
    <Modal isOpen={getValue(props, `isOpen`, false)} toggle={props.handleModal}>
      <div className="m-2">
        <div className="mt-2 px-4 d-flex justify-content-between align-items-center">
          <h6 className="header_text">Expense Limits</h6>
          <div
            className="close-image_container ms-3"
            onClick={props.handleModal}
          >
            <img className="close_image" src={`/images/close-black.svg`} />
          </div>
        </div>
        <hr />
      </div>

      <div className="px-4 mb-4 general-limits-form-holder">
      {/* mt-3  */}
        <div className="w-100 align-items-center">
        {/* d-flex  */}
          <h6 className="desc_text">Expense amount limit</h6>
          {/* w-50 */}
          <div>
          {/* className="w-50" */}
            <InputField
              inputType="NUMBER"
              name="expense_amount_limit"
              value={getValue(props, `request.expense_amount_limit`, "")}
              onChange={(e: any) =>
                handleNumberChange(e, props.request, props.setRequest)
              }
              placeholder="Enter Expense amount limit"
            />
          </div>
        </div>
        <div className="w-100 align-items-center">
        {/* d-flex  */}
          <h6 className="desc_text">Receipt required limit</h6>
          {/* w-50  */}
          <div>
          {/* className="w-50" */}
            <InputField
              inputType="NUMBER"
              name="receipt_required_limit"
              value={getValue(props, `request.receipt_required_limit`, "")}
              onChange={(e: any) =>
                handleNumberChange(e, props.request, props.setRequest)
              }
              placeholder="Enter Receipt required limit"
            />
          </div>
        </div>
        <div className="d-flex w-100 align-items-center">
          <h6 className="w-50 desc_text mb-0">Make description mandatory : </h6>
          <div className="choicelist-wrapper custom-switch-wrapper">
            <label className="switch">
              <input
                type="checkbox"
                checked={getValue(
                  props,
                  `request.is_description_required`,
                  false
                )}
                onChange={(e: any) =>
                  handleTextBoolean(
                    props.request,
                    props.setRequest,
                    "is_description_required",
                    !getValue(props, `request.is_description_required`, false)
                  )
                }
              />
              <span className="slider round"></span>
            </label>
          </div>
        </div>

        <div className="add-popup-wrapper__cta-wrapper">
          <hr />

          <button
            className="add-popup-wrapper__cta-btn add-popup-wrapper__cta-btn--secondary"
            onClick={() => {
              // props.setRequest({
              //   ...props.request,
              //   expense_amount_limit: "",
              //   receipt_required_limit: "",
              //   is_description_required: false,
              // });
              props.handleModal();
            }}
          >
            Cancel
          </button>
          <button
            className="add-popup-wrapper__cta-btn add-popup-wrapper__cta-btn--primary"
            onClick={() => props.handleSubmit()}
            disabled={props.isLoading}
          >
            {props.isLoading
              ? "Please wait..."
              : "Submit"}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ExpenseLimitsPopup;
