import { convertCurrentDate } from "@common/date-helpers";
import { formatText } from "@common/text-helpers";
import { getValue } from "@utils/lodash";

function TravelProfilePopup(props: any) {
  const { isOpen, toggle, info } = props;
  return (
    <div
      className={`compelte-task-wrapper ${
        isOpen ? "compelte-task-wrapper--active" : ""
      }`}
      style={{ width: "38%" }}
    >
      <div className="compelte-task-wrapper__header d-flex align-items-center justify-content-between">
        <h6 className="compelte-task-wrapper__title">Traveler Profile</h6>
        <img
          src="/images/close-white.svg"
          className="img-fluid navigation-bar-wrapper__close-btn"
          onClick={() => toggle()}
        />
      </div>
      <div className="approver_flow_main_container">
        <div className="m-4">
          <div className="d-flex align-items-center">
            <p className="small_text__14 w-25">Name</p>
            <h6 className="header_text__16">
              {getValue(info, `salutation`, "")}
              {". "}
              {getValue(info, `first_name`, "")}{" "}
              {getValue(info, `middle_name`, "")}{" "}
              {getValue(info, `last_name`, "")}
            </h6>
          </div>
          <div className="border_bottom mb-2 mt-2"></div>
          <div className="d-flex align-items-center">
            <p className="small_text__14 w-25">Email</p>
            <h6 className="header_text__16">{getValue(info, `email`, "")}</h6>
          </div>
          <div className="border_bottom mb-2 mt-2"></div>
          <div className="d-flex align-items-center">
            <p className="small_text__14 w-25">Mobile Number</p>
            <h6 className="header_text__16">
              {getValue(info, `mobile_number`, "")}
            </h6>
          </div>
          <div className="border_bottom mb-2 mt-2"></div>
          <div className="d-flex align-items-center">
            <p className="small_text__14 w-25">Date of Birth</p>
            <h6 className="header_text__16">
              {convertCurrentDate(getValue(info, `date_of_birth`, ""))}
            </h6>
          </div>
          <div className="border_bottom mb-2 mt-2"></div>
          <div className="d-flex align-items-center">
            <p className="small_text__14 w-25">Gender</p>
            <h6 className="header_text__16">
              {formatText(getValue(info, `gender`, ""))}
            </h6>
          </div>
          <div className="border_bottom mb-2 mt-2"></div>

          <div className="">
            <h6 className="header_text__16 color_primary mt-3 mb-3">
              Addresses
            </h6>
            {getValue(info, `addresses.length`, 0) > 0 ? (
              getValue(info, `addresses`, []).map(
                (item: object, key: number) => {
                  return (
                    <div key={key} className="border border-radius__8 p-3 mb-2">
                      <div className="d-flex align-items-center">
                        <p className="small_text__14 w-25">Address 1</p>
                        <h6 className="header_text__16">
                          {getValue(item, `address1`, "")}
                        </h6>
                      </div>
                      <div className="border_bottom mb-2 mt-2"></div>
                      <div className="d-flex align-items-center">
                        <p className="small_text__14 w-25">Address 2</p>
                        <h6 className="header_text__16">
                          {getValue(item, `address2`, "")}
                        </h6>
                      </div>
                      <div className="border_bottom mb-2 mt-2"></div>

                      <div className="d-flex align-items-center">
                        <p className="small_text__14 w-25">Country</p>
                        <h6 className="header_text__16">
                          {getValue(item, `country`, "")}
                        </h6>
                      </div>
                      <div className="border_bottom mb-2 mt-2"></div>

                      <div className="d-flex align-items-center">
                        <p className="small_text__14 w-25">State</p>
                        <h6 className="header_text__16">
                          {getValue(item, `state`, "")}
                        </h6>
                      </div>
                      <div className="border_bottom mb-2 mt-2"></div>

                      <div className="d-flex align-items-center">
                        <p className="small_text__14 w-25">City</p>
                        <h6 className="header_text__16">
                          {getValue(item, `city`, "-")}
                        </h6>
                      </div>
                      <div className="border_bottom mb-2 mt-2"></div>

                      <div className="d-flex align-items-center">
                        <p className="small_text__14 w-25">Pincode</p>
                        <h6 className="header_text__16">
                          {getValue(item, `pin_code`, "")}
                        </h6>
                      </div>
                      <div className="border_bottom mb-2 mt-2"></div>
                    </div>
                  );
                }
              )
            ) : (
              <p className="small_text__18 d-flex justify-content-center">
                No address added
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TravelProfilePopup;
