import { getValue } from "@utils/lodash";
import React from "react";

function CategoryProjectSvgComponent(props: any) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        className="icon align-text-top mr-1"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"
        }
        fill={
          getValue(props, `color`, "")
            ? getValue(props, `color`, "")
            : "#3c4043"
        }
      >
        <path d="m308.5 227.5-76.9 82-30.3-24.3c-9-7.3-22.3-5.8-29.5 3.2-7.3 9-5.8 22.3 3.2 29.5l45.5 36.5c3.9 3.1 8.5 4.6 13.1 4.6 5.6 0 11.2-2.2 15.3-6.6l90.2-96.2c7.9-8.5 7.5-21.8-1-29.7-8.4-7.9-21.7-7.5-29.6 1z"></path>
        <path d="M384.2 55.7c-1-30-25.7-54.1-56-54.1H183.7c-30.3 0-55 24.1-56 54.1-47.9 2.6-86 42.3-86 90.9v272.9c0 50.2 40.8 91 91 91h246.6c50.2 0 91-40.8 91-91v-273c0-48.5-38.2-88.2-86.1-90.8zm-214.5 1.8c0-7.7 6.3-14 14-14h144.6c7.7 0 14 6.3 14 14v37c0 7.7-6.3 14-14 14H183.7c-7.7 0-14-6.3-14-14v-37zm258.6 362c0 27-22 49-49 49H132.7c-27 0-49-22-49-49v-273c0-25.4 19.4-46.3 44.1-48.8 1.7 29.4 26.1 52.8 55.9 52.8h144.6c29.8 0 54.2-23.4 55.9-52.8 24.7 2.5 44.1 23.4 44.1 48.8v273z"></path>
      </svg>
    </div>
  );
}

export default CategoryProjectSvgComponent;
