import { formatText } from "@common/text-helpers";
import SingleSelectSearchDropdown from "@components/common/CustomSearchableDropdown/SingleSelectSearchDropdown";
import { getValue } from "@utils/lodash";
import React, { useState } from "react";

function Analyticsbuilder(props: any) {
  const [dateList] = useState([
    { value: "THIS_WEEK", label: "This Week" },
    { value: "THIS_QUATER", label: "This Quarter" },
    { value: "THIS_MONTH", label: "This Month" },
    { value: "THIS_YEAR", label: "This Year" },
    { value: "PREVIOUS_WEEK", label: "Previous Week" },
    { value: "PREVIOUS_MONTH", label: "Previous Month" },
    { value: "PREVIOUS_QUATER", label: "Previous Quarter" },
    { value: "PREVIOUS_YEAR", label: "Previous Year" },
    { value: "TODAY", label: "Today" },
    { value: "YESTERDAY", label: "Yesterday" },
    { value: "CUSTOM", label: "Custom" },
  ]);
  const [approval_status_list] = useState([
    { value: "unsubmitted", label: formatText("unsubmitted") },
    { value: "awaiting_approval", label: formatText("awaiting_approval") },
    { value: "approved", label: formatText("approved") },
    { value: "rejected", label: formatText("rejected") },
    { value: "reimbursed", label: formatText("reimbursed") },
    { value: "expired", label: formatText("expired") },
    { value: "archived", label: formatText("archived") },
  ]);

  const { request, setRequest, i } = props;
  const renderForm = (item: any) => {
    switch (getValue(item, `key`, "")) {
      case "created_at":
      case "updated_at":
      case "expense_date":
      case "created_at":
      case "submitted_at":
      case "approved_at":
      case "departure_date":
      case "arrival_date":
      case "approved_at":
      case "pick_up_date":
      case "drop_off_date":
      case "checkin_date":
      case "checkout_date":
      case "duration_from":
      case "duration_to":
        return (
          <SingleSelectSearchDropdown
            data={dateList}
            label={"label"}
            value={getValue(item, `value`, "")}
            selectKey={"value"}
            onChange={(e: any) =>
              setRequest((object: any) => {
                return {
                  ...object,
                  ["report_filter"]: object["report_filter"].map(
                    (x: any, index: number) => {
                      if (index !== i) return x;
                      return {
                        ...x,
                        value: getValue(e, `value`, ""),
                        custom_from_date: null,
                        custom_to_date: null,
                      };
                    }
                  ),
                };
              })
            }
            width={"100%"}
          />
        );

      case "approval_status":
        return (
          <SingleSelectSearchDropdown
            data={approval_status_list}
            label={"label"}
            value={getValue(item, `value`, "")}
            selectKey={"value"}
            onChange={(e: any) =>
              setRequest((object: any) => {
                return {
                  ...object,
                  ["report_filter"]: object["report_filter"].map(
                    (x: any, index: number) => {
                      if (index !== i) return x;
                      return {
                        ...x,
                        value: getValue(e, `value`, ""),
                      };
                    }
                  ),
                };
              })
            }
            width={"100%"}
          />
        );

      case "policy_id":
        return (
          <SingleSelectSearchDropdown
            lookup_api={"policies"}
            label={"label"}
            value={getValue(item, `value`, "")}
            selectKey={"id"}
            onChange={(e: any) =>
              setRequest((object: any) => {
                return {
                  ...object,
                  ["report_filter"]: object["report_filter"].map(
                    (x: any, index: number) => {
                      if (index !== i) return x;
                      return {
                        ...x,
                        value: getValue(e, `id`, ""),
                      };
                    }
                  ),
                };
              })
            }
            width={"100%"}
          />
        );

      case "expense_type":
        return (
          <SingleSelectSearchDropdown
            lookup_api={"expense_type"}
            label={"label"}
            value={getValue(item, `value`, "")}
            selectKey={"id"}
            onChange={(e: any) =>
              setRequest((object: any) => {
                return {
                  ...object,
                  ["report_filter"]: object["report_filter"].map(
                    (x: any, index: number) => {
                      if (index !== i) return x;
                      return {
                        ...x,
                        value: getValue(e, `id`, ""),
                      };
                    }
                  ),
                };
              })
            }
            width={"100%"}
          />
        );

      default:
        return null;
    }
  };
  return <div>{renderForm(props.item)}</div>;
}

export default Analyticsbuilder;
