import { sortJSONObjectArray } from "@common/text-helpers";
import { getValue } from "@utils/lodash";

export const generatePrefillTripRequest = (resp: any) => {
  let flightTrips =
    getValue(resp, `itineraries.length`, 0) > 0
      ? getValue(resp, `itineraries`, []).filter(
          (item: object) => getValue(item, `travel_mode`, "") === "flight"
        )
      : [];

  let hotelTrips =
    getValue(resp, `itineraries.length`, 0) > 0
      ? getValue(resp, `itineraries`, []).filter(
          (item: object) => getValue(item, `travel_mode`, "") === "hotel"
        )
      : [];

  let carTrips =
    getValue(resp, `itineraries.length`, 0) > 0
      ? getValue(resp, `itineraries`, []).filter(
          (item: object) => getValue(item, `travel_mode`, "") === "car"
        )
      : [];

  let trainTrips =
    getValue(resp, `itineraries.length`, 0) > 0
      ? getValue(resp, `itineraries`, []).filter(
          (item: object) => getValue(item, `travel_mode`, "") === "train"
        )
      : [];

  let busTrips =
    getValue(resp, `itineraries.length`, 0) > 0
      ? getValue(resp, `itineraries`, []).filter(
          (item: object) => getValue(item, `travel_mode`, "") === "bus"
        )
      : [];
  let type = getValue(flightTrips, `[${0}].air_travel_type`, "");
  generatePrifillFlightResponse(sortJSONObjectArray(flightTrips, "order"));

  let flightItineraries =
    type === "round_trip"
      ? generatePrifillFlightResponse(sortJSONObjectArray(flightTrips, "order"))
      : getValue(flightTrips, `length`, 0) > 0
      ? sortJSONObjectArray(flightTrips, "order").map(
          (item: object, index: number) => ({
            departure_airport_code: getValue(
              item,
              `flight.departure_airport_code`,
              ""
            ),
            itineraries_id: getValue(item, `id`, ""),
            flight_id: getValue(item, `flight.id`, ""),
            departure_date: getValue(item, `flight.departure_date`, ""),
            arrival_airport_code: getValue(
              item,
              `flight.arrival_airport_code`,
              ""
            ),
            arrival_date: getValue(item, `flight.arrival_date`, ""),
            description: getValue(item, `description`, ""),
            departure_time_preference: getValue(
              item,
              `flight.time_preference`,
              ""
            ),
            arrival_time_preference: getValue(
              item,
              `flight.time_preference`,
              ""
            ),
            // is_personal_travel: getValue(item, `is_personal_travel`, false),
            arrival_airport_name: getValue(
              item,
              `flight.arrival_airport_name`,
              ""
            ),
            departure_airport_name: getValue(
              item,
              `flight.departure_airport_name`,
              ""
            ),
            arrival_class:
              type === "round_trip"
                ? getValue(flightTrips, `[${1}].flight.class`, "")
                : "",
            departure_class: getValue(item, `flight.class`, ""),
            order: getValue(item, `order`, ""),
          })
        )
      : [];

  let hotelItineraries =
    getValue(hotelTrips, `length`, 0) > 0
      ? hotelTrips.map((item: object) => ({
          checkin_date: getValue(item, `hotel.checkin_date`, ""),
          id: getValue(item, `id`, ""),
          checkin_time: getValue(item, `hotel.checkin_time`, ""),
          departure_date: getValue(item, `flight.departure_date`, ""),
          checkout_date: getValue(item, `hotel.checkout_date`, ""),
          checkout_time: getValue(item, `hotel.checkout_time`, ""),
          description: getValue(item, `description`, ""),
          city_name: getValue(item, `hotel.city.name`, ""),
          city: getValue(item, `hotel.city.id`, ""),
          hotel_id: getValue(item, `hotel.id`, ""),
          // is_personal_travel: getValue(item, `is_personal_travel`, false),
        }))
      : [];

  let carItineraries =
    getValue(carTrips, `length`, 0) > 0
      ? carTrips.map((item: object) => ({
          pick_up_location: getValue(item, `car.pick_up_location.id`, ""),
          pick_up_city_name: getValue(item, `car.pick_up_location.name`, ""),
          id: getValue(item, `id`, ""),
          pick_up_date: getValue(item, `car.pick_up_date`, ""),
          pick_up_time: getValue(item, `car.pick_up_time`, ""),
          drop_off_location: getValue(item, `car.drop_off_location.id`, ""),
          drop_off_city_name: getValue(item, `car.drop_off_location.name`, ""),
          drop_off_date: getValue(item, `car.drop_off_date`, ""),
          drop_off_time: getValue(item, `car.drop_off_time`, ""),
          city_name: getValue(item, `car.city.name`, ""),
          car_type: getValue(item, `car.car_type`, ""),
          car_id: getValue(item, `car.id`, ""),
          // is_personal_travel: getValue(item, `is_personal_travel`, false),
          is_driver_needed: getValue(item, `car.is_driver_needed`, false),
          description: getValue(item, `description`, ""),
        }))
      : [];

  let trainItineraries =
    getValue(trainTrips, `length`, 0) > 0
      ? trainTrips.map((item: object) => ({
          departure_date: getValue(item, `train.departure_date`, ""),
          departure_city: getValue(item, `train.departure_city.id`, ""),
          departure_city_name: getValue(item, `train.departure_city.name`, ""),
          arrival_city: getValue(item, `train.arrival_city.id`, ""),
          arrival_city_name: getValue(item, `train.arrival_city.name`, ""),
          // is_personal_travel: getValue(item, `is_personal_travel`, false),
          id: getValue(item, `id`, ""),
          train_id: getValue(item, `train.id`, ""),
          description: getValue(item, `description`, ""),
        }))
      : [];

  let busItineraries =
    getValue(busTrips, `length`, 0) > 0
      ? busTrips.map((item: object) => ({
          departure_date: getValue(item, `bus.departure_date`, ""),
          departure_city: getValue(item, `bus.departure_city.id`, ""),
          departure_city_name: getValue(item, `bus.departure_city.name`, ""),
          arrival_city: getValue(item, `bus.arrival_city.id`, ""),
          arrival_city_name: getValue(item, `bus.arrival_city.name`, ""),
          // is_personal_travel: getValue(item, `is_personal_travel`, false),
          id: getValue(item, `id`, ""),
          bus_id: getValue(item, `bus.id`, ""),
          description: getValue(item, `description`, ""),
        }))
      : [];

  return {
    flightTrips: flightTrips,
    flightItineraries: flightItineraries,
    hotelItineraries: hotelItineraries,
    carItineraries: carItineraries,
    trainItineraries: trainItineraries,
    busItineraries: busItineraries,
  };
};

export const generatePrifillFlightResponse = (flightTrips: any) => {
  if (getValue(flightTrips, `length`, 0) === 2) {
    let object = {
      description: getValue(flightTrips, `[${0}].description`, ""),

      //---------- IDs -----------//
      departure_id: getValue(flightTrips, `[${0}].id`, ""),
      departure_flight_id: getValue(flightTrips, `[${0}].flight.id`, ""),
      arrival_id: getValue(flightTrips, `[${1}].id`, ""),
      arrival_flight_id: getValue(flightTrips, `[${1}].flight.id`, ""),

      //---------- arrival -----------//
      arrival_country_name: getValue(
        flightTrips,
        `[${0}].flight.arrival_country_name`,
        ""
      ),
      arrival_city: getValue(flightTrips, `[${0}].flight.arrival_city`, ""),
      arrival_airport_name: getValue(
        flightTrips,
        `[${0}].flight.arrival_airport_name`,
        ""
      ),
      arrival_airport_code: getValue(
        flightTrips,
        `[${0}].flight.arrival_airport_code`,
        ""
      ),
      arrival_class: getValue(flightTrips, `[${1}].flight.class`, ""),
      arrival_date: getValue(flightTrips, `[${1}].flight.arrival_date`, ""),
      arrival_time_preference: getValue(
        flightTrips,
        `[${1}].flight.time_preference`,
        ""
      ),
      //---------- departure -----------//
      departure_country_name: getValue(
        flightTrips,
        `[${0}].flight.departure_country_name`,
        ""
      ),
      departure_city: getValue(flightTrips, `[${0}].flight.departure_city`, ""),
      departure_airport_name: getValue(
        flightTrips,
        `[${0}].flight.departure_airport_name`,
        ""
      ),
      departure_airport_code: getValue(
        flightTrips,
        `[${0}].flight.departure_airport_code`,
        ""
      ),
      departure_class: getValue(flightTrips, `[${0}].flight.class`, ""),
      departure_date: getValue(flightTrips, `[${0}].flight.departure_date`, ""),
      departure_time_preference: getValue(
        flightTrips,
        `[${0}].flight.time_preference`,
        ""
      ),
    };
    return [object];
  }
};

export const generateFlightPayload = (type: string, arr: any, request: any) => {
  if (getValue(arr, `length`, 0) > 0) {
    if (type === "one_way") {
      let array = arr.map((item: object, index: 0) => {
        if (index === 0)
          return {
            order: 1,
            air_travel_type: getValue(request, `air_travel_type`, ""),
            description: getValue(item, `description`, ""),
            id: getValue(item, `itineraries_id`, ""),
            travel_mode: "flight",
            flight: {
              flight_id: getValue(item, `flight_id`, ""),
              departure_airport_code: getValue(
                item,
                `departure_airport_code`,
                ""
              ),
              departure_date: getValue(item, `departure_date`, ""),
              arrival_airport_code: getValue(item, `arrival_airport_code`, ""),
              arrival_date: getValue(item, `arrival_date`, "")
                ? getValue(item, `arrival_date`, "")
                : getValue(item, `departure_date`, ""),
              time_preference: getValue(item, `departure_time_preference`, ""),
              class: getValue(arr, `[${0}].departure_class`, ""),
              seat_type: getValue(request, `seat_type`, ""),
              meal_type: getValue(request, `meal_type`, ""),
            },
          };
      });
      const cleanedData = array.filter((item: any) => item);
      return cleanedData;
    }
    if (type === "round_trip") {
      // Extract the "from" and "to" itineraries based on their order
      const fromItinerary = arr.find((item: any) => item.order === 1);
      const toItinerary = arr.find((item: any) => item.order === 2);
      if (fromItinerary && toItinerary) {
        let from = {
          order: fromItinerary.order,
          air_travel_type: getValue(request, `air_travel_type`, ""),
          description: getValue(fromItinerary, "description", ""),
          id: fromItinerary.itineraries_id,
          travel_mode: "flight",
          flight: {
            flight_id: fromItinerary.flight_id,
            departure_airport_code: fromItinerary.departure_airport_code,
            departure_date: fromItinerary.departure_date,
            arrival_airport_code: fromItinerary.arrival_airport_code,
            arrival_date: fromItinerary.arrival_date,
            time_preference: fromItinerary.departure_time_preference,
            class: fromItinerary.departure_class,
            seat_type: getValue(request, "seat_type", ""),
            meal_type: getValue(request, "meal_type", ""),
          },
        };

        let to = {
          order: toItinerary.order,
          air_travel_type: getValue(request, "air_travel_type", ""),
          description: getValue(toItinerary, "description", ""),
          id: toItinerary.itineraries_id,
          travel_mode: "flight",
          flight: {
            flight_id: toItinerary.flight_id,
            departure_airport_code: toItinerary.departure_airport_code,
            departure_date: toItinerary.departure_date,
            arrival_airport_code: toItinerary.arrival_airport_code,
            arrival_date: toItinerary.arrival_date,
            time_preference: toItinerary.arrival_time_preference,
            class: toItinerary.arrival_class,
            seat_type: getValue(request, "seat_type", ""),
            meal_type: getValue(request, "meal_type", ""),
          },
        };

        return [from, to];
      } else {
        // Handle the case where "from" or "to" itineraries are not found
        // You can return an error or handle it as per your requirements
        console.log("Error: Itineraries not found for both 'from' and 'to'");
        return null; // or return an error object
      }
    }
    if (type === "multi_city") {
      let array = arr.map((item: object, index: number) => ({
        order: index + 1,
        air_travel_type: getValue(request, `air_travel_type`, ""),
        // is_personal_travel: getValue(item, `is_personal_travel`, ""),
        description: getValue(item, `description`, ""),
        id: getValue(item, `itineraries_id`, ""),
        travel_mode: "flight",
        flight: {
          flight_id: getValue(item, `flight_id`, ""),
          departure_airport_code: getValue(item, `departure_airport_code`, ""),
          departure_date: getValue(item, `departure_date`, ""),
          arrival_airport_code: getValue(item, `arrival_airport_code`, ""),
          arrival_date: getValue(item, `arrival_date`, "")
            ? getValue(item, `arrival_date`, "")
            : getValue(item, `departure_date`, ""),
          time_preference: getValue(item, `departure_time_preference`, ""),
          class: getValue(item, `departure_class`, ""),
          seat_type: getValue(request, `seat_type`, ""),
          meal_type: getValue(request, `meal_type`, ""),
          // flight_id: "",
        },
      }));
      const cleanedData = array.filter((item: any) => item);
      return cleanedData;
    }
  } else {
    return [];
  }
};
export const generateFlightCreatePayload = (
  type: string,
  arr: any,
  request: any
) => {
  if (getValue(arr, `length`, 0) > 0) {
    if (type === "one_way") {
      let array = arr.map((item: object, index: 0) => {
        if (index === 0)
          return {
            order: 1,
            air_travel_type: getValue(request, `air_travel_type`, ""),
            description: getValue(item, `description`, ""),
            id: getValue(item, `itineraries_id`, ""),
            travel_mode: "flight",
            flight: {
              flight_id: getValue(item, `flight_id`, ""),
              departure_airport_code: getValue(
                item,
                `departure_airport_code`,
                ""
              ),
              departure_date: getValue(item, `departure_date`, ""),
              arrival_airport_code: getValue(item, `arrival_airport_code`, ""),
              arrival_date: getValue(item, `departure_date`, ""),
              // arrival_date: getValue(item, `arrival_date`, "")
              //   ? getValue(item, `arrival_date`, "")
              //   : getValue(item, `departure_date`, ""),
              time_preference: getValue(item, `departure_time_preference`, ""),
              class: getValue(arr, `[${0}].departure_class`, ""),
              seat_type: getValue(request, `seat_type`, ""),
              meal_type: getValue(request, `meal_type`, ""),
            },
          };
      });
      const cleanedData = array.filter((item: any) => item);
      return cleanedData;
    }
    if (type === "round_trip") {
      let from = {
        order: 1,
        air_travel_type: getValue(request, `air_travel_type`, ""),
        description: getValue(arr, `[${0}].description`, ""),
        id: getValue(arr, `[${0}].departure_id`, ""),
        travel_mode: "flight",
        flight: {
          flight_id: getValue(arr, `[${0}].departure_flight_id`, ""),
          departure_airport_code: getValue(
            arr,
            `[${0}].departure_airport_code`,
            ""
          ),
          departure_date: getValue(arr, `[${0}].departure_date`, ""),
          arrival_airport_code: getValue(
            arr,
            `[${0}].arrival_airport_code`,
            ""
          ),
          arrival_date: getValue(arr, `[${0}].departure_date`, ""),
          time_preference: getValue(
            arr,
            `[${0}].departure_time_preference`,
            ""
          ),
          class: getValue(arr, `[${0}].departure_class`, ""),
          seat_type: getValue(request, `seat_type`, ""),
          meal_type: getValue(request, `meal_type`, ""),
        },
      };
      let to = {
        order: 2,
        air_travel_type: getValue(request, `air_travel_type`, ""),
        description: getValue(arr, `[${0}].description`, ""),
        id: getValue(arr, `[${0}].arrival_id`, ""),
        travel_mode: "flight",
        flight: {
          flight_id: getValue(arr, `[${0}].arrival_flight_id`, ""),
          departure_airport_code: getValue(
            arr,
            `[${0}].arrival_airport_code`,
            ""
          ),
          departure_date: getValue(arr, `[${0}].arrival_date`, ""),
          arrival_airport_code: getValue(
            arr,
            `[${0}].departure_airport_code`,
            ""
          ),
          arrival_date: getValue(arr, `[${0}].arrival_date`, ""),
          time_preference: getValue(arr, `[${0}].arrival_time_preference`, ""),
          class: getValue(arr, `[${0}].arrival_class`, ""),
          seat_type: getValue(request, `seat_type`, ""),
          meal_type: getValue(request, `meal_type`, ""),
        },
      };
      return [from, to];
    }
    if (type === "multi_city") {
      let array = arr.map((item: object, index: number) => ({
        order: index + 1,
        air_travel_type: getValue(request, `air_travel_type`, ""),
        description: getValue(item, `description`, ""),
        id: getValue(item, `itineraries_id`, ""),
        travel_mode: "flight",
        flight: {
          flight_id: getValue(item, `flight_id`, ""),
          departure_airport_code: getValue(item, `departure_airport_code`, ""),
          departure_date: getValue(item, `departure_date`, ""),
          arrival_airport_code: getValue(item, `arrival_airport_code`, ""),
          arrival_date: getValue(item, `arrival_date`, "")
            ? getValue(item, `arrival_date`, "")
            : getValue(item, `departure_date`, ""),
          time_preference: getValue(item, `departure_time_preference`, ""),
          class: getValue(item, `departure_class`, ""),
          seat_type: getValue(request, `seat_type`, ""),
          meal_type: getValue(request, `meal_type`, ""),
        },
      }));
      const cleanedData = array.filter((item: any) => item);
      return cleanedData;
    }
  } else {
    return [];
  }
};
export const generateHotelPayload = (arr: any) => {
  let array = arr.map((item: object, index: number, params: any) => ({
    order: index + 1,
    // is_personal_travel: getValue(item, `is_personal_travel`, false),
    description: getValue(item, `description`, ""),
    travel_mode: "hotel",
    id: getValue(params, `id`, "") ? getValue(item, `id`, "") : "",
    hotel: {
      id: getValue(item, `hotel_id`, ""),
      checkin_date: getValue(item, `checkin_date`, ""),
      checkin_time: getValue(item, `checkin_time`, ""),
      checkout_date: getValue(item, `checkout_date`, ""),
      checkout_time: getValue(item, `checkout_time`, ""),
      city: getValue(item, `city`, ""),
    },
  }));
  const cleanedData = array.filter((item: any) => item);
  return cleanedData;
};
export const generateCarPayload = (arr: any, params: any) => {
  let array = arr.map((item: object, index: number) => ({
    order: index + 1,
    // is_personal_travel: getValue(item, `is_personal_travel`, false),
    description: getValue(item, `description`, ""),
    travel_mode: "car",
    id: getValue(params, `id`, "") ? getValue(item, `id`, "") : "",
    car: {
      id: getValue(item, `car_id`, ""),
      pick_up_location: getValue(item, `pick_up_location`, ""),
      pick_up_date: getValue(item, `pick_up_date`, ""),
      pick_up_time: getValue(item, `pick_up_time`, ""),
      drop_off_location: getValue(item, `drop_off_location`, ""),
      drop_off_date: getValue(item, `drop_off_date`, ""),
      drop_off_time: getValue(item, `drop_off_time`, ""),
      is_driver_needed: getValue(item, `is_driver_needed`, ""),
      car_type: getValue(item, `car_type`, ""),
    },
  }));
  const cleanedData = array.filter((item: any) => item);
  return cleanedData;
};
export const generateTrainPayload = (arr: any, params: any) => {
  let array = arr.map((item: object, index: number) => ({
    order: index + 1,
    // is_personal_travel: getValue(item, `is_personal_travel`, false),
    description: getValue(item, `description`, ""),
    travel_mode: "train",
    id: getValue(params, `id`, "") ? getValue(item, `id`, "") : "",
    train: {
      departure_date: getValue(item, `departure_date`, ""),
      departure_city: getValue(item, `departure_city`, ""),
      arrival_city: getValue(item, `arrival_city`, ""),
      id: getValue(item, `train_id`, ""),
    },
  }));
  const cleanedData = array.filter((item: any) => item);
  return cleanedData;
};
export const generateBusPayload = (arr: any, params: any) => {
  let array = arr.map((item: object, index: number) => ({
    order: index + 1,
    // is_personal_travel: getValue(item, `is_personal_travel`, false),
    description: getValue(item, `description`, ""),
    travel_mode: "bus",
    id: getValue(params, `id`, "") ? getValue(item, `id`, "") : "",
    bus: {
      departure_date: getValue(item, `departure_date`, ""),
      departure_city: getValue(item, `departure_city`, ""),
      arrival_city: getValue(item, `arrival_city`, ""),
      id: getValue(item, `bus_id`, ""),
    },
  }));
  const cleanedData = array.filter((item: any) => item);
  return cleanedData;
};
