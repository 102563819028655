import { useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import InputField from "@common/InputRuleFeild";
import LoginPageLyout from "@layouts/login";
import { adminForgotPassword } from "@services/auth.service";
import SimpleReactValidator from "simple-react-validator";
import { useParams, useNavigate } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { MoveLeft } from "lucide-react";

interface ILoginPageProps {}

const LoginPage: React.FunctionComponent<ILoginPageProps> = (props: any) => {
  let navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [isLoading, setIsLoading] = useState(false);
  const [, forceUpdate] = useState(0);
  const [email, setEmail] = useState("");
  const [user_type] = useState("VENDOR");

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setIsLoading(true);
        let payload = {
          email: email,
          user_type: user_type,
        };
        let resp = await adminForgotPassword(payload);
        if (resp) {
          setIsLoading(false);
          toast.success(getValue(resp, "message", ""));
          navigate(`/sent-email`);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && e.target.tagName.toLowerCase() !== "button") {
      e.preventDefault();
      handleSubmit(e);
    }
  };
  return (
    <LoginPageLyout>
      <div className="form-wrapper flex-1  position-relative d-flex justify-content-center justify-content-lg-end">
        {/* <a href="#0" className=""> */}

        {/* </a> */}
        <div className="form-wrapper__form px-1 pb-4">
          <img src="/images/logo.png" className="signin-mobile-logo" />
          {/* m-auto */}
          <NavLink to="/login">
            <a href="#0" className="back-btn gap-2">
              <MoveLeft color="#1966A1" />
              Back
            </a>
          </NavLink>
          <h2>Password Reset</h2>
          <div className="login-fields-wrap">
            <label htmlFor="email">Email</label>
            <InputField
              inputType="EMAIL"
              id="email"
              name="email"
              value={email}
              onChange={(e: any) => setEmail(e.target.value.toLowerCase())}
              placeholder="Enter Email"
              className="form-control"
              label="Email"
              validator={simpleValidator}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="form-cta-button form-cta-password-reset">
            {isLoading ? (
              <button className="form-cta-button__primary-btn form-cta-button__primary-btn--reset-btn">
                Please wait...
              </button>
            ) : (
              <button
                className="form-cta-button__primary-btn form-cta-button__primary-btn--reset-btn"
                onClick={handleSubmit}
              >
                Get Reset Link
              </button>
            )}
          </div>
        </div>
      </div>
    </LoginPageLyout>
  );
};

export default LoginPage;
