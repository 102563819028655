import { config } from "../env";
import {
  Delete,
  get,
  patch,
  post,
  postFileUpload,
} from "./helpers/http-handler";

//list-item
export const listManagementItem = (queryRequest: string) =>
  get(`${config.API_URL}/list-management/list-item?${queryRequest}`);

export const listManagementByGroupcode = (group_code: string) =>
  get(`${config.API_URL}/list-management/list/${group_code}`);

//list - group
export const listManagementCreateGroup = (payload: object) =>
  post(`${config.API_URL}/list-management/groups`, payload);

export const getListManagementGroups = (queryRequest: string) =>
  get(`${config.API_URL}/list-management/groups?${queryRequest}`);

export const updateListManagementGroups = (id: string, payload: object) =>
  patch(`${config.API_URL}/list-management/groups/${id}`, payload);

export const deleteListManagementGroup = (id: string) =>
  Delete(`${config.API_URL}/list-management/groups/${id}`);

//list - group items

export const listManagementCreateGroupItem = (
  group_id: string,
  payload: object
) =>
  post(`${config.API_URL}/list-management/groups/${group_id}/items`, payload);

export const getlistManagementGroupItem = (
  group_id: string,
  queryRequest: string
) =>
  get(
    `${config.API_URL}/list-management/groups/${group_id}/items?${queryRequest}`
  );

export const getListManagementGroupItemsByGroupId = (
  group_id: string,
  queryRequest: string
) =>
  get(
    `${config.API_URL}/list-management/groups/${group_id}/items?${queryRequest}`
  );

export const updateListManagementGroupItemsByGroupId = (
  group_id: string,
  item_id: string,
  payload: object
) =>
  patch(
    `${config.API_URL}/list-management/groups/${group_id}/items/${item_id}`,
    payload
  );

export const deleteListManagementGroupItemsByGroupId = (
  group_id: string,
  item_id: string
) =>
  Delete(
    `${config.API_URL}/list-management/groups/${group_id}/items/${item_id}`
  );

//list - location management

export const getlocationManagementCountries = (queryRequest: string) =>
  get(`${config.API_URL}/location-management/list/countries?${queryRequest}`);

export const getlocationManagementStates = (queryRequest: string) =>
  get(`${config.API_URL}/location-management/list/states?${queryRequest}`);

export const getlocationManagementCities = (queryRequest: string) =>
  get(`${config.API_URL}/location-management/list/cities?${queryRequest}`);

export const locationManagementImportCountries = (payload: any) =>
  postFileUpload(
    `${config.API_URL}/location-management/import/countries`,
    payload
  );

export const locationManagementImportStates = (payload: any) =>
  postFileUpload(
    `${config.API_URL}/location-management/import/states`,
    payload
  );

export const locationManagementImportCities = (payload: any) =>
  postFileUpload(
    `${config.API_URL}/location-management/import/cities`,
    payload
  );

export const locationManagementSearchCities = (queryRequest: string) =>
  get(`${config.API_URL}/location-management/search/cities?${queryRequest}`);

export const locationManagementSearchCountries = (queryRequest: string) =>
  get(`${config.API_URL}/location-management/list/countries?${queryRequest}`);
