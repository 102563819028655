import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllProjects = (queryRequest: string) =>
  get(`${config.API_URL}/project?${queryRequest}`);

export const getSpecificProject = (id: string) =>
  get(`${config.API_URL}/project/${id}`);

export const createProject = (payload: object) =>
  post(`${config.API_URL}/project`, payload);

export const updateProject = (id: string, payload: object) =>
  patch(`${config.API_URL}/project/${id}`, payload);

export const deleteProject = (id: string) =>
  Delete(`${config.API_URL}/project/${id}`);

export const updateProjectsBulk = (payload: object) =>
  patch(`${config.API_URL}/project/bulk/active-inactive`, payload);
