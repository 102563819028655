import AdminModuleCustomers from "@pages/Admin/Modules/Customers";
import CustomerDetailsPage from "@pages/Admin/Modules/Customers/Detail";
import AdminExpenseActiveCategories from "@pages/Admin/Modules/Expense/active-categories";
import AdminExpenseTypes from "@pages/Admin/Modules/Expense/expense-types";
import AdminModulesExpense from "@pages/Admin/Modules/Expense/fields";
import FieldDetails from "@pages/Admin/Modules/Expense/fields/Detail";
import AdminModuleProjects from "@pages/Admin/Modules/Projects";
import ProjectsDetailsPage from "@pages/Admin/Modules/Projects/Detail";
import AdminModulesReports from "@pages/Admin/Modules/Reports/fields";
import ReportFieldDetails from "@pages/Admin/Modules/Reports/fields/Detail";
import AdminModulesTrips from "@pages/Admin/Modules/Trips/fields";
import TripFieldDetails from "@pages/Admin/Modules/Trips/fields/Detail";
import AdminModulesUsers from "@pages/Admin/Modules/Users/fields";
import UsersFieldDetails from "@pages/Admin/Modules/Users/fields/Detail";
import AdminArchivedFields from "@pages/Admin/Modules/common/fields";

export const AdminModulesRoutes = [
  //expenses
  {
    path: "/admin/modules/expenses/fields",
    name: "admin_modules_expenses",
    component: AdminModulesExpense,
  },
  {
    path: "/admin/modules/:type/inactive-fields",
    name: "admin_modules_expenses",
    component: AdminArchivedFields,
  },
  {
    path: "/admin/modules/expenses/fields/add",
    name: "admin_modules_expenses",
    component: FieldDetails,
  },
  {
    path: "/admin/modules/expenses/fields/detail/:id",
    name: "admin_modules_expenses",
    component: FieldDetails,
  },
  {
    path: "/admin/modules/expenses/active-categories",
    name: "admin_modules_expenses",
    component: AdminExpenseActiveCategories,
  },
  {
    path: "/admin/modules/expenses/expense-types",
    name: "admin_modules_expenses",
    component: AdminExpenseTypes,
  },

  //reports
  {
    path: "/admin/modules/reports/fields",
    name: "admin_modules_reports",
    component: AdminModulesReports,
  },
  {
    path: "/admin/modules/reports/fields/add",
    name: "admin_modules_reports",
    component: ReportFieldDetails,
  },
  {
    path: "/admin/modules/reports/fields/detail/:id",
    name: "admin_modules_reports",
    component: ReportFieldDetails,
  },
  //Trips
  {
    path: "/admin/modules/trips/fields",
    name: "admin_modules_trips",
    component: AdminModulesTrips,
  },
  {
    path: "/admin/modules/trips/fields/add",
    name: "admin_modules_trips",
    component: TripFieldDetails,
  },
  {
    path: "/admin/modules/trips/fields/detail/:id",
    name: "admin_modules_trips",
    component: TripFieldDetails,
  },
  //users
  {
    path: "/admin/modules/users/fields",
    name: "admin_modules_users",
    component: AdminModulesUsers,
  },
  {
    path: "/admin/modules/users/fields/add",
    name: "admin_modules_users",
    component: UsersFieldDetails,
  },
  {
    path: "/admin/modules/users/fields/detail/:id",
    name: "admin_modules_users",
    component: UsersFieldDetails,
  },

  //customers
  {
    path: "/admin/modules/customers",
    name: "Dashboard",
    component: AdminModuleCustomers,
  },
  {
    path: "/admin/modules/customers/add",
    name: "Dashboard",
    component: CustomerDetailsPage,
  },
  {
    path: "/admin/modules/customers/detail/:id",
    name: "Dashboard",
    component: CustomerDetailsPage,
  },
  //projects
  {
    path: "/admin/modules/projects",
    name: "admin_modules_projects",
    component: AdminModuleProjects,
  },
  {
    path: "/admin/modules/projects/add",
    name: "admin_modules_projects",
    component: ProjectsDetailsPage,
  },
  {
    path: "/admin/modules/projects/detail/:id",
    name: "admin_modules_projects",
    component: ProjectsDetailsPage,
  },
];
