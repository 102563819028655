import * as React from "react";
import Lottie from "lottie-react";
import NoAccess from "./noaccess.json";
import './index.scss'
interface INoAccessPageProps {}

const NoAccessPage: React.FunctionComponent<INoAccessPageProps> = (props) => {
	return (
        <>
		<div className="centered">
			<div style={{ width: "20%", height: "20%" }}>
				<Lottie animationData={NoAccess} loop={true} />
			</div>
		</div>
        <p className="desc">No Access, Please Contact Admin</p>
        </>
	);
};

export default NoAccessPage;
