import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import InfoSection from "@pages/Client/Dashboard/components/info";
import React, { useEffect, useState } from "react";
import Summarysection from "./components/summarysection";
import AdminDashboardReportSection from "./components/reportsection";
import { QueryRequestHelper } from "@common/query-request-helper";
import { convertDate } from "@common/date-helpers";
import { getValue } from "@utils/lodash";
import {
  dashboardDepartmentSummary,
  dashboardExpenseGraph,
  dashboardExpenseSummary,
  dashboardExpenseTypeSummary,
  dashboardProjectSummary,
  dashboardTripSummary,
} from "@services/dashboard.service";
import { AbbreviateNumber } from "@common/number-helper";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";
import { toast } from "react-toastify";
import GearLoader from "@components/common/GearLoader";
import NoAccessPage from "@components/common/NoAccess";
import Loader from "@components/common/Loader";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  sortJSONObjectAmount,
  sortJSONObjectArray,
} from "@common/text-helpers";

function AdminDashboard(props: any) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const [isLoading, setIsLoading] = React.useState(true);
  const [info, setInfo] = React.useState<[]>([]);
  const [tripInfo, setTripInfo] = useState({});
  const [expenseReportInfo, setExpenseReportInfo] = useState({});
  const [expenseCategoryInfo, setExpenseCategoryInfo] = useState({});
  const [expenseDepartmentInfo, setExpenseDepartmentInfo] = useState({});
  const [expenseProjectInfo, setExpenseProjectInfo] = useState({});

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (Object.keys(urlParams).length === 0 && props.permissionAPITriggered) {
      const currentMonth = moment().startOf("month");
      const firstDay: any = currentMonth.format("YYYY-MM-DD");
      const lastDay: any = currentMonth.endOf("month").format("YYYY-MM-DD");
      setFirstDay(firstDay);
      setLastDay(lastDay);
      getData();
    }
  }, [props.permissionAPITriggered]);

  useEffect(() => {
    const currentMonth = moment().startOf("month");
    const firstDay: any = currentMonth.format("YYYY-MM-DD");
    const lastDay: any = currentMonth.endOf("month").format("YYYY-MM-DD");
    let from = urlParams.from_date
      ? new Date(urlParams.from_date)
      : new Date(firstDay);
    let to = urlParams.to_date
      ? new Date(urlParams.to_date)
      : new Date(lastDay);
    setFirstDay(from);
    setLastDay(to);
    getData();
  }, [window.location.href, props.permissionAPITriggered]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const monthLabels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getData = async () => {
    const currentMonth = moment().startOf("month");
    const firstDay = currentMonth.format("YYYY-MM-DD");
    const lastDay = currentMonth.endOf("month").format("YYYY-MM-DD");

    let from = urlParams.from_date ? new Date(urlParams.from_date) : firstDay;
    let to = urlParams.to_date ? new Date(urlParams.to_date) : lastDay;
    try {
      let payload = {
        from_date: convertDate(from),
        to_date: convertDate(to),
      };
      let queryRequest = QueryRequestHelper(payload);
      setIsLoading(true);
      let graphResponse = await dashboardExpenseGraph(queryRequest);
      let expenseTypeReponse = await dashboardExpenseTypeSummary(queryRequest);
      let expenseResponse = await dashboardExpenseSummary(queryRequest);
      let tripReponse = await dashboardTripSummary(queryRequest);
      let departmentReponse = await dashboardDepartmentSummary(queryRequest);

      let projectResponse = await dashboardProjectSummary(queryRequest);

      if (expenseTypeReponse) {
        setExpenseCategoryInfo(
          sortJSONObjectAmount(
            getValue(expenseTypeReponse, `data`, []),
            "amount"
          )
        );
      }

      if (tripReponse) {
        setTripInfo(getValue(tripReponse, `data`, {}));
      }
      if (expenseResponse) {
        setExpenseReportInfo(getValue(expenseResponse, `data`, {}));
      }
      if (departmentReponse) {
        setExpenseDepartmentInfo(
          sortJSONObjectAmount(
            getValue(departmentReponse, `data`, {}),
            "amount"
          )
        );
      }
      if (projectResponse) {
        setExpenseProjectInfo(
          sortJSONObjectAmount(getValue(projectResponse, `data`, {}), "amount")
        );
      }

      if (graphResponse) {
        setInfo(getValue(graphResponse, `data`, {}));
        const summaryGraph = getValue(graphResponse, "data", {});
        const summaryArray = Object.entries(summaryGraph).map(
          ([monthIndex, { total_amount, base_currency_code }]: any) => ({
            label: monthLabels[monthIndex],
            base_currency_code,
            total_amount,
          })
        );
        if (getValue(summaryArray, `length`, 0) > 0) {
          let label: any = summaryArray.map((item: object) =>
            getValue(item, `label`, "")
          );
          let info: any = summaryArray.map((item: any) =>
            getValue(item, `total_amount`, "")
          );
          setData((prevData: any) => ({
            ...prevData,
            labels: label,
            datasets: [
              {
                ...prevData.datasets[0],
                data: info,
                backgroundColor: "#005BA6",
                fill: false,
                datalabels: {
                  anchor: "end",
                  align: "top",
                  formatter: (value: any, context: any) =>
                    AbbreviateNumber(value),
                },
              },
            ],
          }));
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const [data, setData] = useState<any>({
    labels: [],
    datasets: [
      {
        label: "Expense Spend",
        data: [],
        borderColor: "#005BA6",
        backgroundColor: "#005BA6",
      },
    ],
  });
  /* -------------------------------------------------------------------------- */
  /*                              Date Routes                                   */
  /* -------------------------------------------------------------------------- */
  const [from_date, setFirstDay] = React.useState(new Date());
  const [to_date, setLastDay] = React.useState(new Date());

  const handleDate = (date: any) => {
    setFirstDay(date[0]);
    setLastDay(date[1]);
  };
  const handleSubmitDate = () => {
    let first = moment(from_date).format("YYYY-MM-DD");
    let last = moment(to_date).format("YYYY-MM-DD");
    if (first && last && first !== "Invalid date" && last !== "Invalid date") {
      let payload: any = {
        from_date: first,
        to_date: last,
      };
      if (payload.from_date && payload.to_date) {
        let queryRequest = QueryRequestHelper(payload);
        navigate(`${window.location.pathname}?${queryRequest}`);
      } else {
        toast.error("Start Date and End Date Required");
      }
    } else {
      toast.error("Please select date");
    }
  };
  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      {!isLoading &&
      !getValue(props, `subTabPermissionList`, []).includes(
        "admin_dashboard"
      ) ? (
        <NoAccessPage />
      ) : (
        <>
          <div className="dashboard-wrapper__header d-flex align-items-center justify-content-between">
            <h4 className="dashbaord-wrapper__header-title">Dashboard</h4>
            <div>
              <h3 className="dashboard-compliance__date dashboard-compliance__date--compliance overview-wrapper__header-date d-flex align-items-center">
                <div className="d-flex">
                  <DatePicker
                    selectsRange={true}
                    startDate={from_date}
                    endDate={to_date}
                    className="react-date-picker__inputGroup__input_dashboard"
                    onChange={(update: any) => {
                      handleDate(update);
                    }}
                    dateFormat="d MMM yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                  <button
                    className="dashboard-wrapper__apply-btn"
                    onClick={handleSubmitDate}
                  >
                    Apply
                  </button>
                </div>
              </h3>
            </div>
          </div>

          {/* <Skeleton /> */}
          {/* <Skeleton count={5} />  */}

          <div className="dashbaord-wrapper_section_container w-100">
            {isLoading || !props.permissionAPITriggered ? (
              <GearLoader />
            ) : (
              <div className="">
                <InfoSection />
                <Summarysection data={data} info={info} tripInfo={tripInfo} />
                <AdminDashboardReportSection
                  tripInfo={tripInfo}
                  info={info}
                  expenseCategoryInfo={expenseCategoryInfo}
                  expenseResponse={expenseReportInfo}
                  departmentResponse={expenseDepartmentInfo}
                  projectResponse={expenseProjectInfo}
                />
              </div>
            )}
          </div>
        </>
      )}
    </DashboardLayout>
  );
}

export default AdminDashboard;
