import EditSvgComponent from "@assets/svg-components/edit";
import { getColorStyle } from "@common/color";
import {
  convertCurrentDate,
  convertCurrentDateWithTime,
} from "@common/date-helpers";
import { formatText } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

function ExpenseActiveCategoryTable(props: any) {
  const { list } = props;
  return (
    <div>
      <table className="datatable_table">
        <thead className="datatable_table_thead">
          <th className="datatable_table_th" style={{ width: "70px" }}>
            <label className="m-0 position-relative" htmlFor="header-checkbox">
              <input
                type="checkbox"
                className="default-checkbox"
                id="header-checkbox"
                onChange={() =>
                  props.handleCheckAll(
                    getValue(props, `list.length`, 0) ===
                      getValue(props, `selectedIDs.length`, 0)
                      ? "All"
                      : "None"
                  )
                }
                checked={
                  getValue(props, `list.length`, 0) ===
                  getValue(props, `selectedIDs.length`, 0)
                }
              />
              <div className="custom-checkbox">
                <img
                  src="/images/uploaded-details/tick.svg"
                  className="img-fluid uploaded-tick"
                />
              </div>
            </label>
          </th>
          <th className="datatable_table_th">
            <h6 className="">Category Name</h6>
          </th>
          <th className="datatable_table_th">
            <h6 className="">Category Code</h6>
          </th>
          <th className="datatable_table_th">
            <h6 className="">Description</h6>
          </th>
          <th className="datatable_table_th">
            <h6 className="">Status</h6>
          </th>
          <th className="datatable_table_th">
            <h6 className="">Added on</h6>
          </th>
          <th className="datatable_table_th">
            <h6 className="">Action</h6>
          </th>
        </thead>
        <tbody className="datatable_table_tbody">
          {getValue(list, `length`, 0) > 0 &&
            list.map((bodyObj: object, index: number) => {
              return (
                <tr key={index} className="datatable_table_tr">
                  <td className="datatable_table_td" style={{ width: "70px" }}>
                    <label
                      className="m-0 position-relative"
                      htmlFor={`header-checkbox-${index}`}
                    >
                      <input
                        type="checkbox"
                        className="default-checkbox"
                        id={`header-checkbox-${index}`}
                        onChange={() =>
                          props.handleCheckUsers(getValue(bodyObj, `id`, ""))
                        }
                        checked={getValue(props, `selectedIDs`, []).includes(
                          getValue(bodyObj, `id`, "")
                        )}
                      />
                      <div className="custom-checkbox">
                        <img
                          src="/images/uploaded-details/tick.svg"
                          className="img-fluid uploaded-tick"
                        />
                      </div>
                    </label>
                  </td>
                  <td className="datatable_table_td">
                    <h6 className=" color_primary">
                      {formatText(getValue(bodyObj, `category_name`, ""))}
                    </h6>
                  </td>
                  <td className="datatable_table_td">
                    <h6 className="">
                      {formatText(getValue(bodyObj, `category_code`, ""))}
                    </h6>
                  </td>
                  <td className="datatable_table_td">
                    <h6 className="">
                      {formatText(getValue(bodyObj, `description`, ""))}
                    </h6>
                  </td>
                  <td className="datatable_table_td">
                    <h6
                      className=""
                      style={getColorStyle(
                        getValue(bodyObj, `is_active`, false)
                          ? "Active"
                          : "Inactive"
                      )}
                    >
                      {getValue(bodyObj, `is_active`, "")
                        ? "Active"
                        : "Inactive"}
                    </h6>
                  </td>
                  <td className="datatable_table_td">
                    <h6 className="">
                      {getValue(bodyObj, `created_at`, null)
                        ? convertCurrentDateWithTime(
                            getValue(bodyObj, `created_at`, null)
                          )
                        : null}
                    </h6>
                  </td>

                  <td className="datatable_table_td action-icon-td">
                    {/* <div
                      onClick={() =>
                        props.handleEditEntity(getValue(bodyObj, `id`, ""))
                      }
                    >
                      <EditSvgComponent />
                    </div> */}
                    {getValue(props, `permissions`, []).includes("update") && (
                      <UncontrolledDropdown
                        className="table-action-icon-wrap"
                        direction="end"
                        container={"body"}
                      >
                        <DropdownToggle>
                          <div className="switch_header__dropdown m-0 align-items-center">
                            <div className="close-image_container action-icon-wrap">
                              <img
                                src={"/images/more.svg"}
                                className=" close_image"
                              />
                            </div>
                          </div>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() =>
                              props.handleEditEntity(
                                getValue(bodyObj, `id`, "")
                              )
                            }
                          >
                            <div className={`more-dropdown-main-item `}>
                              <p>Edit</p>
                            </div>
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default ExpenseActiveCategoryTable;
