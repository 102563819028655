import { useEffect, useMemo, useRef, useState } from "react";
import "./index.scss";
import { getValue } from "@utils/lodash";
import CloseSmallSvgComponent from "@assets/svg-components/close-small";
import { camelToSnake } from "@common/text-helpers";
import { getLookupAPIs } from "../lookup-apis";
import RemoveSvgComponent from "@assets/svg-components/remove";

const MultiSelectDropdown = (props) => {
  const { label, id, value, onChange, values, data, setData } = props;

  const inputRef = useRef(null);
  const ref = useRef();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [inputChange, setInputChange] = useState(false);
  const colorCodes = [
    "#82E0AA",
    "#fea7a7",
    "#fdd399",
    "#fff1a3",
    "#a8e8ad",
    "#b1dbfd",
    "#d4c9fd",
    "#beeae8",
    "#e9bfed",
    "#bbd2ef",
    "#d0d9e2",
  ];
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    setQuery("");
  }, [props.resetInput]);

  useEffect(() => {
    if (query) {
      setInputChange(true);
    }
  }, [query]);
  /* -------------------------------------------------------------------------- */
  /*                      Hadleclick Outside Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (getValue(props, `lookup_api`, "")) {
      fetchDefaultData();
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  const [isLoading, setIsLoading] = useState(true);
  const [info, setinfo] = useState([]);

  const fetchDefaultData = async () => {
    try {
      setIsLoading(true);
      let resp = await getLookupAPIs(
        getValue(props, `lookup_api`, ""),
        // getValue(props, `lookup_id`, ""),
        "",
        ""
      );
      if (resp) {
        setinfo(resp);
        setIsLoading(false);
      } else {
        setinfo([]);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handleFetchData = async (search_text) => {
    // if (search_text) {
    //   try {
    //     setIsLoading(true);
    //     let resp = await getLookupAPIs(
    //       getValue(props, `lookup_api`, ""),
    //       getValue(props, `lookup_id`, ""),
          
    //       search_text
    //     );
    //     if (resp) {
    //       setinfo(resp);
    //       setIsLoading(false);
    //     } else {
    //       setinfo([]);
    //       setIsLoading(false);
    //     }
    //   } catch (error) {
    //     setIsLoading(false);
    //   }
    // } else {
    //   fetchDefaultData();
    //   onChange(null);
    // }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  const toggleBox = (e) => {
    setIsOpen(!isOpen);
  };
  const handleClickOption = (option) => {
    onChange && onChange(option);
    setInputChange(false);
    setQuery("");
  };
  const handleRemoveOption = (option) => {
    onChange(option);
  };
  const handleClose = (e) => {
    e.stopPropagation();
    onChange(null);
  };
  const filter = (options) => {
    return getValue(options, `length`, 0) > 0
      ? options.filter(
          (option) =>
            getValue(option, `[${label}]`, "") &&
            option[label].toLowerCase().indexOf(query.toLowerCase()) > -1
        )
      : [];
  };
  const findSelectedPipeline = (id) => {
    let list =
      getValue(data, `length`, 0) > 0
        ? data.filter((item) => getValue(item, `id`, "") === id)
        : [];
    return getValue(list, `length`, 0) > 0
      ? getValue(list, `[${0}].label`, "")
      : "";
  };
  const valueLabel = useMemo(() => findSelectedPipeline(value), [
    value,
    getValue(info, `length`, 0) > 0,
  ]);
  const valuesSet = getValue(props, `value`, []) && new Set(getValue(props, `value`, []));
  const selectedData = useMemo(() => {
    return (
      getValue(info, `length`, 0) > 0 &&
      info.filter((item) => valuesSet.has(getValue(item, "id", "")))
    );
  }, [info, valuesSet, values]);

  return (
    <>
      <div className={"tag-dropdownselect-form"} ref={ref}>
        {getValue(props, `showLabel`, false) && (
          <label
            className={
              getValue(props, `mandatory`, false)
                ? "form-label-error"
                : "form-label"
            }
          >
            {getValue(props, `name`, "")}
            <span className="ms-1">
              {getValue(props, `mandatory`, false) ? "*" : ""}
            </span>
          </label>
        )}
        <div
          className={`tag-multidropdown-box position-relative ${
            isOpen ? "active" : ""
          }`}
          onClick={toggleBox}
          style={{ width: props.width }} /* add custom width here */
        >
          <div className="tag-search_container">
            {getValue(selectedData, `length`, 0) > 0 &&
              selectedData.map((item, index) => {
                if (item)
                  return (
                    <div
                      className="d-flex align-items-center tag_container"
                      key={index}
                      style={{ background: getValue(item, `color_code`, "") }}
                    >
                      <p className="tag-text">{getValue(item, `label`, "")}</p>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveOption(item);
                        }}
                        className="tag-item-close"
                      >
                        <RemoveSvgComponent size={17} color={"red"} />
                      </div>
                    </div>
                  );
              })}
            <input
              className="tag-search_input"
              ref={inputRef}
              type="text"
              value={
                inputChange
                  ? query
                    ? query
                    : valueLabel
                  : valueLabel
                  ? valueLabel
                  : query
              }
              name="searchTerm"
              autoComplete="off"
              placeholder={getValue(
                props,
                `placeholder`,
                "Please Add/Search here..."
              )}
              onChange={(e) => {
                handleFetchData(getValue(e, `target.value`, ""));
                !e.target.value && onChange(null);
                setQuery(e.target.value);
                e.target.value.length === 1 && setIsOpen(true);
              }}
              // onKeyDown={handleKeyDown}
              // onClick={toggleBox}
            />
          </div>
          <div className="right_container">
            {valueLabel && (
              <div className="close_image_container">
                <img
                  src={"/dropdown/close.svg"}
                  className="close_image"
                  onClick={(e) => handleClose(e)}
                />
              </div>
            )}
          </div>
        </div>
        <div
          className={`tag-options ${isOpen ? "open" : ""}`}
          style={{ width: props.width }} /* add custom width here */
        >
          <div>
            <div className="tag-option_container">
              {filter(info).length > 0 ? (
                filter(info).map((option, index) => {
                  if (!value.includes(getValue(option, `id`, ""))) {
                    return (
                      <div
                        onClick={() => {
                          handleClickOption(option);
                        }}
                        className={`tag_option ${
                          option.id === value ? "selected" : ""
                        }`}
                        key={`${id}-${index}`}
                      >
                        <div
                          className="selected_tag_text px-3 p-1"
                          style={{
                            background: getValue(option, `color_code`, ""),
                          }}
                        >
                          {option[label]}
                        </div>
                      </div>
                    );
                  }
                })
              ) : (
                <div>
                  <div className="nodata">No Data Found</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MultiSelectDropdown;
