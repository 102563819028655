import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const createPerDiem = (payload: object) =>
  post(`${config.API_URL}/per-diem`, payload);

export const getPerDiemByPolicy = (id: any) =>
  get(`${config.API_URL}/per-diem/policy/${id}`);

export const updatePerDiem = (id: string, payload: object) =>
  patch(`${config.API_URL}/per-diem/${id}`, payload);

//Duration
export const createPerDiemDuration = (id: string, payload: object) =>
  post(`${config.API_URL}/per-diem/${id}/per-diem-duration`, payload);

export const getAllPerDiemDuration = (id: string, queryRequest: string) =>
  get(`${config.API_URL}/per-diem/per-diem-duration/${id}?${queryRequest}`);

export const updatePerDiemDuration = (duration_id: string, payload: object) =>
  patch(`${config.API_URL}/per-diem/per-diem-duration/${duration_id}`, payload);

export const deletePerDiemDuration = (duration_id: string) =>
  Delete(`${config.API_URL}/per-diem/per-diem-duration/${duration_id}`);

//Location
export const createPerDiemRates = (id: string, payload: object) =>
  post(`${config.API_URL}/per-diem/${id}/per-diem-rates`, payload);

export const updatePerDiemRates = (rate_id: string, payload: object) =>
  patch(`${config.API_URL}/per-diem/per-diem-rates/${rate_id}`, payload);

export const deletePerDiemRates = (rate_id: string) =>
  Delete(`${config.API_URL}/per-diem/per-diem-rates/${rate_id}`);

//Expense Type Per Diem
export const getAllPerDiemLocation = (queryRequest: string) =>
  get(`${config.API_URL}/per-diem/name?${queryRequest}`);

export const getExpensePerDiemRatesList = (queryRequest: string) =>
  get(`${config.API_URL}/per-diem/expense/per-diem-rates?${queryRequest}`);

export const getSingleExpensePerDiemRatesList = (queryRequest: string) =>
  get(`${config.API_URL}/per-diem/expense/one-per-diem-rates?${queryRequest}`);
