import React from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import AddSvgComponent from "@assets/svg-components/add";

export default function ApprovalReportsNotfound() {
  return (
    <>
      <div className="not-found-wrapper d-flex w-100 align-items-center justify-content-center new-not-found-wrapper">
        <div className="not-found-wrapper__details text-center">
          <img
            src="/images/notfound/approval-reports-not-found.svg"
            className="not-found-image img-fluid trips-image"
          />
          <p className="header_text__19 text-center not-found-text">
            No Expense Report Approval Requests Found. <br /> You’ll receive an email notification once there are requests awaiting your approval.
          </p>
        </div>
      </div>
    </>
  );
}
