import { downloadURL } from "@services/common.service";
import { getValue } from "@utils/lodash";
import { saveAs } from "file-saver";
import { getEncodedKey } from "./text-helpers";
export const handleDownloadOnClick = async (url: string, name: string) => {
  // var link = document.createElement("a");
  // link.target = "_blank";
  // link.download = "Contact";
  // link.name ='sk'
  // link.href ='https://assets.surveydemolink.in/public/client1/reports/360-feedback/0eed191d-f24b-4578-8cb1-c7c3969cef52-Feedback-Report.pdf'
  // https://user-import.s3.ap-south-1.amazonaws.com/documents/7db528adb2067346bdb86e24c2b4fb41231fa9a1.pdf
  // link.click();
  // saveAs(url, name);
  let resp = await downloadURL(getEncodedKey(url));
  if (resp) {
    let url = getValue(resp, `data.url`, "")
      ? getValue(resp, `data.url`, "").includes("https://")
        ? getValue(resp, `data.url`, "")
        : `https://${getValue(resp, `data.url`, "")}`
      : getValue(resp, `data.url`, "");
    saveAs(url, name);
  }
};
