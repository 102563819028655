import { getValue } from "@utils/lodash";
import React from "react";

function TripsTitleHeader(props: any) {
  return (
    <div>
      <div className="trips_details_main_container_header">
        <h6 className="header_text__32">
          {getValue(props, `formInfo.trip_name`, "")}
        </h6>
        {getValue(props, `formInfo.approval_config.id`, "") && (
          <p className="small_text__16 trips_details_main_container_sub-header">
            Approval ID: {getValue(props, `formInfo.approval_config.id`, "")}
          </p>
        )}
      </div>
    </div>
  );
}

export default TripsTitleHeader;
