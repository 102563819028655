import { getValue } from "@utils/lodash";
import { toast } from "react-toastify";
import { removeNullValues } from "./query-request-helper";
import { config } from "../env";

export const formatText = (str: string) => {
  if (str) {
    const arr = str && str.split("_");
    const result = [];
    for (const word of arr) {
      result.push(
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // 👈️ lowercase remainder
      );
    }
    return result.join(" ");
  }
};
export const formatBoolean = (str: any) => {
  switch (str) {
    case "true":
      return true;
    case "false":
      return false;
    default:
      return str;
  }
};

export const formatSmallString = (str: string) => {
  let length = str ? str.length : 0;
  return length > 30 ? str.substring(0, 30).concat("...") : str;
};

export const convertStringToNumber = (str: string) => {
  return str ? parseInt(str) : str;
};

export const getFirstLetter = (str: string) => {
  return str ? str.charAt(0).toUpperCase() : str;
};

export const getFirstLetterOfEachString = (str: string) => {
  let acronym = str
    ? str
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), "")
    : "";
  return acronym.toUpperCase();
};
export const CopyToClipboard = (value: string) => {
  /* Get the text field */
  //   var copyText = event.currentTarget.firstElementChild.nextElementSibling.value;
  /* Copy the text inside the text field */
  navigator.clipboard.writeText(value);
  /* Alert the copied text */
  // alert("Copied: " + value);
  toast.success(`${value}${" "} copied to clipboard`);
};

export const formatDecimal = (value: any) => {
  if (value) {
    return value ? parseFloat(value).toFixed(2) : value;
  } else {
    return value;
  }
};

export const textCapitalize = (str: string) => {
  const arr = str.split("_");
  const result = [];
  for (const word of arr) {
    result.push(
      word.toUpperCase() // 👈️ lowercase remainder
    );
  }
  return result.join(" ");
};

export const concatDotToString = (value: any) => {
  if (value) {
    return getValue(value, `length`, 0) > 0
      ? value.substring(0, 100).concat("...")
      : value;
  } else {
    return value;
  }
};

export const concatDotToDocumentString = (value: any) => {
  if (value) {
    return getValue(value, `length`, 0) > 20
      ? value.substring(0, 20).concat("...")
      : value;
  } else {
    return value;
  }
};

export function json2array(json: any) {
  var result: any = [];
  var keys = Object.keys(json);
  keys.forEach(function (key) {
    result.push({
      [key]: json[key],
    });
  });
  return result;
}

export const concatString = (str: any, length: number) => {
  return str
    ? str.length > length
      ? str.substring(0, length).concat("...")
      : str
    : null;
};

export const numberFormatter = (num: any) => {
  return num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const capitalizeFirstLetter = (str: string) => {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
};

export const removeNullOrUndefinedProperties = (obj: any) => {
  // return removeNullValues(obj);
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (
        (obj[key] === null ||
          obj[key] === "" ||
          obj[key] === undefined ||
          (Array.isArray(obj[key]) && obj[key].length === 0)) &&
        typeof obj[key] !== "boolean"
      ) {
        delete obj[key];
      }
    }
  }
  return obj;
  // for (let key in obj) {
  //   if (obj.hasOwnProperty(key)) {
  //     if (
  //       obj[key] === null ||
  //       obj[key] === "" ||
  //       obj[key] === undefined ||
  //       (Array.isArray(obj[key]) && obj[key].length === 0)
  //     ) {
  //       delete obj[key];
  //     }
  //   }
  // }
  // return obj;
};
export const removeNullOrUndefinedPropertiesKeepArray = (obj: any) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (
        (obj[key] === null || obj[key] === "" || obj[key] === undefined) &&
        typeof obj[key] !== "boolean"
      ) {
        delete obj[key];
      }
    }
  }
  return obj;
};
export function removeNullValuesFromObjectArray(arr: any[]) {
  return arr.map((item: any) => {
    const filteredObj: any = {};
    for (const key in item) {
      if (item.hasOwnProperty(key)) {
        const value = item[key];
        if (value !== null && value !== undefined) {
          if (typeof value !== "boolean") {
            filteredObj[key] = !isNaN(value) ? Number(value) : value;
          } else {
            filteredObj[key] = value;
          }
        }
      }
    }
    return filteredObj;
  });
}
export function removeEmptyProperties(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map((item) => removeEmptyProperties(item));
  } else if (typeof obj === "object" && obj !== null) {
    const cleanedObj: any = {};

    for (const key in obj) {
      const value = removeEmptyProperties(obj[key]);
      if (
        value !== null &&
        value !== undefined &&
        value !== 0 &&
        value !== "" &&
        (!Array.isArray(value) || value.length > 0) && // Check for non-empty arrays
        (typeof value !== "object" || Object.keys(value).length > 0) // Check for non-empty objects
      ) {
        cleanedObj[key] = value;
      }
    }
    return cleanedObj;
  } else {
    return obj;
  }
}

export function removeEmptyPropertiesWithZero(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map((item) => removeEmptyProperties(item));
  } else if (typeof obj === "object" && obj !== null) {
    const cleanedObj: any = {};

    for (const key in obj) {
      const value = removeEmptyProperties(obj[key]);
      if (
        value !== null &&
        value !== undefined &&
        value !== "" &&
        (!Array.isArray(value) || value.length > 0) && // Check for non-empty arrays
        (typeof value !== "object" || Object.keys(value).length > 0) // Check for non-empty objects
      ) {
        cleanedObj[key] = value;
      }
    }
    return cleanedObj;
  } else {
    return obj;
  }
}

export const formatAmount = (amount: any) => {
  if (typeof amount === "number") {
    return amount.toFixed(2);
  } else if (typeof amount === "string") {
    const parsedAmount = parseFloat(amount);
    if (!isNaN(parsedAmount)) {
      return parsedAmount.toFixed(2);
    } else {
      return amount;
    }
  } else {
    return amount;
  }
};

export const findValueFromOptions = (options: any, name: string) => {
  let filters =
    getValue(options, `length`, 0) > 0
      ? options.filter((item: any) => getValue(item, `value`, "") === name)
      : [];
  if (getValue(filters, `length`, 0) > 0) {
    return getValue(filters, `[${0}].label`, "");
  }
};
export const filteredData = (data: any) => {
  const filteredItems: any[] = []; // Initialize an array to hold the filtered items

  data.map((item: any) => {
    const filteredItem: any = {};
    for (const [key, value] of Object.entries(item)) {
      if (value !== null) {
        if (typeof value === "object" && !Array.isArray(value)) {
          const nonEmptySubObject: any = {};
          for (const [subKey, subValue] of Object.entries(value)) {
            if (subValue !== null && subValue !== "") {
              nonEmptySubObject[subKey] = subValue;
            }
          }
          if (Object.keys(nonEmptySubObject).length > 0) {
            filteredItem[key] = nonEmptySubObject;
          }
        } else if (value !== "") {
          filteredItem[key] = value;
        }
      }
    }
    filteredItems.push(filteredItem); // Add the filtered item to the array
  });

  return filteredItems; // Return the array of filtered items
};

export const convertElementaryArrayToJSONArray = (arr: any) => {
  if (getValue(arr, `length`, 0) > 0) {
    return arr.map((item: string) => ({
      value: item,
      label: formatText(item),
    }));
  } else {
    return [];
  }
};

export const sortJSONObjectArray = (arr: any, key: string) => {
  if (getValue(arr, `length`, 0) > 0) {
    return arr.sort((a: any, b: any) => a[key] - b[key]);
  } else {
    return [];
  }
};

export const sortJSONObjectAmount = (arr: any, key: string) => {
  if (getValue(arr, `length`, 0) > 0) {
    return arr.sort((a: any, b: any) => b[key] - a[key]);
  } else {
    return [];
  }
};

export function camelToSnake(str: string): string {
  if (str)
    str =
      str[0].toLowerCase() +
      str
        .slice(1, str.length)
        .replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
  str = str.replaceAll(" _", "_");

  return str.replaceAll(" ", "_").replace(/(^_*|_*$)/g, "");
}

export async function aesGcmEncrypt(plaintext: string) {
  const pwUtf8 = new TextEncoder().encode(config.AUTH_SHARED_KEY); // encode secretKey as UTF-8
  const pwHash = await crypto.subtle.digest("SHA-256", pwUtf8); // hash the secretKey

  const iv = crypto.getRandomValues(new Uint8Array(12)); // get 96-bit random iv
  const ivStr = Array.from(iv)
    .map((b) => String.fromCharCode(b))
    .join(""); // iv as utf-8 string

  const alg = { name: "AES-GCM", iv: iv }; // specify algorithm to use

  const key = await window.crypto.subtle.importKey("raw", pwHash, alg, false, [
    "encrypt",
  ]); // generate key from pw

  const ptUint8 = new TextEncoder().encode(plaintext); // encode plaintext as UTF-8
  const ctBuffer = await window.crypto.subtle.encrypt(alg, key, ptUint8); // encrypt plaintext using key

  const ctArray = Array.from(new Uint8Array(ctBuffer)); // ciphertext as byte array
  const ctStr = ctArray.map((byte) => String.fromCharCode(byte)).join(""); // ciphertext as string
  return btoa(ivStr + ctStr); // iv+ciphertext base64-encoded
}

export const getEncodedKey = (url: string) => {
  return btoa(decodeURIComponent(new URL(url).pathname.replace("/", "")));
};
