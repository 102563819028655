import { useEffect, useRef, useState } from "react";
import "./search.scss";
import ReactSelect, { components } from "react-select";

const Control = ({ children, ...props }: any) => (
  <components.Control {...props}>
    <div className="d-flex align-items-center w-100 justify-content-between">
      <div className="d-flex align-items-center">
        <img
          src="/images/add-location.svg"
          alt="Image"
          className="pindrop-icon"
        />
        {children}
      </div>
      <div>
        {props.getValue().length > 0 && (
          <components.ClearIndicator
            {...props}
            innerProps={{ onClick: () => props.clearValue() }}
          />
        )}
      </div>
    </div>
  </components.Control>
);

const Option = (props: any) => (
  <components.Option {...props}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
        src="/images/dashboard/trips/online/pin-drop.png"
        alt="Option Image"
        style={{ marginRight: "8px", width: "20px", height: "20px" }}
      />
      {props.label}
    </div>
  </components.Option>
);

const CustomStyle = {
  container: (base: any) => ({
    ...base,
    width: "400px",
    height: "52px",
    background: "#fff",
    display: "flex",
    alignItems: "center",
    paddingLeft: "-10px",
    boxShadow: "4px 4px 10px #00000029",
    marginBottom: "0",
    borderRadius: "4px 4px 0 0",
  }),
  control: (base: any, state: any) => ({
    ...base,
    background: state.isSelected ? "#DEEBFF" : "#fff",
    color: "#5F6368",
    fontFamily: "Deeptravel-font, sans-serif",
    fontWeight: "500",
    fontSize: "14px",
    cursor: "pointer",
    width: "100%",
    border: "none",
    outline: "none",
    zIndex: "9",
    borderBottom: "1px solid #00000029",
    borderRadius: "4px 4px 0 0",
  }),
  input: (base: any) => ({
    ...base,
    border: "none",
    padding: "10px 0",
  }),
  menu: (base: any) => ({
    ...base,
    boxShadow: "4px 4px 10px #00000029",
    fontFamily: "Deeptravel-font, sans-serif",
    color: "#5F6368",
    fontWeight: "500",
    fontSize: "14px",
    borderRadius: "0 0 4px 4px",
    marginTop: "0",
    outline: "none",
    border: "none",
  }),
  menuList: (base: any) => ({
    ...base,
    margin: "8px 0",
  }),
  option: (base: any, state: any) => ({
    ...base,
    cursor: "pointer",
    padding: "10px 12px",
    background: state.isSelected ? "#DEEBFF" : "#fff",
    color: "#5F6368",
    "&:hover": {
      backgroundColor: "#ecf3fd",
    },
  }),
};

function HotelLocation({ caption, placeholder }: any) {
  const [showPopover, setShowPopover] = useState(false);
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const options = [
    { value: "option1", label: "Mumbai" },
    { value: "option2", label: "Delhi" },
    { value: "option3", label: "Bangalore" },
    { value: "option4", label: "Hyderabad" },
    { value: "option5", label: "Ahmedabad" },
    { value: "option6", label: "Chennai" },
    { value: "option7", label: "Kolkata" },
    { value: "option8", label: "Surat" },
    { value: "option9", label: "Pune" },
    { value: "option10", label: "Jaipur" },
    { value: "option11", label: "Lucknow" },
    { value: "option12", label: "Kanpur" },
  ];

  const handleButtonClick = () => {
    setShowPopover(!showPopover);
  };
  const handleChange = (selectedOption: any) => {
    setSelectedValue(selectedOption);
    setShowPopover(false);
  };

  const handleClickOutside = (event: any) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      setShowPopover(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="position-relative">
      <button onClick={handleButtonClick} className="location-trigger">
        <img
          src="/images/dashboard/trips/online/search.png"
          className="search"
        />
        {selectedValue ? (
          selectedValue.label
        ) : (
          <div className="grayed-placeholder">{caption}</div>
        )}
      </button>
      {showPopover && (
        <div className="location-popover" ref={popoverRef}>
          <ReactSelect
            options={options}
            menuIsOpen={true}
            isClearable={isClearable}
            isSearchable={isSearchable}
            components={{
              Control,
              Option,
              DropdownIndicator: () => null,
              ClearIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            onChange={handleChange}
            value={selectedValue}
            styles={CustomStyle}
            placeholder={placeholder}
          />
        </div>
      )}
    </div>
  );
}

export default HotelLocation;
