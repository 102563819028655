import { getValue } from "@utils/lodash";
import React from "react";

function CategoryExpenseSvgComponent(props: any) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        className="icon align-sub mr-1"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"
        }
        fill={
          getValue(props, `color`, "")
            ? getValue(props, `color`, "")
            : "#3c4043"
        }
      >
        <path d="M266 157h210c11.6 0 21-9.4 21-21s-9.4-21-21-21H266c-11.6 0-21 9.4-21 21s9.4 21 21 21zM155.3 54.8c-23.1-23.1-60.6-23.1-83.6 0L32.3 94.2c-23.1 23.1-23.1 60.6 0 83.6l39.3 39.3c11.2 11.2 26 17.3 41.8 17.3s30.7-6.2 41.8-17.3l39.3-39.3c11.2-11.2 17.3-26 17.3-41.8s-6.2-30.7-17.3-41.8l-39.2-39.4zm9.6 93.3-39.3 39.3c-6.7 6.7-17.6 6.7-24.2 0L62 148.1c-6.7-6.7-6.7-17.6 0-24.2l39.3-39.3c3.3-3.3 7.7-5 12.1-5s8.8 1.7 12.1 5l39.3 39.3c6.8 6.6 6.8 17.5.1 24.2zM476 359H266c-11.6 0-21 9.4-21 21s9.4 21 21 21h210c11.6 0 21-9.4 21-21s-9.4-21-21-21zM113.5 285.5C61.4 285.5 19 327.9 19 380s42.4 94.5 94.5 94.5S208 432.1 208 380s-42.4-94.5-94.5-94.5zm0 147c-29 0-52.5-23.6-52.5-52.5s23.6-52.5 52.5-52.5S166 351 166 380s-23.6 52.5-52.5 52.5z"></path>
      </svg>
    </div>
  );
}

export default CategoryExpenseSvgComponent;
