import { getValue } from "@utils/lodash";
import React from "react";

function InfoBoxSvgComponent(props: any) {
  return (
    <div
      className="ms-1 cursor-pointer d-flex align-items-center"
      style={{
        borderRadius: "50%",
        backgroundColor: "#cccccc",
        padding: "2px",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="14px"
        viewBox="0 -960 960 960"
        width="14px"
        fill="#ffffff"
      >
        <path d="M480-680q-33 0-56.5-23.5T400-760q0-33 23.5-56.5T480-840q33 0 56.5 23.5T560-760q0 33-23.5 56.5T480-680Zm-60 560v-480h120v480H420Z" />
      </svg>
    </div>
  );
}

export default InfoBoxSvgComponent;
