import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

//Travel Documents
//client
export const getAllTravelDocuments = (queryRequest: string) =>
  get(`${config.API_URL}/travel-documents?${queryRequest}`);

export const getSpecificTravelDocument = (id: string) =>
  get(`${config.API_URL}/travel-documents/${id}`);

export const createTravelDocument = (payload: object) =>
  post(`${config.API_URL}/travel-documents`, payload);

export const updateTravelDocument = (id: string, payload: object) =>
  patch(`${config.API_URL}/travel-documents/${id}`, payload);

export const deleteTravelDocument = (id: string) =>
  Delete(`${config.API_URL}/travel-documents/${id}`);

export const updateTravelDocumentStatusBulk = (payload: object) =>
  patch(`${config.API_URL}/travel-documents/bulk/active-inactive`, payload);

export const changeTravelDocumentToMandatory = (id: string) =>
  patch(`${config.API_URL}/travel-documents/set-primary/${id}`);

export const downloadTravelDocumentExcel = (queryRequest: string) =>
  get(`${config.API_URL}/travel-documents/excel-download?${queryRequest}`);

export const downloadAdminTravelDocumentExcel = (queryRequest: string) =>
  get(
    `${config.API_URL}/travel-documents/admin/excel-download?${queryRequest}`
  );

//admin
export const getAllTravelDocumentsAdmin = (queryRequest: string) =>
  get(`${config.API_URL}/travel-documents/admin?${queryRequest}`);

export const getSpecificTravelDocumentAdmin = (id: string) =>
  get(`${config.API_URL}/travel-documents/admin${id}`);

export const createTravelDocumentAdmin = (payload: object) =>
  post(`${config.API_URL}/travel-documents/admin`, payload);

export const updateTravelDocumentAdmin = (id: string, payload: object) =>
  patch(`${config.API_URL}/travel-documents/admin/${id}`, payload);

export const deleteTravelDocumentAdmin = (id: string) =>
  Delete(`${config.API_URL}/travel-documents/${id}`);

// export const updateTravelDocumentStatusBulk = (payload: object) =>
//   patch(`${config.API_URL}/travel-documents/bulk/active-inactive`, payload);

// export const changeTravelDocumentToMandatory = (id: string) =>
//   patch(`${config.API_URL}/travel-documents/set-primary/${id}`);

//approval - config

export const getApprovalConfigConditionFields = (queryRequest: string) =>
  get(`${config.API_URL}/approval-config/condition-fields?${queryRequest}`);

export const getAllApprovalConfigList = (queryRequest: string) =>
  get(`${config.API_URL}/approval-config?${queryRequest}`);

export const getSpecificApprovalConfig = (id: string) =>
  get(`${config.API_URL}/approval-config/${id}`);

export const createApprovalConfig = (payload: object) =>
  post(`${config.API_URL}/approval-config`, payload);

export const updateApprovalConfigStatusBulk = (payload: object) =>
  patch(`${config.API_URL}/approval-config/bulk/active-inactive`, payload);

export const updateApprovalConfigPriority = (payload: object) =>
  patch(`${config.API_URL}/approval-config/change-priority-order`, payload);

export const createApprovalConfigApproverList = (payload: object) =>
  post(`${config.API_URL}/approval-config/approver-list`, payload);

//Traveller
export const getAllTraveler = (queryRequest: string) =>
  get(`${config.API_URL}/traveler?${queryRequest}`);

export const getSpecificTravelerUser = (id: string) =>
  get(`${config.API_URL}/traveler/user/${id}`);

export const createTraveler = (payload: object) =>
  post(`${config.API_URL}/traveler`, payload);

export const updateTraveler = (id: string, payload: object) =>
  patch(`${config.API_URL}/traveler/${id}`, payload);

export const getAllTravelDocumentsUser = (queryRequest: string) =>
  get(`${config.API_URL}/travel-documents/user?${queryRequest}`);
