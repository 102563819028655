import HeaderLayout from "@layouts/withoutNaviagtion/withoutNavigationLayout";
import React from "react";
import HotelSearch from "../components/OnlineBooking/HotelSearch";
import PropertyFilter from "../components/PropertyFilter/PropertyFilter";
import "../online/trip-online.scss";
import { Link } from "react-router-dom";

const filteredProperty = [
  {
    property_name: "1",
    image: "/images/dashboard/trips/online/property-1.png",
  },
  {
    property_name: "2",
    image: "/images/dashboard/trips/online/property-2.png",
  },
  {
    property_name: "3",
    image: "/images/dashboard/trips/online/property-3.png",
  },
  {
    property_name: "4",
    image: "/images/dashboard/trips/online/property-4.png",
  },
  {
    property_name: "5",
    image: "/images/dashboard/trips/online/property-5.png",
  },
  {
    property_name: "6",
    image: "/images/dashboard/trips/online/property-6.png",
  },
];

const FilteredProperties = () => {
  return (
    <HeaderLayout>
      <div className="d-flex justify-content-center position-relative online-booking-wrapper py-4">
        <HotelSearch value="New Delhi" />
      </div>
      <div className="container-lg d-flex">
        <PropertyFilter />
        <div className="p-5 w-100">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h3 className="dashbaord-wrapper__header-title">
                New Delhi: 1,987 properties found
              </h3>
              <p className="gray-color">
                Total price excludes taxes + fees . Other fees may apply.
              </p>
            </div>
            <div className="calender-wrapper">
              <img src="/images/dashboard/trips/online/filter.svg" />
              <span>Popularity</span>
              <img
                src="/images/dashboard/trips/online/arrow_drop_down.svg"
                className="ps-5"
              />
            </div>
          </div>
          <div className="d-flex gap-3 my-4">
            <div className="cab-options cab-selected">
              <span className="color_primary header_text__14">3 Star</span>
              <img src="/images/dashboard/trips/online/cross.png" />
            </div>
            <div className="cab-options cab-selected">
              <span className="color_primary header_text__14">
                40,000 to 60,000{" "}
              </span>
              <img src="/images/dashboard/trips/online/cross.png" />
            </div>
            <div className="cab-options cab-selected">
              <span className="color_primary header_text__14"> Just Deals</span>
              <img src="/images/dashboard/trips/online/cross.png" />
            </div>
          </div>
          <div>
            {filteredProperty.map((property, index) => (
              <div key={index} className="mb-4 filtered-property">
                <div className="d-flex ">
                  <div>
                    <img src={property.image} />
                  </div>
                  <div className="p-4">
                    <Link
                      to={`/client/trips/online-booking/properties/${property.property_name}`}
                      className="text-black"
                    >
                      <h5 className="">
                        Hotel Grand Godwin - Near New Delhi Railway Station -
                        Paharganj
                      </h5>
                    </Link>
                    <div className="d-flex gap-1 align-items-center gray-color my-2 ">
                      3.8
                      <div className="calender-border-left d-flex gap-1 align-items-center ps-2">
                        <img src="/images/dashboard/trips/online/star.png" />
                        <img src="/images/dashboard/trips/online/star.png" />
                        <img src="/images/dashboard/trips/online/star.png" />
                        <img src="/images/dashboard/trips/online/star-half.png" />
                        <img src="/images/dashboard/trips/online/star-path.png" />
                      </div>
                      <Link href="/" className="color_primary ps-3">
                        120 Reviews
                      </Link>
                    </div>
                    <p className="gray-color header_text__14">
                      8502/41 Arakashan Road Ram Nagar Pahar Ganj, Paharganj,
                      110055 New Delhi, India
                    </p>
                    <div className="d-flex align-items-center gap-3 my-2 header_text__14">
                      <Link href="/" className="color_primary">
                        Show on map
                      </Link>
                      <div className="dot-seprator"></div>
                      <span className="gray-color">5 Km to city centre</span>
                      <div className="dot-seprator"></div>
                      <span className="gray-color">
                        15 Km to International Airport
                      </span>
                    </div>
                    <div className="mt-4 d-flex flex-column gap-1">
                      <h6 className="header_text__16">Superior King Room</h6>
                      <p className="gray-color header_text__14">
                        1 Large double bed
                      </p>
                      <p
                        className="header_text__14"
                        style={{ color: "#3AA757" }}
                      >
                        Breakfast Included
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center  py-2 px-4 grayed-border-top">
                  <div>
                    <div className="d-flex gap-1 header_text__16">
                      <span className="original-price">₹10,000</span>
                      <span className="text-danger">₹9,000</span>
                    </div>
                    <p className="gray-color small_text__12 mt-1">
                      1 Night, 2 Adults
                    </p>
                  </div>
                  <div className="d-flex gap-3 align-items-center">
                    <Link className="color_primary">View More Details</Link>
                    <Link
                      className="deeptravel-button deeptravel-button--secondary deeptravel-button--header-buttons"
                      href="/"
                    >
                      See Availability
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-end my-5">
            <div className="d-flex justify-content-center align-items-center gap-3 gray-color">
              <span className="color_primary">1</span>
              <span>2</span>
              <span>3</span>
              <span>4</span>
              <span>5</span>
              <span className="color_primary">Next</span>
              <div className="calender-wrapper ">
                <span>09 per page</span>
                <img
                  src="/images/dashboard/trips/online/arrow_drop_down.svg"
                  className="ps-5"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </HeaderLayout>
  );
};

export default FilteredProperties;
