import AddSvgComponent from "@assets/svg-components/add";
import CloseSmallSvgComponent from "@assets/svg-components/close-small";
import ExpenseSvgComponent from "@assets/svg-components/sidebar/expense";
import LightGalleryPreview from "@common/lightgallery-preview";
import { concatString, formatText } from "@common/text-helpers";
import UploadLoader from "@components/common/UploadLoader";
import ExpenseUpload from "@pages/Client/Expense/Detail/DetailContainer/ExpenseUpload";
import { getValue } from "@utils/lodash";
import React, { useCallback, useRef, useState } from "react";
import TripsUploadComponent from "./TripsUpload";
import EditSvgComponent from "@assets/svg-components/edit";
import { handleDownloadOnClick } from "@common/downloadfile";
import { QueryRequestHelper } from "@common/query-request-helper";
import { tripDeleteAttachmentDocuments } from "@services/trips.service";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DeleteModal from "@common/popup/DeleteModal";

function TripReportUploadSection(props: any) {
  const { handleUploadDocuments, uploadLoading, formInfo } = props;
  const params = useParams();
  /* -------------------------------------------------------------------------- */
  /*                            Image Preview section                           */
  /* -------------------------------------------------------------------------- */
  const lightGallery = useRef<any>(null);
  const openGallery = useCallback(() => {
    lightGallery.current.openGallery();
  }, []);
  const onInit = useCallback((detail: any) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const [documentInfo, setDocumentInfo] = useState({});
  const handleDocumentModal = (item: any) => {
    setRequest({
      ...request,
      id: getValue(item, `id`, ""),
      travel_mode: getValue(item, `travel_mode`, ""),
      type: getValue(item, `type`, ""),
    });
    handleModal();
  };

  /* -------------------------------------------------------------------------- */
  /*                          Delete Document section                           */
  /* -------------------------------------------------------------------------- */
  const [deleteValue, setDeleteValue] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [mainId, setMainId] = useState("");
  const handleDeleteFun = (mainId: string, id: string, value: string) => {
    setMainId(mainId);
    setDeleteValue(value);
    setDeleteId(id);
    handleDelete();
  };
  const [isDelete, setIsDelete] = useState(false);
  const handleDelete = () => {
    setIsDelete(!isDelete);
  };
  const handleDeleteFunction = async () => {
    try {
      let payload = {
        document_id: deleteId,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await tripDeleteAttachmentDocuments(mainId, queryRequest);
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        handleDelete();
        props.getData(false);
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                                  Request section                           */
  /* -------------------------------------------------------------------------- */
  const intialState = {
    id: "",
    travel_mode: "flight",
    type: "",
    document_ids: [],
  };
  const [request, setRequest] = useState(intialState);
  const reset = () => {
    setRequest(intialState);
  };
  return (
    <div>
      <div className="border_bottom trips_mandate_approver_seperator"></div>
      {/* <ExpenseUpload
        reports={true}
        handleUploadDocuments={handleUploadDocuments}
        permissions={getValue(props, `permissions`, [])}
      /> */}
      <div className="d-flex align-items-center justify-content-between report_right_documents_upload w-100">
        <p className="desc_text report_right_documents_upload_label mt-0">
          Documents:
        </p>
        <span
          className="d-flex align-items-center cursor-pointer"
          onClick={handleModal}
        >
          <AddSvgComponent size={18} color={"#1966a1 "} />
          <h5 className="header_text_blue report_right_documents_addFiles_label color_primary">
            Add Files
          </h5>
        </span>
      </div>
      {uploadLoading ? (
        // <p className="text-center desc_text mt-3">Please wait...</p>
        <UploadLoader />
      ) : getValue(props, `formInfo.attachments.length`, 0) === 0 ? (
        <p className="text-center desc_text mt-5">No Documents Found</p>
      ) : (
        <div className="expense_mandate_preview_document_wrapper">
          {getValue(props, `formInfo.attachments`, []).map((item: object) => {
            return (
              <div className="d-flex align-items-center border-radius__8 justify-content-between border p-3 mb-2">
                <div className="w-100">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h6 className="header_text__16">
                      {formatText(getValue(item, `travel_mode`, ""))}{" "}
                      {`(${getValue(item, `type`, "")})`}
                    </h6>
                    <div onClick={() => handleDocumentModal(item)}>
                      <EditSvgComponent />
                    </div>
                  </div>
                  {getValue(item, `documents`, []).map((items: object) => {
                    return (
                      <div className=" d-flex align-items-center border-radius__8 justify-content-between border w-100 p-2 mb-2 cursor-pointer">
                        <div
                          className=" d-flex align-items-center "
                          // onClick={openGallery}
                          onClick={() =>
                            handleDownloadOnClick(
                              getValue(items, `url`, ""),
                              getValue(items, `name`, "")
                            )
                          }
                        >
                          <ExpenseSvgComponent color={"#5F6368"} />
                          <p className="document_list_view_filename ms-2">
                            {concatString(getValue(items, `name`, ""), 20)}
                          </p>
                        </div>
                        {getValue(props, `permissions`, []).includes(
                          "delete"
                        ) && (
                          <div
                            onClick={() =>
                              handleDeleteFun(
                                getValue(item, `id`, ""),
                                getValue(items, `id`, ""),
                                getValue(items, `name`, "")
                              )
                            }
                          >
                            <CloseSmallSvgComponent />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      )}
      {getValue(formInfo, `documents.length`, 0) > 0 && (
        <LightGalleryPreview
          onInit={onInit}
          imageList={getValue(formInfo, `documents`, []).map((item: any) => ({
            id: getValue(item, `id`, ""),
            size: "1400-933",
            src: getValue(item, `url`, ""),
            thumb: getValue(item, `url`, ""),
            subHtml: `<div class="lightGallery-captions">
                <h4><a href="">${getValue(item, `name`, "")}</a></h4>
            </div>`,
          }))}
        />
      )}
      <TripsUploadComponent
        isOpen={isOpen}
        handleModal={handleModal}
        documentInfo={documentInfo}
        setDocumentInfo={setDocumentInfo}
        getData={props.getData}
        request={request}
        setRequest={setRequest}
        reset={reset}
      />
      <DeleteModal
        isOpen={isDelete}
        handleModal={handleDelete}
        handleSubmit={handleDeleteFunction}
        deleteValue={deleteValue}
      />
    </div>
  );
}

export default TripReportUploadSection;
