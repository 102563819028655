import { config } from "../env";
import { post, get, Delete, patch } from "./helpers/http-handler";

export const getShowAndHideColumnsList = (queryRequest: string) =>
  get(`${config.API_URL}/reports/show-hide-column?${queryRequest}`);

export const getReportsFilterkey = (queryRequest: string) =>
  get(`${config.API_URL}/reports/filter-key?${queryRequest}`);

export const getAnalyticsReports = (queryRequest: string) =>
  get(`${config.API_URL}/reports?${queryRequest}`);

export const createAnalyticsReport = (payload: object) =>
  post(`${config.API_URL}/reports`, payload);

export const updateAnalyticsReport = (id: string, payload: object) =>
  patch(`${config.API_URL}/reports/${id}`, payload);

export const deleteAnalyticsReport = (id: string) =>
  Delete(`${config.API_URL}/reports/${id}`);
