import { getValue } from "@utils/lodash";
import React from "react";

function DeleteSvgComponent(props) {
  return (
    <div className="delete_image_container">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        class="icon align-text-bottom mr-1 text-danger"
        className="delete_image"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
      >
        <path d="M464.1 81.5h-79v-25c0-30.9-25.1-56-56-56H182.9c-30.9 0-56 25.1-56 56v25h-79c-11.6 0-21 9.4-21 21s9.4 21 21 21H58l15.6 301.7c2.5 48.4 42.4 86.3 90.9 86.3h183.2c48.5 0 88.4-37.9 90.9-86.3l15.6-301.7h10.1c11.6 0 21-9.4 21-21s-9.6-21-21.2-21zm-295.2-25c0-7.7 6.3-14 14-14h146.2c7.7 0 14 6.3 14 14v25H168.9v-25zM396.6 423c-1.3 26.1-22.8 46.5-48.9 46.5H164.4c-26.1 0-47.6-20.4-48.9-46.5L100 123.5h312L396.6 423z"></path>
      </svg>
    </div>
  );
}

export default DeleteSvgComponent;
