import { getValue } from "@utils/lodash";
import { NavLink } from "react-router-dom";
import BackSvgComponent from "@assets/svg-components/back";
import { formatText } from "@common/text-helpers";
import { getColorStyle } from "@common/color";
import InfoSvgComponent from "@assets/svg-components/info";
import RejectCommentPopup from "@components/common/reject-popup";
import EyeSvgComponent from "@assets/svg-components/eye";
import ChatSvgComponent from "@assets/svg-components/chat";
import { X } from "lucide-react";

function TripsStickyHeader(props: any) {
  const { route, handleSubmitTrip, submitTripLoading, recallLoading } = props;
  return (
    <div className="common-sticky-header">
      <section className="inner-page-navigation-with-back-cta-buttons position-relative bg-white">
        <div className="container-fluid  d-flex justify-content-between align-items-center">
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <NavLink to={route ? `${route}` : "#"}>
                <X className="me-3" size={20} />
              </NavLink>
              <p className="header_text">
                {getValue(props, `formInfo.trip_id`, "Trips")} -&nbsp;
              </p>
              <div className="d-flex align-items-center">
                <h6
                  className="header_text__16"
                  style={getColorStyle(
                    getValue(props, `formInfo.approval_status`, "")
                  )}
                >
                  {formatText(getValue(props, `formInfo.approval_status`, ""))}
                </h6>
                {getValue(props, `formInfo.approval_status`, "") ===
                  "rejected" && (
                  <RejectCommentPopup
                    header={
                      <div className="ms-2 cursor-pointer">
                        <ChatSvgComponent color={"#EC6861"} />
                      </div>
                    }
                    rejectComment={props.rejectComment}
                    comment={getValue(props, `rejectComment.comments`, "")}
                  />
                )}

                {getValue(props, `formInfo.approval_status`, "") ===
                  "sent_back" && (
                  <RejectCommentPopup
                    header={
                      <div className="ms-2 cursor-pointer">
                        <ChatSvgComponent color={"#EC6861"} />
                      </div>
                    }
                    type="sent_back"
                    rejectComment={props.sent_backComment}
                    comment={getValue(props, `sent_backComment.comments`, "")}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center gap-2">
            {getValue(props, `histoyList.length`, 0) > 0 && (
              <div
                className="border border-radius__8 d-flex align-items-center deeptravel-button deeptravel-button--header-buttons deeptravel-button--secondary"
                onClick={props.toggleHistory}
              >
                <InfoSvgComponent color={"gray"} size={24} />
                <h6 className="header_text__16 cursor-pointer">History</h6>
              </div>
            )}

            {getValue(props, `permissions`, []).includes("update") && (
              <>
                {(getValue(props, `formInfo.approval_status`, "") ===
                  "unsubmitted" ||
                  getValue(props, `formInfo.approval_status`, "") ===
                    "recalled") && (
                  <div
                    className="header_square position-relative  ms-2"
                    onClick={props.handleEdit}
                  >
                    <img
                      src="/images/edit-gray.svg"
                      className="header_square_image img-fluid"
                    />
                    <h6 className="header_text__14 mx-2">Update/Edit</h6>
                  </div>
                )}
                {/* <div
              className="more-dropdown-main align-items-center"
              style={{ height: "42px" }}
            ></div> */}
                {getValue(props, `formInfo.approval_status`, "") &&
                getValue(props, `formInfo.approval_status`, "") ===
                  "awaiting_approval" ? (
                  <button
                    className={`deeptravel-button deeptravel-button--header-buttons deeptravel-button--orange`}
                    onClick={() => handleSubmitTrip("recall")}
                    disabled={recallLoading}
                  >
                    {recallLoading ? "Please wait..." : "Recall"}
                  </button>
                ) : (
                  <div style={{ height: "48px" }}></div>
                )}
                {getValue(props, `formInfo.approval_status`, "") &&
                (getValue(props, `formInfo.approval_status`, "") ===
                  "unsubmitted" ||
                  getValue(props, `formInfo.approval_status`, "") ===
                    "recalled" ||
                  getValue(props, `formInfo.approval_status`, "") ===
                    "sent_back") ? (
                  <button
                    onClick={() => handleSubmitTrip("submit")}
                    disabled={submitTripLoading}
                    className="deeptravel-button deeptravel-button--header-buttons deeptravel-button--secondary"
                  >
                    {submitTripLoading ? "Please wait..." : "Submit"}
                  </button>
                ) : null}
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default TripsStickyHeader;
