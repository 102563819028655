import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import LightGallery from "lightgallery/react";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

const images = [
  {
    image: "/images/dashboard/trips/online/property-5.png",
    alt: "",
  },
  {
    image: "/images/dashboard/trips/online/property-1.png",
    alt: "",
  },
  {
    image: "/images/dashboard/trips/online/property-2.png",
    alt: "",
  },
  {
    image: "/images/dashboard/trips/online/property-3.png",
    alt: "",
  },
  {
    image: "/images/dashboard/trips/online/property-4.png",
    alt: "",
  },
  {
    image: "/images/dashboard/trips/online/property-6.png",
    alt: "",
  },
  {
    image: "/images/dashboard/trips/online/property-4.png",
    alt: "",
  },
  {
    image: "/images/dashboard/trips/online/property-5.png",
    alt: "",
  },
  {
    image: "/images/dashboard/trips/online/property-6.png",
    alt: "",
  },
];

const PropertyImages = () => {
  const containerStyle = {
    height: "160px",
  };
  const imageStyle = {
    width: "100%",
    objectFit: "contain",
  };
  return (
    <Swiper
      spaceBetween={5}
      slidesPerView={6}
      navigation={true}
      modules={[Navigation]}
      className="mySwiper"
    >
      {images.map((img, index) => (
        <SwiperSlide key={index}>
          <LightGallery plugins={[lgThumbnail, lgZoom]}>
            <a href={img.image}>
              <div
                style={containerStyle}
                className="d-flex justify-content-center align-items-center"
              >
                <img src={img.image} alt="" style={imageStyle} />
              </div>
            </a>
          </LightGallery>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default PropertyImages;
