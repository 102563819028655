import * as React from 'react';
import {
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Button,
} from 'reactstrap';
import InputField from '@common/InputRuleFeild';
import { getValue } from '@utils/lodash';
import '../popup/addpopup.scss';

interface IChoiceValuePopupProps {
	isOpen: boolean;
	handleModal: () => void;
	text: string;
	setText: any;
	handleSubmit: () => void;
	handleModalCancel: () => void;
}

const ChoiceValuePopup: React.FunctionComponent<IChoiceValuePopupProps> = (
	props
) => {
	return (
		<Modal
			isOpen={getValue(props, `isOpen`, '')}
			toggle={props.handleModalCancel}
			centered
			className="common-popup-spacing"
		>
			<section className="add-popup-wrapper">
				<div className="common-popup-spacing__popup">
					<div className="add-popup-wrapper__form-group form-group">
						<Label className='form-label-error'>Value *</Label>
						<InputField
							inputType="TEXT"
							placeholder="Enter Value"
							name={'text'}
							value={props.text}
							id={'text'}
							onChange={(e: any) => props.setText(e.target.value)}
						/>
					</div>
					<div className="add-popup-wrapper__cta-wrapper">
						<button
							onClick={props.handleModalCancel}
							className="add-popup-wrapper__cta-btn add-popup-wrapper__cta-btn--secondary"
						>
							Cancel
						</button>
						<button
							className="add-popup-wrapper__cta-btn add-popup-wrapper__cta-btn--primary"
							onClick={props.handleSubmit}
						>
							Save
						</button>
					</div>
				</div>
			</section>
		</Modal>
	);
};

export default ChoiceValuePopup;
