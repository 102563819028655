import DocumentImageList from "@common/FileUpload/document-image-list";
import { convertDateAsString } from "@common/date-helpers";
import { formatText } from "@common/text-helpers";
import { getValue } from "@utils/lodash";

function TravelDocumentUserPopup(props: any) {
  console.log(props, "PROPS")
  const { isOpen, toggle } = props;
  return (
    <div
      className={`compelte-task-wrapper ${
        isOpen ? "compelte-task-wrapper--active" : ""
      }`}
      style={{ width: "38%" }}
    >
      <div className="compelte-task-wrapper__header d-flex align-items-center justify-content-between">
        <h6 className="compelte-task-wrapper__title">Travel Document Info</h6>
        <img
          src="/images/close-white.svg"
          className="img-fluid navigation-bar-wrapper__close-btn"
          onClick={() => toggle()}
        />
      </div>
      <div className="approver_flow_main_container m-3">
        {getValue(props, `list.passport.length`, 0) > 0 ? (
          getValue(props, `list.passport`, []).map((item: object) => {
            console.log(item, "---ITEM---")
            return (
              <div className="p-3 position-relative travel-document-info-container">
                <div>
                  <h6 className="header_text__16">
                    <span>{getValue(item, `user.first_name`, "")}</span>
                    <span className="ms-1">
                      {getValue(item, `user.middle_name`, "")}
                    </span>
                    <span className="ms-1">
                      {getValue(item, `user.last_name`, "")}
                    </span>
                  </h6>
                  <p className="small_text__12">
                    {getValue(item, `user.email`, "")}
                  </p>
                </div>
                <div className="d-flex mt-3 w-75 align-items-center">
                  <p className="small_text__14 w-50">Document Type</p>
                  <p className="small_text__14 mx-3">:</p>
                  <h6 className="header_text__16">
                    {" "}
                    {formatText(getValue(item, `document_type`, ""))}
                  </h6>
                </div>
                <div className="d-flex w-75 mt-1">
                  <p className="small_text__14 w-50">
                    {formatText(getValue(item, `document_type`, ""))} Number
                  </p>
                  <p className="small_text__14 mx-3">:</p>
                  <h6 className="header_text__16">
                    {" "}
                    {getValue(item, `document_number`, "")}
                  </h6>
                </div>
                {/* <div className="d-flex w-75 mt-1">
                  <p className="small_text__14">
                    {formatText(getValue(item, `document_type`, ""))} Issued on
                  </p>
                  <p className="small_text__14">:</p>
                  <h6 className="header_text__16">
                    {" "}
                    {convertDate(getValue(item, `document_issued_on`, ""))}
                  </h6>
                </div> */}
                <div className="d-flex w-75 mt-1">
                  <p className="small_text__14 w-50">
                    {formatText(getValue(item, `document_type`, ""))} Valid From
                  </p>
                  <p className="small_text__14  mx-3">:</p>
                  <h6 className="header_text__16">
                    {" "}
                    {convertDateAsString(
                      getValue(item, `document_valid_from`, "")
                    )}
                  </h6>
                </div>
                <div className="d-flex w-75 mt-1">
                  <p className="small_text__14 w-50">
                    {formatText(getValue(item, `document_type`, ""))} Expired On
                  </p>
                  <p className="small_text__14  mx-3">:</p>
                  <h6 className="header_text__16">
                    {" "}
                    {convertDateAsString(
                      getValue(item, `document_expires_on`, "")
                    )}
                  </h6>
                </div>

                <div className="d-flex w-75 mt-1">
                  <p className="small_text__14 w-50">
                    {formatText(getValue(item, `document_type`, ""))} Issued
                    Country
                  </p>
                  <p className="small_text__14  mx-3">:</p>
                  <h6 className="header_text__16">
                    {" "}
                    {getValue(item, `document_country.name`, "")}{" "}
                    {"( " +
                      getValue(item, `document_country.currency`, "") +
                      " )"}
                  </h6>
                </div>

                <h6 className="header_text__16  mt-3">Attachments</h6>
                <div className="expense-detail-wrapper_report_documentsection w-100">
                  <div className="d-flex  mt-3" style={{ flexWrap: "wrap" }}>
                    <DocumentImageList
                      documents={getValue(item, `attachments`, [])}
                      containerStayle={{ height: "100px", width: "150px" }}
                      textContainerStayle={{ height: "40px", width: "150px" }}
                      textStyle={{ fontSize: "12px" }}
                      length={10}
                      travelDocuments
                    />
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <h6 className="header_text_blue text-center mt-5">
            No Passport Document Found
          </h6>
        )}

        {getValue(props, `list.visa.length`, 0) > 0 ? (
          getValue(props, `list.visa`, []).map((item: object) => {
            console.log(item, "---ITEM---")
            return (
              <div className="p-3 position-relative travel-document-info-container mt-3">
                <div>
                  <h6 className="header_text__16">
                    <span>{getValue(item, `user.first_name`, "")}</span>
                    <span className="ms-1">
                      {getValue(item, `user.middle_name`, "")}
                    </span>
                    <span className="ms-1">
                      {getValue(item, `user.last_name`, "")}
                    </span>
                  </h6>
                  <p className="small_text__12">
                    {getValue(item, `user.email`, "")}
                  </p>
                </div>
                <div className="d-flex mt-3 w-75 align-items-center">
                  <p className="small_text__14 w-50">Document Type</p>
                  <p className="small_text__14 mx-3">:</p>
                  <h6 className="header_text__16">
                    {" "}
                    {formatText(getValue(item, `document_type`, ""))}
                  </h6>
                </div>
                <div className="d-flex w-75 mt-1">
                  <p className="small_text__14 w-50">
                    {formatText(getValue(item, `document_type`, ""))} Number
                  </p>
                  <p className="small_text__14 mx-3">:</p>
                  <h6 className="header_text__16">
                    {" "}
                    {getValue(item, `document_number`, "")}
                  </h6>
                </div>
                {/* <div className="d-flex w-75 mt-1">
                  <p className="small_text__14">
                    {formatText(getValue(item, `document_type`, ""))} Issued on
                  </p>
                  <p className="small_text__14">:</p>
                  <h6 className="header_text__16">
                    {" "}
                    {convertDate(getValue(item, `document_issued_on`, ""))}
                  </h6>
                </div> */}
                <div className="d-flex w-75 mt-1">
                  <p className="small_text__14 w-50">
                    {formatText(getValue(item, `document_type`, ""))} Valid From
                  </p>
                  <p className="small_text__14  mx-3">:</p>
                  <h6 className="header_text__16">
                    {" "}
                    {convertDateAsString(
                      getValue(item, `document_valid_from`, "")
                    )}
                  </h6>
                </div>
                <div className="d-flex w-75 mt-1">
                  <p className="small_text__14 w-50">
                    {formatText(getValue(item, `document_type`, ""))} Expired On
                  </p>
                  <p className="small_text__14  mx-3">:</p>
                  <h6 className="header_text__16">
                    {" "}
                    {convertDateAsString(
                      getValue(item, `document_expires_on`, "")
                    )}
                  </h6>
                </div>

                <div className="d-flex w-75 mt-1">
                  <p className="small_text__14 w-50">
                    {formatText(getValue(item, `document_type`, ""))} Issued
                    Country
                  </p>
                  <p className="small_text__14  mx-3">:</p>
                  <h6 className="header_text__16">
                    {" "}
                    {getValue(item, `document_country.name`, "")}{" "}
                    {"( " +
                      getValue(item, `document_country.currency`, "") +
                      " )"}
                  </h6>
                </div>

                <h6 className="header_text__16  mt-3">Attachments</h6>
                <div className="expense-detail-wrapper_report_documentsection w-100">
                  <div className="d-flex  mt-3" style={{ flexWrap: "wrap" }}>
                    <DocumentImageList
                      documents={getValue(item, `attachments`, [])}
                      containerStayle={{ height: "100px", width: "150px" }}
                      textContainerStayle={{ height: "40px", width: "150px" }}
                      textStyle={{ fontSize: "12px" }}
                      length={10}
                      travelDocuments
                    />
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <h6 className="header_text_blue text-center mt-5">
            No Visa Document Found
          </h6>
        )}
      </div>
    </div>
  );
}

export default TravelDocumentUserPopup;
