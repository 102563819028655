import "./Header.scss";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap";
import { getValue } from "@utils/lodash";
import { useEffect, useState } from "react";
import { clientLogout } from "@services/auth.service";
import { useNavigate } from "react-router-dom";
import MyViewSvgComponent from "@assets/svg-components/my-view";
import AdminViewSvgComponent from "@assets/svg-components/admin-view";
import { getAllOrganisation } from "@services/organisation.service";
import { formatText } from "@common/text-helpers";
import { useNavigationData } from "../LeftNavbar/NavigationContext";
import PersonSvgComponent from "@assets/svg-components/person";
import { getColorStyle } from "@common/color";
import UsercontrolSvgComponent from "@assets/svg-components/sidebar/usercontrol";
import { Tooltip } from "react-tooltip";
import CloseSmallSvgComponent from "@assets/svg-components/close-small";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  CheckCircle,
  ChevronDown,
  CircleCheck,
  Grip,
  UserRound,
  UserRoundCog,
} from "lucide-react";

export default function Header() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handleLogout = async () => {
    // let payload = {
    // 	refresh_token: getValue(localStorage, `refreshToken`, ""),
    // };
    // let resp = await clientLogout(payload);
    // localStorage.removeItem("accessToken");
    // localStorage.removeItem("user");
    // window.location.href = `/login`;
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    window.location.href = `/login`;
    let payload = {
      refresh_token: getValue(localStorage, `refreshToken`, ""),
    };
    let resp = await clientLogout(payload);
  };
  const handleChangeView = async (view: string) => {
    if (view === "admin") {
      localStorage.setItem("view", "admin");
      window.location.href = `/admin/dashboard`;
    } else {
      localStorage.setItem("view", "client");
      window.location.href = `/`;
    }
  };
  const handleNavigateHome = (view: string) => {
    if (view === "admin") {
      navigate(`/admin/dashboard`);
    } else {
      navigate(`/`);
    }
  };
  useEffect(() => {
    if (!getValue(localStorage, `organisation_logo`, "")) {
      getData();
    }
  }, []);
  const getData = async () => {
    try {
      let resp = await getAllOrganisation("");
      if (resp) {
        if (
          getValue(resp, `data.organisations[${0}].organisation_logo.url`, "")
        ) {
          localStorage.setItem(
            "organisation_logo",
            getValue(resp, `data.organisations[${0}].organisation_logo.url`, "")
          );
        }
      }
    } catch (error) {}
  };

  const { info, user } = useNavigationData();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [clientNavigationMenu] = useState([
    {
      title: "Home",
      icon: "/images/navigation-icons/dashboard.svg",
      link: "/",
      parentLink: "/",
      name: "home",
    },
    {
      title: "Dashboard",
      icon: "/images/navigation-icons/dashboard.svg",
      link: "/client/dashboard",
      parentLink: "/client/dashboard",
      name: "dashboard",
    },
    {
      title: "Trips",
      icon: "/images/navigation-icons/receipt.svg",
      link: "/client/trips",
      parentLink: "/client/trips",
      name: "trips",
    },
    {
      title: "Expenses",
      icon: "/images/navigation-icons/receipt.svg",
      link: "/client/expenses",
      parentLink: "/client/expenses",
      name: "expenses",
    },
    {
      title: "Reports",
      icon: "/images/navigation-icons/reports.svg",
      link: "/client/reports",
      parentLink: "/client/reports",
      name: "reports",
    },
    {
      title: "My Approvals",
      icon: "/images/navigation-icons/reports.svg",
      link: "/client/approval",
      parentLink: "/client/approval",
      name: "approvals",
      subMenu: [
        {
          title: "Trips",
          link: "/client/approval/trips",
          name: "approvals_trips",
        },
        {
          title: "Reports",
          link: "/client/approval/reports",
          name: "approvals_reports",
        },
      ],
    },
    {
      title: "Settings",
      icon: "/images/navigation-icons/settings.svg",
      link: "/client/settings",
      parentLink: "/client/settings",
      name: "settings",
      subMenu: [
        {
          title: "Personal Information",
          link: "/client/settings/personal-information",
          name: "settings_personal_information",
        },
        {
          title: "Travel Profile",
          link: "/client/settings/travel-profile",
          name: "settings_travel_profile",
        },
        {
          title: "Travel Documents",
          link: "/client/settings/travel-documents",
          name: "settings_travel_documents",
        },
      ],
    },
  ]);

  const [adminNavigationMenu] = useState([
    {
      title: "Dashboard",
      icon: "/images/navigation-icons/dashboard.svg",
      link: "/admin/dashboard",
      parentLink: "/admin/dashboard",
      name: "admin_dashboard",
    },
    {
      title: "Trips",
      icon: "/images/navigation-icons/receipt.svg",
      link: "/admin/trips",
      parentLink: "/admin/trips",
      name: "admin_trips",
    },
    {
      title: "Reports",
      icon: "/images/navigation-icons/reports.svg",
      link: "/admin/reports",
      parentLink: "/admin/reports",
      name: "admin_reports",
    },
    {
      title: "Organisations",
      icon: "/images/navigation-icons/org.svg",
      link: "/admin/organisation/profile",
      parentLink: "/admin/organisation",
      name: "admin_organisations",
      subMenu: [
        {
          title: "Org Profile",
          link: "/admin/organisation/profile",
          name: "admin_organisations_org_profile",
        },
        {
          title: "Currencies",
          link: "/admin/organisation/currencies",
          name: "admin_organisations_currencies",
        },
      ],
    },
    {
      title: "User Controls",
      icon: "/images/navigation-icons/user-control.svg",
      link: "/admin/user-controls/users",
      parentLink: "/admin/user-controls",
      name: "admin_user_controls",
      subMenu: [
        {
          title: "Users",
          link: "/admin/user-controls/users",
          name: "admin_user_controls_users",
        },
        {
          title: "Roles & Permissions",
          link: "/admin/user-controls/roles",
          name: "admin_user_controls_roles_permission",
        },
        {
          title: "Departments",
          link: "/admin/user-controls/departments",
          name: "admin_user_controls_departments",
        },
        {
          title: "Policies",
          link: "/admin/user-controls/policies",
          name: "admin_user_controls_policies",
        },
        {
          title: "Email Templates",
          link: "/admin/user-controls/email-templates",
          name: "admin_user_controls_email_templates",
        },
      ],
    },
    {
      title: "Modules",
      icon: "/images/leftnav/tasks.svg",
      link: "/admin/modules/trips",
      parentLink: "/admin/modules",
      name: "admin_modules",
      subMenu: [
        {
          title: "Trips",
          link: "/admin/modules/trips/fields",
          name: "admin_modules_trips",
        },
        {
          title: "Expenses",
          link: "/admin/modules/expenses/fields",
          name: "admin_modules_expenses",
        },
        {
          title: "Reports",
          link: "/admin/modules/reports/fields",
          name: "admin_modules_reports",
        },
        {
          title: "Users",
          link: "/admin/modules/users/fields",
          name: "admin_modules_users",
        },
        {
          title: "Paid Through Accounts",
          link: "/admin/modules/paid-through-accounts",
          name: "admin_modules_paid_through_accounts",
        },
        // {
        //   title: "Customers",
        //   link: "/admin/modules/customers",
        //   name: "reports",
        // },
        {
          title: "Projects",
          link: "/admin/modules/projects",
          name: "admin_modules_projects",
        },
      ],
    },
    // {
    //   title: "List Management",
    //   icon: "/images/leftnav/list-management.svg",
    //   link: "/admin/list-management/choice-list",
    //   parentLink: "/admin/list-management",
    //   name: "admin_list_management",
    //   subMenu: [
    //     {
    //       title: "Choice List",
    //       link: "/admin/list-management/choice-list",
    //       name: "admin_list_management_choice_list",
    //     },
    //   ],
    // },
    {
      title: "Settings",
      icon: "/images/navigation-icons/settings.svg",
      link: "/admin/settings/approval",
      parentLink: "/admin/settings",
      name: "admin_settings",
      subMenu: [
        {
          title: "Approval",
          link: "/admin/settings/approval",
          name: "admin_settings_approval_flows",
        },

        // {
        //   title: "Basic Information",
        //   link: "/client/settings/basic-information",
        //   name: "profile",
        // },
        {
          title: "Travel Documents",
          link: "/admin/settings/travel-documents",
          name: "admin_settings_travel_documents",
        },
        {
          title: "Choice List",
          link: "/admin/list-management/choice-list",
          name: "admin_list_management_choice_list",
        },
      ],
    },
    {
      title: "Analytics",
      icon: "/images/navigation-icons/receipt.svg",
      link: "/admin/analytics",
      parentLink: "/admin/analytics",
      name: "expenses",
    },
  ]);
  return (
    <header className="header-wrapper d-flex align-items-center">
      <div className="container-fluid d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center cursor-pointer">
          <div
            onClick={() =>
              handleNavigateHome(getValue(localStorage, `view`, ""))
            }
            className="d-inline-block"
          >
            <img
              src={
                getValue(localStorage, `organisation_logo`, "")
                  ? getValue(localStorage, `organisation_logo`, "")
                  : "/images/logo.png"
              }
              // src="/images/demo.svg"
              width="100%"
              height="100%"
              className="img-fluid header_logo"
              alt="logo"
            />
          </div>
          <div className="d-flex align-items-center ms-1 gap-1 navbar-items-wrapper">
            {(getValue(localStorage, `view`, "") === "admin"
              ? adminNavigationMenu
              : clientNavigationMenu
            ).map((item, index) => (
              <div key={index} className={item.subMenu ? "nav-dropdown" : ""}>
                {item.subMenu ? (
                  <>
                    <button
                      className={`nav-option ${
                        item.subMenu.some(
                          (subItem) => pathname === subItem.link
                        )
                          ? "active"
                          : ""
                      }`}
                    >
                      {item.title}
                      <ChevronDown size={16} />
                    </button>
                    <div className="nav-suboption-wrapper">
                      {item.subMenu.map((subItem, subIndex) => (
                        <Link
                          to={subItem.link}
                          key={subIndex}
                          className={`nav-suboption ${
                            pathname === subItem.link ? "active" : ""
                          }`}
                        >
                          {subItem.title}
                        </Link>
                      ))}
                    </div>
                  </>
                ) : (
                  <Link
                    to={item.parentLink}
                    key={index}
                    className={`nav-option ${
                      pathname === item.parentLink ? "active" : ""
                    }`}
                  >
                    {item.title}
                  </Link>
                )}
              </div>
            ))}
          </div>
        </div>
        {getValue(localStorage, `user`, "") && (
          <div className="d-flex align-items-center gap-4">
            {/* {getValue(localStorage, `view`, "") !== "client" && (
              <div
                className="view_header  p-2 px-2 mx-3 border-radius__10 w-auto"
                style={getColorStyle("rejected")}
              >
                <UsercontrolSvgComponent size={20} color={"#d72e38"} />
                <p className="ms-1 pt-1 small_text__15 color_danger">
                  {formatText(getValue(localStorage, `view`, "")) +
                    " " +
                    "View"}
                </p>
              </div>
            )} */}
            {getValue(info, `is_admin`, false) && (
              <div className="switch-dropdown">
                {/* <UncontrolledDropdown> */}
                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                  <DropdownToggle>
                    <div className="switch_header__dropdown m-0">
                      <div
                        className="dropdown-conten d-flex cursor-pointer"
                        data-tooltip-id="switchTooltip"
                      >
                        {/* <Grip /> */}
                        <div className="view_header">
                          <UsercontrolSvgComponent size={18} color={"#fff"} />
                          <p className="small_text__16 text-white">
                            {getValue(localStorage, `view`, "") === "client"
                              ? "My View"
                              : formatText(getValue(localStorage, `view`, "")) +
                                " " +
                                "View"}
                          </p>
                        </div>
                        <Tooltip id="switchTooltip" content="Switch to" />
                      </div>
                    </div>
                  </DropdownToggle>
                  <DropdownMenu className="p-4">
                    <div className="switch-dropdown-item-header px-2 pb-3">
                      <p>Switch to</p>
                      <span onClick={toggle} className="close-svg">
                        {/* <CloseSmallSvgComponent />  */}
                        <img src="/images/close.svg" width={`16px`} alt="" />
                      </span>
                    </div>
                    <DropdownItem onClick={() => handleChangeView("client")}>
                      <div
                        className={`switch-dropdown-item ${
                          getValue(localStorage, `view`, "") !== "admin"
                            ? "justify-content-between"
                            : ""
                        }`}
                      >
                        <div className="d-flex align-items-center gap-2">
                          <UserRound size={18} />
                          <p className="">My View</p>
                        </div>
                        {getValue(localStorage, `view`, "") !== "admin" && (
                          <CircleCheck color="#16a34a" size={18} />
                        )}
                      </div>
                    </DropdownItem>
                    <DropdownItem onClick={() => handleChangeView("admin")}>
                      <div
                        className={`switch-dropdown-item ${
                          getValue(localStorage, `view`, "") === "admin"
                            ? "justify-content-between"
                            : ""
                        }`}
                      >
                        <div className="d-flex align-items-center gap-2">
                          <UserRoundCog size={18} />
                          <p className="">Admin View</p>
                        </div>
                        {getValue(localStorage, `view`, "") === "admin" && (
                          <CircleCheck color="#16a34a" size={18} />
                        )}
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                {/* </UncontrolledDropdown> */}
              </div>
            )}
            <div className="bulk-dropdown user-dropdown">
              <UncontrolledDropdown>
                <DropdownToggle>
                  <div className="header__dropdown m-0">
                    <div className="dropdown-content d-flex cursor-pointer">
                      <span
                        className=" initial-holder"
                        style={{ lineHeight: "normal" }}
                      >
                        {getValue(user, `email`, "").charAt(0).length > 0
                          ? getValue(user, `email`, "").charAt(0).toUpperCase()
                          : "A"}
                      </span>
                    </div>
                  </div>
                </DropdownToggle>
                <DropdownMenu>
                  <div className="user-dropdown-body p-4">
                    <DropdownItem className="profile-icon-circle">
                      <div className="header__dropdown m-0 d-flex align-items-center gap-3">
                        <div className="dropdown-content d-flex cursor-pointer profile-circle">
                          <span className="m-auto profile-initial">
                            {getValue(user, `email`, "").charAt(0).length > 0
                              ? getValue(user, `email`, "")
                                  .charAt(0)
                                  .toUpperCase()
                              : "A"}
                          </span>
                        </div>
                        <div className="d-flex flex-column gap-1">
                          <div className="header_text__16">
                            {getValue(user, `first_name`, "")}{" "}
                            {getValue(user, `middle_name`, "")}{" "}
                            {getValue(user, `last_name`, "")}
                          </div>
                          <div className="small_text__14">
                            {getValue(user, `email`, "")}
                          </div>
                        </div>
                      </div>
                      <button
                        className="deeptravel-button deeptravel-button--primary deeptravel-button--header-buttons w-100 mt-4"
                        onClick={handleLogout}
                      >
                        Sign out
                      </button>
                    </DropdownItem>
                  </div>
                  {/* <DropdownItem divider /> */}

                  {/* <DropdownItem className="pb-0">
                    <div className="text-center user-holder-button"></div>
                  </DropdownItem> */}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
        )}
      </div>
    </header>
  );
}
