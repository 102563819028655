import { NavLink } from "react-router-dom";
import LoginPageLyout from "@layouts/login";
interface SentEmailPageProps {}

const SentEmail: React.FunctionComponent<SentEmailPageProps> = (props) => {
  return (
    <LoginPageLyout>
      <div className="form-wrapper flex-1 d-flex position-relative justify-content-center justify-content-lg-end">
        {/* <a href="#0" className="logo position-absolute">
					<img 
			
					// src="/images/demo.svg"
					className="img-fluid" 
					/>
				</a> */}
        <div className="form-wrapper__form sent-mail-form-content">
          {/* m-auto  */}
          <h2 className="form-wrapper__form-common-title form-wrapper__form-common-title--email">
            Email Sent
          </h2>
          <p className="form-wrapper__form-text-description">
            Check your inbox for instructions from us on how to reset your
            password.
          </p>
          <NavLink to={`/login`}>
            <div className="form-cta-button">
              <button className="form-cta-button__primary-btn form-cta-button__primary-btn--reset-btn">
                OK
              </button>
            </div>
          </NavLink>
        </div>
      </div>
    </LoginPageLyout>
  );
};

export default SentEmail;
