import React, { useEffect, useState } from "react";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import ExpenseSvgComponent from "@assets/svg-components/sidebar/expense";
import ReportsSvgComponent from "@assets/svg-components/sidebar/reports";
import TripsSvgComponent from "@assets/svg-components/dashboard/trips";
import ScheduleModal from "./scheduleModal";
import { getValue } from "@utils/lodash";
import "./index.scss";
import { getShowAndHideColumnsList } from "@services/analytics.service";
import { useNavigate } from "react-router-dom";
import NoAccessPage from "@components/common/NoAccess";
function Analytics(props: any) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    // setIsOpen(!isOpen);
    navigate(`/admin/analytics/create`);
  };
  const reportTypes = [
    { value: "EXPENSE_DETAILS", label: "Expense Details", name: "expense" },
    {
      value: "EXPENSE_REPORTS_DETAILS",
      label: "Expense Report Details",
      name: "expense_report",
    },
    { value: "TRIP_DETAILS", label: "Trip Details", name: "trip" },
    { value: "FLIGHT_DETAILS", label: "Flight Details", name: "flight" },
    { value: "HOTEL_DETAILS", label: "Hotel Details", name: "hotel" },
    { value: "CAR_DETAILS", label: "Car Details", name: "car" },
    { value: "TRAIN_DETAILS", label: "Train Details", name: "train" },
    { value: "BUS_DETAILS", label: "Bus Details", name: "bus" },
  ];
  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      {props.permissionAPITriggered &&
      !getValue(props, `subTabPermissionList`, []).includes(
        "admin_analytics"
      ) ? (
        <NoAccessPage />
      ) : (
        <>
          <div className="dashboard-wrapper__header d-flex align-items-center justify-content-between">
            <h4 className="dashbaord-wrapper__header-title">Analytics</h4>
          </div>
          <div className="analytics_section">
            <div className="m-5 analytics_container">
              <div>
                <div className="d-flex align-items-center mb-2">
                  <ExpenseSvgComponent />
                  <h6 className="header_text__16 ms-2">Reports</h6>
                </div>
                <p className="small_text__13">
                  Know how much you've spent on business expenses by running
                  analytics based on various parameters
                </p>
              </div>
              <div className="d-flex flex-wrap mt-4">
                {reportTypes.map((item: object) => {
                  return (
                    <div
                      className="d-flex align-items-center mb-3 cursor-pointer analytics_item"
                      onClick={() =>
                        navigate(
                          `/admin/analytics/${getValue(
                            item,
                            `value`,
                            ""
                          )}/${getValue(item, `name`, "")}/list`
                        )
                      }
                    >
                      <ExpenseSvgComponent size={20} color={"gray"} />
                      <p className="small_text__14  ms-3">
                        {getValue(item, `label`, "")}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <ScheduleModal isOpen={isOpen} handleModal={handleModal} />
        </>
      )}
    </DashboardLayout>
  );
}

export default Analytics;
