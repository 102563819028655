import Loader from "@components/common/Loader";
import DashboardLayout from "@layouts/withoutNaviagtion/withoutNavigationLayout";
import { authSSOLogin } from "@services/auth.service";
import { getAllOrganisationByToken } from "@services/organisation.service";
import { getValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

function SSOLoginPage(props: any) {
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const code = searchParams.get("token")
    if (code) {
      handleSSOLogin(code);
    }
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const handleSSOLogin = async (code: string) => {
    try {
      let resp = await authSSOLogin({ sso_token: code });
      if (resp) {
        localStorage.setItem("view", "client");
        localStorage.setItem(
          "accessToken",
          getValue(resp, `data.accessToken`, "")
        );
        localStorage.setItem(
          "refreshToken",
          getValue(resp, `data.refreshToken`, "")
        );
        localStorage.setItem(
          "user",
          JSON.stringify(getValue(resp, `data.user`, ""))
        );
        if (getValue(resp, `data.accessToken`, "")) {
          getData(getValue(resp, `data.accessToken`, ""));
        }
        window.location.href = `/`;
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getData = async (token: string) => {
    try {
      let resp = await getAllOrganisationByToken(token);
      if (resp) {
        if (
          getValue(resp, `data.organisations[${0}].organisation_logo.url`, "")
        ) {
          localStorage.setItem(
            "organisation_logo",
            getValue(resp, `data.organisations[${0}].organisation_logo.url`, "")
          );
        }
      }
    } catch (error) {}
  };

  return <DashboardLayout>{isLoading ? <Loader /> : null}</DashboardLayout>;
}

export default SSOLoginPage;
