import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import React, { useEffect, useRef, useState } from "react";
import "./dashboard.scss";
import InfoSection from "./components/info";
import TaskSection from "./components/tasksection";
import { QueryRequestHelper } from "@common/query-request-helper";
import { dashboardUsereSummary } from "@services/dashboard.service";
import { getValue } from "@utils/lodash";
import moment from "moment";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "@components/common/Loader";
import DashboardQuickAddSection from "./components/quick-add";
import NoAccessPage from "@components/common/NoAccess";
import { FileText, HandCoins, Plus, TicketsPlane } from "lucide-react";

function ClientDashboard(props: any) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const [isLoading, setIsLoading] = React.useState(true);
  const [info, setInfo] = React.useState<[]>([]);
  const [tripInfo, setTripInfo] = useState({});
  const [expenseCategoryInfo, setExpenseCategoryInfo] = useState({});
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (Object.keys(urlParams).length === 0 && props.permissionAPITriggered) {
      const currentMonth = moment().startOf("month");
      const firstDay: any = currentMonth.format("YYYY-MM-DD");
      const lastDay: any = currentMonth.endOf("month").format("YYYY-MM-DD");
      setFirstDay(firstDay);
      setLastDay(lastDay);
      getData();
    }
  }, [props.permissionAPITriggered]);

  useEffect(() => {
    if (Object.keys(urlParams).length > 0 && props.permissionAPITriggered) {
      const currentMonth = moment().startOf("month");
      const firstDay: any = currentMonth.format("YYYY-MM-DD");
      const lastDay: any = currentMonth.endOf("month").format("YYYY-MM-DD");
      let from = urlParams.from_date
        ? new Date(urlParams.from_date)
        : new Date(firstDay);
      let to = urlParams.to_date
        ? new Date(urlParams.to_date)
        : new Date(lastDay);
      setFirstDay(from);
      setLastDay(to);
      getData();
    }
  }, [window.location.href, props.permissionAPITriggered]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const monthLabels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [dashboardInfo, setDashboardInfo] = useState({});
  const getData = async () => {
    const currentMonth = moment().startOf("month");
    const firstDay = currentMonth.format("YYYY-MM-DD");
    const lastDay = currentMonth.endOf("month").format("YYYY-MM-DD");

    let from = urlParams.from_date ? new Date(urlParams.from_date) : firstDay;
    let to = urlParams.to_date ? new Date(urlParams.to_date) : lastDay;
    try {
      setIsLoading(true);
      let payload = {
        // from_date: convertDate(from),
        // to_date: convertDate(to),
      };
      let queryRequest = QueryRequestHelper(payload);
      let userResponse = await dashboardUsereSummary(queryRequest);
      if (userResponse) {
        setDashboardInfo(getValue(userResponse, `data`, {}));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                              Date Routes                                   */
  /* -------------------------------------------------------------------------- */
  const [from_date, setFirstDay] = React.useState(new Date());
  const [to_date, setLastDay] = React.useState(new Date());

  const handleDate = (date: any) => {
    setFirstDay(date[0]);
    setLastDay(date[1]);
  };
  const handleSubmitDate = () => {
    let first = moment(from_date).format("YYYY-MM-DD");
    let last = moment(to_date).format("YYYY-MM-DD");
    if (first && last && first !== "Invalid date" && last !== "Invalid date") {
      let payload: any = {
        from_date: first,
        to_date: last,
      };
      if (payload.from_date && payload.to_date) {
        let queryRequest = QueryRequestHelper(payload);
        navigate(`${window.location.pathname}?${queryRequest}`);
      } else {
        toast.error("Start Date and End Date Required");
      }
    } else {
      toast.error("Please select date");
    }
  };

  function openEmailPopup() {
    const email = "mailto:support@deeptravel.in";
    const options =
      "toolbar=no,scrollbars=yes,resizable=yes,width=500,height=500";
    window.open(email, "Email Popup", options);
  }

  const handleNavigate = (url: string) => {
    navigate(url);
  };

  const buttons = [
    {
      heading: "Create Expense",
      icon: <HandCoins />,
      link: "/client/expenses",
    },
    {
      heading: "Create Report",
      icon: <FileText />,
      link: "/client/reports",
    },
    {
      heading: "Create Trip",
      icon: <TicketsPlane />,
      link: "/client/trips/add",
    },
  ];

  const [scrolling, setScrolling] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null); // Use HTMLDivElement instead of HTMLElement

  useEffect(() => {
    const handleScroll = () => {
      const container = scrollContainerRef.current;

      if (container) {
        // Check if the container exists and its scroll position
        if (container.scrollTop > 0) {
          setScrolling(true);
        } else {
          setScrolling(false);
        }
      }
    };

    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  console.log("scroll", scrolling);

  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      {!isLoading &&
      !getValue(props, `subTabPermissionList`, []).includes("dashboard") ? (
        <NoAccessPage />
      ) : (
        <>
          <div></div>
          <div
            className={`dashboard-wrapper__header d-flex align-items-center justify-content-between ${
              scrolling ? "db_header_scroll" : ""
            }`}
          >
            <h4 className="dashbaord-wrapper__header-title">Dashboard</h4>
            <div>
              <h3 className="dashboard-compliance__date dashboard-compliance__date--compliance overview-wrapper__header-date d-flex align-items-center">
                <div className="d-flex">
                  {/* <DatePicker
                selectsRange={true}
                startDate={from_date}
                endDate={to_date}
                className="react-date-picker__inputGroup__input_dashboard"
                onChange={(update: any) => {
                  handleDate(update);
                }}
                dateFormat="d MMM yyyy"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
              <button
                className="dashboard-wrapper__apply-btn"
                onClick={handleSubmitDate}
              >
                Apply
              </button> */}
                </div>
              </h3>
            </div>
          </div>

          <div
            className="dashboard-new-section-container p-0"
            ref={scrollContainerRef}
          >
            {(!isLoading || props.permissionAPITriggered) && (
              <div className="dashboard-banner py-5">
                <div className="dashboard-banner-wrapper py-4">
                  <div>
                    <h6 className="header_text__22">
                      2 hours of <span className="text-danger">FREE</span> Rapid
                      Setup Assistance! 2 hours of Complimentary Rapid Setup
                      Support!
                    </h6>
                    <p className="desc_text__14 mt-2">
                      Our team of product consultants will assist you in
                      customizing Expense to align with your specific needs.
                    </p>
                    <button
                      className="deeptravel-button deeptravel-button--header-buttons deeptravel-button--secondary mt-4"
                      onClick={openEmailPopup}
                    >
                      Contact Support
                    </button>
                    <div className="mt-4">
                      <h6 className="header_text__22">Quick Add</h6>

                      <div className="d-flex align-items-center gap-3 mt-3">
                        {buttons.map((item, index) => (
                          <button
                            className="banner-button"
                            onClick={() => handleNavigate(item.link)}
                            key={index}
                          >
                            {item.icon}
                            {item.heading}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                  <img
                    src="/images/dashboard/banner.png"
                    alt="banner"
                    className="db-banner"
                  />
                </div>
              </div>
            )}
            <div className="dashbaord-wrapper_section_container">
              {isLoading || !props.permissionAPITriggered ? (
                <Loader />
              ) : (
                <div className="">
                  {/* <DashboardQuickAddSection /> */}
                  <div className=" mt-5 mb-3"></div>
                  <TaskSection dashboardInfo={dashboardInfo} />
                  {/* <ReportsummarySection /> */}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
}

export default ClientDashboard;
