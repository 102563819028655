import InputField from "@common/InputRuleFeild";
import SingleSelectSearchDropdown from "@components/common/CustomSearchableDropdown/SingleSelectSearchDropdown";
import { getValue } from "@utils/lodash";
import { Modal } from "reactstrap";

function MileageRateAddPopup(props: any) {
  const {
    isOpen,
    handleModal,
    request,
    setRequest,
    handleSubmit,
    submitLoading,
    editId,
    setEditId,
  } = props;

  return (
    <Modal isOpen={isOpen} toggle={handleModal}>
      <div className="m-2">
        <div className="mt-2 px-4 d-flex justify-content-between align-items-center">
          <h6 className="header_text">New Mileage Rate</h6>
          <div className="close-image_container ms-3" onClick={handleModal}>
            <img className="close_image" src={`/images/close-black.svg`} />
          </div>
        </div>
        <hr />
      </div>

      <div className="px-4 mb-4 general-limits-form-holder">
      {/* mt-3 */}
        <div className="">
          <div className="user-details-wrapper__form-group form-group">
            <label className="checkpoint-active-details__label form-error-label">
              Start Date <span>*</span>{" "}
            </label>
            <InputField
              inputType="DATETYPE-DOB"
              placeholder="Enter Start Date"
              className="form-control release-date-wrapper__vendor-form-control--calendar"
              name="effective_date"
              id="effective_date"
              value={getValue(request, `effective_date`, "")}
              selected={getValue(request, `effective_date`, "")}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  effective_date: e,
                })
              }
              label="Start Date"
              // minDate={new Date()}
            />
          </div>
        </div>

        <div>
          <h6 className=" desc_text">Mileage Rate </h6>
          <div className="">
            <InputField
              inputType="NUMBER"
              name="mileage_rate"
              placeholder="Mileage Rate"
              value={getValue(request, `mileage_rate`, "")}
              onChange={(e: any) =>
                setRequest({ ...request, mileage_rate: e.target.value })
              }
            />
          </div>
        </div>

        <div className="">
          <SingleSelectSearchDropdown
            placeholder={`Choose Vehicle`}
            onChange={(e: any) =>
              props.setRequest({
                ...props.request,
                vehicle: getValue(e, `value`, ""),
              })
            }
            label={`label`}
            //  validator={
            //    getValue(item, `is_mandatory`, false) && simpleValidator
            //  }
            data={props.vehicleList}
            value={getValue(request, `vehicle`, "")}
            selectKey={"value"}
            name={"Vehicle"}
            //   mandatory
            showLabel
            // disabled
          />
        </div>
        <div className="add-popup-wrapper__cta-wrapper">
          <hr />
          <button
            className="add-popup-wrapper__cta-btn add-popup-wrapper__cta-btn--secondary"
            onClick={() => {
              setRequest({
                ...request,
                effective_date: "",
                mileage_rate: 0,
                threshold_mileage_rate: 0,
                vehicle: "",
              });
              setEditId("");
              handleModal();
            }}
          >
            Cancel
          </button>
          <button
            className="add-popup-wrapper__cta-btn add-popup-wrapper__cta-btn--primary"
            onClick={() => handleSubmit()}
            disabled={submitLoading}
          >
            {submitLoading ? "Please wait..." : editId ? "Update" : "Save"}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default MileageRateAddPopup;
