import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllCustomers = (queryRequest: string) =>
  get(`${config.API_URL}/customer?${queryRequest}`);

export const getSpecificCustomer = (id: string) =>
  get(`${config.API_URL}/customer/${id}`);

export const createCustomer = (payload: object) =>
  post(`${config.API_URL}/customer`, payload);

export const updateCustomer = (id: string, payload: object) =>
  patch(`${config.API_URL}/customer/${id}`, payload);

export const deleteCustomer = (id: string) =>
  Delete(`${config.API_URL}/customer/${id}`);

export const updateCustomerBulk = (payload: object) =>
  patch(`${config.API_URL}/customer/bulk/active-inactive`, payload);
