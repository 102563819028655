import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { QueryRequestHelper } from "@common/query-request-helper";
import {
  createCustomer,
  getSpecificCustomer,
  updateCustomer,
} from "@services/customers.service";
import { getValue } from "@utils/lodash";
import { toast } from "react-toastify";
import InputField from "@common/InputRuleFeild";
import SimpleReactValidator from "simple-react-validator";
import PromptModal from "@prompt/modal";
import _ from "lodash";
import { useCallbackPrompt } from "@prompt/useCallbackPrompt";
import { NavLink } from "react-router-dom";
import CustomerForm from "./customerForm";
import StickyHeader from "./StickyHeader";

function CustomerDetailsPage(props: any) {
  const navigate = useNavigate();
  const params = useParams();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());

  /* -------------------------------------------------------------------------- */
  /*                               UseState section                             */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [info, setInfo] = useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [request, setRequest] = useState({
    customer_type: "",
    salutation: "",
    first_name: "",
    last_name: "",
    company_name: "",
    contact_display_name: "",
    email: "",
    phone_no: "",
    mobile_no: "",
    website: "",
    currency: "",
    // contact_person: [
    //   {
    //     customer: 0,
    //     salutation: "",
    //     first_name: "",
    //     last_name: "",
    //     email: "",
    //     phone_no: "",
    //     mobile_no: "",
    //     is_active: true,
    //   },
    // ],
  });

  const [dirtyFields, setDirtyFields] = useState({
    customer_type: "",
    salutation: "",
    first_name: "",
    last_name: "",
    company_name: "",
    contact_display_name: "",
    email: "",
    phone_no: "",
    mobile_no: "",
    website: "",
    currency: "",
    // contact_person: [
    //   {
    //     customer: 0,
    //     salutation: "",
    //     first_name: "",
    //     last_name: "",
    //     email: "",
    //     phone_no: "",
    //     mobile_no: "",
    //     is_active: true,
    //   },
    // ],
  });
  const [types] = useState([
    { value: "BUSSINESS", label: "Bussiness" },
    { value: "INDIVIDUAL", label: "Individual" },
  ]);
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (getValue(params, `id`, "")) {
      getData();
    }
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                                 API section                                */
  /* -------------------------------------------------------------------------- */
  const getData = async () => {
    try {
      setIsLoading(true);
      let resp = await getSpecificCustomer(getValue(params, `id`, ""));
      if (resp) {
        setInfo(getValue(resp, `data`, {}));
        let obj = {
          company_name: getValue(resp, `data.company_name`, ""),
          customer_type: getValue(resp, `data.customer_type`, ""),
          salutation: getValue(resp, `data.salutation`, ""),
          first_name: getValue(resp, `data.first_name`, ""),
          last_name: getValue(resp, `data.last_name`, ""),
          contact_display_name: getValue(resp, `data.contact_display_name`, ""),
          email: getValue(resp, `data.email`, ""),
          phone_no: getValue(resp, `data.phone_no`, ""),
          mobile_no: getValue(resp, `data.mobile_no`, ""),
          website: getValue(resp, `data.website`, ""),
          currency: getValue(resp, `data.currency.id`, ""),
        };
        setRequest({ ...request, ...obj });
        setDirtyFields({ ...dirtyFields, ...obj });

        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        let payload = request;
        setSubmitLoading(true);
        if (getValue(params, `id`, "")) {
          let resp = await updateCustomer(getValue(params, `id`, ""), payload);
          if (resp) {
            toast.success(getValue(resp, `message`, ""));
            setSubmitLoading(false);
            // getData();
            navigate(`/admin/modules/customers`);
          } else {
            setSubmitLoading(false);
          }
        } else {
          let resp = await createCustomer(payload);
          if (resp) {
            toast.success(getValue(resp, `message`, ""));
            setSubmitLoading(false);
            // getData();
            navigate(`/admin/modules/customers`);
          } else {
            setSubmitLoading(false);
          }
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                              OnChange section                              */
  /* -------------------------------------------------------------------------- */
  const handleChangeText = (e: any) => {
    const { name, value } = e.target;
    setRequest({
      ...request,
      [name]: value,
    });
  };
  /* -------------------------------------------------------------------------- */
  /*                                Prompt Section                              */
  /* -------------------------------------------------------------------------- */
  const [isChanged, setIsChanged] = useState(false);
  useEffect(() => {
    setIsChanged(
      _.isEqualWith(request, dirtyFields, (a: any, b: any) => {
        // if both are null or equal to an empty string then they are equal
        if ((_.isNull(a) || a === "") && (_.isNull(b) || b === "")) return true;
      })
    );
  });
  const [
    showPrompt,
    confirmNavigation,
    cancelNavigation,
  ]: any = useCallbackPrompt(!isChanged);
  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      <PromptModal
        showDialog={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <StickyHeader
        isChanged={isChanged}
        handleSubmit={handleSubmit}
        submitLoading={submitLoading}
        id={params.id}
        route={`/admin/modules/customers`}
        title={`${getValue(params, `id`, "") ? "Update" : "New"}${" "}Customer`}
      />
      <CustomerForm
        request={request}
        setRequest={setRequest}
        simpleValidator={simpleValidator}
        handleChangeText={handleChangeText}
        types={types}
      />
    </DashboardLayout>
  );
}

export default CustomerDetailsPage;
