import { getValue } from "@utils/lodash";
import TripsArrowSvgComponent from "@assets/svg-components/trips/tripsarrow";
import Notfound from "@components/Not-Found";
import { convertCurrentTime } from "@common/date-helpers";
import { convertCurrentDate } from "@common/date-helpers";
import LocationSvgComponent from "@assets/svg-components/trips/location";
import CarSvgComponent from "@assets/svg-components/trips/car";
import { formatText, sortJSONObjectArray } from "@common/text-helpers";
import NotfoundSmall from "@components/Not-Found/not-found-small";

function TripsCarItinary(props: any) {
  return (
    <div className="itenary_main_section">
      <div className="itenary_main_section_cards_holder">
        {getValue(props, `list.length`, 0) > 0 ? (
          sortJSONObjectArray(getValue(props, `list`, []), "order").map(
            (item: object, index: number) => {
              return (
                <div
                  className="flight_trip_itenary border border-radius__8 position-relative car_trip_itenary"
                  key={index}
                >
                  <div className="d-flex align-items-center justify-content-center flight_trip_itenary_tripname_wrapper">
                    <div className="itenary-flight-icon-wrapper">
                      <CarSvgComponent size={24} />
                    </div>

                    <div className="d-flex align-items-center justify-content-center">
                      <div className="d-flex align-items-center">
                        <div className="trip_itenary_pickup text-right">
                          <p className="text_danger pickup-location-name">
                            {getValue(item, `car.pick_up_location.name`, "")}
                          </p>
                          <div className="d-flex align-items-center justify-content-end">
                            <h6 className="header_text__16 pickup-location-time">
                              {convertCurrentDate(
                                getValue(item, `car.pick_up_date`, "")
                              )}
                              ,
                              {convertCurrentTime(
                                getValue(item, `car.pick_up_time`, "")
                              )}
                            </h6>
                          </div>
                          <p className="small_text__12 trip_itenary_pickup_label ">
                            Pick-Up
                          </p>
                        </div>

                        <div className="flight_trip_itenary_centerarrow_holder">
                          <TripsArrowSvgComponent />
                        </div>

                        <div className="trip_itenary_dropoff text-left">
                          <p className="text_danger pickup-location-name">
                            {getValue(item, `car.drop_off_location.name`, "")}
                          </p>
                          <div>
                            <div className="d-flex align-items-center justify-content-start">
                              <h6 className="header_text__16 pickup-location-time">
                                {convertCurrentDate(
                                  getValue(item, `car.drop_off_date`, "")
                                )}
                                ,
                                {convertCurrentTime(
                                  getValue(item, `car.drop_off_time`, "")
                                )}
                              </h6>
                            </div>
                            <p className="small_text__12 trip_itenary_pickup_label">
                              Drop-Off
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="dashed-border"></div>

                  <div className="d-flex align-items-center justify-content-center flight_trip_itenary_flight_contentdetails_wrapper">
                    <div className="flight_trip_itenary_flight_contentdetails">
                      <h6 className="header_text__14">
                        {formatText(getValue(item, `car.car_type`, ""))}
                      </h6>
                      <p className="small_text flight_itenary_details_label">
                        Car Type
                      </p>
                    </div>
                    {/* <div className="vr mx-4"></div> */}
                    <div className="flight_trip_itenary_flight_contentdetails">
                      <h6 className="header_text__14">
                        {getValue(item, `car.is_driver_needed`, "")
                          ? "Required"
                          : "Not Required"}
                      </h6>
                      <p className="small_text flight_itenary_details_label">
                        Driver
                      </p>
                    </div>
                  </div>
                  {getValue(item, `description`, "") && (
                    <div
                      style={{ background: "#E8F0FE" }}
                      className="p-2 mt-2 border-radius__8"
                    >
                      <p style={{ color: "#204891" }} className="small_text">
                        {getValue(item, `description`, "")}
                      </p>
                    </div>
                  )}
                </div>
              );
            }
          )
        ) : (
          <NotfoundSmall />
        )}
      </div>
    </div>
  );
}

export default TripsCarItinary;
