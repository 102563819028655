import React from "react";
import "./styles.scss";
import { getValue } from "@utils/lodash";

function AddSvgComponent(props) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        // height="48"
        viewBox="0 -960 960 960"
        // width="48"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "18"
        }
      >
        <path
          d="M433-183v-250H183v-94h250v-250h94v250h250v94H527v250h-94Z"
          fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "#3c4043"
          }
        />
      </svg>
    </>
  );
}

export default AddSvgComponent;
