import Analytics from "@pages/Admin/Analytics";
import CreateAnalytics from "@pages/Admin/Analytics/Create";
import AnalyticsDetailList from "@pages/Admin/Analytics/detail";

export const AdminAnalyticsRoutes = [
  { path: "/admin/analytics", name: "admin_analytics", component: Analytics },
  {
    path: "/admin/analytics/:type/:name/create",
    name: "admin_analytics",
    component: CreateAnalytics,
  },
  {
    path: "/admin/analytics/:type/:name/list",
    name: "admin_analytics",
    component: AnalyticsDetailList,
  },
];
