import InputField from "@common/InputRuleFeild";
import { QueryRequestHelper } from "@common/query-request-helper";
import SingleSelectSearchDropdown from "@components/common/CustomSearchableDropdown/SingleSelectSearchDropdown";
import { getAllCurrency } from "@services/currency.service";
import {
  getlocationManagementCities,
  getlocationManagementCountries,
} from "@services/list-management.service";
import { getValue } from "@utils/lodash";
import { useEffect, useRef, useState } from "react";
import { Modal } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";

function PerDiemRateLocationPopup(props: any) {
  const {
    isOpen,
    handleModal,
    request,
    setRequest,
    handleSubmit,
    submitLoading,
    editId,
    setEditId,
    isPerDiemLocationDefaultRate,
  } = props;

  const [countryList, setCountryList] = useState([]);
  const [citiesList, setCityList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);

  useEffect(() => {
    getCountriesList();
    getCurrencyList();
  }, [props.permissionAPITriggered]);

  useEffect(() => {
    if (request.country) {
      getCitiesList(request.country);
    }
    getCurrencyList();
  }, [request.country, props.permissionAPITriggered]);

  const simpleValidator = useRef(new SimpleReactValidator());

  const getCountriesList = async () => {
    try {
      let resp = await getlocationManagementCountries("");
      if (resp) {
        setCountryList(
          getValue(resp, `data.countries`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `name`, ""),
            label: getValue(item, `name`, ""),
          }))
        );
      } else {
      }
    } catch (error) {}
  };

  const getCitiesList = async (countryId: any) => {
    let payload = {
      countryId: countryId,
    };
    let queryRequest = QueryRequestHelper(payload);
    try {
      let resp = await getlocationManagementCities(queryRequest);
      if (resp) {
        setCityList(
          getValue(resp, `data.cities`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `name`, ""),
            label: getValue(item, `name`, ""),
          }))
        );
      } else {
      }
    } catch (error) {}
  };

  const [isLoading, setIsLoading] = useState(true);
  const [currencyData, setCurrencyData] = useState([]);

  const getCurrencyList = async () => {
    try {
      setIsLoading(true);
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      let payload: any = {
        page_no: params.page_no ? parseInt(params.page_no) : 1,
        search_text: params.search_text,
        // status: params.status,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllCurrency(queryRequest);
      if (resp) {
        setCurrencyData(
          getValue(resp, `data.data`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `currency_code`, ""),
            label: getValue(item, `currency_code`, ""),
          }))
        );
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const handleChangeSelect = (e: any, type: string) => {
    if (type === "country") {
      setRequest({
        ...request,
        country: getValue(e, `id`, ""),
        city: null,
      });
    }
    if (type === "city") {
      setRequest({
        ...request,
        city: getValue(e, `id`, ""),
      });
    }

    if (type === "currency") {
      setRequest({
        ...request,
        currency: getValue(e, `id`, ""),
      });
    }
  };

  const handleChangeText = (e: any) => {
    const { name, value } = e.target;
    setRequest({
      ...request,
      [name]: value,
    });
  };

  console.log(request, "---76---");
  console.log(isPerDiemLocationDefaultRate, "---143---");
  return (
    <Modal isOpen={isOpen} toggle={handleModal}>
      <div className="m-2">
        <div className="mt-2 px-4 d-flex justify-content-between align-items-center">
          <h6 className="header_text">{editId ? "Edit" : "Create"} Per Diem Rates</h6>
          <div className="close-image_container ms-3" onClick={handleModal}>
            <img className="close_image" src={`/images/close-black.svg`} />
          </div>
        </div>
        <hr />
      </div>

      <div className="px-4 mb-4 general-limits-form-holder per-diem-popup-wrap">
        {/* mt-3 */}
        {!isPerDiemLocationDefaultRate ? (
          <>
            <div className="user-details-wrapper__form-group form-group mb-3">
              <label className="checkpoint-active-details__label form-label-error">
                Country <span>*</span>
              </label>
              <InputField
                inputType="INPUT_REACT_SELECT"
                placeholder="Choose Country"
                className="form-control w-100"
                name="country"
                id="country"
                options={countryList}
                value={
                  getValue(countryList, `length`, 0) > 0
                    ? countryList.filter(
                        (item: object) =>
                          getValue(item, `id`, "") ==
                          getValue(request, `country`, "")
                      )[0]
                    : {}
                }
                onChange={(e: any) => handleChangeSelect(e, "country")}
                label="Country"
                isClear
                validator={simpleValidator}

              />
            </div>
            <div className="user-details-wrapper__form-group form-group">
              <label className="checkpoint-active-details__label form-label-error">
                City <span>*</span>
              </label>
              <InputField
                inputType="INPUT_REACT_SELECT"
                placeholder="Choose City"
                className="form-control w-100"
                name="city"
                id="city"
                label="City"
                options={citiesList}
                value={citiesList.filter(
                  (item: object) =>
                    getValue(item, `id`, "") == getValue(request, `city`, "")
                )}
                onChange={(e: any) => handleChangeSelect(e, "city")}
                isClear
              />

              {/* <SingleSelectSearchDropdown
                label={"label"}
                name="city"
                value={citiesList.filter(
                  (item: object) =>
                    getValue(item, `id`, "") == getValue(request, `city`, "")
                )}
                onChange={(e: any) =>
                  setRequest({
                    ...request,
                    city: getValue(e, `id`, ""),
                  })
                }
                data={citiesList}
                placeholder={"Choose city"}
                selectKey={"value"}
                width={"358px"}
              /> */}
            </div>{" "}
          </>
        ) : null}

        <div>
          <h6 className=" desc_text">Display Name </h6>
          <div className="">
            <InputField
              inputType="TEXT"
              name="display_name"
              placeholder="Enter Display Name"
              // value={getValue(request, `mileage_rate`, "") ? getValue(request, `mileage_rate`, "") : "This Field is Auto genrated" }
              value={
                isPerDiemLocationDefaultRate
                  ? "Default Rate"
                  : "This Field is Auto genrated"
              }
              disabled
            />
          </div>

          <div className="user-details-wrapper__form-group form-group">
            <label className="checkpoint-active-details__label form-error-label">
              Effective From <span>*</span>{" "}
            </label>
            <InputField
              inputType="DATETYPE-DOB"
              placeholder="Enter Effective Date"
              className="form-control release-date-wrapper__vendor-form-control--calendar"
              name="effective_date"
              id="effective_date"
              value={getValue(request, `effective_date`, "")}
              selected={getValue(request, `effective_date`, "")}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  effective_date: e,
                })
              }
              label="Start Date"
            />
          </div>

          <div>
            <label htmlFor="">Rate</label>
            <div className="input-group currency-rate-input-wrap d-flex">
              <div style={{width: "40%"}}>
                <InputField
                  inputType="INPUT_REACT_SELECT"
                  placeholder="Choose Currency"
                  className="form-control w-100"
                  name="currency"
                  id="currency"
                  options={currencyData}
                  value={
                    getValue(currencyData, `length`, 0) > 0
                      ? currencyData.filter(
                          (item: object) =>
                            getValue(item, `id`, "") ==
                            getValue(request, `currency`, "")
                        )[0]
                      : {}
                  }
                  onChange={(e: any) => handleChangeSelect(e, "currency")}
                  label="currency"
                  isClear
                />
              </div>
              <div style={{width: "60%"}} className="cr-input-wrap">

              <InputField
                inputType="TEXT"
                placeholder="Enter Rate"
                className="form-control w-100"
                name="amount"
                id="amount"
                label="Rate"
                value={getValue(request, `amount`, 0)}
                onChange={(e: any) => handleChangeText(e)}
              />
              </div>
            </div>
          </div>
        </div>

        <div className="add-popup-wrapper__cta-wrapper">
          <hr />
          <button
            className="add-popup-wrapper__cta-btn add-popup-wrapper__cta-btn--secondary"
            onClick={() => {
              setRequest({
                ...request,
                name: "",
                amount: "",
                currency: "",
                is_default: false,
                country: "",
                city: "",
                effective_date: "",
              });
              setEditId("");
              handleModal();
            }}
          >
            Cancel
          </button>
          <button
            className="add-popup-wrapper__cta-btn add-popup-wrapper__cta-btn--primary"
            onClick={() => handleSubmit()}
            disabled={submitLoading}
          >
            {submitLoading ? "Please wait..." : editId ? "Update" : "Save"}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default PerDiemRateLocationPopup;
