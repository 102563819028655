import React from "react";
import TripsPopup from "../../Dropdown/trip-popup";
import { getValue } from "@utils/lodash";
import { findValueFromOptions } from "@common/text-helpers";
import { timePreferences } from "@common/constants";
import { formHandleSelectArrayChangeByKey } from "@common/handleChange";
import CloseSmallSvgComponent from "@assets/svg-components/close-small";
import ArrowDownSvgComponent from "@assets/svg-components/arrow-down";

function TripFlightTimePreference(props: any) {
  const { item, index, validator, label, setItenaries, preference_key } = props;
  return (
    <div>
      <TripsPopup
        header={
          <div
            className={`d-flex align-items-center ${
              validator &&
              validator.current.message(
                "Tim Preference",
                getValue(item, `${preference_key}`, ""),
                "required"
              )
                ? "border_red"
                : "border"
            } border-radius__8 cursor-pointer flight_time_section justify-content-between`}
          >
            <div className="d-flex align-items-center">
              {/* <p className="small_text__14">{label}</p>
              <div className="vr mx-2"></div> */}
              <p className="small_text__14">
                {getValue(item, `${preference_key}`, "")
                  ? findValueFromOptions(
                      timePreferences,
                      getValue(item, `${preference_key}`, "")
                    )
                  : label}
              </p>
            </div>
            {getValue(item, `${preference_key}`, "") ? (
              <div
                className="mx-1"
                onClick={() =>
                  formHandleSelectArrayChangeByKey(
                    index,
                    "",
                    preference_key,
                    setItenaries
                  )
                }
              >
                <CloseSmallSvgComponent />
              </div>
            ) : (
              <ArrowDownSvgComponent color={"gray"} />
            )}
          </div>
        }
        options={timePreferences}
        value={getValue(item, `${preference_key}`, "")}
        onChange={(e: any) =>
          formHandleSelectArrayChangeByKey(
            index,
            { value: e },
            preference_key,
            setItenaries
          )
        }
        label={label}
      />
    </div>
  );
}

export default TripFlightTimePreference;
