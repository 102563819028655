import EditSvgComponent from "@assets/svg-components/edit";
import Switch from "../switch";
import { getValue } from "@utils/lodash";
import DeleteSvgComponent from "@assets/svg-components/delete";

export default function AddLocation(props: any) {
  return (
    <div
      className={`list-managemnet-wrapper__country-items d-flex align-items-center justify-content-between ${
        getValue(props, `selected`, "") == getValue(props, `id`, "")
          ? "active"
          : ""
      }`}
      onClick={() => props.onChange(getValue(props, `id`, ""))}
    >
      <h6 className="list-managemnet-wrapper__country-items-title">
        {props.name}
        {getValue(props, `category_of_law.text`, "") && (
          <span style={{ color: "#d7242e" }}>
            {getValue(props, `category_of_law.text`, "")}
          </span>
        )}
      </h6>
      <div className="list-managemnet-wrapper__country-items-alter d-flex align-items-center">
        {/* <img
          src="/images/list-management/country/edit-icon.svg"
          width={24}
          height={24}
          className="img-fluid"
          onClick={() => props.handleEdit(getValue(props, `item`, ""))}
        /> */}

        <div onClick={() => props.handleEdit(getValue(props, `item`, ""))}>
          <EditSvgComponent />
        </div>

        {/* <div onClick={() => props.handleDelete(getValue(props, `item`, ""))}>
          <DeleteSvgComponent />
        </div> */}

        {/* <img
          src="/images/list-management/country/delete-icon.svg"
          className="img-fluid"
          width={24}
          height={24}
          onClick={() => props.handleDelete(getValue(props, `item`, ""))}
        /> */}
        <div className="mx-2">
          <Switch
            is_active={getValue(props, `is_active`, "")}
            onChange={props.handleChangeStatus}
            id={getValue(props, `id`, "")}
          />
        </div>
      </div>
    </div>
  );
}
