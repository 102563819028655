import {
  convertCurrentDate,
  convertTimeHoursMins,
  convertTimeString,
} from "@common/date-helpers";
import { formatText } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import {
  Banknote,
  CalendarDays,
  CarFront,
  Clock,
  HandCoins,
  Hourglass,
  MapPin,
  Navigation,
} from "lucide-react";
import React from "react";

function ExpenseDetailSection(props: any) {
  const { formInfo } = props;
  return (
    <div>
      <h6 className="expense-more-details mb-3">Other info</h6>
      <div className="expense-detail-wrapper_report_infosection expense-detail-wrapper_report_infosection-grid border rounded-3 p-3">
        <div className="expense-detail-wrapper_report d-flex align-items-center gap-2">
          <CalendarDays size={18} />
          <div className="">
            <p className="desc_text">Expense Date</p>
            <h5 className="header_text">
              {convertCurrentDate(getValue(formInfo, `expense_date`, ""))}
            </h5>
          </div>
          {/* <div className="vertical_line"></div> */}
        </div>
        <div className="expense-detail-wrapper_report d-flex align-items-center gap-2">
          <HandCoins size={18} />
          <div className="">
            <p className="desc_text">Type</p>
            <h5 className="header_text">
              {getValue(formInfo, `expense_type.expense_type_name`, "")}
            </h5>
          </div>
          {/* <div className="vertical_line"></div> */}
        </div>
        <div className="expense-detail-wrapper_report d-flex align-items-center gap-2">
          <Banknote size={18} />
          <div className="">
            <p className="desc_text">Paid Through</p>
            <h5 className="header_text">
              {" "}
              {formatText(getValue(formInfo, `paid_through.account_name`, ""))}
            </h5>
          </div>
          {/* <div className="vertical_line"></div> */}
        </div>
        {getValue(formInfo, `expense_entry_mileage.distance`, "") && (
          <>
            <div className="expense-detail-wrapper_report d-flex align-items-center gap-2">
              <Navigation size={18} />
              <div className="">
                <p className="desc_text">Distance</p>
                <h5 className="header_text">
                  {getValue(formInfo, `expense_entry_mileage.distance`, "")}{" "}
                  {getValue(formInfo, `expense_entry_mileage.mileage_unit`, "")}
                </h5>
              </div>
              {/* <div className="vertical_line"></div> */}
            </div>
            <div className="expense-detail-wrapper_report d-flex align-items-center gap-2">
              <CarFront size={18} />
              <div className="">
                <p className="desc_text">Vehicle Type</p>
                <h5 className="header_text">
                  {getValue(
                    formInfo,
                    `expense_entry_mileage.vehicle.vehicle_name`,
                    ""
                  )}
                </h5>
              </div>
              {/* <div className="vertical_line"></div> */}
            </div>
          </>
        )}

        {getValue(formInfo, `expense_entry_per_diem.name`, "") && (
          <>
            <div className="expense-detail-wrapper_report d-flex align-items-center gap-2">
              <MapPin size={18} />
              <div className="">
                <p className="desc_text">Location</p>
                <h5 className="header_text">
                  {getValue(formInfo, `expense_entry_per_diem.name`, "")}{" "}
                </h5>
              </div>
              {/* <div className="vertical_line"></div> */}
            </div>

            <div className="expense-detail-wrapper_report d-flex align-items-center gap-2">
              <Clock size={18} />
              <div className="">
                <p className="desc_text">From Time</p>
                <h5 className="header_text">
                  {convertTimeHoursMins(
                    getValue(formInfo, `expense_entry_per_diem.start_time`, "")
                  )}
                </h5>
              </div>
            </div>
            <div className="expense-detail-wrapper_report d-flex align-items-center gap-2">
              <Clock size={18} />
              <div className="">
                <p className="desc_text">To Time</p>
                <h5 className="header_text">
                  {convertTimeHoursMins(
                    getValue(formInfo, `expense_entry_per_diem.end_time`, "")
                  )}
                </h5>
              </div>
            </div>

            <div className="expense-detail-wrapper_report d-flex align-items-center gap-2">
              <Hourglass size={18} />
              <div className="">
                <p className="desc_text">Duration</p>
                <h5 className="header_text">
                  {convertTimeString(
                    getValue(formInfo, `expense_entry_per_diem.duration`, "")
                  )}
                </h5>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ExpenseDetailSection;
