import { getValue } from "@utils/lodash";
import { createContext, useContext, useEffect, useState } from "react";

const StateContext = createContext();
export function GlobalStateProvider({ children }) {
  const [userInfo, setUserInfo] = useState({});
  useEffect(() => {
    let user =
      getValue(localStorage, `user`, "") &&
      JSON.parse(getValue(localStorage, `user`, {}));
      setUserInfo(user)
  }, []);
  return (
    <StateContext.Provider
      value={{
        userInfo,
      }}
    >
      {children}
    </StateContext.Provider>
  );
}

export function useGlobalContext() {
  return useContext(StateContext);
}
