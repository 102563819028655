import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const themeDestination = [
  {
    image: "/images/dashboard/trips/online/family-vacation.png",
    alt: "",
    category: "Family Destination",
    location: "30+ designation",
  },
  {
    image: "/images/dashboard/trips/online/friends-vacation.png",
    alt: "",
    category: "Friends/Group",
    location: "100+ designation",
  },
  {
    image: "/images/dashboard/trips/online/solo-vacation.png",
    alt: "",
    category: "Solo Destination",
    location: "100+ designation",
  },
  {
    image: "/images/dashboard/trips/online/adventure-vacation.png",
    alt: "",
    category: "Adventure Destination",
    location: "100+ designation",
  },
  {
    image: "/images/dashboard/trips/online/nature-vacation.png",
    alt: "",
    category: "Nature Destination",
    location: "100+ designation",
  },
  {
    image: "/images/dashboard/trips/online/nature-vacation.png",
    alt: "",
    category: "Nature Destination",
    location: "100+ designation",
  },
];

const ThemeDestination = () => {
  const navigate = useNavigate();

  return (
    <div className="container-lg my-5">
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="dashbaord-wrapper__header-title">
            Explore destinations by theme
          </h3>
          <div className="d-flex align-items-center gap-4">
            <div className="d-flex flex-column align-items-end">
              <p className="gray-color header_text__16">130+ Destinations</p>
              <Link href="/" className="">
                View all
              </Link>
            </div>
            <div className="mySwiper d-flex gap-3">
              <button className="arrow mr-2 mr-md-4 theme-slide-left">
                <img
                  src="/images/dashboard/trips/online/arrow-back.svg"
                  width={22}
                />
              </button>
              <button className="arrow theme-slide-right">
                <img
                  src="/images/dashboard/trips/online/arrow-forward.svg"
                  width={22}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex gap-4 mt-4">
        <Swiper
          spaceBetween={20}
          slidesPerView={5}
          navigation={{
            nextEl: ".theme-slide-right",
            prevEl: ".theme-slide-left",
          }}
          modules={[Navigation]}
          className="mySwiper"
        >
          {themeDestination.map((destination, index) => (
            <SwiperSlide key={index}>
              <div className="theme-destination-card">
                <img
                  src={destination.image}
                  alt=""
                  className="destination-images"
                />
                <h5 className="mt-2">{destination.category}</h5>
                <p className="gray-color header_text__14">
                  {destination.location}
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default ThemeDestination;
