import { getValue } from "@utils/lodash";
import React from "react";
import "../index.scss";
import { formatText } from "@common/text-helpers";
import _ from "lodash";
import moment from "moment";
import { NotepadText } from "lucide-react";
function DetailContainerDetail(props: any) {
  // console.log(JSON.stringify(getValue(props,`formInfo.expense_entry_cf`,{})))

  const expenseEntryData = getValue(props, "formInfo.expense_entry_cf", {});

  // Extract non-null key-value pairs into an array of objects
  const data = _.chain(expenseEntryData)
    .omitBy(_.isNull)
    .map((value: any, label: any) => ({ value, label }))
    .value();

  const formatDate = (inputDate: string) => {
    // const parsedDate = moment(inputDate);
    const parsedDate = moment(inputDate, ["DD-MM-YYYY", "YYYY-MM-DD"], true);
    // Check if the parsed date is valid
    if (parsedDate.isValid()) {
      // Format the date to "DD MMM YYYY" format
      const formattedDate = parsedDate.format("DD MMM YYYY");
      // console.log(formattedDate); // Output: 29 Feb 2024
      return formattedDate;
    } else {
      return inputDate;
    }
  };
  return (
    <div className="expense_detail_container_wrapper">
      {/* {getValue(props, `formInfo.description`, "") && ( */}
      {/* <h6 className="expense-more-details mb-3 mt-4">More details</h6> */}
      <div className=" border rounded-3 p-3">
        <div className="d-flex align-items-center gap-2 p-3">
          <NotepadText size={18} />
          <div className="">
            <h5 className="label_text">Description</h5>
            <p className="header_text">
              {getValue(props, `formInfo.description`, "")
                ? getValue(props, `formInfo.description`, "")
                : "-"}
            </p>
          </div>
        </div>
      </div>

      {getValue(data, `length`, 0) > 0 && (
        <div className="border-bottom border-bottom-spacing-expense-detail-wrap"></div>
      )}

      {getValue(data, `length`, 0) > 0 &&
        data.map((item: object) => {
          return (
            <div className="desc_container">
              <h5 className="label_text">{getValue(item, `label`, "")}</h5>
              <p className="header_text">
                {getValue(item, `value`, "")
                  ? formatDate(getValue(item, `value`, ""))
                  : "-"}
              </p>
            </div>
          );
        })}
      {/* )} */}

      {/*
      <div className="desc_text_container">
        <p className="header_text">Bank Remittance</p>
        <h5 className="label_text">Payment</h5>
      </div>
      <div className="desc_text_container">
        <p className="header_text">AS6687980</p>
        <h5 className="label_text">Reference #</h5>
      </div>
      <div className="desc_text_container">
        <p className="header_text">ICICI - Billable</p>
        <h5 className="label_text">Customer</h5>
      </div>
      <div className="desc_text_container">
        <p className="header_text">ICICI India Project</p>
        <h5 className="label_text">Project</h5>
      </div>
      <div className="desc_text_container">
        <p className="header_text">New Delhi</p>
        <h5 className="label_text">Location</h5>
      </div> */}
      {/* <div className="horizontal_line"></div> */}
    </div>
  );
}

export default DetailContainerDetail;
