import * as React from "react";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import { getValue } from "@utils/lodash";
import InfoSvgComponent from "@assets/svg-components/info";
import InputField from "@common/InputRuleFeild";

interface IConfirmModalProps {
  isOpen: boolean;
  handleModal: () => void;
  handleSubmit: () => void;
}
const ConfirmModal = (props: any) => {
  return (
    <Modal
      isOpen={getValue(props, `isOpen`, "")}
      //   toggle={handleModal}
    >
      <ModalHeader style={{ background: "#f8f8fd" }}>
        <div className="d-flex align-items-center w-100">
          <InfoSvgComponent size={28} color={"#FD9933"} />
          <h6 className="header_text__18 ms-2">
            {getValue(props, `submitStatus`, "") !== "reject" &&
            getValue(props, `submitStatus`, "") !== "sent-back"
              ? "Confirm"
              : getValue(props, `submitStatus`, "") === "sent-back"
              ? "Send Back to User"
              : "Reject"}
          </h6>
        </div>
      </ModalHeader>
      <div className="m-3">
        {getValue(props, `submitStatus`, "") !== "reject" && (
          <h6 className="header_text__17">
            Are you sure you want to continue?
          </h6>
        )}

        {(getValue(props, `submitStatus`, "") === "reject" ||
          getValue(props, `submitStatus`, "") === "sent-back") && (
          <div className="mt-3">
            <p className="small_text__14 mb-1">Reason *</p>
            <InputField
              inputType="TEXTAREA"
              name={"rejectComments"}
              onChange={(e: any) =>
                props.setComment(
                  e.target.value.length < 250
                    ? e.target.value
                    : getValue(props, `comment`, "")
                )
              }
              value={getValue(props, `comment`, "")}
              placeholder={"Enter Reason"}
            />
          </div>
        )}
      </div>
      <ModalFooter>
        <div className="delete-popup-wrapper__cta-wrapper">
          <button
            onClick={() => {
              props.handleModal();
            }}
            className="delete-popup-wrapper__cta-btn delete-popup-wrapper__cta-btn--secondary"
          >
            Cancel
          </button>
          <button
            className="delete-popup-wrapper__cta-btn delete-popup-wrapper__cta-btn--primary"
            onClick={props.handleSubmit}
            disabled={props.submitLoading}
          >
            {props.submitLoading ? "Please wait..." : "Submit"}
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmModal;
