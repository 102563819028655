import { getValue } from "@utils/lodash";
import React from "react";

function ViolationSvgComponent(props: any) {
  return (
    <>
      <svg
        id="report_problem_black_24dp"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        // width="48"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
      >
        <path
          id="Path_46274"
          data-name="Path 46274"
          d="M0,0H24V24H0Z"
          fill="none"
        />
        <path
          id="Path_46275"
          data-name="Path 46275"
          d="M12,5.99,19.53,19H4.47L12,5.99M12,2,1,21H23Zm1,14H11v2h2Zm0-6H11v4h2Z"
          fill={
            getValue(props, `color`, "")
              ? getValue(props, `color`, "")
              : "lightgray"
          }
        />
      </svg>
    </>
  );
}

export default ViolationSvgComponent;
